{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCStatusBar;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}

interface

uses
  Classes, SysUtils,
  {$IFNDEF LCLLIB}
  Types,
  {$IFNDEF WEBLIB}
  UITYpes,
  {$ENDIF}
  {$ENDIF}
  {$IFDEF WEBLIB}
  WEBLib.Controls, WEBLib.ExtCtrls,
  {$ENDIF}
  {$IFDEF CMNLIB}
  ExtCtrls,
  {$ENDIF}
  {$IFDEF FMXLIB}
  FMX.Types,
  {$ENDIF}
  {$IFDEF LCLLIB}
  Controls,
  {$ENDIF}
  {$IFDEF VCLLIB}
  VCL.Controls,
  {$ENDIF}
  WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCTypes, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes,
  WEBLib.TMSFNCBitmapContainer, WEBLib.TMSFNCUtils, WEBLib.TMSFNCStyles;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 2; // Release nr.
  BLD_VER = 0; // Build nr.

  //Version history
  //v1.0.0.0 : First Release
  //v1.0.0.1 : Fixed: Issue with alignment
  //v1.0.0.2 : Fixed: Issue with HTML image not drawing
  //v1.0.0.3 : Fixed : Issue with Anchor if font was changed
  //v1.0.0.4 : Fixed : Issue in Delphi 11 with begin and end scene for CreateBitmapCanvas
  //v1.0.1.0 : New : Visible property added to the panels.
  //v1.0.2.0 : New : Support for high dpi

type
  TTMSFNCCustomStatusBar = class;

  TTMSFNCStatusBarPanelStyle = (spsHTML, spsText, spsOwnerDraw, spsTime, spsDate,
    spsProgress, spsImage, spsImageList, spsEllipsText);
  TTMSFNCStatusBarVerticalPanelAlignment = (spaTop, spaCenter, spaBottom);

  TTMSFNCStatusBarPanelProgressIndication = (ppiPercentage, ppiAbsolute);

  TTMSFNCStatusBarPanelAnchor = record
    AAnchor: String;
    AIndex: Integer;
  end;

  TTMSFNCStatusBarAppearance = class(TPersistent)
  private
    FFont: TTMSFNCGraphicsFont;
    FFill: TTMSFNCGraphicsFill;
    FOnChange: TNotifyEvent;
    FStroke: TTMSFNCGraphicsStroke;
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
  protected
    procedure Changed;
    procedure FillChanged(Sender: TObject);
    procedure FontChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
  public
    constructor Create;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  published
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
  end;

  TTMSFNCStatusBarProgressAppearance = class(TPersistent)
  private
    FMax: Integer;
    FLevel1Perc: Integer;
    FShowIndication: Boolean;
    FBackgroundFill: TTMSFNCGraphicsFill;
    FMin: Integer;
    FIndication: TTMSFNCStatusBarPanelProgressIndication;
    FLevel2Fill: TTMSFNCGraphicsFill;
    FLevel3Fill: TTMSFNCGraphicsFill;
    FLevel0Fill: TTMSFNCGraphicsFill;
    FLevel1Fill: TTMSFNCGraphicsFill;
    FBorderStroke: TTMSFNCGraphicsStroke;
    FOnChange: TNotifyEvent;
    FPosition: Integer;
    FLevel2Perc: Integer;
    procedure SetBackgroundFill(const Value: TTMSFNCGraphicsFill);
    procedure SetBorderStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetIndication(const Value: TTMSFNCStatusBarPanelProgressIndication);
    procedure SetLevel0Fill(const Value: TTMSFNCGraphicsFill);
    procedure SetLevel1Fill(const Value: TTMSFNCGraphicsFill);
    procedure SetLevel1Perc(const Value: Integer);
    procedure SetLevel2Fill(const Value: TTMSFNCGraphicsFill);
    procedure SetLevel2Perc(const Value: Integer);
    procedure SetLevel3Fill(const Value: TTMSFNCGraphicsFill);
    procedure SetMax(const Value: Integer);
    procedure SetMin(const Value: Integer);
    procedure SetPosition(const Value: Integer);
    procedure SetShowIndication(const Value: Boolean);
  protected
    procedure Changed;
    procedure FillChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
  public
    constructor Create;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  published
    property BackgroundFill: TTMSFNCGraphicsFill read FBackgroundFill write SetBackgroundFill;
    property BorderStroke: TTMSFNCGraphicsStroke read FBorderStroke write SetBorderStroke;
    property Indication: TTMSFNCStatusBarPanelProgressIndication read FIndication write SetIndication default ppiPercentage;
    property Level0Fill: TTMSFNCGraphicsFill read FLevel0Fill write SetLevel0Fill;
    property Level1Fill: TTMSFNCGraphicsFill read FLevel1Fill write SetLevel1Fill;
    property Level2Fill: TTMSFNCGraphicsFill read FLevel2Fill write SetLevel2Fill;
    property Level3Fill: TTMSFNCGraphicsFill read FLevel3Fill write SetLevel3Fill;
    property Level1Perc: Integer read FLevel1Perc write SetLevel1Perc default 70;
    property Level2Perc: Integer read FLevel2Perc write SetLevel2Perc default 90;
    property Max: Integer read FMax write SetMax default 100;
    property Min: Integer read FMin write SetMin default 0;
    property Position: Integer read FPosition write SetPosition;
    property ShowIndication: Boolean read FShowIndication write SetShowIndication default True;
  end;

  TTMSFNCStatusBarPanel = class(TCollectionItem)
  private
    FEnabled: Boolean;
    FButton: Boolean;
    FTimeFormat: string;
    FWidth: Integer;
    FAlignment: TAlignment;
    FMinWidth: Integer;
    FDateFormat: string;
    FVerticalAlign: TTMSFNCStatusBarVerticalPanelAlignment;
    FText: string;
    FStyle: TTMSFNCStatusBarPanelStyle;
    FImageIndex: Integer;
    FImageCount: Integer;
    FAutoSize: Boolean;
    FHint: string;
    FProgress: TTMSFNCStatusBarProgressAppearance;
    FOffset: TTMSFNCPoint;
    FVisible: Boolean;
    procedure SetAlignment(const Value: TAlignment);
    procedure SetButton(const Value: Boolean);
    procedure SetDateFormat(const Value: string);
    procedure SetEnabled(const Value: Boolean);
    procedure SetMinWidth(const Value: Integer);
    procedure SetStyle(const Value: TTMSFNCStatusBarPanelStyle);
    procedure SetText(const Value: string);
    procedure SetTimeFormat(const Value: string);
    procedure SetVerticalAlign(const Value: TTMSFNCStatusBarVerticalPanelAlignment);
    procedure SetWidth(const Value: Integer);
    procedure SetImageIndex(const Value: Integer);
    procedure SetImageCount(const Value: Integer);
    procedure SetAutoSize(const Value: Boolean);
    procedure SetHint(const Value: string);
    procedure SetProgress(const Value: TTMSFNCStatusBarProgressAppearance);
    procedure SetOffset(const Value: TTMSFNCPoint);
    function IsDateFormatStored: Boolean;
    function IsTextStored: Boolean;
    function IsTimeFormatStored: Boolean;
    function IsHintStored: Boolean;
    procedure SetVisible(const Value: Boolean);
  protected
    procedure Update;
    procedure OffsetChanged(Sender: TObject);
    procedure ProgressChanged(Sender: TObject);
  public
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    procedure StepIt;
  published
    property AutoSize: Boolean read FAutoSize write SetAutoSize default False;
    property Text: string read FText write SetText stored IsTextStored;
    property Style: TTMSFNCStatusBarPanelStyle read FStyle write SetStyle default spsHTML;
    property MinWidth: Integer read FMinWidth write SetMinWidth default 20;
    property Enabled: Boolean read FEnabled write SetEnabled default True;
    property DateFormat: string read FDateFormat write SetDateFormat stored IsDateFormatStored;
    property TimeFormat: string read FTimeFormat write SetTimeFormat stored IsTimeFormatStored;
    property Alignment: TAlignment read FAlignment write SetAlignment default taLeftJustify;
    property ImageIndex: Integer read FImageIndex write SetImageIndex default -1;
    property ImageCount: Integer read FImageCount write SetImageCount default 1;
    property VerticalAlign: TTMSFNCStatusBarVerticalPanelAlignment read FVerticalAlign write SetVerticalAlign default spaCenter;
    property Button: Boolean read FButton write SetButton default False;
    property Width: Integer read FWidth write SetWidth;
    property Hint: string read FHint write SetHint stored IsHintStored;
    property Progress: TTMSFNCStatusBarProgressAppearance read FProgress write SetProgress;
    property Offset: TTMSFNCPoint read FOffset write SetOffset;
    property Visible: Boolean read FVisible write SetVisible default true;
  end;

  TTMSFNCStatusBarPanels = class(TCollection)
  private
    FOwner: TTMSFNCCustomStatusBar;
    FOnChange: TNotifyEvent;
    procedure SetItem(Index: integer; const Value: TTMSFNCStatusBarPanel);
    function GetItem(Index: integer): TTMSFNCStatusBarPanel;
  protected
    procedure DoChange; virtual;
    procedure Update(Item: TCollectionItem); override;
  public
    constructor Create(AOwner: TTMSFNCCustomStatusBar);
    function Add: TTMSFNCStatusBarPanel;
    property Items[Index: integer]: TTMSFNCStatusBarPanel read GetItem write SetItem;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TTMSFNCStatusBarBeforeDrawPanelEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; APanel: TTMSFNCStatusBarPanel; AAllow: Boolean; ADefaultDraw: Boolean) of object;
  TTMSFNCStatusBarAfterDrawPanelEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; APanel: TTMSFNCStatusBarPanel) of object;
  TTMSFNCStatusBarDrawCustomPanelEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; APanel: TTMSFNCStatusBarPanel) of object;
  TTMSFNCStatusBarPanelLeftClickEvent = procedure(Sender: TObject; PanelIndex: Integer) of object;
  TTMSFNCStatusBarPanelRightClickEvent = procedure(Sender: TObject; PanelIndex: Integer) of object;
  TTMSFNCStatusBarPanelAnchorClickEvent = procedure(Sender: TObject; APanel: TTMSFNCStatusBarPanel; AAnchor: string) of object;

  TTMSFNCCustomStatusBar = class(TTMSFNCCustomControl, ITMSFNCBitmapContainer)
  private
    FTimer: TTimer;
    FHoverPanelIndex: Integer;
    FDownPanelIndex: Integer;
    FBitmapContainer: TTMSFNCBitmapContainer;
    FPanels: TTMSFNCStatusBarPanels;
    FPanelPos: Integer;
    FOnDrawCustomPanel: TTMSFNCStatusBarDrawCustomPanelEvent;
    FOnPanelLeftClick: TTMSFNCStatusBarPanelLeftClickEvent;
    FOnPanelRightClick: TTMSFNCStatusBarPanelRightClickEvent;
    FOnAnchorClick: TTMSFNCStatusBarPanelAnchorClickEvent;
    FOnAfterDrawPanel: TTMSFNCStatusBarAfterDrawPanelEvent;
    FOnBeforeDrawPanel: TTMSFNCStatusBarBeforeDrawPanelEvent;
    FAppearance: TTMSFNCStatusBarAppearance;
    FShowSplitter: Boolean;
    FMouseDown: Boolean;
    FVerMargin: Integer;
    procedure SetPanels(const Value: TTMSFNCStatusBarPanels);
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    procedure SetAppearance(const Value: TTMSFNCStatusBarAppearance);
    procedure SetShowSplitter(const Value: Boolean);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
  protected
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure DoAnchorClick(APanel: TTMSFNCStatusBarPanel; AAnchor: string); virtual;
    procedure DoBeforeDrawPanel(AGraphics: TTMSFNCGraphics; ARect: TRectF; APanel: TTMSFNCStatusBarPanel; AAllow: Boolean; ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawPanel(AGraphics: TTMSFNCGraphics; ARect: TRectF; APanel: TTMSFNCStatusBarPanel); virtual;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure Draw({%H-}AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF); override;
    procedure DoAfterDraw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure HandleMouseDown({%H-}Button: TTMSFNCMouseButton; {%H-}Shift: TShiftState; {%H-}X, {%H-}Y: Single); override;
    procedure HandleMouseMove({%H-}Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseUp({%H-}Button: TTMSFNCMouseButton; {%H-}Shift: TShiftState; {%H-}X, {%H-}Y: Single); override;
    procedure HandleMouseLeave; override;
    procedure DrawAllPanels(AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF);
    procedure DrawPanel(APanel: TTMSFNCStatusBarPanel; AGraphics: TTMSFNCGraphics; ARect: TRectF);
    procedure DrawProgressBar(AGraphics: TTMSFNCGraphics; ARect: TRectF; APanel: TTMSFNCStatusBarPanel);
    procedure AutoSizePanels;
    procedure Loaded; override;
    procedure ApplyStyle; override;
    procedure ResetToDefaultStyle; override;
    procedure UpdateStatusBar;
    procedure PanelsChanged(Sender: TObject);
    procedure AppearanceChanged(Sender: TObject);
    procedure StatusBarTimer(Sender: TObject);
    function GetVersion: string; override;
    function GetHintString: string; override;
    function HasHint: Boolean; override;
    function GetLastPanelWidth: Integer;
    function GetTextWidth(AText: string; IsHTML: Boolean): Integer;
    function GetPanelIndex(AX, AY: Single): Integer;
    function XYToPanelAnchor(AX, AY: Single): TTMSFNCStatusBarPanelAnchor;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
    property Panels: TTMSFNCStatusBarPanels read FPanels write SetPanels;
    property PanelAppearance: TTMSFNCStatusBarAppearance read FAppearance write SetAppearance;
    property ShowSplitter: Boolean read FShowSplitter write SetShowSplitter default True;
    property OnDrawCustomPanel: TTMSFNCStatusBarDrawCustomPanelEvent read FOnDrawCustomPanel write FOnDrawCustomPanel;
    property OnPanelLeftClick: TTMSFNCStatusBarPanelLeftClickEvent read FOnPanelLeftClick write FOnPanelLeftClick;
    property OnPanelRightClick: TTMSFNCStatusBarPanelRightClickEvent read FOnPanelRightClick write FOnPanelRightClick;
    property OnAnchorClick: TTMSFNCStatusBarPanelAnchorClickEvent read FOnAnchorClick write FOnAnchorClick;
    property OnBeforeDrawPanel: TTMSFNCStatusBarBeforeDrawPanelEvent read FOnBeforeDrawPanel write FOnBeforeDrawPanel;
    property OnAfterDrawPanel: TTMSFNCStatusBarAfterDrawPanelEvent read FOnAfterDrawPanel write FOnAfterDrawPanel;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function XYToPanel(AX, AY: Single): TTMSFNCStatusBarPanel;
    function GetPanelRect(Index: Integer): TRectF;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCStatusBar = class(TTMSFNCCustomStatusBar)
  published
    {$IFDEF FMXLIB}
    property Align default TAlignLayout.Bottom;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    property Align default alBottom;
    {$ENDIF}
    property Panels;
    property PanelAppearance;
    property BitmapContainer;
    property ShowSplitter;
    property Fill;
    property Stroke;
    property OnDrawCustomPanel;
    property OnPanelLeftClick;
    property OnPanelRightClick;
    property OnAnchorClick;
    property OnBeforeDrawPanel;
    property OnAfterDrawPanel;
  end;

implementation

uses
  Math
  {$IFDEF VCLLIB}
  ,VCL.Graphics
  {$ENDIF}
  ;

{ TTMSFNCStatusBarPanels }

function TTMSFNCStatusBarPanels.Add: TTMSFNCStatusBarPanel;
begin
  Result := TTMSFNCStatusBarPanel(inherited Add);
end;

constructor TTMSFNCStatusBarPanels.Create(AOwner: TTMSFNCCustomStatusBar);
begin
  inherited Create(TTMSFNCStatusBarPanel);
  FOwner := AOwner;
end;

procedure TTMSFNCStatusBarPanels.DoChange;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

function TTMSFNCStatusBarPanels.GetItem(Index: integer): TTMSFNCStatusBarPanel;
begin
  Result := TTMSFNCStatusBarPanel(inherited Items[Index]);
end;

procedure TTMSFNCStatusBarPanels.SetItem(Index: integer;
  const Value: TTMSFNCStatusBarPanel);
begin
  inherited Items[Index] := Value;
end;

procedure TTMSFNCStatusBarPanels.Update(Item: TCollectionItem);
begin
  inherited;
  DoChange;
end;

{ TTMSFNCStatusBarPanel }

procedure TTMSFNCStatusBarPanel.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCStatusBarPanel then
  begin
    FText := (Source as TTMSFNCStatusBarPanel).Text;
    FStyle := (Source as TTMSFNCStatusBarPanel).Style;
    FMinWidth := (Source as TTMSFNCStatusBarPanel).MinWidth;
    FWidth := (Source as TTMSFNCStatusBarPanel).Width;
    FEnabled := (Source as TTMSFNCStatusBarPanel).Enabled;
    FDateFormat := (Source as TTMSFNCStatusBarPanel).DateFormat;
    FTimeFormat := (Source as TTMSFNCStatusBarPanel).TimeFormat;
    FAlignment := (Source as TTMSFNCStatusBarPanel).Alignment;
    FVerticalAlign := (Source as TTMSFNCStatusBarPanel).VerticalAlign;
    FButton := (Source as TTMSFNCStatusBarPanel).Button;
    FImageIndex := (Source as TTMSFNCStatusBarPanel).ImageIndex;
    FImageCount := (Source as TTMSFNCStatusBarPanel).ImageCount;
    FAutoSize := (Source as TTMSFNCStatusBarPanel).AutoSize;
    FProgress.Assign((Source as TTMSFNCStatusBarPanel).Progress);
    FOffset.Assign((Source as TTMSFNCStatusBarPanel).Offset);
  end
  else
    inherited;
end;

constructor TTMSFNCStatusBarPanel.Create(ACollection: TCollection);
begin
  inherited;
  FText := '';
  FStyle := spsHTML;
  FMinWidth := 20;
  FWidth := 50;
  FEnabled := True;
  FDateFormat := 'dd/MM/yyyy';
  FTimeFormat := 'hh:mm:ss';
  FAlignment := taLeftJustify;
  FVerticalAlign := spaCenter;
  FButton := False;
  FImageIndex := -1;
  FImageCount := 1;
  FAutoSize := False;
  FProgress := TTMSFNCStatusBarProgressAppearance.Create;
  FProgress.OnChange := @ProgressChanged;
  FOffset := TTMSFNCPoint.Create;
  FOffset.OnChange := @OffsetChanged;
  FVisible := True;
end;

destructor TTMSFNCStatusBarPanel.Destroy;
begin
  FProgress.Free;
  FOffset.Free;
  inherited;
end;

function TTMSFNCStatusBarPanel.IsDateFormatStored: Boolean;
begin
  Result := FDateFormat <> 'dd/MM/yyyy';
end;

function TTMSFNCStatusBarPanel.IsHintStored: Boolean;
begin
  Result := FHint <> '';
end;

function TTMSFNCStatusBarPanel.IsTextStored: Boolean;
begin
  Result := FText <> '';
end;

function TTMSFNCStatusBarPanel.IsTimeFormatStored: Boolean;
begin
  Result := FTimeFormat <> 'hh:mm:ss';
end;

procedure TTMSFNCStatusBarPanel.OffsetChanged(Sender: TObject);
begin
  Update;
end;

procedure TTMSFNCStatusBarPanel.ProgressChanged(Sender: TObject);
begin
  Update;
end;

procedure TTMSFNCStatusBarPanel.SetAlignment(const Value: TAlignment);
begin
  if FAlignment <> Value then
  begin
    FAlignment := Value;
    Update;
  end;
end;

procedure TTMSFNCStatusBarPanel.SetAutoSize(const Value: Boolean);
begin
  if FAutoSize <> Value then
  begin
    FAutoSize := Value;

    if AutoSize then
      if Assigned(Collection) then
        TTMSFNCStatusBarPanels(Collection).FOwner.AutoSizePanels;

    Update;
  end;
end;

procedure TTMSFNCStatusBarPanel.SetButton(const Value: Boolean);
begin
  if FButton <> Value then
  begin
    FButton := Value;
    Update;
  end;
end;

procedure TTMSFNCStatusBarPanel.SetDateFormat(const Value: string);
begin
  if FDateFormat <> Value then
  begin
    FDateFormat := Value;
    Update;
  end;
end;

procedure TTMSFNCStatusBarPanel.SetEnabled(const Value: Boolean);
begin
  if FEnabled <> Value then
  begin
    FEnabled := Value;
    Update;
  end;
end;

procedure TTMSFNCStatusBarPanel.SetHint(const Value: string);
begin
  if FHint <> Value then
    FHint := Value;
end;

procedure TTMSFNCStatusBarPanel.SetImageCount(const Value: Integer);
begin
  if FImageCount <> Value then
  begin
    FImageCount := Value;
    Update;
  end;
end;

procedure TTMSFNCStatusBarPanel.SetImageIndex(const Value: Integer);
begin
  if FImageIndex <> Value then
  begin
    FImageIndex := Value;
    Update;
  end;
end;

procedure TTMSFNCStatusBarPanel.SetMinWidth(const Value: Integer);
begin
  if FMinWidth <> Value then
  begin
    FMinWidth := Value;
    Update;
  end;
end;

procedure TTMSFNCStatusBarPanel.SetOffset(const Value: TTMSFNCPoint);
begin
  if FOffset <> Value then
  begin
    FOffset := Value;
    Update;
  end;
end;

procedure TTMSFNCStatusBarPanel.SetProgress(
  const Value: TTMSFNCStatusBarProgressAppearance);
begin
  FProgress.Assign(Value);
end;

procedure TTMSFNCStatusBarPanel.SetStyle(
  const Value: TTMSFNCStatusBarPanelStyle);
begin
  if FStyle <> Value then
  begin
    FStyle := Value;

    if FStyle = spsTime then
      FText := FormatDateTime(FTimeFormat, Now)
    else if FStyle = spsDate then
      FText := FormatDateTime(FDateFormat, Now);

    Update;
  end;
end;

procedure TTMSFNCStatusBarPanel.SetText(const Value: string);
begin
  if FText <> Value then
  begin
    FText := Value;

    if AutoSize then
      if Assigned(Collection) then
        TTMSFNCStatusBarPanels(Collection).FOwner.AutoSizePanels;

    Update;
  end;
end;

procedure TTMSFNCStatusBarPanel.SetTimeFormat(const Value: string);
begin
  if FTimeFormat <> Value then
  begin
    FTimeFormat := Value;
    Update;
  end;
end;

procedure TTMSFNCStatusBarPanel.SetVerticalAlign(
  const Value: TTMSFNCStatusBarVerticalPanelAlignment);
begin
  if FVerticalAlign <> Value then
  begin
    FVerticalAlign := Value;
    Update;
  end;
end;

procedure TTMSFNCStatusBarPanel.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    Update;
  end;
end;

procedure TTMSFNCStatusBarPanel.SetWidth(const Value: Integer);
begin
  if FWidth <> Value then
  begin
    FWidth := Value;
    Update;
  end;
end;

procedure TTMSFNCStatusBarPanel.StepIt;
begin
  if Progress.Position < Progress.Max then
    Progress.Position := Progress.Position + 1;
end;

procedure TTMSFNCStatusBarPanel.Update;
begin
  TTMSFNCStatusBarPanels(Collection).Update(Self);
end;

{ TTMSFNCCustomStatusBar }

procedure TTMSFNCCustomStatusBar.AppearanceChanged(Sender: TObject);
begin
  Invalidate;
end;

procedure TTMSFNCCustomStatusBar.ApplyStyle;
var
  c: TTMSFNCGraphicsColor;
begin
  inherited;

  c := gcNull;
  if TTMSFNCStyles.GetStyleTextFontColor(c) then
    PanelAppearance.Font.Color := c;

  c := gcNull;
  if TTMSFNCStyles.GetStyleLineFillColor(c) then
  begin
    PanelAppearance.Stroke.Color := c;
    Stroke.Color := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleAlternativeBackgroundFillColor(c) then
  begin
    PanelAppearance.Fill.Color := c;
    Fill.Color := c;
  end;
end;

procedure TTMSFNCCustomStatusBar.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCCustomStatusBar) then
  begin
    FBitmapContainer.Assign((Source as TTMSFNCCustomStatusBar).BitmapContainer);
    FPanels.Assign((Source as TTMSFNCCustomStatusBar).Panels);
    FAppearance.Assign((Source as TTMSFNCCustomStatusBar).PanelAppearance);
    FShowSplitter := (Source as TTMSFNCCustomStatusBar).ShowSplitter;
  end
  else
    inherited;
end;

procedure TTMSFNCCustomStatusBar.AutoSizePanels;
var
  I, imgw, w: Integer;
  p: TTMSFNCStatusBarPanel;
begin
  for I := 0 to Panels.Count - 1 do
  begin
    p := Panels.Items[I];
    if p.AutoSize then
    begin
      imgw := 0;
      w := 0;

      case p.Style of
        spsHTML: w := GetTextWidth(p.Text, True);
        spsText, spsDate, spsTime: w := GetTextWidth(p.Text, False);
        spsImage:
        begin
          w := GetTextWidth(p.Text, False);

          if Assigned(BitmapContainer) and (p.ImageIndex > -1) and (p.ImageIndex < BitmapContainer.ItemCount) then
          begin
            imgw := BitmapContainer.Items[p.ImageIndex].Bitmap.Width + ScalePaintValue(FVerMargin);
          end;
        end;
      end;

      p.Width := Max(imgw + w + ScalePaintValue(2 * FVerMargin), p.MinWidth);
    end;
  end;
end;

procedure TTMSFNCCustomStatusBar.ChangeDPIScale(M, D: Integer);
var
  I: Integer;
begin
  inherited;
  BeginUpdate;
  PanelAppearance.Font.Height := TTMSFNCUtils.MulDivInt(PanelAppearance.Font.Height, M, D);
  for I := 0 to Panels.Count - 1 do
  begin
    Panels.Items[I].MinWidth := TTMSFNCUtils.MulDivInt(Panels.Items[I].MinWidth, M, D);
    Panels.Items[I].Width := TTMSFNCUtils.MulDivInt(Panels.Items[I].Width, M, D);
    Panels.Items[I].Offset.X := TTMSFNCUtils.MulDivSingle(Panels.Items[I].Offset.X, M, D);
    Panels.Items[I].Offset.Y := TTMSFNCUtils.MulDivSingle(Panels.Items[I].Offset.Y, M, D);
  end;
  EndUpdate;
end;

constructor TTMSFNCCustomStatusBar.Create(AOwner: TComponent);
begin
  inherited;
  FPanels := TTMSFNCStatusBarPanels.Create(Self);
  FTimer := TTimer.Create(Self);
  FTimer.Interval := 100;
  FAppearance := TTMSFNCStatusBarAppearance.Create;
  FPanelPos := 0;
  Height := 20;
  FHoverPanelIndex := -1;
  FDownPanelIndex := -1;
  FVerMargin := 10;
  FShowSplitter := True;
  FMouseDown := False;
  {$IFDEF FMXLIB}
  Align := TAlignLayout.Bottom;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  Align := alBottom;
  {$ENDIF}

  FTimer.OnTimer := StatusBarTimer;
  FPanels.OnChange := @PanelsChanged;
  FAppearance.OnChange := @AppearanceChanged;
end;

destructor TTMSFNCCustomStatusBar.Destroy;
begin
  FPanels.Free;
  FAppearance.Free;
  inherited;
end;

procedure TTMSFNCCustomStatusBar.DoAfterDraw(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
begin
  inherited;
  AGraphics.Stroke.Assign(PanelAppearance.Stroke);
  AGraphics.Fill.Kind := gfkNone;
  AGraphics.DrawRectangle(ARect);
end;

procedure TTMSFNCCustomStatusBar.DoAfterDrawPanel(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; APanel: TTMSFNCStatusBarPanel);
begin
  if Assigned(OnAfterDrawPanel) then
    OnAfterDrawPanel(Self, AGraphics, ARect, APanel);
end;

procedure TTMSFNCCustomStatusBar.DoAnchorClick(APanel: TTMSFNCStatusBarPanel;
  AAnchor: string);
begin
  if Assigned(OnAnchorClick) then
    OnAnchorClick(Self, APanel, AAnchor)
  else
    TTMSFNCUtils.OpenURL(AAnchor);
end;

procedure TTMSFNCCustomStatusBar.DoBeforeDrawPanel(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; APanel: TTMSFNCStatusBarPanel; AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawPanel) then
    OnBeforeDrawPanel(Self, AGraphics, ARect, APanel, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomStatusBar.Draw(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
begin
  inherited;
  AGraphics.BitmapContainer := BitmapContainer;
  DrawAllPanels(AGraphics, ARect);
end;

procedure TTMSFNCCustomStatusBar.DrawAllPanels(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
var
  I, lvp: Integer;
  r: TRectF;
  b, df: Boolean;
begin
  lvp := -1;

  for I := Panels.Count - 1 downto 0 do
  begin
    if Panels.Items[I].Visible then
    begin
      lvp := I;
      Break;
    end;
  end;

  for I := 0 to Panels.Count - 1 do
  begin
    if Panels.Items[I].Visible then
    begin
      if I = lvp then
        r := RectF(FPanelPos, 0, FPanelPos + GetLastPanelWidth, Height)
      else
        r := RectF(FPanelPos, 0, FPanelPos + Panels.Items[I].Width, Height);

      b := True;
      df := True;
      DoBeforeDrawPanel(AGraphics, r, FPanels.Items[I], b, df);
      if b then
      begin
        if df then
          DrawPanel(FPanels.Items[I], AGraphics, r);

        DoAfterDrawPanel(AGraphics, r, FPanels.Items[I]);
      end;

      FPanelPos := FPanelPos + FPanels.Items[I].Width;
    end;
  end;

  FPanelPos := 0;
end;

procedure TTMSFNCCustomStatusBar.DrawPanel(APanel: TTMSFNCStatusBarPanel;
  AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  I, m, txtw, imgw, imgh: Integer;
  bmpr, txtr: TRectF;
  va, ha: TTMSFNCGraphicsTextAlign;
begin
  if APanel.Button then
  begin
    if APanel.Enabled then
    begin
      if APanel.Index = FDownPanelIndex then
        AGraphics.DrawButton(ARect, True)
      else if APanel.Index = FHoverPanelIndex then
        AGraphics.DrawButton(ARect, False, True)
      else
        AGraphics.DrawButton(ARect);
    end
    else
      AGraphics.DrawButton(ARect, False, False, False);
  end
  else
  begin
    AGraphics.Fill.Assign(PanelAppearance.Fill);
    AGraphics.Stroke.Color := PanelAppearance.Fill.Color;
    AGraphics.DrawRectangle(ARect, gcrmNone);
  end;

  if ShowSplitter then
  begin
    AGraphics.Stroke.Assign(PanelAppearance.Stroke);
    AGraphics.DrawLine(PointF(ARect.Left, ARect.Top), PointF(ARect.Left, ARect.Bottom));
  end;

  AGraphics.Font.AssignSource(PanelAppearance.Font);

  if not APanel.Enabled then
    AGraphics.Font.Color := gcSilver
  else
    AGraphics.Font.Color := PanelAppearance.Font.Color;

  txtr := ARect;
  txtr.Right := txtr.Right - APanel.Offset.X;
  txtr.Bottom := txtr.Bottom - APanel.Offset.Y;
  OffsetRectEx(txtr, APanel.Offset.X, APanel.Offset.Y);

  ha := gtaCenter;
  va := gtaCenter;

  case APanel.Alignment of
    taLeftJustify: ha := gtaLeading;
    taRightJustify: ha := gtaTrailing;
    taCenter: ha := gtaCenter;
  end;

  case APanel.VerticalAlign of
    spaTop: va := gtaLeading;
    spaCenter: va := gtaCenter;
    spaBottom: va := gtaTrailing;
  end;

  case APanel.Style of
    spsText:
    begin
      InflateRectEx(txtr, -ScalePaintValue(10), 0);
      AGraphics.DrawText(txtr, APanel.Text, False, ha, va, gttNone, 0, -1, -1, False);
    end;
    spsHTML:
    begin
      InflateRectEx(txtr, -ScalePaintValue(10), 0);
      AGraphics.DrawText(txtr, APanel.Text, False, ha, va, gttNone, 0, -1, -1, True);
    end;
    spsOwnerDraw:
    begin
      if Assigned(OnDrawCustomPanel) then
        OnDrawCustomPanel(Self, AGraphics, ARect, APanel);
    end;
    spsProgress:
    begin
      DrawProgressBar(AGraphics, ARect, APanel);
    end;
    spsImage:
    begin
      if Assigned(BitmapContainer) then
      begin
        if APanel.ImageIndex <> -1 then
        begin
          imgw := BitmapContainer.Bitmaps[APanel.ImageIndex].Width;
          imgh := BitmapContainer.Bitmaps[APanel.ImageIndex].Height;
          bmpr := ARect;
          bmpr.Right := bmpr.Left + imgw;

          txtw := 0;
          if APanel.Text <> '' then
          begin
            txtr := ARect;
            txtr.Right := ARect.Right - imgw - ScalePaintValue(FVerMargin);
            OffsetRectEx(txtr, imgw + ScalePaintValue(FVerMargin), 0);
            txtw := Round(AGraphics.CalculateTextWidth(APanel.Text, txtr, False, False));
            txtr.Right := txtr.Right  - APanel.Offset.X;
            txtr.Bottom := txtr.Bottom - APanel.Offset.Y;
            OffsetRectEx(txtr, APanel.Offset.X, APanel.Offset.Y);
            InflateRectEx(txtr, -ScalePaintValue(FVerMargin), 0);

            AGraphics.DrawText(txtr, APanel.Text, False, ha, va, gttNone, 0, -1, -1, False);
          end;

          case ha of
            gtaCenter: bmpr.Left := ARect.Left + (ARect.Right - ARect.Left - txtw) / 2 - imgw;
            gtaLeading: bmpr.Left := ARect.Left + ScalePaintValue(FVerMargin);
            gtaTrailing: bmpr.Left := ARect.Right - imgw - ScalePaintValue(2 * FVerMargin)- txtw;
          end;

          case va of
            gtaCenter: bmpr.Top := (Height - imgh) / 2;
            gtaLeading: bmpr.Top := 1;
            gtaTrailing: bmpr.Top := Height - imgh;
          end;

          AGraphics.DrawBitmap(bmpr.Left, bmpr.Top, BitmapContainer.Bitmaps[APanel.ImageIndex]);
        end;
      end;
    end;
    spsImageList:
    begin
      if Assigned(BitmapContainer) then
      begin
        if (APanel.ImageIndex > -1) and (APanel.ImageIndex < BitmapContainer.ItemCount) then
        begin
          imgw := BitmapContainer.Bitmaps[APanel.ImageIndex].Width;
          imgh := BitmapContainer.Bitmaps[APanel.ImageIndex].Height;
          bmpr := ARect;
          bmpr.Right := bmpr.Left + imgw;
          OffsetRectEx(bmpr, ScalePaintValue(FVerMargin), 0);

          case va of
            gtaCenter: bmpr.Top := (Height - imgh) / 2;
            gtaLeading: bmpr.Top := 1;
            gtaTrailing: bmpr.Top := Height - imgh;
          end;

          m := Min(APanel.ImageIndex + APanel.ImageCount, BitmapContainer.ItemCount);
          for I := APanel.ImageIndex to m - 1 do
          begin
            AGraphics.DrawBitmap(bmpr.Left, bmpr.Top, BitmapContainer.Bitmaps[I]);

            OffsetRectEx(bmpr, imgw, 0);
          end;
        end;
      end;
    end;
    spsEllipsText, spsTime, spsDate:
    begin
      InflateRectEx(txtr, -ScalePaintValue(FVerMargin), 0);
      AGraphics.DrawText(txtr, APanel.Text, False, ha, va, gttCharacter, 0, -1, -1, False);
    end;
  end;
end;

procedure TTMSFNCCustomStatusBar.DrawProgressBar(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; APanel: TTMSFNCStatusBarPanel);
var
  perc: Integer;
  w: Single;
  r: TRectF;
begin
  InflateRectEx(ARect, -ScalePaintValue(2), -ScalePaintValue(2));
  AGraphics.Fill.Assign(APanel.Progress.BackgroundFill);
  AGraphics.Font.AssignSource(PanelAppearance.Font);
  AGraphics.Stroke.Color := gcNull;
  AGraphics.DrawRectangle(ARect);

  perc := Round(APanel.Progress.Position / APanel.Progress.Max * 100);
  if perc <= APanel.Progress.Level1Perc then
  begin
    AGraphics.Fill.Assign(APanel.Progress.Level0Fill);
  end
  else if (perc > APanel.Progress.Level1Perc) and (perc <= APanel.Progress.Level2Perc) then
  begin
    AGraphics.Fill.Assign(APanel.Progress.Level1Fill);
  end
  else if (perc > APanel.Progress.Level2Perc) and (perc < APanel.Progress.Max) then
  begin
    AGraphics.Fill.Assign(APanel.Progress.Level2Fill);
  end
  else
  begin
    AGraphics.Fill.Assign(APanel.Progress.Level3Fill);
  end;

  r := ARect;
  w := perc / 100 * (r.Right - r.Left);
  r.Right := r.Left + w;
  AGraphics.DrawRectangle(r, gcrmNone);

  if APanel.Progress.ShowIndication then
  begin
    case APanel.Progress.Indication of
      ppiPercentage: AGraphics.DrawText(ARect, IntToStr(perc) + '%', False, gtaCenter, gtaCenter);
      ppiAbsolute: AGraphics.DrawText(ARect, IntToStr(APanel.Progress.Position), False, gtaCenter, gtaCenter);
    end;
  end;

  AGraphics.Stroke.Assign(APanel.Progress.BorderStroke);
  AGraphics.Fill.Color := gcNull;
  AGraphics.DrawRectangle(ARect);
end;

function TTMSFNCCustomStatusBar.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FBitmapContainer;
end;

function TTMSFNCCustomStatusBar.GetHintString: string;
var
  p: TTMSFNCStatusBarPanel;
begin
  Result := inherited GetHintString;
  if FHoverPanelIndex <> -1 then
  begin
    p := FPanels.Items[FHoverPanelIndex];
    Result := p.Hint;
  end;
end;

function TTMSFNCCustomStatusBar.GetLastPanelWidth: Integer;
var
  w, I, lvp: Integer;
begin

  lvp := -1;
  for I := Panels.Count - 1 downto 0 do
  begin
    if Panels.Items[I].Visible then
    begin
      lvp := I;
      Break;
    end;
  end;

  w := 0;
  for I := 0 to Panels.Count - 1 do
  begin
    if Panels.Items[I].Visible then
    begin
      if (w + ScalePaintValue(2 * FVerMargin)) > Width then
      begin
        Result := ScalePaintValue(2 * FVerMargin);
        Exit;
      end;

      if I = lvp then
      begin
        Result := Round(Width) - w;
        Exit;
      end;
      w := w + Panels.Items[I].Width;
    end;
  end;
  Result := Round(Width) - w;
end;

function TTMSFNCCustomStatusBar.GetPanelIndex(AX, AY: Single): Integer;
var
  I, pos, w, lvi: Integer;

begin
  Result := -1;
  if (AY > Height) or (AY < 0) then
    Exit;

  pos := 0;
  lvi := -1;

  for I := Panels.Count - 1 downto 0 do
  begin
    if Panels.Items[I].Visible then
    begin
      lvi := I;
      Break;
    end;
  end;

  for I := 0 to Panels.Count - 1 do
  begin
      if Panels.Items[I].Visible then
      begin
      if I = lvi then
        w := GetLastPanelWidth
      else
        w := Panels.Items[I].Width;

      if AX <= (pos + w) then
      begin
        Result := I;
        Exit;
      end;

      pos := pos + w;
    end;
  end;
end;

function TTMSFNCCustomStatusBar.GetPanelRect(Index: Integer): TRectF;
var
  I, pos: Integer;
  r: TRectF;
begin
  r := RectF(-1, -1, -1, -1);
  pos := 0;

  for I := 0 to Index - 1 do
  begin
    if Panels.Items[I].Visible then
      pos := pos + Panels.Items[I].Width;
  end;

  if (Index >= 0) and (Index <= Panels.Count - 1) and Panels.Items[Index].Visible then
  begin
    r.Left := pos;
    r.Top := 0;
    r.Bottom := Height;

      r.Right := r.Left;
  end;

  Result := r;
end;

function TTMSFNCCustomStatusBar.GetTextWidth(AText: string; IsHTML: Boolean): Integer;
var
  g: TTMSFNCGraphics;
begin
  g := TTMSFNCGraphics.CreateBitmapCanvas;
  g.Font.AssignSource(PanelAppearance.Font);
  g.BeginScene;
  try
    if IsHTML then
      Result := Round(g.CalculateTextWidth(AText))
    else
      Result := Round(g.CalculateTextWidth(AText, RectF(0, 0, 10000, 10000), False, False));
  finally
    g.EndScene;
    g.Free;
  end;
end;

function TTMSFNCCustomStatusBar.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCCustomStatusBar.HandleMouseDown(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
var
  pind: Integer;
begin
  inherited;
  CaptureEx;
  FMouseDown := True;
  pind := GetPanelIndex(X, Y);

  if pind <> -1 then
  begin
    FDownPanelIndex := pind;

    if Assigned(OnPanelLeftClick) and (Button = {$IFNDEF WEBLIB}TTMSFNCMouseButton.{$ENDIF}mbLeft) then
      OnPanelLeftClick(Self, pind);

    if Assigned(OnPanelRightClick) and (Button = {$IFNDEF WEBLIB}TTMSFNCMouseButton.{$ENDIF}mbRight) then
      OnPanelRightClick(Self, pind);
  end;

  Invalidate;
end;

procedure TTMSFNCCustomStatusBar.HandleMouseLeave;
begin
  inherited;
  FHoverPanelIndex := -1;
  Invalidate;
end;

procedure TTMSFNCCustomStatusBar.HandleMouseMove(Shift: TShiftState; X,
  Y: Single);
var
  pa: TTMSFNCStatusBarPanelAnchor;
begin
  inherited;
  pa := XYToPanelAnchor(X, Y);

  if pa.AAnchor <> '' then
    Cursor := crHandPoint
  else
    Cursor := crDefault;

  if FHoverPanelIndex <> pa.AIndex then
  begin
    FHoverPanelIndex := pa.AIndex;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomStatusBar.HandleMouseUp(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
var
  I: Integer;
  str: string;
begin
  inherited;
  str := XYToPanelAnchor(X, Y).AAnchor;
  if str <> '' then
  begin
    I := GetPanelIndex(X, Y);
    DoAnchorClick(Panels.Items[I], str);
  end;
  FMouseDown := False;
  FDownPanelIndex := -1;
  ReleaseCaptureEx;
  Invalidate;
end;

function TTMSFNCCustomStatusBar.HasHint: Boolean;
begin
  Result := False;

  if FHoverPanelIndex <> -1 then
    Result := FPanels.Items[FHoverPanelIndex].Hint <> '';
end;

procedure TTMSFNCCustomStatusBar.Loaded;
begin
  inherited;
  AutoSizePanels;
end;

procedure TTMSFNCCustomStatusBar.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;

  if (Operation = opRemove) and (AComponent = FBitmapContainer) then
    FBitmapContainer := nil;
end;

procedure TTMSFNCCustomStatusBar.PanelsChanged(Sender: TObject);
begin
  if (FPanels.UpdateCount > 0) or (csLoading in ComponentState) then
    Exit;

  Invalidate;
end;

procedure TTMSFNCCustomStatusBar.ResetToDefaultStyle;
begin
  inherited;
  PanelAppearance.Fill.Color := gcWhite;
  PanelAppearance.Stroke.Color := gcSilver;
  PanelAppearance.Font.Color := gcBlack;

  Fill.Color := gcWhite;
  Stroke.Color := gcSilver;
end;

procedure TTMSFNCCustomStatusBar.SetAppearance(
  const Value: TTMSFNCStatusBarAppearance);
begin
  FAppearance.Assign(Value);
end;

procedure TTMSFNCCustomStatusBar.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
begin
  FBitmapContainer := Value;
  Invalidate;
end;

procedure TTMSFNCCustomStatusBar.SetPanels(const Value: TTMSFNCStatusBarPanels);
begin
  FPanels.Assign(Value);
end;

procedure TTMSFNCCustomStatusBar.SetShowSplitter(const Value: Boolean);
begin
  if FShowSplitter <> Value then
  begin
    FShowSplitter := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomStatusBar.StatusBarTimer(Sender: TObject);
begin
  UpdateStatusBar;
end;

procedure TTMSFNCCustomStatusBar.UpdateStatusBar;
var
  I: Integer;
begin
  for I := 0 to Panels.Count - 1 do
  begin
    case Panels.Items[I].Style of
      spsDate: Panels.Items[I].Text := FormatDateTime(Panels.Items[I].DateFormat, Now);
      spsTime: Panels.Items[I].Text := FormatDateTime(Panels.Items[I].TimeFormat, Now);
    end;
  end;
end;

function TTMSFNCCustomStatusBar.XYToPanelAnchor(AX, AY: Single): TTMSFNCStatusBarPanelAnchor;
var
  pnlr: TRectF;
  I: Integer;
  p: TTMSFNCStatusBarPanel;
  va, ha: TTMSFNCGraphicsTextAlign;
  str, a: string;
  g: TTMSFNCGraphics;
begin
  Result.AAnchor := '';
  I := GetPanelIndex(AX, AY);
  Result.AIndex := I;
  if I <> -1 then
  begin
    p := Panels.Items[I];
    if p.Style = spsHTML then
    begin
      pnlr := GetPanelRect(I);
      if I = Panels.Count - 1 then
        pnlr.Right := pnlr.Left + GetLastPanelWidth;

      InflateRectEx(pnlr, -ScalePaintValue(FVerMargin), 0);
      pnlr.Right := pnlr.Right - p.Offset.X;
      pnlr.Bottom := pnlr.Bottom - p.Offset.Y;
      OffsetRectEx(pnlr, p.Offset.X, p.Offset.Y);

      ha := gtaLeading;
      va := gtaCenter;

      case p.Alignment of
        taLeftJustify: ha := gtaLeading;
        taRightJustify: ha := gtaTrailing;
        taCenter: ha := gtaCenter;
      end;

      case p.VerticalAlign of
        spaTop: va := gtaLeading;
        spaCenter: va := gtaCenter;
        spaBottom: va := gtaTrailing;
      end;

      str := p.Text;
      a := '';
      g := TTMSFNCGraphics.CreateBitmapCanvas;
      g.BeginScene;
      g.BitmapContainer := BitmapContainer;
      g.Font.AssignSource(PanelAppearance.Font);
      try
        a := g.DrawText(pnlr, str, False, ha, va, gttNone, 0, -1, -1, True, True, AX, AY);
      finally
        g.EndScene;
        g.Free;
      end;

      Result.AAnchor := a;
    end;
  end;
end;

function TTMSFNCCustomStatusBar.XYToPanel(AX, AY: Single): TTMSFNCStatusBarPanel;
var
  I: Integer;
begin
  Result := nil;
  I := GetPanelIndex(AX, AY);
  if I <> -1 then
    Result := Panels.Items[I];
end;

{ TTMSFNCStatusBarAppearance }

procedure TTMSFNCStatusBarAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCStatusBarAppearance then
  begin
    FFill.Assign((Source as TTMSFNCStatusBarAppearance).Fill);
    FStroke.Assign((Source as TTMSFNCStatusBarAppearance).Stroke);
    FFont.AssignSource((Source as TTMSFNCStatusBarAppearance).Font);
  end
  else
    inherited;
end;

procedure TTMSFNCStatusBarAppearance.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCStatusBarAppearance.Create;
begin
  FFont := TTMSFNCGraphicsFont.Create;
  FFill := TTMSFNCGraphicsFill.Create;
  FStroke := TTMSFNCGraphicsStroke.Create;

  FFont.OnChanged := @FontChanged;
  FFill.OnChanged := @FillChanged;
  FStroke.OnChanged := @StrokeChanged;
end;

destructor TTMSFNCStatusBarAppearance.Destroy;
begin
  FFont.Free;
  FFill.Free;
  FStroke.Free;
  inherited;
end;

procedure TTMSFNCStatusBarAppearance.FillChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCStatusBarAppearance.FontChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCStatusBarAppearance.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  FFill.Assign(Value);
end;

procedure TTMSFNCStatusBarAppearance.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  FFont.AssignSource(Value);
end;

procedure TTMSFNCStatusBarAppearance.SetStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FStroke.Assign(Value);
end;

procedure TTMSFNCStatusBarAppearance.StrokeChanged(Sender: TObject);
begin
  Changed;
end;

{ TTMSFNCStatusBarProgressAppearance }

procedure TTMSFNCStatusBarProgressAppearance.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCStatusBarProgressAppearance) then
  begin
    FLevel0Fill.Assign((Source as TTMSFNCStatusBarProgressAppearance).Level0Fill);
    FLevel1Fill.Assign((Source as TTMSFNCStatusBarProgressAppearance).Level1Fill);
    FLevel2Fill.Assign((Source as TTMSFNCStatusBarProgressAppearance).Level2Fill);
    FLevel3Fill.Assign((Source as TTMSFNCStatusBarProgressAppearance).Level3Fill);
    FBackgroundFill.Assign((Source as TTMSFNCStatusBarProgressAppearance).BackgroundFill);
    FBorderStroke.Assign((Source as TTMSFNCStatusBarProgressAppearance).BorderStroke);
    FLevel1Perc := (Source as TTMSFNCStatusBarProgressAppearance).Level1Perc;
    FLevel2Perc := (Source as TTMSFNCStatusBarProgressAppearance).Level2Perc;
    FIndication := (Source as TTMSFNCStatusBarProgressAppearance).Indication;
    FMin := (Source as TTMSFNCStatusBarProgressAppearance).Min;
    FMax := (Source as TTMSFNCStatusBarProgressAppearance).Max;
    FPosition := (Source as TTMSFNCStatusBarProgressAppearance).Position;
    FShowIndication := (Source as TTMSFNCStatusBarProgressAppearance).ShowIndication;
  end
  else
    inherited;
end;

procedure TTMSFNCStatusBarProgressAppearance.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCStatusBarProgressAppearance.Create;
begin
  FLevel0Fill := TTMSFNCGraphicsFill.Create;
  FLevel1Fill := TTMSFNCGraphicsFill.Create;
  FLevel2Fill := TTMSFNCGraphicsFill.Create;
  FLevel3Fill := TTMSFNCGraphicsFill.Create;
  FBackgroundFill := TTMSFNCGraphicsFill.Create;
  FBorderStroke := TTMSFNCGraphicsStroke.Create;
  FIndication := ppiPercentage;
  FMin := 0;
  FMax := 100;
  FPosition := 0;
  FLevel1Perc := 70;
  FLevel2Perc := 90;
  FShowIndication := True;

  FLevel0Fill.OnChanged := @FillChanged;
  FLevel1Fill.OnChanged := @FillChanged;
  FLevel2Fill.OnChanged := @FillChanged;
  FLevel3Fill.OnChanged := @FillChanged;
  FBackgroundFill.OnChanged := @FillChanged;
  FBorderStroke.OnChanged := @StrokeChanged;

  FBackgroundFill.Color := gcWhite;
  FBorderStroke.Color := gcSilver;
  FLevel0Fill.Color := gcLime;
  FLevel1Fill.Color := gcYellow;
  FLevel2Fill.Color := gcOrange;
  FLevel3Fill.Color := gcRed;
end;

destructor TTMSFNCStatusBarProgressAppearance.Destroy;
begin
  FLevel0Fill.Free;
  FLevel1Fill.Free;
  FLevel2Fill.Free;
  FLevel3Fill.Free;
  FBackgroundFill.Free;
  FBorderStroke.Free;
  inherited;
end;

procedure TTMSFNCStatusBarProgressAppearance.FillChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCStatusBarProgressAppearance.SetBackgroundFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FBackgroundFill.Assign(Value);
end;

procedure TTMSFNCStatusBarProgressAppearance.SetBorderStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FBorderStroke.Assign(Value);
end;

procedure TTMSFNCStatusBarProgressAppearance.SetIndication(
  const Value: TTMSFNCStatusBarPanelProgressIndication);
begin
  if FIndication <> Value then
  begin
    FIndication := Value;
    Changed;
  end;
end;

procedure TTMSFNCStatusBarProgressAppearance.SetLevel0Fill(
  const Value: TTMSFNCGraphicsFill);
begin
  FLevel0Fill.Assign(Value);
end;

procedure TTMSFNCStatusBarProgressAppearance.SetLevel1Fill(
  const Value: TTMSFNCGraphicsFill);
begin
  FLevel1Fill.Assign(Value);
end;

procedure TTMSFNCStatusBarProgressAppearance.SetLevel1Perc(
  const Value: Integer);
begin
  if FLevel1Perc <> Value then
  begin
    FLevel1Perc := Value;
    Changed;
  end;
end;

procedure TTMSFNCStatusBarProgressAppearance.SetLevel2Fill(
  const Value: TTMSFNCGraphicsFill);
begin
  FLevel2Fill.Assign(Value);
end;

procedure TTMSFNCStatusBarProgressAppearance.SetLevel2Perc(
  const Value: Integer);
begin
  if FLevel2Perc <> Value then
  begin
    FLevel2Perc := Value;
    Changed;
  end;
end;

procedure TTMSFNCStatusBarProgressAppearance.SetLevel3Fill(
  const Value: TTMSFNCGraphicsFill);
begin
  FLevel3Fill.Assign(Value);
end;

procedure TTMSFNCStatusBarProgressAppearance.SetMax(const Value: Integer);
begin
  if FMax <> Value then
  begin
    FMax := Value;
    Changed;
  end;
end;

procedure TTMSFNCStatusBarProgressAppearance.SetMin(const Value: Integer);
begin
  if FMin <> Value then
  begin
    FMin := Value;
    Changed;
  end;
end;

procedure TTMSFNCStatusBarProgressAppearance.SetPosition(const Value: Integer);
begin
  if FPosition <> Value then
  begin
    FPosition := Value;
    Changed;
  end;
end;

procedure TTMSFNCStatusBarProgressAppearance.SetShowIndication(
  const Value: Boolean);
begin
  if FShowIndication <> Value then
  begin
    FShowIndication := Value;
    Changed;
  end;
end;

procedure TTMSFNCStatusBarProgressAppearance.StrokeChanged(Sender: TObject);
begin
  Changed;
end;

end.

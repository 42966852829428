unit KORRLISTAN;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, Vcl.Controls,
  VCL.TMSFNCCustomControl, VCL.TMSFNCPanel, VCL.TMSFNCGridCell,
  VCL.TMSFNCGridOptions, VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData,
  VCL.TMSFNCCustomGrid, VCL.TMSFNCGrid, WEBLib.REST, VCL.TMSFNCToolBar,
  Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.ExtCtrls;

type
  TForm5 = class(TForm)
    TMSFNCPanel1: TTMSFNCPanel;
    TMSFNCGrid1: TTMSFNCGrid;
    WebHttpRequest1: THttpRequest;
    procedure KORRIG_REF;
    procedure INLASNING;
    procedure WebFormCreate(Sender: TObject);
    procedure TMSFNCGrid1DblClick(Sender: TObject);
    procedure WebHttpRequest1RequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
    procedure TMSFNCGrid1GetCellLayout(Sender: TObject; ACol, ARow: Integer; ALayout: TTMSFNCGridCellLayout; ACellState: TTMSFNCGridCellState);
    procedure KEYTEST(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure TANGENTER(Sender: TObject; var Key: Char);
    procedure AVSLUTA(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form5: TForm5;

implementation

Uses Front, KORREFERENS;

{$R *.dfm}

Function TOKEN(aString,SepChar: String; TokenNum: Byte): String;
  Var
    retvar: String;
    StrLen,Num,EndofToken: Integer;
  Begin
    StrLen:=Length(aString);
    Num:=1;
    EndofToken:=StrLen;
    While ((Num<=TokenNum) And (EndofToken<>0)) Do
      Begin
        EndofToken:=Pos(SepChar,aString);
        If EndofToken<>0 Then
          Begin
            retvar:=Copy(aString,1,EndofToken-1);
            Delete(aString,1,EndofToken);
            Inc(Num);
          End
        Else
          retvar:=aString;
      End;
    If Num>=TokenNum Then
      Result:=retvar
    Else
      Result:='';
  End;

procedure TForm5.KORRIG_REF;
  Var
    newform: TForm;
    y: Integer;

  procedure AfterShowModal(AValue: TModalResult);
    Begin
      If Length(Form1.PARAM_P)>0 Then
        Begin
          TMSFNCGrid1.Cells[0,y]:=' '+Form1.PARAM_P;
          Form1.PARAM_P:='';
        End;

      TMSFNCGrid1.SetFocus;
    End;

  procedure AfterCreate(AForm: TObject);
    Begin
     (AForm as TForm8).Left:=Form1.Width Div 2 - 340;
     (AForm as TForm8).Top:=Form1.Height Div 2 - 32;
    End;

  Begin
    y:=TMSFNCGrid1.FocusedCell.Row;

    Form1.PARAM_P:=Trim(TMSFNCGrid1.Cells[0,y]);
    Form1.PARAM_R:=IntToStr(y);

    newform := TForm8.CreateNew(@AfterCreate);
    newform.Popup := True;
    newform.ShowModal(@AfterShowModal);
  End;

procedure TForm5.INLASNING;
  Var wp,path: String;
  Begin
    wp:='https://www.connectsystem.se/';
    path:=wp+'dsidrefkunder.php';

    WebHttpRequest1.URL:=path;
    WebHttpRequest1.Execute;
  End;

procedure TForm5.WebFormCreate(Sender: TObject);
  Begin
    INLASNING;
  End;

procedure TForm5.TMSFNCGrid1DblClick(Sender: TObject);
  Begin
    KORRIG_REF;
  End;

procedure TForm5.WebHttpRequest1RequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
  Var
    remsa,kund: String;
    y,i: Integer;
  Begin
    remsa:=AResponse;

    TMSFNCGrid1.Clear;
    TMSFNCGrid1.RowCount:=2;

    y:=-1;
    For i:=1 To 50 Do
      Begin
        kund:=TOKEN(remsa,';',i);
        If Length(kund)>0 Then
          Begin
            y:=y+1;
            If y>1 Then
              TMSFNCGrid1.RowCount:=TMSFNCGrid1.RowCount+1;

            TMSFNCGrid1.Cells[0,y]:=' '+Trim(kund);
          End;
      End;

    TMSFNCGrid1.Selection:=MakeCellRange(0,0,0,0);
    TMSFNCGrid1.Resize;

    TMSFNCGrid1.SetFocus;
  End;

procedure TForm5.TMSFNCGrid1GetCellLayout(Sender: TObject; ACol, ARow: Integer; ALayout: TTMSFNCGridCellLayout; ACellState: TTMSFNCGridCellState);
  Begin
    ALayout.Font.Color:=clBlack;
    ALayout.Font.Size:=11;
  End;

procedure TForm5.KEYTEST(Sender: TObject; var Key: Word; Shift: TShiftState);
  Begin
    If Key=27 Then
      Close
  End;

procedure TForm5.TANGENTER(Sender: TObject; var Key: Char);
  Begin
    If Ord(Key)=13 Then
      Begin
        Key:=#0;

        KORRIG_REF;
      End;
  End;

procedure TForm5.AVSLUTA(Sender: TObject);
  Begin
    Close;
  End;

procedure TForm5.LoadDFMValues;
begin
  inherited LoadDFMValues;

  TMSFNCPanel1 := TTMSFNCPanel.Create(Self);
  TMSFNCGrid1 := TTMSFNCGrid.Create(Self);
  WebHttpRequest1 := THttpRequest.Create(Self);

  TMSFNCPanel1.BeforeLoadDFMValues;
  TMSFNCGrid1.BeforeLoadDFMValues;
  WebHttpRequest1.BeforeLoadDFMValues;
  try
    Name := 'Form5';
    Width := 234;
    Height := 496;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnKeyDown', 'KEYTEST');
    TMSFNCPanel1.SetParentComponent(Self);
    TMSFNCPanel1.Name := 'TMSFNCPanel1';
    TMSFNCPanel1.Left := 0;
    TMSFNCPanel1.Top := 0;
    TMSFNCPanel1.Width := 234;
    TMSFNCPanel1.Height := 30;
    TMSFNCPanel1.Align := alTop;
    TMSFNCPanel1.BevelInner := bvNone;
    TMSFNCPanel1.BevelOuter := bvNone;
    TMSFNCPanel1.ParentDoubleBuffered := False;
    TMSFNCPanel1.DoubleBuffered := True;
    TMSFNCPanel1.TabOrder := 0;
    SetEvent(TMSFNCPanel1, Self, 'OnKeyDown', 'KEYTEST');
    TMSFNCPanel1.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel1.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel1.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel1.SectionsAppearance.Font.Style := [];
    TMSFNCPanel1.SectionsAppearance.Fill.Color := 15790320;
    TMSFNCPanel1.Header.Text := ' References';
    TMSFNCPanel1.Header.HorizontalTextAlign := gtaLeading;
    TMSFNCPanel1.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Header.Font.Color := clWindowText;
    TMSFNCPanel1.Header.Font.Height := -11;
    TMSFNCPanel1.Header.Font.Name := 'Tahoma';
    TMSFNCPanel1.Header.Font.Style := [];
    TMSFNCPanel1.Header.Fill.Color := clBtnFace;
    TMSFNCPanel1.Header.Buttons := [pbClose];
    TMSFNCPanel1.Footer.Text := 'Footer';
    TMSFNCPanel1.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Footer.Font.Color := clWindowText;
    TMSFNCPanel1.Footer.Font.Height := -11;
    TMSFNCPanel1.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel1.Footer.Font.Style := [];
    TMSFNCPanel1.Footer.Fill.Color := 15000804;
    SetEvent(TMSFNCPanel1, Self, 'OnHeaderCloseButtonClick', 'AVSLUTA');
    TMSFNCGrid1.SetParentComponent(Self);
    TMSFNCGrid1.Name := 'TMSFNCGrid1';
    TMSFNCGrid1.Left := 0;
    TMSFNCGrid1.Top := 30;
    TMSFNCGrid1.Width := 234;
    TMSFNCGrid1.Height := 466;
    TMSFNCGrid1.Align := alClient;
    TMSFNCGrid1.BevelInner := bvNone;
    TMSFNCGrid1.BevelOuter := bvNone;
    TMSFNCGrid1.ParentDoubleBuffered := False;
    TMSFNCGrid1.DoubleBuffered := True;
    TMSFNCGrid1.Enabled := False;
    TMSFNCGrid1.TabOrder := 1;
    SetEvent(TMSFNCGrid1, Self, 'OnDblClick', 'TMSFNCGrid1DblClick');
    SetEvent(TMSFNCGrid1, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(TMSFNCGrid1, Self, 'OnKeyPress', 'TANGENTER');
    TMSFNCGrid1.DefaultRowHeight := 22.000000000000000000;
    TMSFNCGrid1.DefaultColumnWidth := 228.000000000000000000;
    TMSFNCGrid1.FixedColumns := 0;
    TMSFNCGrid1.FixedRows := 0;
    TMSFNCGrid1.ColumnCount := 1;
    TMSFNCGrid1.RowCount := 1;
    TMSFNCGrid1.Options.Borders.FixedCellBorders := bNone;
    TMSFNCGrid1.Options.Borders.CellBorders := bNone;
    TMSFNCGrid1.Options.Editing.CalcFormat := '%g';
    TMSFNCGrid1.Options.Editing.Enabled := False;
    TMSFNCGrid1.Options.Grouping.CalcFormat := '%g';
    TMSFNCGrid1.Options.Grouping.GroupCountFormat := '(%d)';
    TMSFNCGrid1.Options.IO.XMLEncoding := 'ISO-8859-1';
    TMSFNCGrid1.Options.Mouse.ClickMargin := 0;
    TMSFNCGrid1.Options.Mouse.ColumnSizeMargin := 6;
    TMSFNCGrid1.Options.Mouse.RowSizeMargin := 6;
    TMSFNCGrid1.Options.Selection.Mode := smSingleRow;
    TMSFNCGrid1.HorizontalScrollBarVisible := False;
    TMSFNCGrid1.VerticalScrollBarVisible := False;
    TMSFNCGrid1.Columns.Clear;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 228.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 228.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 228.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 228.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 228.000000000000000000;
    end;
    TMSFNCGrid1.DefaultFont.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.DefaultFont.Color := clWindowText;
    TMSFNCGrid1.DefaultFont.Height := -11;
    TMSFNCGrid1.DefaultFont.Name := 'Tahoma';
    TMSFNCGrid1.DefaultFont.Style := [];
    TMSFNCGrid1.TopRow := 0;
    TMSFNCGrid1.Appearance.FixedLayout.Fill.Color := -1;
    TMSFNCGrid1.Appearance.FixedLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.FixedLayout.Font.Color := clWindowText;
    TMSFNCGrid1.Appearance.FixedLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.FixedLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.FixedLayout.Font.Style := [fsBold];
    TMSFNCGrid1.Appearance.NormalLayout.Fill.Color := -1;
    TMSFNCGrid1.Appearance.NormalLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.NormalLayout.Font.Color := clWindowText;
    TMSFNCGrid1.Appearance.NormalLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.NormalLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.NormalLayout.Font.Style := [];
    TMSFNCGrid1.Appearance.GroupLayout.Fill.Color := -1;
    TMSFNCGrid1.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.GroupLayout.Font.Color := clWhite;
    TMSFNCGrid1.Appearance.GroupLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.GroupLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.GroupLayout.Font.Style := [];
    TMSFNCGrid1.Appearance.SummaryLayout.Fill.Color := -1;
    TMSFNCGrid1.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.SummaryLayout.Font.Color := clWindowText;
    TMSFNCGrid1.Appearance.SummaryLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.SummaryLayout.Font.Style := [];
    TMSFNCGrid1.Appearance.SelectedLayout.Fill.Color := -1;
    TMSFNCGrid1.Appearance.SelectedLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.SelectedLayout.Font.Color := clWhite;
    TMSFNCGrid1.Appearance.SelectedLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.SelectedLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.SelectedLayout.Font.Style := [];
    TMSFNCGrid1.Appearance.FocusedLayout.Fill.Color := clBtnFace;
    TMSFNCGrid1.Appearance.FocusedLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.FocusedLayout.Font.Color := clWhite;
    TMSFNCGrid1.Appearance.FocusedLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.FocusedLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.FocusedLayout.Font.Style := [];
    TMSFNCGrid1.Appearance.FixedSelectedLayout.Fill.Color := -1;
    TMSFNCGrid1.Appearance.FixedSelectedLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.FixedSelectedLayout.Font.Color := clWindowText;
    TMSFNCGrid1.Appearance.FixedSelectedLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.FixedSelectedLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.FixedSelectedLayout.Font.Style := [];
    TMSFNCGrid1.Appearance.BandLayout.Fill.Color := 16053492;
    TMSFNCGrid1.Appearance.BandLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.BandLayout.Font.Color := clWindowText;
    TMSFNCGrid1.Appearance.BandLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.BandLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.BandLayout.Font.Style := [];
    TMSFNCGrid1.Appearance.BandLayout.WordWrapping := True;
    TMSFNCGrid1.Appearance.ProgressLayout.Format := '%.0f%%';
    TMSFNCGrid1.LeftCol := 0;
    TMSFNCGrid1.ScrollMode := scmItemScrolling;
    SetEvent(TMSFNCGrid1, Self, 'OnGetCellLayout', 'TMSFNCGrid1GetCellLayout');
    WebHttpRequest1.SetParentComponent(Self);
    WebHttpRequest1.Name := 'WebHttpRequest1';
    SetEvent(WebHttpRequest1, Self, 'OnRequestResponse', 'WebHttpRequest1RequestResponse');
    WebHttpRequest1.Left := 56;
    WebHttpRequest1.Top := 102;
  finally
    TMSFNCPanel1.AfterLoadDFMValues;
    TMSFNCGrid1.AfterLoadDFMValues;
    WebHttpRequest1.AfterLoadDFMValues;
  end;
end;

end.
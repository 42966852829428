{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016 - 2022                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCGrid;

{$I WEBLib.TMSFNCDefines.inc}

interface

uses
  Classes, WEBLib.TMSFNCCustomGrid, WEBLib.TMSFNCGridData
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  , UITypes, WEBLib.TMSFNCTypes
  {$ENDIF}
  {$ENDIF}
  ;

type
  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCGrid = class(TTMSFNCCustomGrid)
  protected
    procedure RegisterRuntimeClasses; override;
  published
    property Adapter;
    property HorizontalScrollBarVisible;
    property VerticalScrollBarVisible;
    property Columns;
    property ToolBarPopup;
    property ToolBarPopupMode;
    property Version;
    property Fill;
    property DefaultFont;
    property Stroke;
    property TopRow;
    property Appearance;
    property LeftCol;
    property ScrollMode;
    property ScrollUpdate;
    property CustomScrollBars;
    property FreezeColumns;
    property FreezeRows;
    property OnTopLeftChanged;
    property OnGetCellClass;
    property OnGetCellData;
    property OnGetCellProperties;
    property OnGetCellLayout;
    property OnGetCellMergeInfo;
    property OnGetCellReadOnly;
    property OnGetRowIsBand;
    property OnCanInsertRow;
    property OnCanAppendRow;
    property OnCanAppendColumn;
    property OnCanDeleteRow;
    property OnInsertRow;
    property OnAppendRow;
    property OnAppendColumn;
    property OnDeleteRow;
    property OnCellAnchorClick;
    property OnGetCellEditorCustomClassType;
    property OnGetCellEditorType;
    property OnCellEditGetData;
    property OnCellEditValidateData;
    property OnCellEditSetData;
    property OnCellEditGetColor;
    property OnCellEditValidateColor;
    property OnCellEditSetColor;
    property OnCellEditDone;
    property OnCellEditCancel;
    property OnGetCellEditorProperties;
    property OnGetCellIsFixed;
    property OnFixedCellDropDownButtonClick;
    property OnCellComboCloseUp;
    property OnCellBeforeDraw;
    property OnCellAfterDraw;
    property OnCellBitmapClick;
    property OnCellRadioButtonClick;
    property OnCellCheckBoxClick;
    property OnCellButtonClick;
    property OnCellCommentClick;
    property OnCellSortClick;
    property OnCellNodeClick;
    property OnCanSizeColumn;
    property OnCanSizeRow;
    property OnColumnSize;
    property OnRowSize;
    property OnColumnSized;
    property OnRowSized;
    property OnCanDragColumn;
    property OnCanDragRow;
    property OnColumnDragged;
    property OnRowDragged;
    property OnBeforeColumnDrop;
    property OnBeforeCellEditExit;
    property OnBeforeRowDrop;
    property OnColumnSorted;
    property OnCanSortColumn;
    property OnCellRightClick;
    property OnFixedCellRightClick;
    property OnCellClick;
    property OnFixedCellClick;
    property OnSelectCell;
    property OnSelectedCell;
  end;

implementation

{ TTMSFNCGrid }

procedure TTMSFNCGrid.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClasses([TTMSFNCGrid, TTMSFNCGridColumn]);
end;

end.

program consys;

uses
  Vcl.Forms,
  WEBLib.Forms,
  FRONT in 'FRONT.pas'{*.html},
  FRAMSIDAN in 'FRAMSIDAN.pas'{*.html},
  MEDDELANDE in 'MEDDELANDE.pas'{*.html},
  PROJECT in 'PROJECT.pas'{*.html},
  KORRIGERING in 'KORRIGERING.pas'{*.html},
  KORRPROJECT in 'KORRPROJECT.pas'{*.html},
  KORRLISTAN in 'KORRLISTAN.pas'{*.html},
  KORREFERENS in 'KORREFERENS.pas'{*.html},
  DEMOSIDA in 'DEMOSIDA.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TForm1, Form1);
  Application.Run;
end.

unit FRONT;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, Vcl.Imaging.jpeg,
  VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCCustomControl, VCL.TMSFNCToolBar, Vcl.StdCtrls, WEBLib.StdCtrls,
  VCL.TMSFNCStatusBar, WEBLib.REST, VCL.TMSFNCGridCell, VCL.TMSFNCGridOptions,
  VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData, VCL.TMSFNCCustomGrid,
  VCL.TMSFNCGrid;

type
  TForm1 = class(TForm)
    WebHttpRequest1: THttpRequest;
    TGrid: TTMSFNCGrid;
    WebMemo1: TMemo;
    WebImageControl1: TImageControl;
    Procedure SIDAN2;
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    { Private declarations }
  public
    lokalt: Boolean;
    PARAM_P,PARAM_R,PARAM_U,PARAM_D,katn,proj,typs,webp,namn,tele,sprak: String;
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form1: TForm1;

implementation

Uses FRAMSIDAN;

{$R *.dfm}

Procedure TForm1.SIDAN2;
  Var newform: TForm2;

  procedure AfterShowModal(AValue: TModalResult);
    Begin
      //
    End;

  procedure AfterCreate(AForm: TObject);
    Begin
      (AForm as TForm2).Top:=0;
    End;

  Begin
    newform:= TForm2.CreateNew(@AfterCreate);
    newform.Popup := True;
    newform.ShowModal(@AfterShowModal);
  End;

procedure TForm1.WebFormCreate(Sender: TObject);
  Var
    AFormatSettings: TFormatSettings;
    SDateFormat: String;
  Begin
    lokalt:=Copy(Application.ExeName,1,16)='http://localhost';

    AFormatSettings:= TFormatSettings.Create;
    AFormatSettings.ShortDateFormat:= 'yyyy-mm-dd';
    SDateFormat:= AFormatSettings.ShortDateFormat;

    PARAM_P:='';
    PARAM_D:='';

    Form1.Color:=RGB(243,243,243);
  End;

procedure TForm1.WebFormShow(Sender: TObject);
  Begin
    SIDAN2;
  End;

procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebImageControl1 := TImageControl.Create(Self);
  TGrid := TTMSFNCGrid.Create(Self);
  WebMemo1 := TMemo.Create(Self);
  WebHttpRequest1 := THttpRequest.Create(Self);

  WebImageControl1.BeforeLoadDFMValues;
  TGrid.BeforeLoadDFMValues;
  WebMemo1.BeforeLoadDFMValues;
  WebHttpRequest1.BeforeLoadDFMValues;
  try
    Name := 'Form1';
    Width := 735;
    Height := 1000;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebImageControl1.SetParentComponent(Self);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 0;
    WebImageControl1.Top := 0;
    WebImageControl1.Width := 735;
    WebImageControl1.Height := 1000;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 100.000000000000000000;
    WebImageControl1.Align := alClient;
    WebImageControl1.ChildOrder := 2;
    TGrid.SetParentComponent(Self);
    TGrid.Name := 'TGrid';
    TGrid.Left := 20;
    TGrid.Top := 296;
    TGrid.Width := 690;
    TGrid.Height := 24;
    TGrid.ParentDoubleBuffered := False;
    TGrid.DoubleBuffered := True;
    TGrid.TabOrder := 0;
    TGrid.Visible := False;
    TGrid.DefaultColumnWidth := 138.000000000000000000;
    TGrid.FixedColumns := 0;
    TGrid.FixedRows := 0;
    TGrid.RowCount := 1;
    TGrid.Options.Borders.FixedCellBorders := bNone;
    TGrid.Options.Borders.CellBorders := bVertical;
    TGrid.Options.Editing.CalcFormat := '%g';
    TGrid.Options.Editing.Enabled := False;
    TGrid.Options.Editing.EditWithTags := False;
    TGrid.Options.Grouping.CalcFormat := '%g';
    TGrid.Options.Grouping.GroupCountFormat := '(%d)';
    TGrid.Options.IO.XMLEncoding := 'ISO-8859-1';
    TGrid.Options.Mouse.ClickMargin := 0;
    TGrid.Options.Mouse.ColumnSizeMargin := 6;
    TGrid.Options.Mouse.RowSizeMargin := 6;
    TGrid.HorizontalScrollBarVisible := False;
    TGrid.VerticalScrollBarVisible := False;
    TGrid.Columns.Clear;
    with TGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 138.000000000000000000;
    end;
    with TGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 138.000000000000000000;
    end;
    with TGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 138.000000000000000000;
    end;
    with TGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 138.000000000000000000;
    end;
    with TGrid.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 138.000000000000000000;
    end;
    TGrid.DefaultFont.Charset := DEFAULT_CHARSET;
    TGrid.DefaultFont.Color := clWindowText;
    TGrid.DefaultFont.Height := -11;
    TGrid.DefaultFont.Name := 'Tahoma';
    TGrid.DefaultFont.Style := [];
    TGrid.TopRow := 0;
    TGrid.Appearance.FixedLayout.Fill.Color := -1;
    TGrid.Appearance.FixedLayout.Font.Charset := DEFAULT_CHARSET;
    TGrid.Appearance.FixedLayout.Font.Color := clWindowText;
    TGrid.Appearance.FixedLayout.Font.Height := -11;
    TGrid.Appearance.FixedLayout.Font.Name := 'Tahoma';
    TGrid.Appearance.FixedLayout.Font.Style := [fsBold];
    TGrid.Appearance.NormalLayout.Fill.Color := -1;
    TGrid.Appearance.NormalLayout.Font.Charset := DEFAULT_CHARSET;
    TGrid.Appearance.NormalLayout.Font.Color := clWindowText;
    TGrid.Appearance.NormalLayout.Font.Height := -11;
    TGrid.Appearance.NormalLayout.Font.Name := 'Tahoma';
    TGrid.Appearance.NormalLayout.Font.Style := [];
    TGrid.Appearance.GroupLayout.Fill.Color := -1;
    TGrid.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    TGrid.Appearance.GroupLayout.Font.Color := clWhite;
    TGrid.Appearance.GroupLayout.Font.Height := -11;
    TGrid.Appearance.GroupLayout.Font.Name := 'Tahoma';
    TGrid.Appearance.GroupLayout.Font.Style := [];
    TGrid.Appearance.SummaryLayout.Fill.Color := 14009785;
    TGrid.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    TGrid.Appearance.SummaryLayout.Font.Color := clWindowText;
    TGrid.Appearance.SummaryLayout.Font.Height := -11;
    TGrid.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    TGrid.Appearance.SummaryLayout.Font.Style := [];
    TGrid.Appearance.SelectedLayout.Fill.Color := -1;
    TGrid.Appearance.SelectedLayout.Font.Charset := DEFAULT_CHARSET;
    TGrid.Appearance.SelectedLayout.Font.Color := clWhite;
    TGrid.Appearance.SelectedLayout.Font.Height := -11;
    TGrid.Appearance.SelectedLayout.Font.Name := 'Tahoma';
    TGrid.Appearance.SelectedLayout.Font.Style := [];
    TGrid.Appearance.FocusedLayout.Fill.Color := -1;
    TGrid.Appearance.FocusedLayout.Font.Charset := DEFAULT_CHARSET;
    TGrid.Appearance.FocusedLayout.Font.Color := clWhite;
    TGrid.Appearance.FocusedLayout.Font.Height := -11;
    TGrid.Appearance.FocusedLayout.Font.Name := 'Tahoma';
    TGrid.Appearance.FocusedLayout.Font.Style := [];
    TGrid.Appearance.FixedSelectedLayout.Fill.Color := -1;
    TGrid.Appearance.FixedSelectedLayout.Font.Charset := DEFAULT_CHARSET;
    TGrid.Appearance.FixedSelectedLayout.Font.Color := clWindowText;
    TGrid.Appearance.FixedSelectedLayout.Font.Height := -11;
    TGrid.Appearance.FixedSelectedLayout.Font.Name := 'Tahoma';
    TGrid.Appearance.FixedSelectedLayout.Font.Style := [];
    TGrid.Appearance.BandLayout.Fill.Color := -1;
    TGrid.Appearance.BandLayout.Font.Charset := DEFAULT_CHARSET;
    TGrid.Appearance.BandLayout.Font.Color := clWindowText;
    TGrid.Appearance.BandLayout.Font.Height := -11;
    TGrid.Appearance.BandLayout.Font.Name := 'Tahoma';
    TGrid.Appearance.BandLayout.Font.Style := [];
    TGrid.Appearance.ProgressLayout.Format := '%.0f%%';
    TGrid.LeftCol := 0;
    TGrid.ScrollMode := scmItemScrolling;
    WebMemo1.SetParentComponent(Self);
    WebMemo1.Name := 'WebMemo1';
    WebMemo1.Left := 232;
    WebMemo1.Top := 232;
    WebMemo1.Width := 313;
    WebMemo1.Height := 25;
    WebMemo1.AutoSize := False;
    WebMemo1.HeightPercent := 100.000000000000000000;
    WebMemo1.SelLength := 0;
    WebMemo1.SelStart := 0;
    WebMemo1.Visible := False;
    WebMemo1.WidthPercent := 100.000000000000000000;
    WebHttpRequest1.SetParentComponent(Self);
    WebHttpRequest1.Name := 'WebHttpRequest1';
    WebHttpRequest1.Left := 112;
    WebHttpRequest1.Top := 216;
  finally
    WebImageControl1.AfterLoadDFMValues;
    TGrid.AfterLoadDFMValues;
    WebMemo1.AfterLoadDFMValues;
    WebHttpRequest1.AfterLoadDFMValues;
  end;
end;

end.
{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2021                                      }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCPassLock;

{$I WEBLib.TMSFNCDefines.inc}

interface

uses
  {$IFDEF VCLLIB}
  Windows,
  {$ENDIF}
  Classes, WEBLib.TMSFNCCustomControl, WEBLib.Controls, WEBLib.Graphics,
  WEBLib.TMSFNCTypes, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes
  {$IFDEF WEBLIB}
  ,Web, JS
  {$ENDIF}
  {$IFDEF LCLLIB}
  , fgl
  {$ENDIF}
  {$IFNDEF LCLLIB}
  , Generics.Collections
  {$IFNDEF WEBLIB}
  {$HINTS OFF}
  {$IF COMPILERVERSION > 22}
  ,UITypes
  {$IFEND}
  {$HINTS ON}
  ,Types
  {$ENDIF}
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 1; // Release nr.
  BLD_VER = 0; // Build nr.

  // version history
  // v1.0.0.0 : First Release
  // v1.0.1.0: Support for high DPI

type
  TTMSFNCPassLockButtonShape = (plbsCircle, plbsRectangle, plbsRoundedRectangle);
  TTMSFNCPassLockType = (pltNumber, pltPattern);

  TTMSFNCPassLockDrawValue = record
    ItemRect: TRectF;
    Index: Integer;
    Value: string;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCPassLockDrawValue) b : Boolean;
    {$ENDIF}
  end;

  TTMSFNCPassLockDrawEntry = record
    ItemRect: TRectF;
    Index: Integer;
    Checked: Boolean;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCPassLockDrawEntry) b : Boolean;
    {$ENDIF}
  end;

  TTMSFNCPassLockOptions = class(TPersistent)
  private
    FEnableKeyboardInput: Boolean;
    FLearnMode: Boolean;
    FLockType: TTMSFNCPassLockType;
    FShowClearEntryButton: Boolean;
    FShowEntry: Boolean;
    FShowOKButton: Boolean;
    FShowPasswordLength: Boolean;
    FOnChanged: TNotifyEvent;
    FOnLearnModeChanged: TNotifyEvent;
    procedure SetEnableKeyboardInput(const Value: Boolean);
    procedure SetLearnMode(const Value: Boolean);
    procedure SetLockType(const Value: TTMSFNCPassLockType);
    procedure SetShowClearEntryButton(const Value: Boolean);
    procedure SetShowEntry(const Value: Boolean);
    procedure SetShowOKButton(const Value: Boolean);
    procedure SetShowPasswordLength(const Value: Boolean);
  protected
    procedure DoChanged(Sender: TObject); virtual;
    procedure DoLearnModeChanged; virtual;
    property OnChanged: TNotifyEvent read FOnChanged write FOnChanged;
    property OnLearnModeChanged: TNotifyEvent read FOnLearnModeChanged write FOnLearnModeChanged;
  public
    constructor Create(Source: TPersistent);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property EnableKeyboardInput: Boolean read FEnableKeyboardInput write SetEnableKeyboardInput default True;
    property LearnMode: Boolean read FLearnMode write SetLearnMode default False;
    property LockType: TTMSFNCPassLockType read FLockType write SetLockType default pltNumber;
    property ShowClearEntryButton: Boolean read FShowClearEntryButton write SetShowClearEntryButton default False;
    property ShowOKButton: Boolean read FShowOKButton write SetShowOKButton default False;
    property ShowEntry: Boolean read FShowEntry write SetShowEntry default True;
    property ShowPasswordLength: Boolean read FShowPasswordLength write SetShowPasswordLength default True;
  end;

  TTMSFNCPassLockButtonAppearance = class(TPersistent)
  private
    FDownFill: TTMSFNCGraphicsFill;
    FDownFont: TTMSFNCGraphicsFont;
    FDownStroke: TTMSFNCGraphicsStroke;
    FFill: TTMSFNCGraphicsFill;
    FFont: TTMSFNCGraphicsFont;
    FHoverFill: TTMSFNCGraphicsFill;
    FHoverFont: TTMSFNCGraphicsFont;
    FHoverStroke: TTMSFNCGraphicsStroke;
    FMaxSize: Single;
    FPatternLine: TTMSFNCGraphicsStroke;
    FShape: TTMSFNCPassLockButtonShape;
    FSpacing: Single;
    FStroke: TTMSFNCGraphicsStroke;
    FOnChanged: TNotifyEvent;
    procedure SetDownFill(const Value: TTMSFNCGraphicsFill);
    procedure SetDownFont(const Value: TTMSFNCGraphicsFont);
    procedure SetDownStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetHoverFill(const Value: TTMSFNCGraphicsFill);
    procedure SetHoverFont(const Value: TTMSFNCGraphicsFont);
    procedure SetHoverStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetMaxSize(const Value: Single);
    procedure SetPatternLine(const Value: TTMSFNCGraphicsStroke);
    procedure SetShape(const Value: TTMSFNCPassLockButtonShape);
    procedure SetSpacing(const Value: Single);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
  protected
    procedure DoChanged(Sender: TObject); virtual;
    procedure FillChanged(Sender: TObject); virtual;
    procedure FontChanged(Sender: TObject); virtual;
    procedure StrokeChanged(Sender: TObject); virtual;
    property OnChanged: TNotifyEvent read FOnChanged write FOnChanged;
  public
    constructor Create(Source: TPersistent);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property DownFill: TTMSFNCGraphicsFill read FDownFill write SetDownFill;
    property DownFont: TTMSFNCGraphicsFont read FDownFont write SetDownFont;
    property DownStroke: TTMSFNCGraphicsStroke read FDownStroke write SetDownStroke;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property HoverFill: TTMSFNCGraphicsFill read FHoverFill write SetHoverFill;
    property HoverFont: TTMSFNCGraphicsFont read FHoverFont write SetHoverFont;
    property HoverStroke: TTMSFNCGraphicsStroke read FHoverStroke write SetHoverStroke;
    property MaxSize: Single read FMaxSize write SetMaxSize;
    property PatternLine: TTMSFNCGraphicsStroke read FPatternLine write SetPatternLine;
    property Shape: TTMSFNCPassLockButtonShape read FShape write SetShape default plbsCircle;
    property Spacing: Single read FSpacing write SetSpacing;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
  end;

  TTMSFNCPassLockEntryAppearance = class(TPersistent)
  private
    FDownFill: TTMSFNCGraphicsFill;
    FDownStroke: TTMSFNCGraphicsStroke;
    FFill: TTMSFNCGraphicsFill;
    FMaxSize: single;
    FSpacing: Single;
    FStroke: TTMSFNCGraphicsStroke;
    FOnChanged: TNotifyEvent;
    procedure SetDownFill(const Value: TTMSFNCGraphicsFill);
    procedure SetDownStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetMaxSize(const Value: single);
    procedure SetSpacing(const Value: Single);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
  protected
    procedure DoChanged(Sender: TObject); virtual;
    procedure FillChanged(Sender: TObject); virtual;
    procedure StrokeChanged(Sender: TObject); virtual;
    property OnChanged: TNotifyEvent read FOnChanged write FOnChanged;
  public
    constructor Create(Source: TPersistent);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property DownFill: TTMSFNCGraphicsFill read FDownFill write SetDownFill;
    property DownStroke: TTMSFNCGraphicsStroke read FDownStroke write SetDownStroke;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property MaxSize: single read FMaxSize write SetMaxSize;
    property Spacing: Single read FSpacing write SetSpacing;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
  end;

  TTMSFNCPassLockButtonList = class(TList<TTMSFNCPassLockDrawValue>);
  TTMSFNCPassLockEntryList = class(TList<TTMSFNCPassLockDrawEntry>);

  TTMSFNCPassLockPasswordCheckEvent = procedure(Sender: TObject; AEntry: string; var AMatch: Boolean) of object;
  TTMSFNCPassLockAfterDrawBackgroundEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF) of object;
  TTMSFNCPassLockBeforeDrawBackgroundEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPassLockAfterDrawPatternEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; APath: TTMSFNCGraphicsPathPolygon) of object;
  TTMSFNCPassLockBeforeDrawPatternEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; APath: TTMSFNCGraphicsPathPolygon; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPassLockAfterDrawPatternCircleEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF) of object;
  TTMSFNCPassLockBeforeDrawPatternCircleEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPassLockAfterDrawButtonEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AButtonShape: TTMSFNCPassLockButtonShape; AValue: string) of object;
  TTMSFNCPassLockBeforeDrawButtonEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AButtonShape: TTMSFNCPassLockButtonShape; AValue: string; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPassLockBeforeDrawButtonValueEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: string; var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign; var AVerticalTextAlign: TTMSFNCGraphicsTextAlign; var AWordWrap: Boolean; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPassLockAfterDrawButtonValueEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: string; AHorizontalTextAlign: TTMSFNCGraphicsTextAlign; AVerticalTextAlign: TTMSFNCGraphicsTextAlign; AWordWrap: Boolean) of object;
  TTMSFNCPassLockAfterDrawEntryEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AChecked: Boolean) of object;
  TTMSFNCPassLockBeforeDrawEntryEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AChecked: Boolean; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCPassLockPasswordEvent = procedure(Sender: TObject; APassword: string) of object;
  TTMSFNCPassLockPasswordEntryChangedEvent = procedure(Sender:Tobject; APasswordEntry: string; AValue: string) of object;

  TTMSFNCCustomPassLock = class(TTMSFNCCustomControl)
  private
    FButtonAppearance: TTMSFNCPassLockButtonAppearance;
    FButtonDrawList: TTMSFNCPassLockButtonList;
    FDownIndex: Integer;
    FEntryAppearance: TTMSFNCPassLockEntryAppearance;
    FEntryDrawList: TTMSFNCPassLockEntryList;
    FHoverIndex: Integer;
    FKeyChar: string;
    FLearned: Boolean;
    FLearnPass: string;
    FMouseDown: Boolean;
    FOptions: TTMSFNCPassLockOptions;
    FPasswordEntry: string;
    FPasswordLength: integer;
    FPasswordValue: string;
    FOnAfterDrawBackground: TTMSFNCPassLockAfterDrawBackgroundEvent;
    FOnAfterDrawButton: TTMSFNCPassLockAfterDrawButtonEvent;
    FOnAfterDrawButtonValue: TTMSFNCPassLockAfterDrawButtonValueEvent;
    FOnAfterDrawEntry: TTMSFNCPassLockAfterDrawEntryEvent;
    FOnAfterDrawPattern: TTMSFNCPassLockAfterDrawPatternEvent;
    FOnAfterDrawPatternCircle: TTMSFNCPassLockAfterDrawPatternCircleEvent;
    FOnBeforeDrawBackground: TTMSFNCPassLockBeforeDrawBackgroundEvent;
    FOnBeforeDrawButton: TTMSFNCPassLockBeforeDrawButtonEvent;
    FOnBeforeDrawButtonValue: TTMSFNCPassLockBeforeDrawButtonValueEvent;
    FOnBeforeDrawEntry: TTMSFNCPassLockBeforeDrawEntryEvent;
    FOnBeforeDrawPattern: TTMSFNCPassLockBeforeDrawPatternEvent;
    FOnBeforeDrawPatternCircle: TTMSFNCPassLockBeforeDrawPatternCircleEvent;
    FOnButtonAppearanceChanged: TNotifyEvent;
    FOnButtonDownChanged: TTMSFNCPassLockPasswordEntryChangedEvent;
    FOnButtonHoverChanged: TTMSFNCPassLockPasswordEntryChangedEvent;
    FOnButtonUpChanged: TTMSFNCPassLockPasswordEntryChangedEvent;
    FOnConfirmPassword: TTMSFNCPassLockPasswordCheckEvent;
    FOnEntryAppearanceChanged: TNotifyEvent;
    FOnLearnPassword: TTMSFNCPassLockPasswordEvent;
    FOnNewPassword: TTMSFNCPassLockPasswordEvent;
    FOnOptionsChanged: TNotifyEvent;
    FOnPatternEntryChanged: TTMSFNCPassLockPasswordEntryChangedEvent;
    FOnPatternEntryEnd: TTMSFNCPassLockPasswordEvent;
    FOnPasswordCheck: TTMSFNCPassLockPasswordCheckEvent;
    FOnPasswordEntryChanged: TTMSFNCPassLockPasswordEntryChangedEvent;
    procedure SetButtonAppearance(const Value: TTMSFNCPassLockButtonAppearance);
    procedure SetEntryAppearance(const Value: TTMSFNCPassLockEntryAppearance);
    procedure SetOptions(const Value: TTMSFNCPassLockOptions);
    procedure SetPassEntry(const Value: string);
    procedure SetPasswordValue(const Value: string);
    {$IFDEF WEBLIB}
    function GetVisible: Boolean;
    procedure SetVisible(const Value: Boolean);
    {$ENDIF}
  protected
    procedure ChangeDPIScale(M,D: Integer); override;
    procedure CalculateGrid;
    procedure DoAfterDrawBackground(AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
    procedure DoAfterDrawButton(AGraphics: TTMSFNCGraphics; ARect: TRectF; AButtonShape: TTMSFNCPassLockButtonShape; AValue: string); virtual;
    procedure DoAfterDrawButtonValue(AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: string; AHorizontalTextAlign: TTMSFNCGraphicsTextAlign; AVerticalTextAlign: TTMSFNCGraphicsTextAlign; AWordWrap: Boolean); virtual;
    procedure DoAfterDrawEntry(AGraphics: TTMSFNCGraphics; ARect: TRectF; AChecked: Boolean); virtual;
    procedure DoAfterDrawPattern(AGraphics: TTMSFNCGraphics; APath: TTMSFNCGraphicsPathPolygon); virtual;
    procedure DoAfterDrawPatternCircle(AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
    procedure DoBeforeDrawBackground(AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoBeforeDrawButton(AGraphics: TTMSFNCGraphics; ARect: TRectF; AButtonShape: TTMSFNCPassLockButtonShape; AValue: string; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoBeforeDrawButtonValue(AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: string; var AHorizontalTextAlign: TTMSFNCGraphicsTextAlign; var AVerticalTextAlign: TTMSFNCGraphicsTextAlign; var AWordWrap: Boolean; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoBeforeDrawEntry(AGraphics: TTMSFNCGraphics; ARect: TRectF; AChecked: Boolean; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoBeforeDrawPattern(AGraphics: TTMSFNCGraphics; APath: TTMSFNCGraphicsPathPolygon; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoBeforeDrawPatternCircle(AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoButtonAppearanceChanged(Sender: TObject); virtual;
    procedure DoButtonDownChanged(AValue: string);
    procedure DoButtonHoverChanged(AValue: string);
    procedure DoButtonUpChanged(AValue: string);
    procedure DoConfirmPassword(AMatch: Boolean); virtual;
    procedure DoEntryAppearanceChanged(Sender: TObject); virtual;
    procedure DoLearnModeChanged(Sender: TObject); virtual;
    procedure DoLearnPassword; virtual;
    procedure DoNewPassword; virtual;
    procedure DoOptionsChanged(Sender: TObject); virtual;
    procedure DoPasswordCheck(AMatch: Boolean); virtual;
    procedure DoPasswordEntryChanged(AValue: string);
    procedure DoPatternEntryChanged(AValue: string);
    procedure DoPatternEntryEnd;
    procedure DrawBackground(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure DrawButtons(AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF); virtual;
    procedure DrawEntry(AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF); virtual;
    procedure DrawPattern(AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF); virtual;
    procedure HandleKeyPress(var Key: Char); override;
    procedure HandleKeyUp(var Key: Word; Shift: TShiftState); override;
    procedure HandleMouseDown({%H-}Button: TTMSFNCMouseButton; {%H-}Shift: TShiftState; {%H-}X, {%H-}Y: Single); override;
    procedure HandleMouseEnter; override;
    procedure HandleMouseLeave; override;
    procedure HandleMouseMove({%H-}Shift: TShiftState; {%H-}X, {%H-}Y: Single); override;
    procedure HandleMouseUp({%H-}Button: TTMSFNCMouseButton; {%H-}Shift: TShiftState; {%H-}X, {%H-}Y: Single); override;
    procedure PasswordEntered;
    function XYToButton(AX: Single; AY: Single): Integer;
    property ButtonAppearance: TTMSFNCPassLockButtonAppearance read FButtonAppearance write SetButtonAppearance;
    property EntryAppearance: TTMSFNCPassLockEntryAppearance read FEntryAppearance write SetEntryAppearance;
    property Options: TTMSFNCPassLockOptions read FOptions write SetOptions;
    property PasswordEntry: string read FPasswordEntry write SetPassEntry;
    property PasswordLength: integer read FPasswordLength default 4;
    property PasswordValue: string read FPasswordValue write SetPasswordValue;
    {$IFDEF WEBLIB}
    property Visible: Boolean read GetVisible write SetVisible default True;
    {$ENDIF}
    property OnAfterDrawBackground: TTMSFNCPassLockAfterDrawBackgroundEvent read FOnAfterDrawBackground write FOnAfterDrawBackground;
    property OnAfterDrawButton: TTMSFNCPassLockAfterDrawButtonEvent read FOnAfterDrawButton write FOnAfterDrawButton;
    property OnAfterDrawButtonValue: TTMSFNCPassLockAfterDrawButtonValueEvent read FOnAfterDrawButtonValue write FOnAfterDrawButtonValue;
    property OnAfterDrawEntry: TTMSFNCPassLockAfterDrawEntryEvent read FOnAfterDrawEntry write FOnAfterDrawEntry;
    property OnAfterDrawPattern: TTMSFNCPassLockAfterDrawPatternEvent read FOnAfterDrawPattern write FOnAfterDrawPattern;
    property OnAfterDrawPatternCircle: TTMSFNCPassLockAfterDrawPatternCircleEvent read FOnAfterDrawPatternCircle write FOnAfterDrawPatternCircle;
    property OnBeforeDrawBackground: TTMSFNCPassLockBeforeDrawBackgroundEvent read FOnBeforeDrawBackground write FOnBeforeDrawBackground;
    property OnBeforeDrawButton: TTMSFNCPassLockBeforeDrawButtonEvent read FOnBeforeDrawButton write FOnBeforeDrawButton;
    property OnBeforeDrawButtonValue: TTMSFNCPassLockBeforeDrawButtonValueEvent read FOnBeforeDrawButtonValue write FOnBeforeDrawButtonValue;
    property OnBeforeDrawEntry: TTMSFNCPassLockBeforeDrawEntryEvent read FOnBeforeDrawEntry write FOnBeforeDrawEntry;
    property OnBeforeDrawPattern: TTMSFNCPassLockBeforeDrawPatternEvent read FOnBeforeDrawPattern write FOnBeforeDrawPattern;
    property OnBeforeDrawPatternCircle: TTMSFNCPassLockBeforeDrawPatternCircleEvent read FOnBeforeDrawPatternCircle write FOnBeforeDrawPatternCircle;
    property OnButtonAppearanceChanged: TNotifyEvent read FOnButtonAppearanceChanged write FOnButtonAppearanceChanged;
    property OnButtonDownChanged: TTMSFNCPassLockPasswordEntryChangedEvent read FOnButtonDownChanged write FOnButtonDownChanged;
    property OnButtonHoverChanged: TTMSFNCPassLockPasswordEntryChangedEvent read FOnButtonHoverChanged write FOnButtonHoverChanged;
    property OnButtonUpChanged: TTMSFNCPassLockPasswordEntryChangedEvent read FOnButtonUpChanged write FOnButtonUpChanged;
    property OnConfirmPassword: TTMSFNCPassLockPasswordCheckEvent read FOnConfirmPassword write FOnConfirmPassword;
    property OnEntryAppearanceChanged: TNotifyEvent read FOnEntryAppearanceChanged write FOnEntryAppearanceChanged;
    property OnLearnPassword: TTMSFNCPassLockPasswordEvent read FOnLearnPassword write FOnLearnPassword;
    property OnNewPassword: TTMSFNCPassLockPasswordEvent read FOnNewPassword write FOnNewPassword;
    property OnOptionsChanged: TNotifyEvent read FOnOptionsChanged write FOnOptionsChanged;
    property OnPasswordCheck: TTMSFNCPassLockPasswordCheckEvent read FOnPasswordCheck write FOnPasswordCheck;
    property OnPasswordEntryChanged: TTMSFNCPassLockPasswordEntryChangedEvent read FOnPasswordEntryChanged write FOnPasswordEntryChanged;
    property OnPatternEntryChanged: TTMSFNCPassLockPasswordEntryChangedEvent read FOnPatternEntryChanged write FOnPatternEntryChanged;
    property OnPatternEntryEnd: TTMSFNCPassLockPasswordEvent read FOnPatternEntryEnd write FOnPatternEntryEnd;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    procedure EndUpdate; override;
    procedure AddPasswordEntry(AEntry: string);
    procedure ClearLastPasswordEntry;
    procedure ClearPasswordEntry;
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure Loaded; override;
    function PasswordCheck: Boolean;
    procedure Resize; override;
    procedure SetPasswordEntry(AEntry: string);
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCPassLock = class(TTMSFNCCustomPassLock)
  protected
    procedure RegisterRuntimeClasses; override;
  published
    property ButtonAppearance;
    property EntryAppearance;
    property Fill;
    property Options;
    property PasswordEntry;
    property PasswordLength;
    property PasswordValue;
    property Stroke;
    {$IFDEF WEBLIB}
    property Visible;
    {$ENDIF}
    property OnAfterDrawBackground;
    property OnAfterDrawButton;
    property OnAfterDrawButtonValue;
    property OnAfterDrawEntry;
    property OnAfterDrawPattern;
    property OnAfterDrawPatternCircle;
    property OnBeforeDrawBackground;
    property OnBeforeDrawButton;
    property OnBeforeDrawButtonValue;
    property OnBeforeDrawEntry;
    property OnBeforeDrawPattern;
    property OnBeforeDrawPatternCircle;
    property OnButtonAppearanceChanged;
    property OnButtonDownChanged;
    property OnButtonHoverChanged;
    property OnButtonUpChanged;
    property OnConfirmPassword;
    property OnEntryAppearanceChanged;
    property OnLearnPassword;
    property OnNewPassword;
    property OnOptionsChanged;
    property OnPasswordCheck;
    property OnPasswordEntryChanged;
    property OnPatternEntryChanged;
    property OnPatternEntryEnd;
  end;

implementation

uses
  WEBLib.TMSFNCUtils, WEBLib.TMSFNCStyles, SysUtils;

{ TTMSFNCPassLock }

procedure TTMSFNCPassLock.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCPassLock);
end;

{ TTMSFNCCustomPassLock }

procedure TTMSFNCCustomPassLock.AddPasswordEntry(AEntry: string);
begin
  PasswordEntry := PasswordEntry + AEntry;

  DoPasswordEntryChanged(AEntry);

  if ((FOptions.LockType = pltNumber) and (Length(PasswordEntry) >= Length(PasswordValue)) and FOptions.ShowPasswordLength) then
    PasswordEntered;
end;

procedure TTMSFNCCustomPassLock.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCCustomPassLock then
  begin
    FButtonAppearance.Assign((Source as TTMSFNCCustomPassLock).ButtonAppearance);
    FEntryAppearance.Assign((Source as TTMSFNCCustomPassLock).EntryAppearance);
    FPasswordLength := (Source as TTMSFNCCustomPassLock).PasswordLength;
    FPasswordValue := (Source as TTMSFNCCustomPassLock).PasswordValue;
    FPasswordEntry := (Source as TTMSFNCCustomPassLock).PasswordEntry;
    FOptions.Assign((Source as TTMSFNCCustomPassLock).Options);
  end
  else
    inherited;
end;

procedure TTMSFNCCustomPassLock.CalculateGrid;
var
  x, y, w, h: Single;
  I, K, col, row, startrow, showLength: Integer;
  vald: TTMSFNCPassLockDrawValue;
  vale: TTMSFNCPassLockDrawEntry;
  sz, msb: Single;
  bh, bw, bx, by, spe, spb, sh, sw: Single;
begin
  FButtonDrawList.Clear;
  FEntryDrawList.Clear;

  spb := ButtonAppearance.Spacing;
  spe := EntryAppearance.Spacing;

  col := 3;
  startrow := 0;

  case FOptions.LockType of
    pltNumber:
    begin
        row := 4;
    end;
    pltPattern:
    begin
      row := 3;
    end;
    else
      row := 1;
  end;

  sz := EntryAppearance.MaxSize;

  w := Width - (2 * spb) - ((col - 1) * spb);
  h := Height - (3 * spb) - ((row - 1) * spb);

  if (FOptions.LockType = plTNumber) and FOptions.FShowEntry then
  begin
    if Width < ((sz * PasswordLength) + (spe  * (PasswordLength - 1))) then
      sz := (Width - (spe  * (PasswordLength - 1)))/ PasswordLength;
    h := h - sz - spb;
  end;

  bh := h / row;
  bw := w / col;

  if bh > bw then
  begin
    bh := bw
  end
  else
  begin
    bw := bh;
  end;

  msb := FButtonAppearance.MaxSize;
  if (msb >= 0) and (bh > msb) then
  begin
    bh := msb;
    bw := msb;
  end;

  h := Height - ((row * bh) + (row - 1) * spb);
  if FOptions.FShowEntry then
    h := h - sz - spb;
  sh := h / 2;

  if (FOptions.LockType = plTNumber) and FOptions.ShowEntry then
  begin
    if FOptions.ShowPasswordLength then
      showLength := FPasswordLength
    else
      showLength := Length(PasswordEntry);

    sw := (Width - (sz * (showLength) + (spe * (showLength - 1)))) / 2;

    for I := 0 to showLength - 1 do
    begin
      x := sw + (sz * I) + (spe * I);
      y := sh - spb;

      vale.ItemRect := RectF(x, y, x + sz, y + sz);

      vale.Index := FButtonDrawList.Count;
      if (Length(PasswordEntry) > I) then
        vale.Checked := true
      else
        vale.Checked := false;

      FEntryDrawList.Add(vale);
    end;
  end;

  sw := (Width - ((col * bw) + (col - 1) * spb)) / 2;

  for I := 0 to row - 1 do
  begin
    for K := 0 to col - 1 do
    begin
      bx := sw + ((bw + spb) * K);
      by := sh + ((bh + spb) * I);

      if FOptions.FShowEntry then
        by := by + sz;

      vald.ItemRect := RectF(bx,by,bx + bw, by + bh);
      vald.Index := FButtonDrawList.Count;

      if vald.Index >= 9 then
        vald.Value := '0'
      else
        vald.Value := IntToStr(vald.Index + 1);

      if (I <= (startrow + 2)) or (K = 1) then
        FButtonDrawList.Add(vald);

      if FOptions.ShowClearEntryButton and (I > (startrow + 2)) and (K = 0) then
      begin
        vald.Value := 'CE';
        vald.Index := -1;
        FButtonDrawList.Add(vald);
      end;

      if (FOptions.ShowOKButton) and (I > (startrow + 2)) and (K = 2) then
      begin
        vald.Value := 'OK';
        vald.Index := -10;
        FButtonDrawList.Add(vald);
      end;
    end;
  end;

  Invalidate;
end;

procedure TTMSFNCCustomPassLock.ChangeDPIScale(M, D: Integer);
begin
  inherited;
  BeginUpdate;

  FButtonAppearance.FFont.Height := TTMSFNCUtils.MulDivInt(FButtonAppearance.FFont.Height, M, D);
  FButtonAppearance.FHoverFont.Height := TTMSFNCUtils.MulDivInt(FButtonAppearance.FHoverFont.Height, M, D);
  FButtonAppearance.FDownFont.Height := TTMSFNCUtils.MulDivInt(FButtonAppearance.FDownFont.Height, M, D);
  FButtonAppearance.FMaxSize := TTMSFNCUtils.MulDivSingle(FButtonAppearance.FMaxSize, M, D);
  FButtonAppearance.FPatternLine.Width := TTMSFNCUtils.MulDivSingle(FButtonAppearance.FPatternLine.Width, M, D);
  FButtonAppearance.FSpacing := TTMSFNCUtils.MulDivSingle(FButtonAppearance.FSpacing, M, D);

  FEntryAppearance.FSpacing := TTMSFNCUtils.MulDivSingle(FEntryAppearance.FSpacing, M, D);
  FEntryAppearance.FMaxSize := TTMSFNCUtils.MulDivSingle(FEntryAppearance.FMaxSize, M, D);

  EndUpdate;
end;

procedure TTMSFNCCustomPassLock.ClearLastPasswordEntry;
begin
  if Length(FPasswordEntry) > 0 then
  begin
    Delete(FPasswordEntry, Length(FPasswordEntry), 1);
    DoPasswordEntryChanged('CE');

    CalculateGrid;
  end;
end;

procedure TTMSFNCCustomPassLock.ClearPasswordEntry;
begin
  PasswordEntry := '';
  CalculateGrid;
end;

constructor TTMSFNCCustomPassLock.Create(AOwner: TComponent);
begin
  inherited;

  Transparent := True;

  {$IFDEF VCLLIB}
  ControlStyle := ControlStyle - [csAcceptsControls];
  {$ENDIF}

  FButtonAppearance := TTMSFNCPassLockButtonAppearance.Create(Self);
  FEntryAppearance := TTMSFNCPassLockEntryAppearance.Create(Self);
  FOptions := TTMSFNCPassLockOptions.Create(Self);

  FButtonDrawList := TTMSFNCPassLockButtonList.Create;
  FEntryDrawList := TTMSFNCPassLockEntryList.Create;

  FPasswordValue := '0000';
  FPasswordLength := 4;

  Fill.Kind := gfkNone;
  Stroke.Kind := gskNone;

  FHoverIndex := -1;
  FDownIndex := -1;
  FMouseDown := False;

  FButtonAppearance.OnChanged := @DoButtonAppearanceChanged;
  FEntryAppearance.OnChanged := @DoEntryAppearanceChanged;
  FOptions.OnChanged := @DoOptionsChanged;
  {$IFNDEF LCLLIB}
  FOptions.OnLearnModeChanged := DoLearnModeChanged;
  {$ENDIF}
  {$IFDEF LCLLIB}
  FOptions.OnLearnModeChanged := @DoLearnModeChanged;
  {$ENDIF}

  Height := 270;
  Width := 200;
end;

destructor TTMSFNCCustomPassLock.Destroy;
begin
  FButtonDrawList.Free;
  FEntryDrawList.Free;
  FButtonAppearance.Free;
  FEntryAppearance.Free;
  FOptions.Free;
  inherited;
end;

procedure TTMSFNCCustomPassLock.DoAfterDrawBackground(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  if Assigned(OnAfterDrawBackground) then
    OnAfterDrawBackground(Self, AGraphics, ARect);
end;

procedure TTMSFNCCustomPassLock.DoAfterDrawButton(AGraphics: TTMSFNCGraphics; ARect: TRectF; AButtonShape: TTMSFNCPassLockButtonShape; AValue: string);
begin
  if Assigned(OnAfterDrawButton) then
    OnAfterDrawButton(Self, AGraphics, ARect, AButtonShape, AValue);
end;

procedure TTMSFNCCustomPassLock.DoAfterDrawButtonValue(AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: string;
  AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign; AWordWrap: Boolean);
begin
  if Assigned(OnAfterDrawButtonValue) then
    OnAfterDrawButtonValue(Self, AGraphics, ARect, AValue, AHorizontalTextAlign, AVerticalTextAlign, AWordWrap);
end;

procedure TTMSFNCCustomPassLock.DoAfterDrawEntry(AGraphics: TTMSFNCGraphics; ARect: TRectF; AChecked: Boolean);
begin
  if Assigned(OnAfterDrawEntry) then
    OnAfterDrawEntry(Self, AGraphics, ARect, AChecked);
end;

procedure TTMSFNCCustomPassLock.DoAfterDrawPattern(AGraphics: TTMSFNCGraphics; APath: TTMSFNCGraphicsPathPolygon);
begin
  if Assigned(OnAfterDrawPattern) then
    OnAfterDrawPattern(Self, AGraphics, APath);
end;

procedure TTMSFNCCustomPassLock.DoAfterDrawPatternCircle(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  if Assigned(OnAfterDrawPatternCircle) then
    OnAfterDrawPatternCircle(Self, AGraphics, ARect);
end;

procedure TTMSFNCCustomPassLock.DoBeforeDrawBackground(AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawBackground) then
    OnBeforeDrawBackground(SElf, AGraphics, ARect, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPassLock.DoBeforeDrawButton(AGraphics: TTMSFNCGraphics; ARect: TRectF; AButtonShape: TTMSFNCPassLockButtonShape; AValue: string;
  var AAllow, ADefaultDraw: Boolean);
begin
   if Assigned(OnBeforeDrawButton) then
    OnBeforeDrawButton(Self, AGraphics, ARect, AButtonShape, AValue, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPassLock.DoBeforeDrawButtonValue(AGraphics: TTMSFNCGraphics; ARect: TRectF; AValue: string;
  var AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign; var AWordWrap: Boolean; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawButtonValue) then
    OnBeforeDrawButtonValue(Self, AGraphics, ARect, AValue, AHorizontalTextAlign, AVerticalTextAlign, AWordWrap, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPassLock.DoBeforeDrawEntry(AGraphics: TTMSFNCGraphics; ARect: TRectF; AChecked: Boolean; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawEntry) then
    OnBeforeDrawEntry(Self, AGraphics, ARect, AChecked, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPassLock.DoBeforeDrawPattern(AGraphics: TTMSFNCGraphics; APath: TTMSFNCGraphicsPathPolygon; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawPattern) then
    OnBeforeDrawPattern(Self, AGraphics, APath, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPassLock.DoBeforeDrawPatternCircle(AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawPatternCircle) then
    OnBeforeDrawPatternCircle(Self, AGraphics, ARect, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomPassLock.DoButtonAppearanceChanged(Sender: TObject);
begin
  CalculateGrid;

  if Assigned(OnButtonAppearanceChanged) then
    OnButtonAppearanceChanged(Self);
end;

procedure TTMSFNCCustomPassLock.DoButtonDownChanged(AValue: string);
begin
  if Assigned(OnButtonDownChanged) then
    OnButtonDownChanged(Self, FPasswordEntry, AValue);
end;

procedure TTMSFNCCustomPassLock.DoButtonHoverChanged(AValue: string);
begin
  if Assigned(OnButtonHoverChanged) then
    OnButtonHoverChanged(Self, FPasswordEntry, AValue);
end;

procedure TTMSFNCCustomPassLock.DoButtonUpChanged(AValue: string);
begin
  if Assigned(OnButtonUpChanged) then
    OnButtonUpChanged(Self, FPasswordEntry, AValue);
end;

procedure TTMSFNCCustomPassLock.DoConfirmPassword(AMatch: Boolean);
begin
  if Assigned(OnConfirmPassword) then
    OnConfirmPassword(Self, FPasswordEntry, AMatch);
end;

procedure TTMSFNCCustomPassLock.DoEntryAppearanceChanged(Sender: TObject);
begin
  CalculateGrid;

  if Assigned(OnEntryAppearanceChanged) then
    OnEntryAppearanceChanged(Self);
end;

procedure TTMSFNCCustomPassLock.DoLearnModeChanged(Sender: TObject);
begin
  if FOptions.LearnMode then
    ClearPasswordEntry;
  CalculateGrid;
end;

procedure TTMSFNCCustomPassLock.DoLearnPassword;
begin
  if Assigned(OnLearnPassword) then
    OnLearnPassword(Self, FLearnPass);
end;

procedure TTMSFNCCustomPassLock.DoNewPassword;
begin
  if Assigned(OnNewPassword) then
    OnNewPassword(Self, FPasswordValue);
end;

procedure TTMSFNCCustomPassLock.DoOptionsChanged(Sender: TObject);
begin
  CalculateGrid;

  if Assigned(OnOptionsChanged) then
    OnOptionsChanged(Self);
end;

procedure TTMSFNCCustomPassLock.DoPasswordCheck(AMatch: Boolean);
begin
  if Assigned(OnPasswordCheck) then
    OnPasswordCheck(Self, PasswordEntry, AMatch);
end;

procedure TTMSFNCCustomPassLock.DoPasswordEntryChanged(AValue: string);
begin
  if Assigned(OnPasswordEntryChanged) then
    OnPasswordEntryChanged(Self, FPasswordEntry, AValue);
end;

procedure TTMSFNCCustomPassLock.DoPatternEntryChanged(AValue: string);
begin
  if Assigned(OnPatternEntryChanged) then
    OnPatternEntryChanged(Self, FPasswordEntry, AValue);
end;

procedure TTMSFNCCustomPassLock.DoPatternEntryEnd;
begin
  if Assigned(OnPatternEntryEnd) then
    OnPatternEntryEnd(Self, FPasswordEntry);
end;

procedure TTMSFNCCustomPassLock.Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  inherited;
  DrawBackground(AGraphics, ARect);

  if FOptions.FShowEntry and (FOptions.LockType = pltNumber) then
    DrawEntry(AGraphics, ARect);

  DrawButtons(AGraphics, ARect);

  if FOptions.LockType = pltPattern then
    DrawPattern(AGraphics, ARect);
end;

procedure TTMSFNCCustomPassLock.DrawBackground(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  a, dd: Boolean;
begin
  if (Fill.Kind <> gfkNone) or (Stroke.Kind <> gskNone) then
  begin
    AGraphics.Fill.Assign(Fill);
    AGraphics.Stroke.Assign(Stroke);

    a := True;
    dd := True;

    DoBeforeDrawBackground(AGraphics, ARect, a, dd);

    if a then
    begin
      if dd then
        AGraphics.DrawRectangle(ARect);
      DoAfterDrawBackground(AGraphics, ARect);
    end;
  end;
end;

procedure TTMSFNCCustomPassLock.DrawButtons(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  I: Integer;
  btn: TTMSFNCPassLockDrawValue;
  a, dd, ww: boolean;
  va, ha: TTMSFNCGraphicsTextAlign;
  txt: string;
begin
  inherited;

  for I := 0 to FButtonDrawList.Count - 1 do
  begin
    btn := FButtonDrawList[I];
    if (I = FDownIndex) or ((FOptions.LockType = pltPattern) and (Pos(btn.Value, FPasswordEntry) > 0)) then
    begin
      AGraphics.Fill.Assign(ButtonAppearance.FDownFill);
      AGraphics.Stroke.Assign(ButtonAppearance.FDownStroke);
      AGraphics.Font.Assign(ButtonAppearance.FDownFont);
    end
    else if I = FHoverIndex then
    begin
      AGraphics.Fill.Assign(ButtonAppearance.FHoverFill);
      AGraphics.Stroke.Assign(ButtonAppearance.FHoverStroke);
      AGraphics.Font.Assign(ButtonAppearance.FHoverFont);
    end
    else
    begin
      AGraphics.Fill.Assign(ButtonAppearance.FFill);
      AGraphics.Stroke.Assign(ButtonAppearance.FStroke);
      AGraphics.Font.Assign(ButtonAppearance.FFont);
    end;

    a:= True;
    dd := True;
    txt := btn.Value;

    DoBeforeDrawButton(AGraphics, btn.ItemRect, ButtonAppearance.Shape, txt , a, dd);

    if a then
    begin
      if dd then
      begin
        case ButtonAppearance.Shape of
          plbsRectangle: AGraphics.DrawRectangle(btn.ItemRect);
          plbsRoundedRectangle: AGraphics.DrawRoundRectangle(btn.ItemRect, (btn.ItemRect.Right - btn.ItemRect.Left) / 3);
          else
            AGraphics.DrawEllipse(btn.ItemRect);
        end;
      end;

      DoAfterDrawButton(AGraphics, btn.ItemRect, ButtonAppearance.Shape, txt);
    end;

    if FOptions.LockType = pltNumber then
    begin
      a := True;
      dd := True;
      va := gtaCenter;
      ha := gtaCenter;
      ww := False;

      DoBeforeDrawButtonValue(AGraphics, ARect, txt, ha, va, ww, a, dd);

      if a then
      begin
        if dd then
          AGraphics.DrawText(btn.ItemRect, txt, ww, ha, va, gttNone);

        DoAfterDrawButtonValue(AGraphics, ARect, txt, ha, va, ww);
      end;
    end;
  end;
end;

procedure TTMSFNCCustomPassLock.DrawEntry(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  I: Integer;
  ent: TTMSFNCPassLockDrawEntry;
  a, dd, c: Boolean;
begin
  inherited;

  for I := 0 to FEntryDrawList.Count - 1 do
  begin
    ent := FEntryDrawList[I];

    if I < Length(PasswordEntry) then
    begin
      c := True;
      AGraphics.Fill.Assign(EntryAppearance.FDownFill);
      AGraphics.Stroke.Assign(EntryAppearance.FDownStroke);
    end
    else
    begin
      c := False;
      AGraphics.Fill.Assign(EntryAppearance.FFill);
      AGraphics.Stroke.Assign(EntryAppearance.FStroke);
    end;

    a := True;
    dd := True;

    DoBeforeDrawEntry(AGraphics, ent.ItemRect, c, a, dd);

    if a then
    begin
      if dd then
        AGraphics.DrawEllipse(ent.ItemRect);
      DoAfterDrawEntry(AGraphics, ent.ItemRect, c);
    end;
  end;
end;

procedure TTMSFNCCustomPassLock.DrawPattern(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  I, Idx: Integer;
  r: TRectF;
  pth: TTMSFNCGraphicsPathPolygon;
  a, dd: Boolean;
begin
  if Length(FPasswordEntry) > 0 then
  begin
    SetLength(pth, Length(FPasswordEntry));

    try
      Idx := StrToInt(FPasswordEntry[1]) - 1;
      r := FButtonDrawList[Idx].ItemRect;

      pth[0] := PointF(r.Left + ((r.Right - r.Left)/2), r.Top + ((r.Bottom - r.Top)/2));

      for I := 2 to Length(FPasswordEntry) do
      begin
        Idx := StrToInt(FPasswordEntry[I]) - 1;
        r := FButtonDrawList[Idx].ItemRect;
        pth[I - 1] := PointF(r.Left + ((r.Right - r.Left)/2), r.Top + ((r.Bottom - r.Top)/2));
      end;

      AGraphics.Stroke.Assign(FButtonAppearance.PatternLine);
      AGraphics.Fill.Kind := gfkNone;

      a := True;
      dd := True;

      DoBeforeDrawPattern(AGraphics, pth, a, dd);

      if a then
      begin
        if dd then
          AGraphics.DrawPolyline(pth);

        DoAfterDrawPattern(AGraphics, pth);
      end;

      for I := 0 to Length(pth) - 1 do
      begin
        AGraphics.Stroke.Assign(FButtonAppearance.PatternLine);
        AGraphics.Fill.Assign(FButtonAppearance.PatternLine);

        r := RectF(pth[I].X - AGraphics.Stroke.Width, pth[I].Y - AGraphics.Stroke.Width, pth[I].X + AGraphics.Stroke.Width, pth[I].Y + AGraphics.Stroke.Width);

        a:= True;
        dd := True;

        if a then
        begin
          if dd then
            AGraphics.DrawEllipse(r);

          DoAfterDrawPatternCircle(AGraphics, r);
        end;
      end;
    finally
      SetLength(pth, 0);
    end;
  end;
end;

procedure TTMSFNCCustomPassLock.EndUpdate;
begin
  CalculateGrid;

  inherited;
end;

{$IFDEF WEBLIB}
function TTMSFNCCustomPassLock.GetVisible: Boolean;
begin
  Result := TControl(Self).Visible;
end;
{$ENDIF}

procedure TTMSFNCCustomPassLock.HandleKeyPress(var Key: Char);
begin
  inherited;

  if (FOptions.FLockType = pltNumber) and  (Pos(Key,'0123456789') > 0) and (Length(Key) = 1) then
  begin
    FKeyChar := Key;
  end;
end;

procedure TTMSFNCCustomPassLock.HandleKeyUp(var Key: Word; Shift: TShiftState);
begin
  inherited;
  if FOptions.FLockType = pltNumber then
  begin
    case Key of
      KEY_BACK: //Backspace
      begin
       ClearLastPasswordEntry;
      end;
      KEY_RETURN: //Enter
      begin
        if FOptions.ShowOKButton then
          PasswordEntered;
      end;
      else
      begin
        if (Length(FKeyChar) > 0) and (FOptions.LockType = pltNumber) then
          AddPasswordEntry(FKeyChar);
        FKeyChar := '';
      end;
    end;
  end;
end;

procedure TTMSFNCCustomPassLock.HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single);
var
  di: Integer;
  v: string;
begin
  inherited;

  di := XYToButton(X,Y);

  FMouseDown := True;

  if FDownIndex <> di then
  begin
    FDownIndex := di;

    if FDownIndex > -1 then
    begin
      v := FButtonDrawList[FDownIndex].Value;

      if (FOptions.LockType = pltPattern) then
      begin
        AddPasswordEntry(v);
        DoPatternEntryChanged(v);
      end
      else
      begin
        if (Length(PasswordEntry) >= Length(PasswordValue)) and FOptions.ShowPasswordLength then
        begin
          FPasswordEntry := '';
        end;
      end;
    end
    else
      v := '';

    DoButtonDownChanged(v);

    Invalidate;
  end;
end;

procedure TTMSFNCCustomPassLock.HandleMouseEnter;
begin
  inherited;
end;

procedure TTMSFNCCustomPassLock.HandleMouseLeave;
begin
  inherited;
  FMouseDown := False;
  FHoverIndex := -1;
  FDownIndex := -1;
  if FOptions.FLockType = pltPattern then
    FPasswordEntry := '';
  Invalidate;
end;

procedure TTMSFNCCustomPassLock.HandleMouseMove(Shift: TShiftState; X, Y: Single);
var
  hi: Integer;
  v: string;
begin
  inherited;

  hi := XYToButton(X,Y);

  if FHoverIndex <> hi then
  begin
    FHoverIndex := hi;

    if FHoverIndex <> -1 then
    begin
      v := FButtonDrawList[FHoverIndex].Value;
      if (FOptions.LockType = pltPattern) and FMouseDown and (FHoverIndex <> -1) then
      begin
        if not (Pos(v, PasswordEntry) > 0) then
        begin
          AddPasswordEntry(v);
          DoPatternEntryChanged(v);
        end;
      end;
    end
    else
      v := '';

    DoButtonHoverChanged(v);
    Invalidate;
  end;
end;

procedure TTMSFNCCustomPassLock.HandleMouseUp(Button: TTMSFNCMouseButton;Shift: TShiftState; X, Y: Single);
var
  v: string;
begin
  inherited;

  FMouseDown := False;
  v := '';

  if FDownIndex > -1 then
  begin
    v := FButtonDrawList[FDownIndex].Value;

    if (FOptions.LockType = pltPattern) then
    begin
      DoPatternEntryEnd;

      PasswordEntered;
    end
    else
    begin
      if (FButtonDrawList[FDownIndex].Index = -1) then
        ClearLastPasswordEntry
      else if (FButtonDrawList[FDownIndex].Index = -10) then
        PasswordEntered
      else
        AddPasswordEntry(v);
    end;

    FDownIndex := -1;

    Invalidate;
  end;

  DoButtonUpChanged(v);
end;

procedure TTMSFNCCustomPassLock.Loaded;
begin
  inherited;
  CalculateGrid;
end;

function TTMSFNCCustomPassLock.PasswordCheck: Boolean;
var
  b: Boolean;
begin
  if PasswordEntry = PasswordValue then
    b := True
  else
    b := False;

  DoPasswordCheck(b);

  if not b then
    ClearPasswordEntry;

  Result := b;
end;

procedure TTMSFNCCustomPassLock.PasswordEntered;
var
  success: Boolean;
begin
  if FOptions.LearnMode then
  begin
    if not FLearned then
    begin
      FLearned := True;
      FLearnPass := FPasswordEntry;
      DoLearnPassword;
    end
    else
    begin
      FLearned := False;
      if FLearnPass = FPasswordEntry then
        success := True
      else
        success := False;

      DoConfirmPassword(success);

      if success then
      begin
        PasswordValue := FLearnPass;
        FOptions.FLearnMode := False;
      end;
    end;

    ClearPasswordEntry;
  end
  else
    PasswordCheck;
end;

procedure TTMSFNCCustomPassLock.Resize;
begin
  inherited;

  CalculateGrid;
end;

procedure TTMSFNCCustomPassLock.SetPassEntry(const Value: string);
begin
  if FPasswordEntry <> Value then
  begin
    FPasswordEntry := Value;
    CalculateGrid;
  end;
end;

procedure TTMSFNCCustomPassLock.SetPasswordValue(const Value: string);
begin
  if FPasswordValue <> Value then
  begin
    FPasswordValue := Value;
    FPasswordLength := Length(FPasswordValue);

    if not (csLoading in ComponentState) then
      DoNewPassword;

    CalculateGrid;
  end;
end;

{$IFDEF WEBLIB}
procedure TTMSFNCCustomPassLock.SetVisible(const Value: Boolean);
begin
  TControl(Self).Visible := Value;
  Invalidate;
end;
{$ENDIF}

procedure TTMSFNCCustomPassLock.SetButtonAppearance(const Value: TTMSFNCPassLockButtonAppearance);
begin
  FButtonAppearance.Assign(Value);
end;

procedure TTMSFNCCustomPassLock.SetEntryAppearance(
  const Value: TTMSFNCPassLockEntryAppearance);
begin
  FButtonAppearance.Assign(Value);
end;

procedure TTMSFNCCustomPassLock.SetOptions(const Value: TTMSFNCPassLockOptions);
begin
  FOptions.Assign(Value);
end;

procedure TTMSFNCCustomPassLock.SetPasswordEntry(AEntry: string);
begin
  PasswordEntry := AEntry;

  DoPassWordEntryChanged(AEntry);
end;

function TTMSFNCCustomPassLock.XYToButton(AX, AY: Single): Integer;
var
  I: Integer;
  r: TRectF;
begin
  Result := -1;
  for I := 0 to FButtonDrawList.Count - 1 do
  begin
    r := FButtonDrawList[i].ItemRect;

    if TTMSFNCGraphics.PointInRect(PointF(AX,AY), r) then
    begin
      if (FButtonAppearance.Shape = plbsCircle) then
      begin
        if TTMSFNCGraphics.PointInCircle(PointF(AX,AY), PointF(r.Left + (r.Right - r.Left)/2, r.Top + (r.Bottom - r.Top)/2), (r.Right - r.Left) / 2) then
        begin
          Result := I;
          Break;
        end;
      end
      else
      begin
        Result := I;
        Break;
      end;
    end;
  end;
end;

{ TTMSFNCPassLockButtonAppearance }

procedure TTMSFNCPassLockButtonAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCPassLockButtonAppearance then
  begin
    FDownFill.Assign((Source as TTMSFNCPassLockButtonAppearance).DownFill);
    FHoverFill.Assign((Source as TTMSFNCPassLockButtonAppearance).HoverFill);
    FFill.Assign((Source as TTMSFNCPassLockButtonAppearance).Fill);
    FDownStroke.Assign((Source as TTMSFNCPassLockButtonAppearance).DownStroke);
    FHoverStroke.Assign((Source as TTMSFNCPassLockButtonAppearance).HoverStroke);
    Stroke.Assign((Source as TTMSFNCPassLockButtonAppearance).Stroke);
    FDownFont.Assign((Source as TTMSFNCPassLockButtonAppearance).DownFont);
    FHoverFont.Assign((Source as TTMSFNCPassLockButtonAppearance).HoverFont);
    FFont.Assign((Source as TTMSFNCPassLockButtonAppearance).Font);
    FPatternLine.Assign((Source as TTMSFNCPassLockButtonAppearance).PatternLine);
    FSpacing := (Source as TTMSFNCPassLockButtonAppearance).Spacing;
    FMaxSize := (Source as TTMSFNCPassLockButtonAppearance).MaxSize;
    FShape := (Source as TTMSFNCPassLockButtonAppearance).Shape;
  end
  else
    inherited;
end;

constructor TTMSFNCPassLockButtonAppearance.Create(Source: TPersistent);
begin
  FDownFill := TTMSFNCGraphicsFill.Create(gfkSolid, TTMSFNCGraphics.HTMLToColor('#1BADF8'));
  FHoverFill := TTMSFNCGraphicsFill.Create(gfkSolid, TTMSFNCGraphics.HTMLToColor('#BBC4CC'));
  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcWhite);
  FDownStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcGray);
  FHoverStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcLightslategray);
  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkgray);
  FDownFont := TTMSFNCGraphicsFont.Create;
  FDownFont.Color := gcWhite;
  FHoverFont := TTMSFNCGraphicsFont.Create;
  FFont := TTMSFNCGraphicsFont.Create;

  FPatternLine := TTMSFNCGraphicsStroke.Create(gskSolid, gcGray);
  FPatternLine.Width := 6;

  FSpacing := 6;
  FMaxSize := 50;
  FShape := plbsCircle;

  FDownFill.OnChanged := @FillChanged;
  FHoverFill.OnChanged := @FillChanged;
  FFill.OnChanged := @FillChanged;
  FDownStroke.OnChanged := @StrokeChanged;
  FHoverStroke.OnChanged := @StrokeChanged;
  FStroke.OnChanged := @StrokeChanged;
  FDownFont.OnChanged := @FontChanged;
  FHoverFont.OnChanged := @FontChanged;
  FFont.OnChanged := @FontChanged;
  FPatternLine.OnChanged := @DoChanged;
end;

destructor TTMSFNCPassLockButtonAppearance.Destroy;
begin
  FDownFill.Free;
  FHoverFill.Free;
  FDownStroke.Free;
  FHoverStroke.Free;
  FDownFont.Free;
  FHoverFont.Free;
  FFont.Free;
  FFill.Free;
  FStroke.Free;
  FPatternLine.Free;

  inherited;
end;

procedure TTMSFNCPassLockButtonAppearance.DoChanged(Sender: TObject);
begin
  if Assigned(OnChanged) then
    OnChanged(Self);
end;

procedure TTMSFNCPassLockButtonAppearance.FillChanged(Sender: TObject);
begin
  DoChanged(Self);
end;

procedure TTMSFNCPassLockButtonAppearance.FontChanged(Sender: TObject);
begin
  DoChanged(Self);
end;

procedure TTMSFNCPassLockButtonAppearance.SetDownFill(const Value: TTMSFNCGraphicsFill);
begin
  FDownFill.Assign(Value);
  FillChanged(Self);
end;

procedure TTMSFNCPassLockButtonAppearance.SetDownFont(const Value: TTMSFNCGraphicsFont);
begin
  FDownFont.Assign(Value);
  FillChanged(Self);
end;

procedure TTMSFNCPassLockButtonAppearance.SetDownStroke(const Value: TTMSFNCGraphicsStroke);
begin
  FDownStroke.Assign(Value);
  StrokeChanged(Self);
end;

procedure TTMSFNCPassLockButtonAppearance.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  FFill.Assign(Value);
  FillChanged(Self);
end;

procedure TTMSFNCPassLockButtonAppearance.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  FFont.Assign(Value);
  FontChanged(Self);
end;

procedure TTMSFNCPassLockButtonAppearance.SetHoverFill(const Value: TTMSFNCGraphicsFill);
begin
  FHoverFill.Assign(Value);
  FillChanged(Self);
end;

procedure TTMSFNCPassLockButtonAppearance.SetHoverFont(const Value: TTMSFNCGraphicsFont);
begin
  FHoverFont.Assign(Value);
  FontChanged(Self);
end;

procedure TTMSFNCPassLockButtonAppearance.SetHoverStroke(const Value: TTMSFNCGraphicsStroke);
begin
  FHoverStroke.Assign(Value);
  StrokeChanged(Self);
end;

procedure TTMSFNCPassLockButtonAppearance.SetMaxSize(const Value: Single);
begin
  if FMaxSize <> Value then
  begin
    FMaxSize := Value;
    DoChanged(Self);
  end;
end;

procedure TTMSFNCPassLockButtonAppearance.SetPatternLine(
  const Value: TTMSFNCGraphicsStroke);
begin
  FPatternLine.Assign(Value);
  DoChanged(Self);
end;

procedure TTMSFNCPassLockButtonAppearance.SetShape(
  const Value: TTMSFNCPassLockButtonShape);
begin
  if FShape <> Value then
  begin
    FShape := Value;
    DoChanged(Self);
  end;
end;

procedure TTMSFNCPassLockButtonAppearance.SetSpacing(const Value: Single);
begin
  if FSpacing <> Value then
  begin
    FSpacing := Value;
    DoChanged(Self);
  end;
end;

procedure TTMSFNCPassLockButtonAppearance.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  FStroke.Assign(Value);
  StrokeChanged(Self);
end;

procedure TTMSFNCPassLockButtonAppearance.StrokeChanged(Sender: TObject);
begin
  DoChanged(Self);
end;

{ TTMSFNCPassLockEntryAppearance }

procedure TTMSFNCPassLockEntryAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCPassLockEntryAppearance then
  begin
    FDownFill.Assign((Source as TTMSFNCPassLockEntryAppearance).DownFill);
    FFill.Assign((Source as TTMSFNCPassLockEntryAppearance).Fill);
    FDownStroke.Assign((Source as TTMSFNCPassLockEntryAppearance).DownStroke);
    Stroke.Assign((Source as TTMSFNCPassLockEntryAppearance).Stroke);
    FSpacing := (Source as TTMSFNCPassLockEntryAppearance).Spacing;
    FMaxSize := (Source as TTMSFNCPassLockEntryAppearance).MaxSize;
  end
  else
    inherited;
end;

constructor TTMSFNCPassLockEntryAppearance.Create(Source: TPersistent);
begin
  FDownFill := TTMSFNCGraphicsFill.Create(gfkSolid, TTMSFNCGraphics.HTMLToColor('#1BADF8'));
  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcWhite);
  FDownStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcGray);
  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDarkgray);

  FSpacing := 6;
  FMaxSize := 10;

  FDownFill.OnChanged := @FillChanged;
  FFill.OnChanged := @FillChanged;
  FDownStroke.OnChanged := @StrokeChanged;
  FStroke.OnChanged := @StrokeChanged;
end;

destructor TTMSFNCPassLockEntryAppearance.Destroy;
begin
  FDownFill.Free;
  FDownStroke.Free;
  FFill.Free;
  FStroke.Free;

  inherited;
end;

procedure TTMSFNCPassLockEntryAppearance.DoChanged(Sender: TObject);
begin
  if Assigned(OnChanged) then
    OnChanged(Self);
end;

procedure TTMSFNCPassLockEntryAppearance.FillChanged(Sender: TObject);
begin
  DoChanged(Self);
end;

procedure TTMSFNCPassLockEntryAppearance.SetDownFill(const Value: TTMSFNCGraphicsFill);
begin
  FDownFill.Assign(Value);
  FillChanged(Self);
end;

procedure TTMSFNCPassLockEntryAppearance.SetDownStroke(const Value: TTMSFNCGraphicsStroke);
begin
  FDownStroke.Assign(Value);
  StrokeChanged(Self);
end;

procedure TTMSFNCPassLockEntryAppearance.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  FFill.Assign(Value);
  FillChanged(Self);
end;

procedure TTMSFNCPassLockEntryAppearance.SetMaxSize(const Value: single);
begin
  if FMaxSize <> Value then
  begin
    FMaxSize := Value;
    DoChanged(Self);
  end;
end;

procedure TTMSFNCPassLockEntryAppearance.SetSpacing(const Value: Single);
begin
  if FSpacing <> Value then
  begin
    FSpacing := Value;
    DoChanged(Self);
  end;
end;

procedure TTMSFNCPassLockEntryAppearance.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  FStroke.Assign(Value);
  StrokeChanged(Self);
end;

procedure TTMSFNCPassLockEntryAppearance.StrokeChanged(Sender: TObject);
begin
  DoChanged(Self);
end;

{ TTMSFNCPassLockOptions }

procedure TTMSFNCPassLockOptions.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCPassLockOptions then
  begin
    FLockType := (Source as TTMSFNCPassLockOptions).LockType;
    FShowEntry := (Source as TTMSFNCPassLockOptions).ShowEntry;
    FShowClearEntryButton := (Source as TTMSFNCPassLockOptions).ShowClearEntryButton;
    FShowOKButton := (Source as TTMSFNCPassLockOptions).ShowOKButton;
    FLearnMode:= (Source as TTMSFNCPassLockOptions).LearnMode;
    FShowPasswordLength := (Source as TTMSFNCPassLockOptions).ShowPasswordLength;
    FEnableKeyboardInput := (Source as TTMSFNCPassLockOptions).EnableKeyboardInput;
  end
  else
    inherited;
end;

constructor TTMSFNCPassLockOptions.Create(Source: TPersistent);
begin
  FLockType := pltNumber;
  FShowEntry := True;
  FShowClearEntryButton := False;
  FShowOKButton := False;
  FShowPasswordLength := True;
  FEnableKeyboardInput := True;
end;

destructor TTMSFNCPassLockOptions.Destroy;
begin

  inherited;
end;

procedure TTMSFNCPassLockOptions.DoChanged(Sender: TObject);
begin
  if Assigned(OnChanged) then
    OnChanged(Self);
end;

procedure TTMSFNCPassLockOptions.DoLearnModeChanged;
begin
  if Assigned(OnLearnModeChanged) then
    OnLearnModeChanged(Self);
end;

procedure TTMSFNCPassLockOptions.SetEnableKeyboardInput(const Value: Boolean);
begin
  if FEnableKeyboardInput <> Value then
  begin
    FEnableKeyboardInput := Value;
    DoChanged(Self);
  end;
end;

procedure TTMSFNCPassLockOptions.SetLearnMode(const Value: Boolean);
begin
  if FLearnMode <> Value then
  begin
    FLearnMode := Value;
    DoLearnModeChanged;
  end;
end;

procedure TTMSFNCPassLockOptions.SetLockType(const Value: TTMSFNCPassLockType);
begin
  if FLockType <> Value then
  begin
    FLockType := Value;
    DoChanged(Self);
  end
end;

procedure TTMSFNCPassLockOptions.SetShowClearEntryButton(const Value: Boolean);
begin
  if FShowClearEntryButton <> Value then
  begin
    FShowClearEntryButton := Value;
    DoChanged(Self);
  end;
end;

procedure TTMSFNCPassLockOptions.SetShowEntry(const Value: Boolean);
begin
  if FShowEntry <> Value then
  begin
    FShowEntry := Value;
    DoChanged(Self);
  end;
end;

procedure TTMSFNCPassLockOptions.SetShowOKButton(const Value: Boolean);
begin
  if FShowOKButton <> Value then
  begin
    FShowOKButton := Value;
    DoChanged(Self);
  end;
end;

procedure TTMSFNCPassLockOptions.SetShowPasswordLength(const Value: Boolean);
begin
  if FShowPasswordLength <> Value then
  begin
    FShowPasswordLength := Value;

    if not FShowPasswordLength then
      FShowOKButton := True;
    DoChanged(Self);
  end;
end;

{$IFDEF LCLLIB}
class operator TTMSFNCPassLockDrawValue.=(z1, z2: TTMSFNCPassLockDrawValue)b: boolean;
begin
  Result := z1 = z2;
end;
{$ENDIF}
{$IFDEF LCLLIB}
class operator TTMSFNCPassLockDrawEntry.=(z1, z2: TTMSFNCPassLockDrawEntry)b: boolean;
begin
  Result := z1 = z2;
end;
{$ENDIF}

end.

{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCGridOptions;

{$I WEBLib.TMSFNCDefines.inc}

interface

uses
  Classes, WEBLib.TMSFNCGridDataUtil, WEBLib.TMSFNCGraphicsTypes;

type
  TTMSFNCGridTabKeyHandling = (tkhNextControl, tkhNextCell, tkhMixed);

  TTMSFNCGridTabKeyDirection = (tkdNextColumnCell, tkdNextRowCell);

  TTMSFNCGridPrintPosition = (ppTopLeft, ppTopCenter, ppTopRight, ppBottomLeft, ppBottomCenter, ppBottomRight);

  TTMSFNCGridPrintCellBackground = (cbNone, cbCellColor, cbFull);

  TTMSFNCGridScrollBarOptions = class(TPersistent)
  private
    FOnChange: TNotifyEvent;
    FVerticalScrollBarVisible: Boolean;
    FHorizontalScrollBarVisible: Boolean;
    procedure SetHorizontalScrollBarVisible(const Value: Boolean);
    procedure SetVerticalScrollBarVisible(const Value: Boolean);
  protected
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create;
    destructor Destroy; override;
  published
    property HorizontalScrollBarVisible: Boolean read FHorizontalScrollBarVisible write SetHorizontalScrollBarVisible default True;
    property VerticalScrollBarVisible: Boolean read FVerticalScrollBarVisible write SetVerticalScrollBarVisible default True;
  end;

  {$IFDEF FMXLIB}
  TTMSFNCGridStylingOptions = class(TPersistent)
  private
    FAdaptToStyle: Boolean;
    FOnChange: TNotifyEvent;
    procedure SetAdaptToStyle(const Value: Boolean);
  protected
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create;
    destructor Destroy; override;
  published
    property AdaptToStyle: Boolean read FAdaptToStyle write SetAdaptToStyle default False;
  end;
  {$ENDIF}

  TTMSFNCGridBandOptions = class(TPersistent)
  private
    FEnabled: Boolean;
    FOnChange: TNotifyEvent;
    FBandRowCount: integer;
    FNormalRowCount: integer;
    FTotalRowCount: integer;
    procedure SetEnabled(const Value: Boolean);
    procedure SetBandRowCount(const Value: integer);
    procedure SetNormalRowCount(const Value: integer);
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create;
    procedure Changed; virtual;
    property TotalRowCount: integer read FTotalRowCount;
  published
    property NormalRowCount: integer read FNormalRowCount write SetNormalRowCount default 1;
    property BandRowCount: integer read FBandRowCount write SetBandRowCount default 1;
    property Enabled: Boolean read FEnabled write SetEnabled default False;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TTMSFNCGridSelectionMode = (smNone, smSingleCell, smSingleRow, smSingleColumn, smCellRange, smRowRange, smColumnRange, smDisjunctRow, smDisjunctColumn, smDisjunctCell);

  TTMSFNCGridSelection = class(TPersistent)
  private
    FMode: TTMSFNCGridSelectionMode;
    FOnChange: TNotifyEvent;
    FShowSelectionInFixedCells: Boolean;
    procedure SetMode(const Value: TTMSFNCGridSelectionMode);
    procedure SetShowSelectionInFixedCells(const Value: Boolean);
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create;
    procedure Changed;
  published
    property Mode: TTMSFNCGridSelectionMode read FMode write SetMode default smSingleCell;
    property ShowSelectionInFixedCells: Boolean read FShowSelectionInFixedCells write SetShowSelectionInFixedCells default False;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TTMSFNCGridFixedCellSelectionMode = (fcsAll, fcsRow, fcsColumn, fcsRowRange, fcsColumnRange, fcsDisjunctRow, fcsDisjunctColumn);

  TTMSFNCGridFixedCellSelection = set of TTMSFNCGridFixedCellSelectionMode;

  TTMSFNCGridAutoScrollingSpeedMode = (asmPixels, asmCell);

  TTMSFNCGridMouseOptions = class(TPersistent)
  private
    FDirectEdit: boolean;
    FWheelScrollSize: Integer;
    FAutoDragging: Boolean;
    FFixedRowSizing: Boolean;
    FFixedColumnSizing: Boolean;
    FRowDragging: Boolean;
    FColumnDragging: Boolean;
    FAutoScrollingInterval: Integer;
    FRowSizing: Boolean;
    FColumnSizing: Boolean;
    FAutoScrollingSpeed: Integer;
    FAutoScrolling: Boolean;
    FTouchScrolling: Boolean;
    FTouchScrollingSensitivity: Single;
    FFixedCellSelection: TTMSFNCGridFixedCellSelection;
    FRowAutoSizeOnDblClick: Boolean;
    FColumnAutoSizeOnDblClick: Boolean;
    FWheelScrollKeepSelection: Boolean;
    FRowSizeMargin: Integer;
    FColumnSizeMargin: Integer;
    FAutoScrollingSpeedMode: TTMSFNCGridAutoScrollingSpeedMode;
    FClickMargin: Integer;
    procedure SetWheelScrollSize(const Value: Integer);
    procedure SetAutoScrollingInterval(const Value: Integer);
    procedure SetTouchScrolling(const Value: Boolean);
    procedure SetWheelScrollKeepSelection(const Value: Boolean);
    function IsTouchScrollingSensitivityStored: Boolean;
    procedure SetColumnSizeMargin(const Value: Integer);
    procedure SetRowSizeMargin(const Value: Integer);
    procedure SetClickMargin(const Value: Integer);
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create;
  published
    property DirectEdit: boolean read FDirectEdit write FDirectEdit default false;
    property WheelScrollSize: Integer read FWheelScrollSize write SetWheelScrollSize default 4;
    property WheelScrollKeepSelection: Boolean read FWheelScrollKeepSelection write SetWheelScrollKeepSelection default False;

    property ColumnSizing: Boolean read FColumnSizing write FColumnSizing default False;
    property ClickMargin: Integer read FClickMargin write SetClickMargin default 2;
    property ColumnSizeMargin: Integer read FColumnSizeMargin write SetColumnSizeMargin default 2;
    property RowSizing: Boolean read FRowSizing write FRowSizing default False;
    property RowSizeMargin: Integer read FRowSizeMargin write SetRowSizeMargin default 2;
    property FixedColumnSizing: Boolean read FFixedColumnSizing write FFixedColumnSizing default False;
    property FixedRowSizing: Boolean read FFixedRowSizing write FFixedRowSizing default False;
    property FixedCellSelection: TTMSFNCGridFixedCellSelection read FFixedCellSelection write FFixedCellSelection default [];

    property ColumnAutoSizeOnDblClick: Boolean read FColumnAutoSizeOnDblClick write FColumnAutoSizeOnDblClick default True;
    property RowAutoSizeOnDblClick: Boolean read FRowAutoSizeOnDblClick write FRowAutoSizeOnDblClick default True;

    property ColumnDragging: Boolean read FColumnDragging write FColumnDragging default False;
    property RowDragging: Boolean read FRowDragging write FRowDragging default False;

    property AutoScrolling: Boolean read FAutoScrolling write FAutoScrolling default True;
    property AutoDragging: Boolean read FAutoDragging write FAutoDragging default True;
    property AutoScrollingSpeedMode: TTMSFNCGridAutoScrollingSpeedMode read FAutoScrollingSpeedMode write FAutoScrollingSpeedMode default asmPixels;
    property AutoScrollingSpeed: Integer read FAutoScrollingSpeed write FAutoScrollingSpeed default 1;
    property AutoScrollingInterval: Integer read FAutoScrollingInterval write SetAutoScrollingInterval default 100;

    property TouchScrolling: Boolean read FTouchScrolling write SetTouchScrolling default False;
    property TouchScrollingSensitivity: Single read FTouchScrollingSensitivity write FTouchScrollingSensitivity stored IsTouchScrollingSensitivityStored nodefault;
  end;

  TTMSFNCGridInsertKeyHandling = (ikhNone, ikhInsertRowBefore, ikhInsertRowAfter);

  TTMSFNCGridEnterKeyHandling = (ekhNone, ekhNextColumn, ekhNextRow, ekhNextColumnInRow, ekhNextRowInColumn,
    ekhNextColumnAndAppend, ekhNextRowAndAppend, ekhNextRowAndColumnAppend, ekhNextColumnAndRowAppend);

  TTMSFNCGridDeleteKeyHandling = (dkhNone, dkhDeleteRow);

  TTMSFNCGridKeyboardOptions = class(TPersistent)
  private
    FTabKeyDirection: TTMSFNCGridTabKeyDirection;
    FTabKeyHandling: TTMSFNCGridTabKeyHandling;
    FPageScrollSize: Integer;
    FEnterKeyHandling: TTMSFNCGridEnterKeyHandling;
    FEnterKeyDirectEdit: Boolean;
    FDeleteKeyHandling: TTMSFNCGridDeleteKeyHandling;
    FInsertKeyHandling: TTMSFNCGridInsertKeyHandling;
    FTabKeyDirectEdit: Boolean;
    FArrowKeyDirectEdit: Boolean;
    FAllowCellMergeShortCut: boolean;
    procedure SetPageScrollSize(const Value: Integer);
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create;
  published
    property AllowCellMergeShortCut: boolean read FAllowCellMergeShortCut write FAllowCellMergeShortCut default False;
    property TabKeyDirectEdit: Boolean read FTabKeyDirectEdit write FTabKeyDirectEdit default False;
    property ArrowKeyDirectEdit: Boolean read FArrowKeyDirectEdit write FArrowKeyDirectEdit default False;
    property InsertKeyHandling: TTMSFNCGridInsertKeyHandling read FInsertKeyHandling write FInsertKeyHandling default ikhNone;
    property DeleteKeyHandling: TTMSFNCGridDeleteKeyHandling read FDeleteKeyHandling write FDeleteKeyHandling default dkhNone;
    property EnterKeyHandling: TTMSFNCGridEnterKeyHandling read FEnterKeyHandling write FEnterKeyHandling default ekhNone;
    property EnterKeyDirectEdit: Boolean read FEnterKeyDirectEdit write FEnterKeyDirectEdit default False;
    property TabKeyHandling: TTMSFNCGridTabKeyHandling read FTabKeyHandling write FTabKeyHandling default tkhMixed;
    property TabKeyDirection: TTMSFNCGridTabKeyDirection read FTabKeyDirection write FTabKeyDirection default tkdNextColumnCell;
    property PageScrollSize: Integer read FPageScrollSize write SetPageScrollSize default 0;
  end;

  TTMSFNCGridSortBlankPosition = (blFirst,blLast);

  TTMSFNCGridSortingMode = (gsmNone, gsmNormal, gsmIndexed);

  TTMSFNCGridSortColumns = (scAll, scNormal, scSingle);

  TTMSFNCGridBorders = (bNone, bAll, bVertical, bHorizontal);

  TTMSFNCGridSortingOptions = class(TPersistent)
  private
    FMode: TTMSFNCGridSortingMode;
    FRow: Integer;
    FColumns: TTMSFNCGridSortColumns;
    FMultiColumn: boolean;
    FIgnoreCase: boolean;
    FIgnoreBlanks: boolean;
    FBlankPosition: TTMSFNCGridSortBlankPosition;
    procedure SetMode(const Value: TTMSFNCGridSortingMode);
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create;
  published
    property BlankPosition: TTMSFNCGridSortBlankPosition read FBlankPosition write FBlankPosition default blFirst;
    property Row: Integer read FRow write FRow default 0;
    property IgnoreCase: boolean read FIgnoreCase write FIgnoreCase default false;
    property IgnoreBlanks: boolean read FIgnoreBlanks write FIgnoreBlanks default false;
    property MultiColumn: boolean read FMultiColumn write FMultiColumn default true;
    property Mode: TTMSFNCGridSortingMode read FMode write SetMode default gsmNone;
    property Columns: TTMSFNCGridSortColumns read FColumns write FColumns default scAll;
  end;

  TTMSFNCGridBorderOptions = class(TPersistent)
  private
    FFixedCellBorders: TTMSFNCGridBorders;
    FCellBorders: TTMSFNCGridBorders;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create;
  published
    property FixedCellBorders: TTMSFNCGridBorders read FFixedCellBorders write FFixedCellBorders default bAll;
    property CellBorders: TTMSFNCGridBorders read FCellBorders write FCellBorders default bAll;
  end;

  TTMSFNCGridAutoComplete = (acDisabled, acNormal, acLookupList);

  TTMSFNCGridAutoPost = (apNone, apCell, apRow);

  TTMSFNCGridEditingOptions = class(TPersistent)
  private
    FEnabled: Boolean;
    FAutoComplete: TTMSFNCGridAutoComplete;
    FAutoCompleteItems: TStringList;
    FAutoHistory: boolean;
    FAutoCompleteCaseSensitive: boolean;
    FCalcFormat: string;
    FAutoPost: TTMSFNCGridAutoPost;
    FAutoCancel: Boolean;
    FDirectComboClose: Boolean;
    FDirectComboDrop: Boolean;
    FEditWithTags: boolean;
    procedure SetAutoCompleteItems(const Value: TStringList);
    procedure SetAutoPost(const Value: TTMSFNCGridAutoPost);
    procedure SetAutoCancel(const Value: Boolean);
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create;
    destructor Destroy; override;
  published
    property AutoCancel: Boolean read FAutoCancel write SetAutoCancel default False;
    property AutoComplete: TTMSFNCGridAutoComplete read FAutoComplete write FAutoComplete default acDisabled;
    property AutoCompleteCaseSensitive: boolean read FAutoCompleteCaseSensitive write FAutoCompleteCaseSensitive default true;
    property AutoCompleteItems: TStringList read FAutoCompleteItems write SetAutoCompleteItems;
    property AutoHistory: boolean read FAutoHistory write FAutoHistory default false;
    property AutoPost: TTMSFNCGridAutoPost read FAutoPost write SetAutoPost default apCell;
    property CalcFormat: string read FCalcFormat write FCalcFormat;
    property DirectComboDrop: Boolean read FDirectComboDrop write FDirectComboDrop default false;
    property DirectComboClose: Boolean read FDirectComboClose write FDirectComboClose default false;
    property Enabled: Boolean read FEnabled write FEnabled default True;
    property EditWithTags: boolean read FEditWithTags write FEditWithTags default True;
  end;

  TTMSFNCGridLookupOptions = class(TPersistent)
  private
    FCaseSensitive: boolean;
    FIncremental: boolean;
    FEnabled: boolean;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create;
  published
    property CaseSensitive: boolean read FCaseSensitive write FCaseSensitive default false;
    property Enabled: boolean read FEnabled write FEnabled default false;
    property Incremental: boolean read FIncremental write FIncremental default true;
  end;

  TTMSFNCGridFilterRows = (frNormal, frAll);

  TTMSFNCGridFilteringOptions = class(TPersistent)
  private
    FRows: TTMSFNCGridFilterRows;
    FDropDownFixedRow: integer;
    FDropDown: boolean;
    FOnChange: TNotifyEvent;
    FDropDownHeight: integer;
    FDropDownWidth: integer;
    FMultiColumn: boolean;
    procedure SetDropDown(const Value: boolean);
    procedure SetDropDownFixedRow(const Value: integer);
  public
    constructor Create;
    procedure Assign(Source : TPersistent); override;
    procedure Changed;
  published
    property Rows: TTMSFNCGridFilterRows read FRows write FRows default frNormal;
    property DropDown: boolean read FDropDown write SetDropDown default false;
    property DropDownFixedRow: integer read FDropDownFixedRow write SetDropDownFixedRow default 0;
    property DropDownWidth: integer read FDropDownWidth write FDropDownWidth default 100;
    property DropDownHeight: integer read FDropDownHeight write FDropDownHeight default 120;
    property MultiColumn: boolean read FMultiColumn write FMultiColumn default false;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TTMSFNCGridGroupingOptions = class(TPersistent)
  private
    FMergeHeader: Boolean;
    FMergeSummary: Boolean;
    FSummary: Boolean;
    FShowGroupCount: boolean;
    FCalcFormat: string;
    FAutoSelectGroup: boolean;
    FAutoCheckGroup: boolean;
    FGroupCountFormat: string;
  public
    constructor Create;
    procedure Assign(Source: TPersistent); override;
  published
    property AutoSelectGroup: boolean read FAutoSelectGroup write FAutoSelectGroup default False;
    property AutoCheckGroup: boolean read FAutoCheckGroup write FAutoCheckGroup default False;
    property CalcFormat: string read FCalcFormat write FCalcFormat;
    property MergeHeader: Boolean read FMergeHeader write FMergeHeader default false;
    property MergeSummary: Boolean read FMergeSummary write FMergeSummary default false;
    property ShowGroupCount: Boolean read FShowGroupCount write FShowGroupCount default false;
    property GroupCountFormat: string read FGroupCountFormat write FGroupCountFormat;
    property Summary: Boolean read FSummary write FSummary default false;
  end;

  TTMSFNCGridClipboardPasteAction = (paReplace, paInsert);

  TTMSFNCGridClipboardOptions = class(TPersistent)
  private
    FEnabled: boolean;
    FIgnoreReadOnly: boolean;
    FAllowColGrow: boolean;
    FAllowRowGrow: boolean;
    FPasteAction: TTMSFNCGridClipboardPasteAction;
  public
    constructor Create;
    procedure Assign(Source: TPersistent); override;
  published
    property Enabled: boolean read FEnabled write FEnabled default true;
    property IgnoreReadOnly: boolean read FIgnoreReadOnly write FIgnoreReadOnly default false;
    property AllowColGrow: boolean read FAllowColGrow write FAllowColGrow default false;
    property AllowRowGrow: boolean read FAllowRowGrow write FAllowRowGrow default false;
    property PasteAction: TTMSFNCGridClipboardPasteAction read FPasteAction write FPasteAction default paReplace;
  end;

  TTMSFNCGridHTMLExportOptions = class(TPersistent)
  private
    FHeaderFile: string;
    FFooterFile: string;
    FShow: boolean;
    FConvertSpecialChars: Boolean;
    FWidth: integer;
    FNonBreakingText: Boolean;
    FBorderSize: integer;
    FCellPadding: Integer;
    FPrefixTag: string;
    FSuffixTag: string;
    FSaveFonts: Boolean;
    FExportImages: boolean;
    FCellSpacing: Integer;
    FSummary: string;
    FSaveColors: Boolean;
    FXHTML: boolean;
    FTableStyle: string;
    FColumnWidths: TTMSFNCGridIntList;
  public
    constructor Create;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property ColumnWidths: TTMSFNCGridIntList read FColumnWidths write FColumnWidths;
  published
    property BorderSize: integer read FBorderSize write FBorderSize default 1;
    property CellSpacing: Integer read FCellSpacing write FCellSpacing default 0;
    property CellPadding: Integer read FCellPadding write FCellPadding default 0;
    property ConvertSpecialChars: Boolean read FConvertSpecialChars write FConvertSpecialChars default False;
    property ExportImages: boolean read FExportImages write FExportImages default True;
    property FooterFile: string read FFooterFile write FFooterFile;
    property HeaderFile: string read FHeaderFile write FHeaderFile;
    property NonBreakingText: Boolean read FNonBreakingText write FNonBreakingText default False;
    property PrefixTag: string read FPrefixTag write FPrefixTag;
    property SaveColors: Boolean read FSaveColors write FSaveColors default True;
    property SaveFonts: Boolean read FSaveFonts write FSaveFonts default True;
    property SuffixTag: string read FSuffixTag write FSuffixTag;
    property Show: boolean read FShow write FShow default false;
    property Summary: string read FSummary write FSummary;
    property TableStyle: string read FTableStyle write FTableStyle;
    property Width: integer read FWidth write FWidth default 100;
    property XHTML: boolean read FXHTML write FXHTML default false;
  end;

  TTMSFNCGridURLOptions = class(TPersistent)
  private
    FUnderline: boolean;
    FColor: TTMSFNCGraphicsColor;
    FShow: boolean;
    FOnChange: TNotifyEvent;
    FFull: boolean;
    FOpen: boolean;
    procedure SetColor(const Value: TTMSFNCGraphicsColor);
    procedure SetUnderline(const Value: boolean);
    procedure SetFull(const Value: boolean);
  public
    constructor Create;
    procedure Assign(Source: TPersistent); override;
    procedure Changed; virtual;
  published
    property Color: TTMSFNCGraphicsColor read FColor write SetColor default gcBlue;
    property Full: boolean read FFull write SetFull default true;
    property Open: boolean read FOpen write FOpen default true;
    property Show: boolean read FShow write FShow default true;
    property Underline: boolean read FUnderline write SetUnderline default true;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TTMSFNCGridIOOptions = class(TPersistent)
  private
    FDelimiter: char;
    FQuoteEmptyCells: boolean;
    FXMLEncoding: string;
    FAlwaysQuotes: boolean;
    FSaveVirtualCellData: Boolean;
  public
    constructor Create;
    procedure Assign(Source: TPersistent); override;
  published
    property AlwaysQuotes: boolean read FAlwaysQuotes write FAlwaysQuotes default false;
    property Delimiter: char read FDelimiter write FDelimiter default #0;
    property QuoteEmptyCells: boolean read FQuoteEmptyCells write FQuoteEmptyCells default true;
    property XMLEncoding: string read FXMLEncoding write FXMLEncoding;
    property SaveVirtualCellData: Boolean read FSaveVirtualCellData write FSaveVirtualCellData default True;
  end;

  TTMSFNCGridCellOptions = class(TPersistent)
  private
    FDisplayHTMLFormatting: Boolean;
    FOnChange: TNotifyEvent;
    procedure SetDisplayHTMLFormatting(const Value: Boolean);
  protected
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  public
    constructor Create;
    procedure Assign(Source: TPersistent); override;
  published
    property DisplayHTMLFormatting: Boolean read FDisplayHTMLFormatting write SetDisplayHTMLFormatting default False;
  end;

  TTMSFNCGridColumnSizeOptions = class(TPersistent)
  private
    FStretch: Boolean;
    FStretchAll: Boolean;
    FStretchColumn: Integer;
    FOnChange: TNotifyEvent;
    FSyncWithGrid: Boolean;
    FSyncNormalCellsOnly: Boolean;
    procedure SetStretch(const Value: Boolean);
    procedure SetStretchAll(const Value: Boolean);
    procedure SetStretchColumn(const Value: Integer);
    procedure SetNormalCellsOnly(const Value: Boolean);
    procedure SetSyncWithGrid(const Value: Boolean);
  public
    constructor Create;
    procedure Assign(Source: TPersistent); override;
  published
    property Stretch: Boolean read FStretch write SetStretch default False;
    property StretchColumn: Integer read FStretchColumn write SetStretchColumn default -1;
    property StretchAll: Boolean read FStretchAll write SetStretchAll default False;
    property SyncWithGrid: Boolean read FSyncWithGrid write SetSyncWithGrid default False;
    property SyncNormalCellsOnly: Boolean read FSyncNormalCellsOnly write SetNormalCellsOnly default False;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TTMSFNCGridOptions = class(TPersistent)
  private
    FMouse: TTMSFNCGridMouseOptions;
    FKeyboard: TTMSFNCGridKeyboardOptions;
    FSorting: TTMSFNCGridSortingOptions;
    FGrouping: TTMSFNCGridGroupingOptions;
    FFiltering: TTMSFNCGridFilteringOptions;
    FEditing: TTMSFNCGridEditingOptions;
    FBorders: TTMSFNCGridBorderOptions;
    FLookup: TTMSFNCGridLookupOptions;
    FClipboard: TTMSFNCGridClipboardOptions;
    FHTMLExport: TTMSFNCGridHTMLExportOptions;
    FBands: TTMSFNCGridBandOptions;
    FOnChange: TNotifyEvent;
    FURL: TTMSFNCGridURLOptions;
    FScrollBar: TTMSFNCGridScrollBarOptions;
    FIO: TTMSFNCGridIOOptions;
    FCell: TTMSFNCGridCellOptions;
    FColumnSize: TTMSFNCGridColumnSizeOptions;
    FSelection: TTMSFNCGridSelection;
    {$IFDEF FMXLIB}
    FStyling: TTMSFNCGridStylingOptions;
    {$ENDIF}
    procedure SetKeyboard(const Value: TTMSFNCGridKeyboardOptions);
    procedure SetMouse(const Value: TTMSFNCGridMouseOptions);
    procedure SetGrouping(const Value: TTMSFNCGridGroupingOptions);
    procedure SetSorting(const Value: TTMSFNCGridSortingOptions);
    procedure SetFiltering(const Value: TTMSFNCGridFilteringOptions);
    procedure SetEditing(const Value: TTMSFNCGridEditingOptions);
    procedure SetBorders(const Value: TTMSFNCGridBorderOptions);
    procedure SetLookup(const Value: TTMSFNCGridLookupOptions);
    procedure SetClipboard(const Value: TTMSFNCGridClipboardOptions);
    procedure SetHTMLExport(const Value: TTMSFNCGridHTMLExportOptions);
    procedure SetBands(const Value: TTMSFNCGridBandOptions);
    procedure SetURL(const Value: TTMSFNCGridURLOptions);
    procedure SetScrollBar(const Value: TTMSFNCGridScrollBarOptions);
    procedure SetIO(const Value: TTMSFNCGridIOOptions);
    procedure SetCell(const Value: TTMSFNCGridCellOptions);
    procedure SetColumnSize(const Value: TTMSFNCGridColumnSizeOptions);
    procedure SetSelection(const Value: TTMSFNCGridSelection);
    {$IFDEF FMXLIB}
    procedure SetStyling(const Value: TTMSFNCGridStylingOptions);
    {$ENDIF}
  public
    procedure DoChange(Sender: TObject);
    constructor Create;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  published
    property Bands: TTMSFNCGridBandOptions read FBands write SetBands;
    property Borders: TTMSFNCGridBorderOptions read FBorders write SetBorders;
    property Cell: TTMSFNCGridCellOptions read FCell write SetCell;
    property ColumnSize: TTMSFNCGridColumnSizeOptions read FColumnSize write SetColumnSize;
    property Clipboard: TTMSFNCGridClipboardOptions read FClipboard write SetClipboard;
    property Editing: TTMSFNCGridEditingOptions read FEditing write SetEditing;
    property Filtering: TTMSFNCGridFilteringOptions read FFiltering write SetFiltering;
    property Grouping: TTMSFNCGridGroupingOptions read FGrouping write SetGrouping;
    property HTMLExport: TTMSFNCGridHTMLExportOptions read FHTMLExport write SetHTMLExport;
    property IO: TTMSFNCGridIOOptions read FIO write SetIO;
    property Keyboard: TTMSFNCGridKeyboardOptions read FKeyboard write SetKeyboard;
    property Lookup: TTMSFNCGridLookupOptions read FLookup write SetLookup;
    property Mouse: TTMSFNCGridMouseOptions read FMouse write SetMouse;
    {$IFDEF FMXLIB}
    property Styling: TTMSFNCGridStylingOptions read FStyling write SetStyling;
    {$ENDIF}
    property Sorting: TTMSFNCGridSortingOptions read FSorting write SetSorting;
    property URL: TTMSFNCGridURLOptions read FURL write SetURL;
    property ScrollBar: TTMSFNCGridScrollBarOptions read FScrollBar write SetScrollBar;
    property Selection: TTMSFNCGridSelection read FSelection write SetSelection;
  end;

implementation

{$IFDEF WEBLIB}
uses
  Types;
{$ENDIF}

{ TTMSFNCGridGroupingOptions }

procedure TTMSFNCGridGroupingOptions.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGridGroupingOptions) then
  begin
    FCalcFormat := (Source as TTMSFNCGridGroupingOptions).CalcFormat;
    FMergeHeader := (Source as TTMSFNCGridGroupingOptions).MergeHeader;
    FMergeSummary := (Source as TTMSFNCGridGroupingOptions).MergeSummary;
    FShowGroupCount := (Source as TTMSFNCGridGroupingOptions).ShowGroupCount;
    FAutoCheckGroup := (Source as TTMSFNCGridGroupingOptions).AutoCheckGroup;
    FAutoSelectGroup := (Source as TTMSFNCGridGroupingOptions).AutoSelectGroup;
    FGroupCountFormat := (Source as TTMSFNCGridGroupingOptions).GroupCountFormat;
  end;
end;

constructor TTMSFNCGridGroupingOptions.Create;
begin
  inherited Create;
  FCalcFormat := '%g';
  FGroupCountFormat := '(%d)';
end;

{ TTMSFNCGridOptions }

procedure TTMSFNCGridOptions.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGridOptions) then
  begin
    FMouse.Assign((Source as TTMSFNCGridOptions).Mouse);
    FKeyboard.Assign((Source as TTMSFNCGridOptions).Keyboard);
    FFiltering.Assign((Source as TTMSFNCGridOptions).Filtering);
    FSorting.Assign((Source as TTMSFNCGridOptions).Sorting);
    FGrouping.Assign((Source as TTMSFNCGridOptions).Grouping);
    FEditing.Assign((Source as TTMSFNCGridOptions).Editing);
    FBorders.Assign((Source as TTMSFNCGridOptions).Borders);
    FLookup.Assign((Source as TTMSFNCGridOptions).Lookup);
    FClipboard.Assign((Source as TTMSFNCGridOptions).Clipboard);
    FCell.Assign((Source as TTMSFNCGridOptions).Cell);
    FBands.Assign((Source as TTMSFNCGridOptions).Bands);
    {$IFDEF FMXLIB}
    FStyling.Assign((Source as TTMSFNCGridOptions).Styling);
    {$ENDIF}
    FHTMLExport.Assign((Source as TTMSFNCGridOptions).HTMLExport);
    FURL.Assign((Source as TTMSFNCGridOptions).URL);
    FScrollBar.Assign((Source as TTMSFNCGridOptions).ScrollBar);
    FIO.Assign((Source as TTMSFNCGridOptions).IO);
    FSelection.Assign((Source as TTMSFNCGridOptions).Selection);
    FColumnSize.Assign((Source as TTMSFNCGridOptions).ColumnSize);
  end;
end;

constructor TTMSFNCGridOptions.Create;
begin
  inherited;
  FScrollBar := TTMSFNCGridScrollBarOptions.Create;
  FScrollBar.OnChange := @DoChange;
  FMouse := TTMSFNCGridMouseOptions.Create;
  FKeyboard := TTMSFNCGridKeyboardOptions.Create;
  FSorting := TTMSFNCGridSortingOptions.Create;
  FGrouping := TTMSFNCGridGroupingOptions.Create;
  FFiltering := TTMSFNCGridFilteringOptions.Create;
  FFiltering.OnChange := @DoChange;
  FCell := TTMSFNCGridCellOptions.Create;
  FCell.OnChange := @DoChange;
  FEditing := TTMSFNCGridEditingOptions.Create;
  FColumnSize := TTMSFNCGridColumnSizeOptions.Create;
  FColumnSize.OnChange := @DoChange;
  {$IFDEF FMXLIB}
  FStyling := TTMSFNCGridStylingOptions.Create;
  FStyling.OnChange := @DoChange;
  {$ENDIF}
  FBorders := TTMSFNCGridBorderOptions.Create;
  FLookup := TTMSFNCGridLookupOptions.Create;
  FClipboard := TTMSFNCGridClipboardOptions.Create;
  FHTMLExport := TTMSFNCGridHTMLExportOptions.Create;
  FIO := TTMSFNCGridIOOptions.Create;
  FBands := TTMSFNCGridBandOptions.Create;
  FBands.OnChange := @DoChange;
  FURL := TTMSFNCGridURLOptions.Create;
  FURL.OnChange := @DoChange;
  FSelection := TTMSFNCGridSelection.Create;
  FSelection.OnChange := @DoChange;
end;

destructor TTMSFNCGridOptions.Destroy;
begin
  FCell.Free;
  {$IFDEF FMXLIB}
  FStyling.Free;
  {$ENDIF}
  FColumnSize.Free;
  FScrollBar.Free;
  FBands.Free;
  FBorders.Free;
  FEditing.Free;
  FMouse.Free;
  FKeyboard.Free;
  FSorting.Free;
  FGrouping.Free;
  FFiltering.Free;
  FLookup.Free;
  FClipboard.Free;
  FHTMLExport.Free;
  FURL.Free;
  FIO.Free;
  FSelection.Free;
  inherited;
end;

procedure TTMSFNCGridOptions.DoChange(Sender: TObject);
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

procedure TTMSFNCGridOptions.SetBands(const Value: TTMSFNCGridBandOptions);
begin
  FBands.Assign(Value);
end;

procedure TTMSFNCGridOptions.SetBorders(const Value: TTMSFNCGridBorderOptions);
begin
  FBorders.Assign(Value);
end;

procedure TTMSFNCGridOptions.SetCell(const Value: TTMSFNCGridCellOptions);
begin
  FCell.Assign(Value);
end;

procedure TTMSFNCGridOptions.SetClipboard(
  const Value: TTMSFNCGridClipboardOptions);
begin
  FClipboard.Assign(Value);
end;

procedure TTMSFNCGridOptions.SetColumnSize(
  const Value: TTMSFNCGridColumnSizeOptions);
begin
  FColumnSize.Assign(Value);
end;

procedure TTMSFNCGridOptions.SetEditing(const Value: TTMSFNCGridEditingOptions);
begin
  FEditing.Assign(Value);
end;

procedure TTMSFNCGridOptions.SetFiltering(
  const Value: TTMSFNCGridFilteringOptions);
begin
  FFiltering.Assign(Value);
end;

procedure TTMSFNCGridOptions.SetGrouping(
  const Value: TTMSFNCGridGroupingOptions);
begin
  FGrouping.Assign(Value);
end;

procedure TTMSFNCGridOptions.SetHTMLExport(
  const Value: TTMSFNCGridHTMLExportOptions);
begin
  FHTMLExport.Assign(Value);
end;

procedure TTMSFNCGridOptions.SetIO(const Value: TTMSFNCGridIOOptions);
begin
  FIO.Assign(Value);
end;

procedure TTMSFNCGridOptions.SetKeyboard(
  const Value: TTMSFNCGridKeyboardOptions);
begin
  FKeyboard.Assign(Value);
end;

procedure TTMSFNCGridOptions.SetLookup(const Value: TTMSFNCGridLookupOptions);
begin
  FLookup.Assign(Value);
end;

procedure TTMSFNCGridOptions.SetMouse(const Value: TTMSFNCGridMouseOptions);
begin
  FMouse.Assign(Value);
end;

procedure TTMSFNCGridOptions.SetScrollBar(
  const Value: TTMSFNCGridScrollBarOptions);
begin
  FScrollBar.Assign(Value);
end;

procedure TTMSFNCGridOptions.SetSelection(const Value: TTMSFNCGridSelection);
begin
  FSelection.Assign(Value);
end;

procedure TTMSFNCGridOptions.SetSorting(const Value: TTMSFNCGridSortingOptions);
begin
  FSorting.Assign(Value);
end;

{$IFDEF FMXLIB}
procedure TTMSFNCGridOptions.SetStyling(const Value: TTMSFNCGridStylingOptions);
begin
  FStyling.Assign(Value);
end;
{$ENDIF}

procedure TTMSFNCGridOptions.SetURL(const Value: TTMSFNCGridURLOptions);
begin
  FURL.Assign(Value);
end;

{ TTMSFNCGridMouseOptions }

procedure TTMSFNCGridMouseOptions.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGridMouseOptions) then
  begin
    FDirectEdit := (Source as TTMSFNCGridMouseOptions).DirectEdit;
    FWheelScrollSize := (Source as TTMSFNCGridMouseOptions).WheelScrollSize;
    FColumnSizing := (Source as TTMSFNCGridMouseOptions).ColumnSizing;
    FRowSizing := (Source as TTMSFNCGridMouseOptions).RowSizing;
    FFixedColumnSizing := (Source as TTMSFNCGridMouseOptions).FixedColumnSizing;
    FFixedRowSizing := (Source as TTMSFNCGridMouseOptions).FixedRowSizing;
    FColumnDragging := (Source as TTMSFNCGridMouseOptions).ColumnDragging;
    FRowDragging := (Source as TTMSFNCGridMouseOptions).RowDragging;
    FAutoScrolling := (Source as TTMSFNCGridMouseOptions).AutoScrolling;
    FAutoDragging := (Source as TTMSFNCGridMouseOptions).AutoDragging;
    FAutoScrollingSpeed := (Source as TTMSFNCGridMouseOptions).AutoScrollingSpeed;
    FAutoScrollingInterval := (Source as TTMSFNCGridMouseOptions).AutoScrollingInterval;
    FTouchScrolling := (Source as TTMSFNCGridMouseOptions).TouchScrolling;
    FTouchScrollingSensitivity := (Source as TTMSFNCGridMouseOptions).TouchScrollingSensitivity;
    FFixedCellSelection := (Source as TTMSFNCGridMouseOptions).FixedCellSelection;
    FRowAutoSizeOnDblClick := (Source as TTMSFNCGridMouseOptions).RowAutoSizeOnDblClick;
    FColumnAutoSizeOnDblClick := (Source as TTMSFNCGridMouseOptions).ColumnAutoSizeOnDblClick;
    FWheelScrollKeepSelection := (Source as TTMSFNCGridMouseOptions).WheelScrollKeepSelection;
    FColumnSizeMargin := (Source as TTMSFNCGridMouseOptions).ColumnSizeMargin;
    FClickMargin := (Source as TTMSFNCGridMouseOptions).ClickMargin;
    FRowSizeMargin := (Source as TTMSFNCGridMouseOptions).RowSizeMargin;
    FAutoScrollingSpeedMode  := (Source as TTMSFNCGridMouseOptions).AutoScrollingSpeedMode;
  end;
end;

constructor TTMSFNCGridMouseOptions.Create;
begin
  FTouchScrollingSensitivity := 1;
  FTouchScrolling := False;
  FDirectEdit := False;
  FWheelScrollSize := 4;
  FColumnSizing := False;
  FRowSizing := False;
  FFixedColumnSizing := False;
  FFixedRowSizing := False;
  FColumnDragging := False;
  FRowDragging := False;
  FAutoScrolling := True;
  FAutoDragging := True;
  FAutoScrollingSpeed := 1;
  FAutoScrollingInterval := 100;
  FFixedCellSelection := [];
  FRowAutoSizeOnDblClick := True;
  FColumnAutoSizeOnDblClick := True;
  FWheelScrollKeepSelection := False;
  FColumnSizeMargin := 6;
  FRowSizeMargin := 6;
  FAutoScrollingSpeedMode := asmPixels;
end;

function TTMSFNCGridMouseOptions.IsTouchScrollingSensitivityStored: Boolean;
begin
  Result := TouchScrollingSensitivity <> 1;
end;

procedure TTMSFNCGridMouseOptions.SetAutoScrollingInterval(
  const Value: Integer);
begin
  FAutoScrollingInterval := Value;
end;

procedure TTMSFNCGridMouseOptions.SetClickMargin(const Value: Integer);
begin
  FClickMargin := Value;
end;

procedure TTMSFNCGridMouseOptions.SetColumnSizeMargin(const Value: Integer);
begin
  FColumnSizeMargin := Value;
end;

procedure TTMSFNCGridMouseOptions.SetRowSizeMargin(const Value: Integer);
begin
  FRowSizeMargin := Value;
end;

procedure TTMSFNCGridMouseOptions.SetTouchScrolling(const Value: Boolean);
begin
  FTouchScrolling := Value;
end;

procedure TTMSFNCGridMouseOptions.SetWheelScrollKeepSelection(
  const Value: Boolean);
begin
  FWheelScrollKeepSelection := Value;
end;

procedure TTMSFNCGridMouseOptions.SetWheelScrollSize(const Value: Integer);
begin
  FWheelScrollSize := Value;
end;

{ TTMSFNCGridKeyboardOptions }

procedure TTMSFNCGridKeyboardOptions.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGridKeyboardOptions) then
  begin
    FTabKeyDirection := (Source as TTMSFNCGridKeyboardOptions).TabKeyDirection;
    FTabKeyHandling := (Source as TTMSFNCGridKeyboardOptions).TabKeyHandling;
    FEnterKeyHandling := (Source as TTMSFNCGridKeyboardOptions).EnterKeyHandling;
    FDeleteKeyHandling := (Source as TTMSFNCGridKeyboardOptions).DeleteKeyHandling;
    FInsertKeyHandling := (Source as TTMSFNCGridKeyboardOptions).InsertKeyHandling;
    FEnterKeyDirectEdit := (Source as TTMSFNCGridKeyboardOptions).EnterKeyDirectEdit;
    FTabKeyDirectEdit := (Source as TTMSFNCGridKeyboardOptions).TabKeyDirectEdit;
    FArrowKeyDirectEdit := (Source as TTMSFNCGridKeyboardOptions).ArrowKeyDirectEdit;
    FAllowCellMergeShortCut := (Source as TTMSFNCGridKeyboardOptions).AllowCellMergeShortCut;
  end;
end;

constructor TTMSFNCGridKeyboardOptions.Create;
begin
  FTabKeyDirection := tkdNextColumnCell;
  FTabKeyHandling := tkhMixed;
  FPageScrollSize := 0;
  FEnterKeyHandling := ekhNone;
  FDeleteKeyHandling := dkhNone;
  FInsertKeyHandling := ikhNone;
  FEnterKeyDirectEdit := False;
  FArrowKeyDirectEdit := False;
  FTabKeyDirectEdit := False;
  FAllowCellMergeShortCut := False;
end;

procedure TTMSFNCGridKeyboardOptions.SetPageScrollSize(const Value: Integer);
begin
  FPageScrollSize := Value;
end;

{ TTMSFNCGridSortingOptions }

procedure TTMSFNCGridSortingOptions.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCGridSortingOptions then
  begin
    FMultiColumn := (Source as TTMSFNCGridSortingOptions).MultiColumn;
    FMode := (Source as TTMSFNCGridSortingOptions).Mode;
    FColumns := (Source as TTMSFNCGridSortingOptions).Columns;
    FRow := (Source as TTMSFNCGridSortingOptions).Row;
    FBlankPosition := (Source as TTMSFNCGridSortingOptions).BlankPosition;
    FIgnoreCase := (Source as TTMSFNCGridSortingOptions).IgnoreCase;
    FIgnoreBlanks := (Source as TTMSFNCGridSortingOptions).IgnoreBlanks;
  end;
end;

constructor TTMSFNCGridSortingOptions.Create;
begin
  inherited Create;
  FMode := gsmNone;
  FMultiColumn := true;
  FRow := 0;
end;

procedure TTMSFNCGridSortingOptions.SetMode(
  const Value: TTMSFNCGridSortingMode);
begin
  FMode := Value;
end;

{ TTMSFNCGridEditingOptions }

procedure TTMSFNCGridEditingOptions.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGridEditingOptions) then
  begin
    FEnabled := (Source as TTMSFNCGridEditingOptions).Enabled;
    FAutoComplete := (Source as TTMSFNCGridEditingOptions).AutoComplete;
    FAutoCompleteItems.Assign((Source as TTMSFNCGridEditingOptions).AutoCompleteItems);
    FAutoHistory := (Source as TTMSFNCGridEditingOptions).AutoHistory;
    FAutoCompleteCaseSensitive := (Source as TTMSFNCGridEditingOptions).AutoCompleteCaseSensitive;
    FCalcFormat := (Source as TTMSFNCGridEditingOptions).CalcFormat;
    FAutoPost := (Source as TTMSFNCGridEditingOptions).AutoPost;
    FAutoCancel := (Source as TTMSFNCGridEditingOptions).AutoCancel;
    FEditWithTags := (Source as TTMSFNCGridEditingOptions).EditWithTags;
  end;
end;

constructor TTMSFNCGridEditingOptions.Create;
begin
  inherited Create;
  FEnabled := True;
  FAutoCompleteItems := TStringList.Create;
  FAutoCompleteItems.Duplicates := dupIgnore;
  FAutoComplete := acDisabled;
  FAutoHistory := false;
  FAutoCompleteCaseSensitive := true;
  FCalcFormat := '%g';
  FAutoPost := apCell;
  FAutoCancel := False;
  FEditWithTags := True;
end;


destructor TTMSFNCGridEditingOptions.Destroy;
begin
  FAutoCompleteItems.Free;
  inherited;
end;

procedure TTMSFNCGridEditingOptions.SetAutoCompleteItems(
  const Value: TStringList);
begin
  FAutoCompleteItems.Assign(Value);
end;

procedure TTMSFNCGridEditingOptions.SetAutoCancel(const Value: Boolean);
begin
  FAutoCancel := Value;
end;

procedure TTMSFNCGridEditingOptions.SetAutoPost(
  const Value: TTMSFNCGridAutoPost);
begin
  FAutoPost := Value;
end;

{ TTMSFNCGridBorderOptions }

procedure TTMSFNCGridBorderOptions.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGridBorderOptions) then
  begin
    FFixedCellBorders := (Source as TTMSFNCGridBorderOptions).FixedCellBorders;
    FCellBorders := (Source as TTMSFNCGridBorderOptions).CellBorders;
  end;
end;

constructor TTMSFNCGridBorderOptions.Create;
begin
  FFixedCellBorders := bAll;
  FCellBorders := bAll;
end;

{ TTMSFNCGridLookupOptions }

procedure TTMSFNCGridLookupOptions.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGridLookupOptions) then
  begin
    FCaseSensitive := (Source as TTMSFNCGridLookupOptions).CaseSensitive;
    FIncremental := (Source as TTMSFNCGridLookupOptions).Incremental;
    FEnabled := (Source as TTMSFNCGridLookupOptions).Enabled;
  end;
end;

constructor TTMSFNCGridLookupOptions.Create;
begin
  inherited;
  FCaseSensitive := false;
  FIncremental := true;
end;

{ TTMSFNCGridClipboardOptions }

procedure TTMSFNCGridClipboardOptions.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGridClipboardOptions) then
  begin
    FEnabled := (Source as TTMSFNCGridClipboardOptions).Enabled;
    FIgnoreReadOnly := (Source as TTMSFNCGridClipboardOptions).IgnoreReadOnly;
    FAllowColGrow := (Source as TTMSFNCGridClipboardOptions).AllowColGrow;
    FAllowRowGrow := (Source as TTMSFNCGridClipboardOptions).AllowRowGrow;
    FPasteAction := (Source as TTMSFNCGridClipboardOptions).PasteAction;
  end;
end;

constructor TTMSFNCGridClipboardOptions.Create;
begin
  inherited;
  FEnabled := true;
  FIgnoreReadOnly := false;
  FAllowColGrow := false;
  FAllowRowGrow := false;
  FPasteAction := paReplace;
end;

{ TTMSFNCGridHTMLExportOptions }

procedure TTMSFNCGridHTMLExportOptions.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGridHTMLExportOptions) then
  begin
    FHeaderFile := (Source as TTMSFNCGridHTMLExportOptions).HeaderFile;
    FFooterFile := (Source as TTMSFNCGridHTMLExportOptions).FooterFile;
    FShow := (Source as TTMSFNCGridHTMLExportOptions).Show;
    FBorderSize := (Source as TTMSFNCGridHTMLExportOptions).BorderSize;
    FCellSpacing := (Source as TTMSFNCGridHTMLExportOptions).CellSpacing;
    FCellPadding := (Source as TTMSFNCGridHTMLExportOptions).CellPadding;
    FConvertSpecialChars := (Source as TTMSFNCGridHTMLExportOptions).ConvertSpecialChars;
    FExportImages := (Source as TTMSFNCGridHTMLExportOptions).ExportImages;
    FNonBreakingText := (Source as TTMSFNCGridHTMLExportOptions).NonBreakingText;
    FPrefixTag := (Source as TTMSFNCGridHTMLExportOptions).PrefixTag;
    FSaveColors := (Source as TTMSFNCGridHTMLExportOptions).SaveColors;
    FSaveFonts := (Source as TTMSFNCGridHTMLExportOptions).SaveFonts;
    FSuffixTag := (Source as TTMSFNCGridHTMLExportOptions).SuffixTag;
    FSummary := (Source as TTMSFNCGridHTMLExportOptions).Summary;
    FTableStyle := (Source as TTMSFNCGridHTMLExportOptions).TableStyle;
    FWidth := (Source as TTMSFNCGridHTMLExportOptions).Width;
    FXHTML := (Source as TTMSFNCGridHTMLExportOptions).XHTML;
  end;
end;

constructor TTMSFNCGridHTMLExportOptions.Create;
begin
  inherited Create;
  FShow := false;
  FSaveColors := True;
  FSaveFonts := True;
  FBorderSize := 1;
  FCellSpacing := 0;
  FCellPadding := 0;
  FWidth := 100;
  FExportImages := True;
  FColumnWidths := TTMSFNCGridIntList.Create;
end;


destructor TTMSFNCGridHTMLExportOptions.Destroy;
begin
  FColumnWidths.Free;
  inherited;
end;

{ TTMSFNCGridBandOptions }

procedure TTMSFNCGridBandOptions.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGridBandOptions) then
  begin
    FEnabled := (Source as TTMSFNCGridBandOptions).Enabled;
  end;
end;

procedure TTMSFNCGridBandOptions.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCGridBandOptions.Create;
begin
  inherited Create;
  FEnabled := False;
  FNormalRowCount := 1;
  FBandRowCount := 1;
end;

procedure TTMSFNCGridBandOptions.SetBandRowCount(const Value: integer);
begin
  if (Value > 0) and (FBandRowCount <> Value) then
  begin
    FBandRowCount := Value;
    FTotalRowCount := FNormalRowCount + FBandRowCount;
    Changed;
  end;
end;

procedure TTMSFNCGridBandOptions.SetEnabled(const Value: Boolean);
begin
  if (FEnabled <> Value) then
  begin
    FEnabled := Value;
    Changed;
  end;
end;

procedure TTMSFNCGridBandOptions.SetNormalRowCount(const Value: integer);
begin
  if (Value > 0) and (FNormalRowCount <> Value) then
  begin
    FNormalRowCount := Value;
    FTotalRowCount := FNormalRowCount + FBandRowCount;
    Changed;
  end;
end;

{ TTMSFNCGridURLOptions }

procedure TTMSFNCGridURLOptions.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGridURLOptions) then
  begin
    FShow := (Source as TTMSFNCGridURLOptions).Show;
    FUnderline := (Source as TTMSFNCGridURLOptions).Underline;
    FColor := (Source as TTMSFNCGridURLOptions).Color;
    FOpen := (Source as TTMSFNCGridURLOptions).Open;
    FFull := (Source as TTMSFNCGridURLOptions).Full;
  end;
end;

procedure TTMSFNCGridURLOptions.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCGridURLOptions.Create;
begin
  inherited Create;
  FShow := true;
  FColor := gcBlue;
  FUnderline := true;
  FFull := true;
  FOpen := True;
end;

procedure TTMSFNCGridURLOptions.SetColor(const Value: TTMSFNCGraphicsColor);
begin
  if (FColor <> Value) then
  begin
    FColor := Value;
    Changed;
  end;
end;

procedure TTMSFNCGridURLOptions.SetFull(const Value: boolean);
begin
  if FFull <> Value then
  begin
    FFull := Value;
    Changed;
  end;
end;

procedure TTMSFNCGridURLOptions.SetUnderline(const Value: boolean);
begin
  if (FUnderLine <> Value) then
  begin
    FUnderline := Value;
    Changed;
  end;
end;

{ TTMSFNCGridFilteringOptions }

procedure TTMSFNCGridFilteringOptions.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGridFilteringOptions) then
  begin
    FRows := (Source as TTMSFNCGridFilteringOptions).Rows;
    FDropDown := (Source as TTMSFNCGridFilteringOptions).DropDown;
    FDropDownFixedRow := (Source as TTMSFNCGridFilteringOptions).DropDownFixedRow;
    FDropDownWidth := (Source as TTMSFNCGridFilteringOptions).DropDownWidth;
    FDropDownHeight := (Source as TTMSFNCGridFilteringOptions).DropDownHeight;
    FMultiColumn := (Source as TTMSFNCGridFilteringOptions).MultiColumn;
  end;
end;

procedure TTMSFNCGridFilteringOptions.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCGridFilteringOptions.Create;
begin
  inherited Create;
  FRows := frNormal;
  FDropDown := false;
  FDropDownFixedRow := 0;
  FDropDownWidth := 100;
  FDropDownHeight := 120;
end;

procedure TTMSFNCGridFilteringOptions.SetDropDown(const Value: boolean);
begin
  if (FDropDown <> Value) then
  begin
    FDropDown := Value;
    Changed;
  end;
end;

procedure TTMSFNCGridFilteringOptions.SetDropDownFixedRow(const Value: integer);
begin
  if (FDropDownFixedRow <> Value) then
  begin
    FDropDownFixedRow := Value;
    Changed;
  end;
end;

{ TTMSFNCGridScrollBarOptions }

procedure TTMSFNCGridScrollBarOptions.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGridScrollBarOptions) then
  begin
    VerticalScrollBarVisible := (Source as TTMSFNCGridScrollBarOptions).VerticalScrollBarVisible;
    HorizontalScrollBarVisible := (Source as TTMSFNCGridScrollBarOptions).HorizontalScrollBarVisible;
  end;
end;

constructor TTMSFNCGridScrollBarOptions.Create;
begin
  FVerticalScrollBarVisible := True;
  FHorizontalScrollBarVisible := True;
end;

destructor TTMSFNCGridScrollBarOptions.Destroy;
begin
  inherited;
end;

procedure TTMSFNCGridScrollBarOptions.SetHorizontalScrollBarVisible(
  const Value: Boolean);
begin
  if FHorizontalScrollBarVisible <> Value then
  begin
    FHorizontalScrollBarVisible := Value;
    if Assigned(OnChange) then
      OnChange(Self);
  end;
end;

procedure TTMSFNCGridScrollBarOptions.SetVerticalScrollBarVisible(
  const Value: Boolean);
begin
  if FVerticalScrollBarVisible <> Value then
  begin
    FVerticalScrollBarVisible := Value;
    if Assigned(OnChange) then
      OnChange(Self);
  end;
end;

{ TTMSFNCGridIOOptions }

procedure TTMSFNCGridIOOptions.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGridIOOptions) then
  begin
    FAlwaysQuotes := (Source as TTMSFNCGridIOOptions).AlwaysQuotes;
    FDelimiter := (Source as TTMSFNCGridIOOptions).Delimiter;
    FQuoteEmptyCells := (Source as TTMSFNCGridIOOptions).QuoteEmptyCells;
    FXMLEncoding := (Source as TTMSFNCGridIOOptions).XMLEncoding;
    FSaveVirtualCellData := (Source as TTMSFNCGridIOOptions).SaveVirtualCellData;
  end;
end;

constructor TTMSFNCGridIOOptions.Create;
begin
  inherited;
  FDelimiter := #0;
  FQuoteEmptyCells := True;
  FXMLEncoding := 'ISO-8859-1';
  FAlwaysQuotes := false;
  FSaveVirtualCellData := True;
end;

{ TTMSFNCGridCellOptions }

procedure TTMSFNCGridCellOptions.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCGridCellOptions then
  begin
    FDisplayHTMLFormatting := (Source as TTMSFNCGridCellOptions).DisplayHTMLFormatting;
  end;
end;

constructor TTMSFNCGridCellOptions.Create;
begin
  inherited;
  FDisplayHTMLFormatting := False;
end;

procedure TTMSFNCGridCellOptions.SetDisplayHTMLFormatting(const Value: Boolean);
begin
  if FDisplayHTMLFormatting <> value then
  begin
    FDisplayHTMLFormatting := Value;
    if Assigned(OnChange) then
      OnChange(Self);
  end;
end;

{ TTMSFNCGridColumnSizeOptions }

procedure TTMSFNCGridColumnSizeOptions.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCGridColumnSizeOptions then
  begin
    FStretch := (Source as TTMSFNCGridColumnSizeOptions).Stretch;
    FStretchAll := (Source as TTMSFNCGridColumnSizeOptions).StretchAll;
    FStretchColumn := (Source as TTMSFNCGridColumnSizeOptions).StretchColumn;
    FSyncNormalCellsOnly := (Source as TTMSFNCGridColumnSizeOptions).SyncNormalCellsOnly;
    FSyncWithGrid := (Source as TTMSFNCGridColumnSizeOptions).SyncWithGrid;
  end;
end;

constructor TTMSFNCGridColumnSizeOptions.Create;
begin
  FStretch := False;
  FStretchAll := False;
  FStretchColumn := -1;
  FSyncWithGrid := False;
  FSyncNormalCellsOnly := False;
end;

procedure TTMSFNCGridColumnSizeOptions.SetNormalCellsOnly(const Value: Boolean);
begin
  if FSyncNormalCellsOnly <> Value then
  begin
    FSyncNormalCellsOnly := Value;
    if Assigned(OnChange) then
      OnChange(Self);
  end;
end;

procedure TTMSFNCGridColumnSizeOptions.SetStretch(const Value: Boolean);
begin
  if FStretch <> Value then
  begin
    FStretch := Value;
    if Assigned(OnChange) then
      OnChange(Self);
  end;
end;

procedure TTMSFNCGridColumnSizeOptions.SetStretchAll(const Value: Boolean);
begin
  if FStretchAll <> Value then
  begin
    FStretchAll := Value;
    if Assigned(OnChange) then
      OnChange(Self);
  end;
end;

procedure TTMSFNCGridColumnSizeOptions.SetStretchColumn(const Value: Integer);
begin
  if FStretchColumn <> Value then
  begin
    FStretchColumn := Value;
    if Assigned(OnChange) then
      OnChange(Self);
  end;
end;

procedure TTMSFNCGridColumnSizeOptions.SetSyncWithGrid(const Value: Boolean);
begin
  if FSyncWithGrid <> Value then
  begin
    FSyncWithGrid := Value;
    if Assigned(OnChange) then
      OnChange(Self);
  end;
end;

{$IFDEF FMXLIB}

{ TTMSFNCGridStylingOptions }

procedure TTMSFNCGridStylingOptions.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGridStylingOptions) then
  begin
    FAdaptToStyle := (Source as TTMSFNCGridStylingOptions).AdaptToStyle;
  end;
end;

constructor TTMSFNCGridStylingOptions.Create;
begin
  FAdaptToStyle := False;
end;

destructor TTMSFNCGridStylingOptions.Destroy;
begin
  inherited;
end;

procedure TTMSFNCGridStylingOptions.SetAdaptToStyle(const Value: Boolean);
begin
  FAdaptToStyle := Value;
  if Assigned(OnChange) then
    OnChange(Self);
end;
{$ENDIF}

{ TTMSFNCGridSelection }

procedure TTMSFNCGridSelection.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCGridSelection) then
  begin
    FMode := (Source as TTMSFNCGridSelection).Mode;
    FShowSelectionInFixedCells := (Source as TTMSFNCGridSelection).ShowSelectionInFixedCells;
  end;
end;

procedure TTMSFNCGridSelection.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCGridSelection.Create;
begin
  FMode := smSingleCell;
end;

procedure TTMSFNCGridSelection.SetMode(const Value: TTMSFNCGridSelectionMode);
begin
  if FMode <> Value then
  begin
    FMode := Value;
    Changed;
  end;
end;

procedure TTMSFNCGridSelection.SetShowSelectionInFixedCells(
  const Value: Boolean);
begin
  if FShowSelectionInFixedCells <> Value then
  begin
    FShowSelectionInFixedCells := Value;
    Changed;
  end;
end;

end.

unit KORRIGERING;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, VCL.TMSFNCGridCell,
  VCL.TMSFNCGridOptions, WEBLib.REST, VCL.TMSFNCCustomScrollControl,
  VCL.TMSFNCGridData, VCL.TMSFNCCustomGrid, VCL.TMSFNCGrid, Vcl.Controls,
  VCL.TMSFNCCustomControl, VCL.TMSFNCPanel, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.ExtCtrls, VCL.TMSFNCToolBar, VCL.TMSFNCStatusBar;

type
  TForm6 = class(TForm)
    TMSFNCPanel1: TTMSFNCPanel;
    TMSFNCGrid1: TTMSFNCGrid;
    WebHttpRequest1: THttpRequest;
    WebMemo1: TMemo;
    TMSFNCStatusBar1: TTMSFNCStatusBar;
    procedure GRIDRENSA;
    procedure INLASNING;
    procedure KORR_PROJECT;
    procedure WebFormCreate(Sender: TObject);
    procedure KORRIGERA(Sender: TObject);
    procedure WebHttpRequest1RequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
    procedure TMSFNCGrid1GetCellLayout(Sender: TObject; ACol, ARow: Integer; ALayout: TTMSFNCGridCellLayout; ACellState: TTMSFNCGridCellState);
    procedure TMSFNCGrid1GetCellProperties(Sender: TObject; ACol, ARow: Integer; Cell: TTMSFNCGridCell);
    procedure KEYTEST(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure TANGENTER(Sender: TObject; var Key: Char);
    procedure AVSLUTA(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form6: TForm6;

implementation

Uses FRONT, KORRPROJECT;

{$R *.dfm}

Function TOKEN(aString,SepChar: String; TokenNum: Byte): String;
  Var
    retvar: String;
    StrLen,Num,EndofToken: Integer;
  Begin
    StrLen:=Length(aString);
    Num:=1;
    EndofToken:=StrLen;
    While ((Num<=TokenNum) And (EndofToken<>0)) Do
      Begin
        EndofToken:=Pos(SepChar,aString);
        If EndofToken<>0 Then
          Begin
            retvar:=Copy(aString,1,EndofToken-1);
            Delete(aString,1,EndofToken);
            Inc(Num);
          End
        Else
          retvar:=aString;
      End;
    If Num>=TokenNum Then
      Result:=retvar
    Else
      Result:='';
  End;

procedure TForm6.GRIDRENSA;
  Begin
    TMSFNCGrid1.Clear;
    TMSFNCGrid1.RowCount:=2;

    TMSFNCGrid1.Cells[0,0]:=' Number';
    TMSFNCGrid1.Cells[1,0]:='Project';
    TMSFNCGrid1.Cells[2,0]:='Type';
    TMSFNCGrid1.Cells[3,0]:='Webpath';
    TMSFNCGrid1.Cells[4,0]:='Name';
    TMSFNCGrid1.Cells[5,0]:='Phone';
  End;

procedure TForm6.INLASNING;
  Var wp,path: String;
  Begin
    wp:='https://www.connectsystem.se/';
    path:=wp+'dsidinfolista.php';

    WebHttpRequest1.URL:=path;
    WebHttpRequest1.Execute;
  End;

procedure TForm6.KORR_PROJECT;
  Var
    newform: TForm;
    ypos: Integer;

  procedure AfterShowModal(AValue: TModalResult);
    Begin
      If Form1.PARAM_P='korrigerat' Then
        Begin
          Form1.PARAM_P:='';

          TMSFNCGrid1.Cells[0,ypos]:=' '+Form1.katn;
          TMSFNCGrid1.Cells[1,ypos]:=Form1.proj;
          TMSFNCGrid1.Cells[2,ypos]:=Form1.typs;
          TMSFNCGrid1.Cells[3,ypos]:=Form1.webp;
          TMSFNCGrid1.Cells[4,ypos]:=Form1.namn;
          TMSFNCGrid1.Cells[5,ypos]:=Form1.tele;

          TMSFNCGrid1.Resize;
        End;

      TMSFNCGrid1.SetFocus;
    End;

  procedure AfterCreate(AForm: TObject);
    Begin
     (AForm as TForm7).Left:=Form1.Width Div 2 - 120;
     (AForm as TForm7).Top:=Form1.Height Div 2 + 80;
    End;

  Begin
    ypos:=TMSFNCGrid1.FocusedCell.Row;

    Form1.katn:=Trim(TMSFNCGrid1.Cells[0,ypos]);
    Form1.proj:=Trim(TMSFNCGrid1.Cells[1,ypos]);
    Form1.typs:=Trim(TMSFNCGrid1.Cells[2,ypos]);
    Form1.webp:=Trim(TMSFNCGrid1.Cells[3,ypos]);
    Form1.namn:=Trim(TMSFNCGrid1.Cells[4,ypos]);
    Form1.tele:=Trim(TMSFNCGrid1.Cells[5,ypos]);

    Form1.PARAM_P:=IntToStr(ypos);

    newform:= TForm7.CreateNew(@AfterCreate);
    newform.Popup := True;
    newform.Border:=fbDialog;
    newform.ShowClose:=False;
    newform.ShowModal(@AfterShowModal);
  End;

procedure TForm6.WebFormCreate(Sender: TObject);
  Begin
    TMSFNCGrid1.ColumnWidths[0]:=120;
    TMSFNCGrid1.ColumnWidths[1]:=382;
    TMSFNCGrid1.ColumnWidths[2]:=70;
    TMSFNCGrid1.ColumnWidths[3]:=230;
    TMSFNCGrid1.ColumnWidths[4]:=180;
    TMSFNCGrid1.ColumnWidths[5]:=122;

    INLASNING;
  End;

procedure TForm6.KORRIGERA(Sender: TObject);
  Begin
    KORR_PROJECT;
  End;

procedure TForm6.WebHttpRequest1RequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
  Var
    remsa,numm,proj,typs,webp,namn,tele: String;
    y,i: Integer;
  Begin
    WebMemo1.Text:=AResponse;

    GRIDRENSA;
    y:=0;

    TMSFNCGrid1.BeginUpdate;

    For i:=0 To WebMemo1.Lines.Count-1 Do
      Begin
        remsa:=WebMemo1.Lines[i];

        numm:=TOKEN(remsa,';',1);
        proj:=TOKEN(remsa,';',2);
        typs:=TOKEN(remsa,';',3);
        webp:=TOKEN(remsa,';',4);
        namn:=TOKEN(remsa,';',5);
        tele:=TOKEN(remsa,';',6);

        y:=y+1;
        If y>1 Then
          TMSFNCGrid1.RowCount:=TMSFNCGrid1.RowCount+1;

        TMSFNCGrid1.Cells[0,y]:=' '+numm;
        TMSFNCGrid1.Cells[1,y]:=proj;
        TMSFNCGrid1.Cells[2,y]:=typs;
        TMSFNCGrid1.Cells[3,y]:=webp;
        TMSFNCGrid1.Cells[4,y]:=namn;
        TMSFNCGrid1.Cells[5,y]:=tele;
      End;

    TMSFNCGrid1.EndUpdate;

    TMSFNCGrid1.Selection:=MakeCellRange(0,1,0,1);

    TMSFNCGrid1.SetFocus;

    WebMemo1.Lines.Clear;
  End;

procedure TForm6.TMSFNCGrid1GetCellLayout(Sender: TObject; ACol, ARow: Integer; ALayout: TTMSFNCGridCellLayout; ACellState: TTMSFNCGridCellState);
  Begin
    ALayout.Font.Color:=clBlack;
    ALayout.Font.Size:=10;
  End;

procedure TForm6.TMSFNCGrid1GetCellProperties(Sender: TObject; ACol, ARow: Integer; Cell: TTMSFNCGridCell);
  Begin
    Cell.Layout.TextAlign:=gtaLeading;
  End;

procedure TForm6.KEYTEST(Sender: TObject; var Key: Word; Shift: TShiftState);
  Begin
    If Key=27 Then
      Close;
  End;

procedure TForm6.TANGENTER(Sender: TObject; var Key: Char);
  Begin
    If Ord(Key)=13 Then
      Begin
        Key:=#0;

        KORR_PROJECT;
      End;
  End;

procedure TForm6.AVSLUTA(Sender: TObject);
  Begin
    Close;
  End;

procedure TForm6.LoadDFMValues;
begin
  inherited LoadDFMValues;

  TMSFNCPanel1 := TTMSFNCPanel.Create(Self);
  TMSFNCGrid1 := TTMSFNCGrid.Create(Self);
  WebMemo1 := TMemo.Create(Self);
  TMSFNCStatusBar1 := TTMSFNCStatusBar.Create(Self);
  WebHttpRequest1 := THttpRequest.Create(Self);

  TMSFNCPanel1.BeforeLoadDFMValues;
  TMSFNCGrid1.BeforeLoadDFMValues;
  WebMemo1.BeforeLoadDFMValues;
  TMSFNCStatusBar1.BeforeLoadDFMValues;
  WebHttpRequest1.BeforeLoadDFMValues;
  try
    Name := 'Form6';
    Width := 1108;
    Height := 512;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnKeyDown', 'KEYTEST');
    TMSFNCPanel1.SetParentComponent(Self);
    TMSFNCPanel1.Name := 'TMSFNCPanel1';
    TMSFNCPanel1.Left := 0;
    TMSFNCPanel1.Top := 0;
    TMSFNCPanel1.Width := 1108;
    TMSFNCPanel1.Height := 28;
    TMSFNCPanel1.Align := alTop;
    TMSFNCPanel1.BevelInner := bvNone;
    TMSFNCPanel1.BevelOuter := bvNone;
    TMSFNCPanel1.Ctl3D := True;
    TMSFNCPanel1.ParentCtl3D := False;
    TMSFNCPanel1.ParentDoubleBuffered := False;
    TMSFNCPanel1.Color := clBtnFace;
    TMSFNCPanel1.DoubleBuffered := True;
    TMSFNCPanel1.TabOrder := 0;
    SetEvent(TMSFNCPanel1, Self, 'OnKeyDown', 'KEYTEST');
    TMSFNCPanel1.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel1.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel1.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel1.SectionsAppearance.Font.Style := [];
    TMSFNCPanel1.SectionsAppearance.Fill.Color := 15790320;
    TMSFNCPanel1.Header.HorizontalTextAlign := gtaLeading;
    TMSFNCPanel1.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Header.Font.Color := clWindowText;
    TMSFNCPanel1.Header.Font.Height := -11;
    TMSFNCPanel1.Header.Font.Name := 'Tahoma';
    TMSFNCPanel1.Header.Font.Style := [];
    TMSFNCPanel1.Header.Fill.Kind := gfkGradient;
    TMSFNCPanel1.Header.Fill.Color := clBtnFace;
    TMSFNCPanel1.Header.Fill.ColorTo := clBtnFace;
    TMSFNCPanel1.Header.Fill.ColorMirror := clBtnFace;
    TMSFNCPanel1.Header.Fill.ColorMirrorTo := clBtnFace;
    TMSFNCPanel1.Header.Buttons := [pbClose];
    TMSFNCPanel1.Footer.Text := 'Footer';
    TMSFNCPanel1.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Footer.Font.Color := clWindowText;
    TMSFNCPanel1.Footer.Font.Height := -11;
    TMSFNCPanel1.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel1.Footer.Font.Style := [];
    TMSFNCPanel1.Footer.Fill.Color := 15000804;
    SetEvent(TMSFNCPanel1, Self, 'OnHeaderCloseButtonClick', 'AVSLUTA');
    TMSFNCGrid1.SetParentComponent(Self);
    TMSFNCGrid1.Name := 'TMSFNCGrid1';
    TMSFNCGrid1.Tag := 1;
    TMSFNCGrid1.Left := 0;
    TMSFNCGrid1.Top := 28;
    TMSFNCGrid1.Width := 1108;
    TMSFNCGrid1.Height := 464;
    TMSFNCGrid1.Align := alClient;
    TMSFNCGrid1.ParentDoubleBuffered := False;
    TMSFNCGrid1.DoubleBuffered := True;
    TMSFNCGrid1.TabOrder := 1;
    SetEvent(TMSFNCGrid1, Self, 'OnDblClick', 'KORRIGERA');
    SetEvent(TMSFNCGrid1, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(TMSFNCGrid1, Self, 'OnKeyPress', 'TANGENTER');
    TMSFNCGrid1.DefaultRowHeight := 20.000000000000000000;
    TMSFNCGrid1.DefaultColumnWidth := 146.000000000000000000;
    TMSFNCGrid1.FixedColumns := 0;
    TMSFNCGrid1.ColumnCount := 7;
    TMSFNCGrid1.RowCount := 2;
    TMSFNCGrid1.Options.Bands.Enabled := True;
    TMSFNCGrid1.Options.Borders.FixedCellBorders := bNone;
    TMSFNCGrid1.Options.Borders.CellBorders := bNone;
    TMSFNCGrid1.Options.Editing.CalcFormat := '%g';
    TMSFNCGrid1.Options.Editing.Enabled := False;
    TMSFNCGrid1.Options.Editing.EditWithTags := False;
    TMSFNCGrid1.Options.Grouping.CalcFormat := '%g';
    TMSFNCGrid1.Options.Grouping.GroupCountFormat := '(%d)';
    TMSFNCGrid1.Options.IO.XMLEncoding := 'ISO-8859-1';
    TMSFNCGrid1.Options.Mouse.ClickMargin := 0;
    TMSFNCGrid1.Options.Mouse.ColumnSizeMargin := 6;
    TMSFNCGrid1.Options.Mouse.RowSizeMargin := 6;
    TMSFNCGrid1.Options.ScrollBar.HorizontalScrollBarVisible := False;
    TMSFNCGrid1.Options.Selection.Mode := smSingleRow;
    TMSFNCGrid1.HorizontalScrollBarVisible := False;
    TMSFNCGrid1.VerticalScrollBarVisible := False;
    TMSFNCGrid1.Columns.Clear;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 146.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      HorzAlignment := gtaCenter;
      ID := '';
      Width := 146.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 146.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      HorzAlignment := gtaTrailing;
      ID := '';
      Width := 146.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      HorzAlignment := gtaTrailing;
      ID := '';
      Width := 146.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      HorzAlignment := gtaTrailing;
      ID := '';
      Width := 146.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      HorzAlignment := gtaTrailing;
      ID := '';
      Width := 146.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      HorzAlignment := gtaTrailing;
      ID := '';
      Width := 146.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 146.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 146.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 146.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 146.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 146.000000000000000000;
    end;
    TMSFNCGrid1.DefaultFont.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.DefaultFont.Color := clWindowText;
    TMSFNCGrid1.DefaultFont.Height := -11;
    TMSFNCGrid1.DefaultFont.Name := 'Tahoma';
    TMSFNCGrid1.DefaultFont.Style := [];
    TMSFNCGrid1.TopRow := 1;
    TMSFNCGrid1.Appearance.FixedLayout.Fill.Color := clBtnFace;
    TMSFNCGrid1.Appearance.FixedLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.FixedLayout.Font.Color := clWindowText;
    TMSFNCGrid1.Appearance.FixedLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.FixedLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.FixedLayout.Font.Style := [fsBold];
    TMSFNCGrid1.Appearance.NormalLayout.Fill.Color := -1;
    TMSFNCGrid1.Appearance.NormalLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.NormalLayout.Font.Color := clWindowText;
    TMSFNCGrid1.Appearance.NormalLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.NormalLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.NormalLayout.Font.Style := [];
    TMSFNCGrid1.Appearance.GroupLayout.Fill.Color := 12817262;
    TMSFNCGrid1.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.GroupLayout.Font.Color := clWhite;
    TMSFNCGrid1.Appearance.GroupLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.GroupLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.GroupLayout.Font.Style := [];
    TMSFNCGrid1.Appearance.SummaryLayout.Fill.Color := 14009785;
    TMSFNCGrid1.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.SummaryLayout.Font.Color := clWindowText;
    TMSFNCGrid1.Appearance.SummaryLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.SummaryLayout.Font.Style := [];
    TMSFNCGrid1.Appearance.SelectedLayout.Fill.Color := 14599344;
    TMSFNCGrid1.Appearance.SelectedLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.SelectedLayout.Font.Color := clWhite;
    TMSFNCGrid1.Appearance.SelectedLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.SelectedLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.SelectedLayout.Font.Style := [];
    TMSFNCGrid1.Appearance.FocusedLayout.Fill.Color := 14599344;
    TMSFNCGrid1.Appearance.FocusedLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.FocusedLayout.Font.Color := clWhite;
    TMSFNCGrid1.Appearance.FocusedLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.FocusedLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.FocusedLayout.Font.Style := [];
    TMSFNCGrid1.Appearance.FixedSelectedLayout.Fill.Color := 14599344;
    TMSFNCGrid1.Appearance.FixedSelectedLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.FixedSelectedLayout.Font.Color := clWindowText;
    TMSFNCGrid1.Appearance.FixedSelectedLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.FixedSelectedLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.FixedSelectedLayout.Font.Style := [];
    TMSFNCGrid1.Appearance.BandLayout.Fill.Color := 15987699;
    TMSFNCGrid1.Appearance.BandLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.BandLayout.Font.Color := clWindowText;
    TMSFNCGrid1.Appearance.BandLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.BandLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.BandLayout.Font.Style := [];
    TMSFNCGrid1.Appearance.ProgressLayout.Format := '%.0f%%';
    TMSFNCGrid1.LeftCol := 0;
    TMSFNCGrid1.ScrollMode := scmItemScrolling;
    TMSFNCGrid1.CustomScrollBars := True;
    SetEvent(TMSFNCGrid1, Self, 'OnGetCellProperties', 'TMSFNCGrid1GetCellProperties');
    SetEvent(TMSFNCGrid1, Self, 'OnGetCellLayout', 'TMSFNCGrid1GetCellLayout');
    WebMemo1.SetParentComponent(TMSFNCGrid1);
    WebMemo1.Name := 'WebMemo1';
    WebMemo1.Left := 152;
    WebMemo1.Top := 166;
    WebMemo1.Width := 217;
    WebMemo1.Height := 22;
    WebMemo1.AutoSize := False;
    WebMemo1.HeightPercent := 100.000000000000000000;
    WebMemo1.Lines.BeginUpdate;
    try
      WebMemo1.Lines.Clear;
      WebMemo1.Lines.Add('WebMemo1');
    finally
      WebMemo1.Lines.EndUpdate;
    end;
    WebMemo1.SelLength := 0;
    WebMemo1.SelStart := 0;
    WebMemo1.Visible := False;
    WebMemo1.WidthPercent := 100.000000000000000000;
    SetEvent(WebMemo1, Self, 'OnKeyDown', 'KEYTEST');
    TMSFNCStatusBar1.SetParentComponent(Self);
    TMSFNCStatusBar1.Name := 'TMSFNCStatusBar1';
    TMSFNCStatusBar1.Left := 0;
    TMSFNCStatusBar1.Top := 492;
    TMSFNCStatusBar1.Width := 1108;
    TMSFNCStatusBar1.Height := 20;
    TMSFNCStatusBar1.ParentDoubleBuffered := False;
    TMSFNCStatusBar1.DoubleBuffered := True;
    TMSFNCStatusBar1.TabOrder := 2;
    SetEvent(TMSFNCStatusBar1, Self, 'OnKeyDown', 'KEYTEST');
    TMSFNCStatusBar1.PanelAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCStatusBar1.PanelAppearance.Font.Color := clWindowText;
    TMSFNCStatusBar1.PanelAppearance.Font.Height := -11;
    TMSFNCStatusBar1.PanelAppearance.Font.Name := 'Tahoma';
    TMSFNCStatusBar1.PanelAppearance.Font.Style := [];
    WebHttpRequest1.SetParentComponent(Self);
    WebHttpRequest1.Name := 'WebHttpRequest1';
    SetEvent(WebHttpRequest1, Self, 'OnRequestResponse', 'WebHttpRequest1RequestResponse');
    WebHttpRequest1.Left := 64;
    WebHttpRequest1.Top := 182;
  finally
    TMSFNCPanel1.AfterLoadDFMValues;
    TMSFNCGrid1.AfterLoadDFMValues;
    WebMemo1.AfterLoadDFMValues;
    TMSFNCStatusBar1.AfterLoadDFMValues;
    WebHttpRequest1.AfterLoadDFMValues;
  end;
end;

end.
{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2019 - 2022                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.Menus;

{$DEFINE NOPP}

interface

uses
  Classes, SysUtils, WEBLib.Controls, WEBLib.Graphics, Web;

type
  TMainMenu = class;
  TMenuItem = class;
  TCustomMainMenu = class;

  TMenuItemAutoFlag = (maAutomatic, maManual, maParent);
  TMenuBreak = (mbNone, mbBreak, mbBarBreak);
  THelpContext = -MaxLongint..MaxLongint;
  //TShortCut = Low(Word)..High(Word);
  TShortCut = type Word;
  TMainMenuHamburgerMenuVisible = (hmAlways, hmNever, hmResponsive);

  TMainMenuChangeEvent = procedure(Sender: TObject; Source: TMenuItem; Rebuild: boolean) of object;

  TMenuItemList = class(TList)
  private
    function GetItem(Index: Integer): TMenuItem;
    procedure PutItem(Index: Integer; const Value: TMenuItem);
  public
    property Items[Index: Integer]: TMenuItem read GetItem write PutItem; default;
  end;

  TMenuItem = class(TComponent)
  private
    FOwner: TMenuItem;
    FCaption: string;
    FItems: TMenuItemList;
    FParentItem: TMenuItem;
    FUpdateCount: integer;
    FOnClick: TNotifyEvent;
    FChecked: Boolean;
    FEnabled: Boolean;
    FDefault: Boolean;
    FAutoCheck: Boolean;
    FHint: string;
    FAutoHotkeys: TMenuItemAutoFlag;
    FRadioItem: Boolean;
    FGroupIndex: Byte;
    FVisible: Boolean;
    FShortCut: TShortCut;
    FBreak: TMenuBreak;
    FImageIndex: Integer;
    FAutoLineReduction: TMenuItemAutoFlag;
    FHelpContext: THelpContext;
    FElementClassName: TElementClassName;
    FParentMenu: TComponent;
    procedure SetChecked(const Value: Boolean);
    procedure SetCaption(const Value: string);
    procedure SetImageIndex(const Value: Integer);
    procedure SetEnabled(const Value: Boolean);
    //function HandleClick(Event: TJSEvent): Boolean; virtual;
  protected
    function GetCount: integer;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    procedure BeginUpdate; virtual; reintroduce;
    procedure EndUpdate; virtual; reintroduce;
    procedure SetParentComponent(Value: TComponent); override;
    procedure Add(Item: TMenuItem);
    procedure Insert(Index: Integer; Item: TMenuItem); reintroduce;
    function IsChild(Item: TMenuItem): boolean;
    function GetOwner: TPersistent; override;
    function GetParentMenu: TCustomMainMenu;
    procedure GetChildren(Proc: TGetChildProc; Root: TComponent); override;
    procedure SetChildOrder(Child: TComponent; Order: Integer); override;
    procedure RemoveItem(AOwner, AItem: TComponent);
    property Items: TMenuItemList read FItems write FItems;
    property Count: Integer read GetCount;
    property Parent: TMenuItem read FParentItem;
    procedure Clear;
    procedure Click;
  published
    property AutoCheck: Boolean read FAutoCheck write FAutoCheck default False;
    property AutoHotkeys: TMenuItemAutoFlag read FAutoHotkeys write FAutoHotkeys default maParent;
    property AutoLineReduction: TMenuItemAutoFlag read FAutoLineReduction write FAutoLineReduction default maParent;
    property Break: TMenuBreak read FBreak write FBreak default mbNone;
    property Caption: string read FCaption write SetCaption;
    property Checked: Boolean read FChecked write SetChecked default False;
    property Default: Boolean read FDefault write FDefault default False;
    property Enabled: Boolean read FEnabled write SetEnabled default True;
    property GroupIndex: Byte read FGroupIndex write FGroupIndex default 0;
    property HelpContext: THelpContext read FHelpContext write FHelpContext default 0;
    property Hint: string read FHint write FHint;
    property ImageIndex: Integer read FImageIndex write SetImageIndex default -1;
    property RadioItem: Boolean read FRadioItem write FRadioItem default False;
    property ShortCut: TShortCut read FShortCut write FShortCut default 0;
    property Visible: Boolean read FVisible write FVisible default True;
    property ElementClassName: TElementClassName read FElementClassName write FElementClassName;
    property OnClick: TNotifyEvent read FOnClick write FOnClick;
  end;

  TMainMenuAppearance = class;

  THamburgerMenu = class(TPersistent)
  private
    FOwner: TMainMenuAppearance;
    FVisible: TMainMenuHamburgerMenuVisible;
    FCaption: string;
    FBackgroundColor: TColor;
    FCaptionColor: TColor;
    FResponsiveMaxWidth: Integer;
    procedure SetVisible(const Value: TMainMenuHamburgerMenuVisible);
    procedure SetBackgroundColor(const Value: TColor);
    procedure SetCaption(const Value: string);
    procedure SetCaptionColor(const Value: TColor);
    procedure SetResponsiveMaxWidth(const Value: Integer);
  public
    constructor Create(AOwner: TMainMenuAppearance); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  published
    property Caption: string read FCaption write SetCaption;
    property CaptionColor: TColor read FCaptionColor write SetCaptionColor default clWhite;
    property BackgroundColor: TColor read FBackgroundColor write SetBackgroundColor default clSilver;
    property Visible: TMainMenuHamburgerMenuVisible read FVisible write SetVisible default hmResponsive;
    property ResponsiveMaxWidth: Integer read FResponsiveMaxWidth write SetResponsiveMaxWidth default 768;
  end;

  TMainMenuAppearance = class(TPersistent)
  private
    FOwner: TMainMenu;
    FBackgroundColor: TColor;
    FHoverFontColor: TColor;
    FHoverColor: TColor;
    FImageURLs: TStringList;
    FHamburgerMenu: THamburgerMenu;
    FImageSize: Integer;
    FSubmenuIndicator: string;
    procedure SetBackgroundColor(const Value: TColor);
    procedure SetHoverColor(const Value: TColor);
    procedure SetHoverFontColor(const Value: TColor);
    procedure SetImageURLs(const Value: TStringList);
    procedure SetImageSize(const Value: Integer);
    procedure SetSubmenuIndicator(const Value: string);
  protected
    procedure HandleImageURLsChanged(Sender: TObject);
  public
    constructor Create(AOwner: TMainMenu); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  published
    property BackgroundColor: TColor read FBackgroundColor write SetBackgroundColor default $F0F0F0;
    property HamburgerMenu: THamburgerMenu read FHamburgerMenu write FHamburgerMenu;
    property HoverColor: TColor read FHoverColor write SetHoverColor default clHighlight;
    property HoverFontColor: TColor read FHoverFontColor write SetHoverFontColor default clHighlightText;
    property ImageURLs: TStringList read FImageURLs write SetImageURLs;
    property ImageSize: Integer read FImageSize write SetImageSize default 16;
    property SubmenuIndicator: string read FSubmenuIndicator write SetSubmenuIndicator;
  end;

  TCustomMainMenu = class(TCustomControl)
  private
    FItems: TMenuItem;
    FOnChange: TMainMenuChangeEvent;
  protected
    procedure Loaded; override;
    procedure UpdateElement; override;
    function GetUniqueName(AName: string): string;
    procedure SetName(const NewName: TComponentName); override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure GetChildren(Proc: TGetChildProc; Root: TComponent); override;
    procedure SetChildOrder(Child: TComponent; Order: Integer); override;
  published
    property Items: TMenuItem read FItems;
    property OnChange: TMainMenuChangeEvent read FOnChange write FOnChange;
  end;

  TMainMenu = class(TCustomMainMenu)
  private
    FMenu: TJSHTMLElement;
    FMainMenu: Boolean;
    FMenuIndex: Integer;
    FAppearance: TMainMenuAppearance;
    FContainer: TControl;
    FIsPopupMenu: Boolean;
    FShowPopup: Boolean;
    FAutoMerge: boolean;
    function HandleChange(Event: TJSEvent): Boolean; virtual;
  protected
    procedure Loaded; override;
    procedure UpdateElement; override;
    function GetMenuSource(Item: TMenuItem): TJSHTMLElement;
    function GetItemByName(AMenu: TMenuItem; Name: string): TMenuItem;
  public
    procedure CreateInitialize; override;
    function CreateElement: TJSElement; override;
    destructor Destroy; override;
    property AutoMerge: boolean read FAutoMerge write FAutoMerge;
  published
    property Appearance: TMainMenuAppearance read FAppearance write FAppearance;
    property Container: TControl read FContainer write FContainer;
  end;

  TWebMainMenu = class(TMainMenu);

  TPopupMenu = class(TMainMenu)
  public
    procedure CreateInitialize; override;
    procedure Popup(X, Y: Integer);
  end;

  TWebPopupMenu = class(TPopupMenu);

  TWebCustomControl = class(TCustomControl)
  private
    FPopupMenu: TPopupMenu;
  protected
    function HandleDoContextMenu(Event: TJSMouseEvent): Boolean; override;
    procedure Notification(AComponent: TComponent;  Operation: TOperation); override;
    property PopupMenu: TPopupMenu read FPopupMenu write FPopupMenu;
  end;

  TWebGraphicControl = class(TGraphicControl)
  private
    FPopupMenu: TPopupMenu;
  protected
    function HandleDoContextMenu(Event: TJSMouseEvent): Boolean; override;
    procedure Notification(AComponent: TComponent;  Operation: TOperation); override;
    property PopupMenu: TPopupMenu read FPopupMenu write FPopupMenu;
  end;


implementation

uses
  Types, WEBLib.Forms;

function MakeValidName(s: string): string;
const
  Alpha = ['A'..'Z', 'a'..'z', '_'];
  AlphaNum = Alpha + ['0'..'9'];
var
  i: integer;
begin
  Result := '';

  for i := 1 to Length(s) do
  begin
    if s[i] in AlphaNum then
      Result := Result + s[i];
  end;
end;


{ TCustomMainMenu }

procedure TCustomMainMenu.CreateInitialize;
begin
  inherited;
  FItems := TMenuItem.Create(Self);
  FItems.FParentMenu := Self;
  WidthStyle := ssAuto;
  Height := 30;
end;

destructor TCustomMainMenu.Destroy;
begin
  FItems.Free;
  inherited;
end;

procedure TCustomMainMenu.GetChildren(Proc: TGetChildProc; Root: TComponent);
var
  I: Integer;
begin
  if Assigned(FItems) and Assigned(FItems.Items) then
  begin
    for I := 0 to FItems.Items.Count - 1 do
    begin
      Proc(TMenuItem(FItems.Items[I]));
    end;
  end;
end;

function TCustomMainMenu.GetUniqueName(AName: string): string;
var
  i,id: integer;
  found: boolean;
begin
  id := 1;

  if AName = ''  then
    AName := Name + 'item';

  AName := MakeValidName(AName);

  repeat
    Result := AName + inttostr(id);

    found := true;

    for i := 0 to Owner.ComponentCount - 1 do
    begin
      if Owner.Components[i].Name = Result then
      begin
        found := false;
        inc(id);
        break;
      end;
    end;

  until found;
end;

procedure TCustomMainMenu.Loaded;
begin
  inherited;

  if IsUpdating then
    Exit;

  if Assigned(ElementHandle) then
    UpdateElement;
end;

procedure TCustomMainMenu.SetChildOrder(Child: TComponent; Order: Integer);
var
  idx: integer;
begin
  idx := FItems.Items.IndexOf(Child);
  if (idx >= 0) and (Order < FItems.Items.Count) then
  begin
    FItems.Items.Move(idx, Order);
  end;
end;

procedure TCustomMainMenu.SetName(const NewName: TComponentName);
begin
  inherited;

  if IsUpdating then
    Exit;
  if (csLoading in ComponentState) then
    Exit;

  UpdateElement;
end;

procedure TCustomMainMenu.UpdateElement;
begin
  inherited;
  if IsUpdating then
    Exit;
end;

{ TMainMenu }

function TMainMenu.GetMenuSource(Item: TMenuItem): TJSHTMLElement;
var
  I: Integer;
  it: TMenuItem;
  MenuClass: string;
  ElListItem, ElLabel, ElCheckBox, ElSymbol: TJSHTMLElement;
  id: Integer;
  caption: string;
begin
  Result := nil;

  if Assigned(Item.Items) and (Item.Items.Count > 0) then
  begin
    Result := TJSHTMLElement(document.createElement('UL'));
    if FMainMenu then
    begin
      FMainMenu := False;
      if FIsPopupMenu then
        MenuClass := 'popup-menu'
      else
        MenuClass := 'main-menu';
    end
    else
      MenuClass := 'sub-menu';

    FMenuIndex := FMenuIndex + 1;

    Result.setAttribute('class', MenuClass);

    for I := 0 to Item.Items.Count - 1 do
    begin
      it := TMenuItem(Item.Items[I]);

      id := id + 1;

      if it.Name = '' then
      begin
        caption := StringReplace(it.Caption, ' ', '', [rfReplaceAll]);
        caption := MakeValidName(caption);
        if caption = '' then
          caption := 'MenuItem' + IntToStr(id);
        it.Name := caption + IntToStr(id);
      end;

      if it.Visible then
      begin
        ElListItem := TJSHTMLElement(document.createElement('LI'));

        if it.Caption <> '-' then
        begin
          ElLabel := TJSHTMLElement(document.createElement('LABEL'));
          ElLabel.setAttribute('class', 'drop-label');
          ElLabel.setAttribute('title', it.Hint);
          if (it.ImageIndex >= 0) and (Appearance.ImageURLs.Count > it.ImageIndex) then
            ElLabel.innerHTML := '<img class="icon" src="' + Appearance.ImageURLs[it.ImageIndex] + '">'
        else
          if it.Checked then
          begin
            if it.RadioItem then
              ElLabel.innerHTML := '<span class="checked">&#9679;</span>'
            else
              ElLabel.innerHTML := '<span class="checked">&#10004;</span>';
          end;
          ElLabel.innerHTML := ElLabel.innerHTML + it.Caption;
        end
        else
        begin
          ElLabel := TJSHTMLElement(document.createElement('SPAN'));
          ElLabel.setAttribute('class', 'menu-separator');
          ElLabel.innerHTML := '';
        end;

        if Assigned(it.Items) and (it.Items.Count > 0) then
        begin
          ElSymbol := TJSHTMLElement(document.createElement('SPAN'));
          ElSymbol.setAttribute('class', 'drop-icon');
//          ElSymbol.innerHTML := '&gt;';
//          ElSymbol.innerHTML := '&#9658;';
//          ElSymbol.innerHTML := '&#9660;';
          ElSymbol.innerHTML := Appearance.SubmenuIndicator;

          ElLabel.setAttribute('for', 'sm' + IntToStr(FMenuIndex));
          ElLabel.appendChild(ElSymbol);

          ElCheckBox := TJSHTMLElement(document.createElement('INPUT'));
          ElCheckBox.setAttribute('type', 'checkbox');
          ElCheckBox.setAttribute('id', 'sm' + IntToStr(FMenuIndex));

          ElListItem.appendChild(ElLabel);
          ElListItem.appendChild(ElCheckBox);
          ElListItem.appendChild(GetMenuSource(it));
        end
        else
        begin
          ElLabel.setAttribute('for', 'tm');
          ElLabel.setAttribute('id', it.Name);
          if it.Enabled then
          begin
            if (MenuClass = 'sub-menu') then
            begin
              //Hide the sub-menu onclick and then revert to the initial state to
              //make it re-appear when hovering
              if it.Enabled then
                ElLabel.setAttribute('onMouseUp', GetID + 'MenuClick(this);');
            end;
            ElLabel.addEventListener('click', @HandleChange);
          end
          else
            ElLabel.style.setProperty('color', 'gray');

          ElListItem.appendChild(ElLabel);
        end;

        Result.appendChild(ElListItem);
      end;
    end;
  end;
end;

function TMainMenu.GetItemByName(AMenu: TMenuItem; Name: string): TMenuItem;
var
  I: Integer;
  it: TMenuItem;
begin
  Result := nil;

  if Assigned(AMenu.Items) then
  begin
    for I := 0 to AMenu.Items.Count - 1 do
    begin
      it := TMenuItem(AMenu.Items.Items[I]);

      if it.Name = Name then
      begin
        Result := it;
        if Assigned(it.OnClick) then
          Result.OnClick := it.OnClick;
        Exit;
      end;

      if not Assigned(Result) then
        Result := GetItemByName(it, Name);
    end;
  end;
end;

function TMainMenu.HandleChange(Event: TJSEvent): Boolean;
var
  h: TJSHTMLElement;
  it: TMenuItem;
begin
  Result := True;

  if FIsPopupMenu then
  begin
    FShowPopup := False;
    UpdateElement;
  end;

  h := TJSHTMLElement(Event.target);
  it := GetItemByName(Items, h.id);

  if Assigned(it) then
  begin
    if it.AutoCheck then
      it.Checked := not it.Checked;

    if Assigned(OnChange) then
      OnChange(Self, it, False);

    it.Click;
  end;
end;

function TMainMenu.CreateElement: TJSElement;
var
  LLabel: TJSHTMLElement;
begin
  if (csDesigning in ComponentState) then
  begin
    Result := document.createElement('DIV');
    LLabel := TJSHTMLElement(document.createElement('DIV'));
    LLabel.innerHTML := 'TWebMainMenu';
    BorderStyle := bsSingle;
    LLabel['align'] := 'center';
    LLabel.style.setProperty('border','1px solid gray');
    LLabel.style.setProperty('vertical-align','middle');
    LLabel.style.setProperty('display','table-cell');
    Result.appendChild(LLabel);
  end
  else
  begin
    Result := document.createElement('DIV');
    if FIsPopupMenu then
      Result.setAttribute('onMouseLeave', GetID + 'MenuOut(this);');
  end;
end;

procedure TMainMenu.CreateInitialize;
begin
  inherited;
  FAppearance := TMainMenuAppearance.Create(Self);
  FContainer := nil;
  FMainMenu := True;
  FMenuIndex := 0;
  FIsPopupMenu := False;
  FShowPopup := False;

  Top := 0;
  Left := 0;
  Width := 100;
  if (csDesigning in ComponentState) then
  begin
    ElementPosition := epRelative;
    WidthPercent := 100;
    WidthStyle := ssPercent;
  end;
end;

destructor TMainMenu.Destroy;
begin
  FAppearance.Free;
  inherited;
end;

procedure TMainMenu.Loaded;
begin
  inherited;

  if IsUpdating then
    Exit;

  if Assigned(ElementHandle) then
    UpdateElement;
end;

procedure TMainMenu.UpdateElement;
var
  MenuStyle, MenuColorStyle, MenuResponsiveStyle, MenuImageSize, ShowPopup: string;
  ElHandle, ElLabel, ElSpan, ElIcon, ElCheckBox, ElStyle, ElScript, ElItems: TJSHTMLElement;
begin
  inherited;

  if IsUpdating then
    Exit;

  if not Assigned(ElementHandle) and not Assigned(Container) then
    Exit;

  if Assigned(Container) then
  begin
    ElHandle := Container.ElementHandle;
    Visible := False;
  end
  else
  begin
    ElHandle := ElementHandle;
    ElHandle.style.setProperty('top', IntToStr(Top)+'px');
    ElHandle.style.setProperty('left', IntToStr(Left)+'px');
    if (csDesigning in ComponentState) and not Assigned(Container) then
      TJSHTMLElement(ElementHandle).style.setProperty('background-color','silver');
  end;

  FMenu := TJSHTMLElement(ElHandle.firstChild);
  if Assigned(FMenu) then
    FMenu.parentElement.removeChild(FMenu);

  if (csDestroying in ComponentState) then
    Exit;

  FMenu := TJSHTMLElement(document.createElement('NAV'));
  FMenu.setAttribute('id', GetID + 'menu');
  FMenu.innerHTML := '';

  if Assigned(Container) then
  begin
    FMenu.style.setProperty('top', '0');
    FMenu.style.setProperty('left', '0');
    FMenu.style.setProperty('position', 'absolute');
    FMenu.style.setProperty('width', '100%');
    FMenu.style.setProperty('z-index', '999997');
  end;

  ElHandle.innerHTML := '';
  ElHandle.style.setProperty('overflow', 'visible');

  if Appearance.HamburgerMenu.Visible <> hmAlways then
  begin
    if FShowPopup then
      ShowPopup := 'block'
    else
      ShowPopup := 'none';

    MenuResponsiveStyle :=
      '  #' + GetID + 'menu .main-menu {'#13
      + '    display: block;'#13
      + '  }'#13

      + '  #' + GetID + 'menu #toggle-menu {'#13
      + '    display: none;'#13
      + '  }'#13

      + '  #' + GetID + 'menu ul span.drop-label {'#13
      + '    display: inline-block;'#13
      + '  }'#13

      + '  #' + GetID + 'menu li {'#13
      + '    float: left;'#13
      + '    border-width: 0 1px 0 0;'#13
      + '  }'#13

      + '  #' + GetID + 'menu .popup-menu li, '#13
      + '  #' + GetID + 'menu .sub-menu li {'#13
      + '    float: none;'#13
      + '  }'#13

      + '  #' + GetID + 'menu .sub-menu {'#13
      + '    top: 100%;'#13
      + '  }'#13

      + '  #' + GetID + 'menu .popup-menu { '#13
      + '    top: 0;'#13
      + '  }'#13

      + '  #' + GetID + 'menu .popup-menu, '#13
      + '  #' + GetID + 'menu .sub-menu {'#13
      + '    border-width: 0;'#13
      + '    margin: 0;'#13
      + '    position: absolute;'#13
      + '    left: 0;'#13
      + '    min-width: 12em;'#13
      + '    z-index: 9999999;'#13
      + '	   white-space: nowrap;'#13
      + '  }'#13

      + '  #' + GetID + 'menu .sub-menu {'#13
      + '    top: 100%;'#13
      + '  }'#13

      + '  #' + GetID + 'menu .popup-menu { '#13
      + '    display: ' + ShowPopup + ';'#13
      + '  }'#13

      + '  #' + GetID + 'menu .sub-menu,'#13
      + '  #' + GetID + 'menu input[type="checkbox"]:checked + .popup-menu,'#13
      + '  #' + GetID + 'menu input[type="checkbox"]:checked + .sub-menu {'#13
      + '    display: none;'#13
      + '  }'#13

      + '  #' + GetID + 'menu .popup-menu li, '#13
      + '  #' + GetID + 'menu .sub-menu li {'#13
      + '    border-width: 1px;'#13
      + '  }'#13

      + '  #' + GetID + 'menu .popup-menu .sub-menu, '#13
      + '  #' + GetID + 'menu .sub-menu .sub-menu {'#13
      + '    top: 0;'#13
      + '    left: 100%;'#13
      + '  }'#13

      + '  #' + GetID + 'menu li:hover > input[type="checkbox"] + .popup-menu, '#13
      + '  #' + GetID + 'menu li:hover > input[type="checkbox"] + .sub-menu {'#13
      + '    display: block;'#13
      + '  }'#13;

    if Appearance.HamburgerMenu.Visible = hmResponsive then
    begin
      MenuResponsiveStyle :=
        '@media only screen and (min-width: ' + IntToStr(Appearance.HamburgerMenu.ResponsiveMaxWidth) + 'px) {'#13
        + MenuResponsiveStyle
        + '}';
    end;
  end;

  if Appearance.ImageSize > 0 then
  begin
    MenuImageSize := '  width: ' + IntToStr(Appearance.ImageSize) + 'px;'#13
      + '  height: ' + IntToStr(Appearance.ImageSize) + 'px;'#13
    ;
  end;

  MenuStyle :=
    '#' + GetID + 'menu ul {'#13
    + '  margin: 0;'#13
    + '  padding: 0;'#13
    + '}'#13

    + '#' + GetID + 'menu .main-menu {'#13
    + '  display: none;'#13
    + '}'#13

    + '#' + GetID + 'menu .popup-menu {'#13
    + '  display: block;'#13
    + '}'#13

    + '#' + GetID + 'menu label img.icon,' +
    '#' + GetID + 'menu label span.checked {'#13
    + MenuImageSize
    + '  margin-right: 10px;'#13
    + '  pointer-events: none;'#13
//    + '  float: left;'#13
    + '}'#13

    + '#tm:checked + .main-menu {'#13
    + '  display: block;'#13
    + '}'#13

    + '#' + GetID + 'menu input[type="checkbox"],'#13
    + '#' + GetID + 'menu ul span.drop-label {'#13
    + '  display: none;'#13
    + '}'#13

    + '#' + GetID + 'menu li,'#13
    + '#' + GetID + 'menu #toggle-menu,'#13
    + '#' + GetID + 'menu .popup-menu,'#13
    + '#' + GetID + 'menu .sub-menu {'#13
    + '  border-width: 1px;'#13
    + '  border-style: solid;'#13
    + '  border-color: rgba(0, 0, 0, .05);'#13
    + '  border-bottom: 0px;'#13
    + '  border-top: 0px;'#13
    + '}'#13

    + '#' + GetID + 'menu li,'#13
    + '#' + GetID + 'menu #toggle-menu {'#13
    + '  border-width: 0 0 3px;'#13
    + '}'#13

    + '#' + GetID + 'menu .popup-menu,'#13
    + '#' + GetID + 'menu .sub-menu {'#13
    + '  border-width: 1px 1px 0;'#13
    + '  margin: 0 1em;'#13
    + '}'#13

    + '#' + GetID + 'menu .popup-menu li:first-child,'#13
    + '#' + GetID + 'menu .sub-menu li:first-child {'#13
    + '  border-top: 1px solid;'#13
    + '  border-color: rgba(0, 0, 0, .05);'#13
    + '}'#13

    + '#' + GetID + 'menu .popup-menu li:last-child,'#13
    + '#' + GetID + 'menu .sub-menu li:last-child {'#13
    + '  border-bottom: 1px solid;'#13
    + '  border-color: rgba(0, 0, 0, .05);'#13
    + '}'#13

    + '#' + GetID + 'menu li,'#13
    + '#' + GetID + 'menu #toggle-menu,'#13
    + '#' + GetID + 'menu li label {'#13
    + '  position: relative;'#13
    + '  display: block;'#13
    + '}'#13

    + '#' + GetID + 'menu #toggle-menu,'#13
    + '#' + GetID + 'menu li label { '#13
    + '  padding: 0.75em 1.5em;'#13
    + '  text-decoration: none;'#13
    + '}'#13

    + '#' + GetID + 'menu li span.menu-separator { '#13
    + '  display: block;'#13
    + '  width: 100%;'#13
    + '  height: 1px;'#13
    + '  background-color: rgba(0, 0, 0, .05);'#13
    + '}'#13

    + '#' + GetID + 'menu .popup-menu, '#13
    + '#' + GetID + 'menu .sub-menu {'#13
    + '  display: none;'#13
    + '}'#13

    + '#' + GetID + 'menu input[type="checkbox"]:checked + .popup-menu,'#13
    + '#' + GetID + 'menu input[type="checkbox"]:checked + .sub-menu {'#13
    + '  display: block;'#13
    + '  z-index: 3000;'#13
    + '}'#13

    + '#' + GetID + 'menu span.drop-icon {'#13
//    + '  float: right;'#13
    + '  margin-left: 10px;'
    + '}'#13

    + '#' + GetID + 'menu span.hamburger-icon {'#13
    + '  float: right;'#13
    + '  padding: 0;'#13
    + '  margin: 0;'#13
    + '  font-weight: 900;'#13
    + '}'#13

    + '@media only screen and (max-width: 64em) and (min-width: 52.01em) {'#13
    + '  #' + GetID + 'menu li {'#13
    + '    width: auto;'#13
    + '  }'#13

    + '  #' + GetID + 'menu .sub-menu li {'#13
    + '    width: auto;'#13
    + '  }'#13
    + '}'#13;

  if ElementClassName = '' then
  begin
    MenuColorStyle := ''

      + '#' + GetID + 'menu .popup-menu, '#13
      + '#' + GetID + 'menu .sub-menu {'#13
      + '  background-color: ' + ColorToHtml(Appearance.BackgroundColor) + ';'#13
      + '}'#13

      + '@media only screen and (max-width: 64em) and (min-width: 52.01em) {'#13
      +   '#' + GetID + 'menu .sub-menu {'#13
      +   '  background-color: ' + ColorToHtml(Appearance.HamburgerMenu.BackgroundColor) + ';'#13
      +   '}'#13
      + '}'#13

      + '#' + GetID + 'menu li,'#13
      + '#' + GetID + 'menu #toggle-menu,'#13
      + '#' + GetID + 'menu li label {'#13
      + '}'#13

      + '#' + GetID + 'menu #toggle-menu {'#13
      + '  background-color: ' + ColorToHtml(Appearance.HamburgerMenu.BackgroundColor) + ';'#13
      + '  color: ' + ColorToHtml(Appearance.HamburgerMenu.CaptionColor) + ';'#13
      + '}'#13

      + '#' + GetID + 'menu .main-menu li label {'#13
      + '  background-color: ' + ColorToHtml(Appearance.BackgroundColor) + ';'#13
      + '}'#13

      + '#' + GetID + 'menu li label:hover {'#13
      + '  background-color: ' + ColorToHtml(Appearance.HoverColor) + ';'#13
      + '  color: ' + ColorToHtml(Appearance.HoverFontColor) + ';'#13
      + '}'#13

      + '#' + GetID + 'menu .popup-menu label:hover, '#13
      + '#' + GetID + 'menu .sub-menu label:hover {'#13
      + '  background-color: ' + ColorToHtml(Appearance.HoverColor) + ';'#13
      + '  color: ' + ColorToHtml(Appearance.HoverFontColor) + ';'#13
      + '}'#13;
  end;

  MenuStyle := MenuStyle
    + MenuColorStyle
    + MenuResponsiveStyle
    ;

  FMainMenu := True;
  FMenuIndex := 0;

  ElStyle := TJSHTMLElement(document.createElement('STYLE'));
  ElStyle.innerHTML := MenuStyle;

  ElScript := TJSHTMLElement(document.createElement('SCRIPT'));
  ElScript.innerHTML := 'function ' + GetID + 'MenuClick(ctrl){'
    + '  el = ctrl.parentElement.parentElement;'
    + '  el.style.display = "none";'
    + '  setTimeout(function(){el.style.removeProperty("display")}, 25);'
    + '}'
    + 'function ' + GetID + 'MenuOut(ctrl){'
    + '  menuel = document.getElementById("' + GetID + '");'
    + '  if(menuel) { '
    + '    popupel = menuel.getElementsByClassName("popup-menu");'
    + '    if(popupel) '
    + '      setTimeout(function(){popupel[0].style.display = "none"}, 25);'
    + '  }'
    + '}';

  ElLabel := TJSHTMLElement(document.createElement('LABEL'));
  ElLabel.setAttribute('for', 'tm');
  ElLabel.setAttribute('id', 'toggle-menu');
  ElLabel.innerHTML := Appearance.HamburgerMenu.Caption;

  ElSpan := TJSHTMLElement(document.createElement('SPAN'));
  ElSpan.setAttribute('class', 'drop-label');
  ElLabel.appendChild(ElSpan);

  ElIcon := TJSHTMLElement(document.createElement('SPAN'));
  ElIcon.setAttribute('class', 'hamburger-icon');
//  ElIcon.innerHTML := '&gt;';
  ElIcon.innerHTML := '&#9776;';
//  ElIcon.innerHTML := '<svg height="32px" id="Layer_1" style="enable-background:new 0 0 32 32;" version="1.1" viewBox="0 0 32 32" width="32px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"/></svg>';
  ElSpan.appendChild(ElIcon);

  ElCheckBox := TJSHTMLElement(document.createElement('INPUT'));
  ElCheckBox.setAttribute('type', 'checkbox');
  ElCheckBox.setAttribute('id', 'tm');

  FMenu.appendChild(ElStyle);
  FMenu.appendChild(ElScript);
  FMenu.appendChild(ElLabel);
  FMenu.appendChild(ElCheckBox);

  ElItems := GetMenuSource(Items);
  if ElItems <> nil then
    FMenu.appendChild(ElItems);
  ElHandle.appendChild(FMenu);
end;

{ TMenuItem }

procedure TMenuItem.Assign(Source: TPersistent);
var
  i: integer;
  srcmenu,mnu: TMenuItem;
  pm: TCustomMainMenu;

begin
  if (Source is TMenuItem) then
  begin
    srcmenu := (Source as TMenuItem);

    FAutoCheck := srcmenu.AutoCheck;
    FAutoHotkeys := srcmenu.AutoHotkeys;
    FAutoLineReduction:= srcmenu.AutoLineReduction;
    FBreak := srcmenu.Break;
    FCaption := srcmenu.Caption;
    FChecked := srcmenu.Checked;
    FDefault := srcmenu.Default;
    FEnabled := srcmenu.Enabled;
    FGroupIndex := srcmenu.GroupIndex;
    FHelpContext := srcmenu.HelpContext;
    FHint := srcmenu.Hint;
    FImageIndex := srcmenu.ImageIndex;
    FRadioItem := srcmenu.RadioItem;
    FShortCut := srcmenu.ShortCut;
    FVisible := srcmenu.Visible;
    FElementClassName := srcmenu.ElementClassName;

    pm := GetParentMenu;

    if Assigned(Parent) then
      Name := pm.GetUniqueName(srcmenu.Caption);

    if Assigned(srcmenu.Items) then
    begin
      if Assigned(FItems) then
      begin
        for I := FItems.Count - 1 downto 0 do
        begin
          RemoveItem(GetParentMenu.Parent, TComponent(FItems[I]));
        end;
        FItems.Clear;
      end
      else
        FItems := TMenuItemList.Create;

      for i := 0 to srcmenu.Items.Count - 1 do
      begin
        // create with form owner
        mnu := TMenuItem.Create(pm.Owner);
        mnu.FParentMenu := pm;
        mnu.FParentItem := Self;
        mnu.Assign(TMenuItem(srcmenu.Items[i]));
        FItems.Add(mnu);
      end;
    end;
  end;
end;

procedure TMenuItem.BeginUpdate;
begin
  inc(FUpdateCount);
end;

procedure TMenuItem.Clear;
var
  i: integer;
  mnu: TMenuItem;
begin
  for i := Count - 1 downto 0 do
  begin
    mnu := TMenuItem(FItems[i]);
    if Assigned(mnu.Items) then
      mnu.Items.Clear;
    mnu.Free;
  end;
end;

procedure TMenuItem.Click;
begin
  if Assigned(OnClick) then
    OnClick(Self);
end;

constructor TMenuItem.Create(AOwner: TComponent);
begin
  FParentMenu := nil;

  if AOwner is TMainMenu then
    FParentMenu := AOwner;

  inherited Create(AOwner);

  FAutoHotkeys := maParent;
  FAutoLineReduction := maParent;
  FImageIndex := -1;
  FHint := '';
  FCaption := 'MenuItem';
  FChecked := False;
  FEnabled := True;
  FDefault := False;
  FRadioItem := False;
  FVisible := True;
  FOwner := TMenuItem(AOwner);
end;

destructor TMenuItem.Destroy;
var
  i: integer;
  pm: TCustomMainMenu;
  mnu: TMenuItem;
begin
  pm := GetParentMenu;

  // delete item from the parent item list
  if Assigned(Parent) and Assigned(pm) then
  begin
    pm.BeginUpdate;

    for i := TMenuItem(Parent).Items.Count - 1 downto 0 do
    begin
      if (TMenuItem(Parent).Items[i] = Self) then
      begin
        TMenuItem(Parent).Items.Delete(i);
      end;
    end;

    pm.EndUpdate;
  end;

  // delete its childs
  if Assigned(Items) then
  begin
    for i := Items.Count - 1 downto 0 do
    begin
      mnu := TMenuItem(Items[i]);
      mnu.FParentItem := nil;
      mnu.Free;
      Items.Delete(i);
    end;
  end;

  inherited;
end;

procedure TMenuItem.EndUpdate;
begin
  if (FUpdateCount > 0) then
  begin
    dec(FUpdateCount);

    if FUpdateCount = 0 then
      GetParentMenu.UpdateElement;
  end;
end;

procedure TMenuItem.GetChildren(Proc: TGetChildProc; Root: TComponent);
var
  I: Integer;
begin
  if Assigned(Items) then
    for I := 0 to Items.Count - 1 do Proc(TMenuItem(Items[I]));
end;

function TMenuItem.GetCount: integer;
begin
  if FItems = nil then
    Result := 0
  else
    Result := FItems.Count;
end;

function TMenuItem.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TMenuItem.GetParentMenu: TCustomMainMenu;
var
  AOwner: TComponent;
begin
  Result := nil;

  AOwner := FParentMenu;

  while Assigned(AOwner) and (AOwner is TMenuItem) do
  begin
    AOwner := TMenuItem(AOwner).FParentMenu;
  end;

  if Assigned(AOwner) then
    Result := TCustomMainMenu(AOwner);
end;

procedure TMenuItem.Insert(Index: Integer; Item: TMenuItem);
begin
  if FItems = nil then
    FItems := TMenuItemList.Create;
  FItems.Insert(Index, Item);
  Item.FParentItem := Self;

  if Assigned(Item.GetParentMenu) then
    Item.GetParentMenu.UpdateElement;
end;

function TMenuItem.IsChild(Item: TMenuItem): boolean;
var
  i: integer;
begin
  Result := false;
  if not Assigned(Items) then
    Exit;

  for i := 0 to Items.Count - 1 do
  begin
    if (Items[i] = Item) then
    begin
      Result := true;
    end;
  end;
end;

procedure TMenuItem.RemoveItem(AOwner, AItem: TComponent);
var
  I: Integer;
begin
  if Assigned(TMenuItem(AItem).Items) then
  begin
    for I := TMenuItem(AItem).Items.Count - 1 downto 0 do
      RemoveItem(AOwner, TComponent(TMenuItem(AItem).Items[I]));
  end;
  AOwner.RemoveComponent(AItem);
end;

procedure TMenuItem.Add(Item: TMenuItem);
begin
  Insert(GetCount, Item);
end;

procedure TMenuItem.SetCaption(const Value: string);
var
  pm: TCustomMainMenu;
begin
  FCaption := Value;
  pm := GetParentMenu;
  if Assigned(pm) then
    pm.UpdateElement;
end;

procedure TMenuItem.SetChecked(const Value: Boolean);
var
  pm: TCustomMainMenu;
  Item, OtherItem: TMenuItem;
  MenuOwner: TComponent;
  I: Integer;
begin
  if FChecked <> Value then
  begin
    FChecked := Value;
    MenuOwner := FParentMenu;
    if Assigned(MenuOwner) then
    begin
      if (MenuOwner is TMenuItem) and Value then
      begin
        Item := TMenuItem(MenuOwner);
        for I := 0 to Item.Items.Count - 1 do
        begin
          OtherItem := TMenuItem(Item.Items[I]);
          if (OtherItem.RadioItem) and (OtherItem <> Self) then
            OtherItem.Checked := False;
        end;
      end;

      while not (MenuOwner is TCustomMainMenu) and Assigned(MenuOwner) do
      begin
        MenuOwner := MenuOwner.Owner;
      end;
    end;

    pm := GetParentMenu;
    if Assigned(pm) then
      pm.UpdateElement;
  end;
end;

procedure TMenuItem.SetChildOrder(Child: TComponent; Order: Integer);
var
  idx: integer;
begin
  idx := FItems.IndexOf(Child);
  if (idx >= 0) and (Order < FItems.Count) then
  begin
    FItems.Move(idx, Order);
  end;
end;

procedure TMenuItem.SetEnabled(const Value: Boolean);
begin
  if FEnabled <> Value then
  begin
    FEnabled := Value;

    GetParentMenu.UpdateElement;
  end;
end;

procedure TMenuItem.SetImageIndex(const Value: Integer);
var
  pm: TCustomMainMenu;
begin
  FImageIndex := Value;
  pm := GetParentMenu;
  if Assigned(pm) then
    pm.UpdateElement;
end;

procedure TMenuItem.SetParentComponent(Value: TComponent);
begin
  inherited;

  FParentMenu := Value;

  if (Value <> nil) then
  begin
    if Value is TCustomMainMenu then
    begin
      if not TCustomMainMenu(Value).Items.IsChild(Self) then
        TCustomMainMenu(Value).Items.Add(Self)
    end
    else if Value is TMenuItem then
    begin
      if not TMenuItem(Value).IsChild(Self) then
        TMenuItem(Value).Add(Self);
    end;
  end;
end;

{ TMainMenuAppearance }

procedure TMainMenuAppearance.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TMainMenu) then
  begin
    FBackgroundColor := (Source as TMainMenuAppearance).BackgroundColor;
    FHoverColor := (Source as TMainMenuAppearance).HoverColor;
    FHoverFontColor := (Source as TMainMenuAppearance).HoverFontColor;
    FHamburgerMenu.Assign((Source as TMainMenuAppearance).HamburgerMenu);
    FImageURLs.Assign((Source as TMainMenuAppearance).ImageURLs);
    FImageSize := (Source as TMainMenuAppearance).ImageSize;
    FSubmenuIndicator := (Source as TMainMenuAppearance).SubmenuIndicator;
  end;
end;

constructor TMainMenuAppearance.Create(AOwner: TMainMenu);
begin
  FBackgroundColor := $F0F0F0;
  FHoverColor := clHighlight;
  FHoverFontColor := clHighlightText;
  FHamburgerMenu := THamburgerMenu.Create(Self);
  FImageURLs := TStringList.Create;
  FImageURLs.SkipLastLinebreak := true;
  FImageURLs.OnChange := HandleImageURLsChanged;
  FImageSize := 16;
  FSubmenuIndicator := '&#9658;';
  FOwner := AOwner;
end;

destructor TMainMenuAppearance.Destroy;
begin
  FHamburgerMenu.Free;
  FImageURLs.Free;
  inherited;
end;

function TMainMenuAppearance.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TMainMenuAppearance.HandleImageURLsChanged(Sender: TObject);
begin
  FOwner.UpdateElement;
end;

procedure TMainMenuAppearance.SetBackgroundColor(const Value: TColor);
begin
  if FBackgroundColor <> Value then
  begin
    FBackgroundColor := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TMainMenuAppearance.SetHoverColor(const Value: TColor);
begin
  if FHoverColor <> Value then
  begin
    FHoverColor := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TMainMenuAppearance.SetHoverFontColor(const Value: TColor);
begin
  if FHoverFontColor <> Value then
  begin
    FHoverFontColor := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TMainMenuAppearance.SetImageSize(const Value: Integer);
begin
  if FImageSize <> Value then
  begin
    FImageSize := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TMainMenuAppearance.SetImageURLs(const Value: TStringList);
begin
  FImageURLs.Assign(Value);
end;

procedure TMainMenuAppearance.SetSubmenuIndicator(const Value: string);
begin
  if FSubmenuIndicator <> Value then
  begin
    FSubmenuIndicator := Value;
    FOwner.UpdateElement;
  end;
end;

{ THamburgerMenu }

procedure THamburgerMenu.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is THamburgerMenu) then
  begin
    FBackgroundColor := (Source as THamburgerMenu).BackgroundColor;
    FCaption := (Source as THamburgerMenu).Caption;
    FCaptionColor := (Source as THamburgerMenu).CaptionColor;
    FVisible := (Source as THamburgerMenu).Visible;
    FResponsiveMaxWidth := (Source as THamburgerMenu).ResponsiveMaxWidth;
  end;
end;

constructor THamburgerMenu.Create(AOwner: TMainMenuAppearance);
begin
  FBackgroundColor := clSilver;
  FCaption := 'Menu';
  FCaptionColor := clWhite;
  FVisible := hmResponsive;
  FResponsiveMaxWidth := 768;
  FOwner := AOwner;
end;

destructor THamburgerMenu.Destroy;
begin
  inherited;
end;

function THamburgerMenu.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure THamburgerMenu.SetBackgroundColor(const Value: TColor);
begin
  if FBackgroundColor <> Value then
  begin
    FBackgroundColor := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure THamburgerMenu.SetCaption(const Value: string);
begin
  if FCaption <> Value then
  begin
    FCaption := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure THamburgerMenu.SetCaptionColor(const Value: TColor);
begin
  if FCaptionColor <> Value then
  begin
    FCaptionColor := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure THamburgerMenu.SetResponsiveMaxWidth(const Value: Integer);
begin
  if FResponsiveMaxWidth <> Value then
  begin
    FResponsiveMaxWidth := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

procedure THamburgerMenu.SetVisible(const Value: TMainMenuHamburgerMenuVisible);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    FOwner.FOwner.UpdateElement;
  end;
end;

{ TMenuItemList }

function TMenuItemList.GetItem(Index: Integer): TMenuItem;
begin
  Result := TMenuItem(inherited Items[Index]);
end;

procedure TMenuItemList.PutItem(Index: Integer; const Value: TMenuItem);
begin
  inherited Items[Index] := Value;
end;

{ TPopupMenu }

procedure TPopupMenu.CreateInitialize;
begin
  inherited;
  Appearance.HamburgerMenu.Visible := hmNever;
  FIsPopupMenu := True;
end;

procedure TPopupMenu.Popup(X, Y: Integer);
begin
  Top := Y;
  Left := X;
  FShowPopup := True;
  UpdateElement;
end;

{ TWebCustomControl }

function TWebCustomControl.HandleDoContextMenu(Event: TJSMouseEvent): Boolean;
var
  pt: TPoint;
  frm: TCustomForm;
  r: TJSDOMRect;
begin
  inherited;
  if  Assigned(FPopupMenu) then
  begin
    Event.stopPropagation;
    Event.preventDefault;
    pt := Point(Round(Event.clientX), Round(Event.clientY));

    frm := GetParentForm(Self);

    if Assigned(frm) {and frm.Popup} and Assigned(frm.Container) then
    begin
      r := frm.Container.getBoundingClientRect;
      pt.X := pt.X - Round(r.Left);
      pt.Y := pt.Y - Round(r.Top);
    end;

    FPopupMenu.Popup(pt.X,pt.Y);
    Result := true;
  end;
end;

procedure TWebCustomControl.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FPopupMenu) then
    FPopupMenu := nil;
end;

{ TWebGraphicControl }

function TWebGraphicControl.HandleDoContextMenu(Event: TJSMouseEvent): Boolean;
var
  pt: TPoint;
  frm: TCustomForm;
  r: TJSDOMRect;
begin
  inherited;
  if  Assigned(FPopupMenu) then
  begin
    Event.stopPropagation;
    Event.preventDefault;
    pt := Point(Round(Event.clientX), Round(Event.clientY));

    frm := GetParentForm(Self);

    if Assigned(frm) and (frm.Popup) and Assigned(frm.Container) then
    begin
      r := frm.Container.getBoundingClientRect;
      pt.X := pt.X - Round(r.Left);
      pt.Y := pt.Y - Round(r.Top);
    end;

    FPopupMenu.Popup(pt.X,pt.Y);
    Result := true;
  end;
end;

procedure TWebGraphicControl.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FPopupMenu) then
    FPopupMenu := nil;
end;

end.

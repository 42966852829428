unit DEMOSIDA;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, Vcl.Controls,
  VCL.TMSFNCCustomControl, VCL.TMSFNCPanel, Vcl.StdCtrls, WEBLib.StdCtrls,
  VCL.TMSFNCToolBar, Vcl.Imaging.jpeg, WEBLib.ExtCtrls, Vcl.Graphics;

type
  TForm9 = class(TForm)
    TMSFNCPanel1: TTMSFNCPanel;
    TMSFNCToolBarButton1: TTMSFNCToolBarButton;
    WebImageControl1: TImageControl;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebImageControl2: TImageControl;
    WebLabel3: TLabel;
    WebImageControl4: TImageControl;
    WebLabel4: TLabel;
    WebImageControl3: TImageControl;
    WebLabel6: TLabel;
    WebLabel7: TLabel;
    WebLabel8: TLabel;
    WebLabel9: TLabel;
    WebLabel10: TLabel;
    WebLabel11: TLabel;
    WebImageControl5: TImageControl;
    TMSFNCToolBarButton2: TTMSFNCToolBarButton;
    procedure WebFormCreate(Sender: TObject);
    procedure DEMOAVCONNECT(Sender: TObject);
    procedure VIDEO_CONNECT(Sender: TObject);
    procedure AVSLUTA(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form9: TForm9;

implementation

Uses FRONT;

{$R *.dfm}

procedure TForm9.WebFormCreate(Sender: TObject);
  Var fil: String;
  Begin
    If Form1.PARAM_D='1' Then
      Begin
        WebLabel1.Caption:='Inventering med streckkoder';
        WebLabel2.Caption:='Beställning med streckkoder';
        WebLabel3.Caption:='Standard ean streckkoder';

        WebLabel6.Caption:='Socket S700, 1D, Bluetooth';
        WebLabel7.Caption:='Socket S700, 1D, Imager, Bluetooth, Inkl., USB-laddningskabel, handledsrem';
        WebLabel8.Caption:='Scanner: 1D | Imager';
        WebLabel9.Caption:='Bluetooth, version 2.1 + EDR';
        WebLabel10.Caption:='USB-laddningskabel, handledsrem ingår';
        WebLabel11.Caption:='Android | iOS | Windows-surfplatta';
      End
    Else
      Begin
        WebLabel1.Caption:='Inventory with barcodes';
        WebLabel2.Caption:='Ordering with barcodes';
        WebLabel3.Caption:='Standard ean barcodes';

        WebLabel6.Caption:='Socket S700, 1D, Bluetooth';
        WebLabel7.Caption:='Socket S700, 1D, Imager, Bluetooth, Inkl., USB-charging cable, hand strap';
        WebLabel8.Caption:='Scanner: 1D | Imager';
        WebLabel9.Caption:='Bluetooth, version 2.1 + EDR';
        WebLabel10.Caption:='USB-charging cable, hand strap is included';
        WebLabel11.Caption:='Android  |iOS | Windows-tablet';
      End;
  End;

procedure TForm9.DEMOAVCONNECT(Sender: TObject);
  Var path: String;
  Begin
    path:='https://connectsystem.se/customer/connect/';
    Application.Navigate(path);
  End;

procedure TForm9.VIDEO_CONNECT(Sender: TObject);
  Var path: String;
  Begin
    path:='https://connectsystem.se/diverse/Connect01.mp4';
    Application.Navigate(path);
  End;

procedure TForm9.AVSLUTA(Sender: TObject);
  Begin
    Close;
  End;

procedure TForm9.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebImageControl1 := TImageControl.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebImageControl2 := TImageControl.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebImageControl4 := TImageControl.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebImageControl3 := TImageControl.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebImageControl5 := TImageControl.Create(Self);
  TMSFNCPanel1 := TTMSFNCPanel.Create(Self);
  TMSFNCToolBarButton1 := TTMSFNCToolBarButton.Create(Self);
  TMSFNCToolBarButton2 := TTMSFNCToolBarButton.Create(Self);

  WebImageControl1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebImageControl2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebImageControl4.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebImageControl3.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebImageControl5.BeforeLoadDFMValues;
  TMSFNCPanel1.BeforeLoadDFMValues;
  TMSFNCToolBarButton1.BeforeLoadDFMValues;
  TMSFNCToolBarButton2.BeforeLoadDFMValues;
  try
    Name := 'Form9';
    Width := 1200;
    Height := 800;
    Caption := 'lab';
    SetEvent(Self, 'OnClick', 'DEMOAVCONNECT');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    WebImageControl1.SetParentComponent(Self);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 64;
    WebImageControl1.Top := 178;
    WebImageControl1.Width := 521;
    WebImageControl1.Height := 191;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 100.000000000000000000;
    WebImageControl1.ChildOrder := 1;
    WebImageControl1.Picture.LoadFromFile('DEMOSIDA.WebImageControl1.Picture.jpg');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 64;
    WebLabel1.Top := 150;
    WebLabel1.Width := 307;
    WebLabel1.Height := 29;
    WebLabel1.Caption := 'Inventering med streckkoder';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -24;
    WebLabel1.Font.Name := 'Tahoma';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 672;
    WebLabel2.Top := 150;
    WebLabel2.Width := 299;
    WebLabel2.Height := 29;
    WebLabel2.Caption := 'Best'#228'llning med streckkoder';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -24;
    WebLabel2.Font.Name := 'Tahoma';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebImageControl2.SetParentComponent(Self);
    WebImageControl2.Name := 'WebImageControl2';
    WebImageControl2.Left := 704;
    WebImageControl2.Top := 178;
    WebImageControl2.Width := 441;
    WebImageControl2.Height := 191;
    WebImageControl2.HeightPercent := 100.000000000000000000;
    WebImageControl2.WidthPercent := 100.000000000000000000;
    WebImageControl2.ChildOrder := 4;
    WebImageControl2.Picture.LoadFromFile('DEMOSIDA.WebImageControl2.Picture.jpg');
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 71;
    WebLabel3.Top := 72;
    WebLabel3.Width := 179;
    WebLabel3.Height := 19;
    WebLabel3.Caption := 'Standard ean streckkoder';
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Tahoma';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebImageControl4.SetParentComponent(Self);
    WebImageControl4.Name := 'WebImageControl4';
    WebImageControl4.Left := 56;
    WebImageControl4.Top := 472;
    WebImageControl4.Width := 217;
    WebImageControl4.Height := 265;
    WebImageControl4.HeightPercent := 100.000000000000000000;
    WebImageControl4.WidthPercent := 100.000000000000000000;
    WebImageControl4.ChildOrder := 7;
    WebImageControl4.Picture.LoadFromFile('DEMOSIDA.WebImageControl4.Picture.jpg');
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 71;
    WebLabel4.Top := 445;
    WebLabel4.Width := 138;
    WebLabel4.Height := 29;
    WebLabel4.Caption := 'Handscanner';
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -24;
    WebLabel4.Font.Name := 'Tahoma';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebImageControl3.SetParentComponent(Self);
    WebImageControl3.Name := 'WebImageControl3';
    WebImageControl3.Left := 72;
    WebImageControl3.Top := 90;
    WebImageControl3.Width := 178;
    WebImageControl3.Height := 40;
    WebImageControl3.HeightPercent := 100.000000000000000000;
    WebImageControl3.WidthPercent := 100.000000000000000000;
    WebImageControl3.ChildOrder := 5;
    WebImageControl3.Picture.LoadFromFile('DEMOSIDA.WebImageControl3.Picture.jpg');
    WebLabel6.SetParentComponent(Self);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 342;
    WebLabel6.Top := 469;
    WebLabel6.Width := 484;
    WebLabel6.Height := 48;
    WebLabel6.Caption := 'Socket S700, 1D, Bluetooth';
    WebLabel6.Font.Charset := DEFAULT_CHARSET;
    WebLabel6.Font.Color := clWindowText;
    WebLabel6.Font.Height := -40;
    WebLabel6.Font.Name := 'Tahoma';
    WebLabel6.Font.Style := [];
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.ParentFont := False;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebLabel7.SetParentComponent(Self);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 342;
    WebLabel7.Top := 536;
    WebLabel7.Width := 567;
    WebLabel7.Height := 21;
    WebLabel7.Caption := 'Socket S700, 1D, Imager, Bluetooth, Inkl., USB-laddningskabel, handledsrem';
    WebLabel7.Font.Charset := DEFAULT_CHARSET;
    WebLabel7.Font.Color := clWindowText;
    WebLabel7.Font.Height := -17;
    WebLabel7.Font.Name := 'Tahoma';
    WebLabel7.Font.Style := [];
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.ParentFont := False;
    WebLabel7.WidthPercent := 100.000000000000000000;
    WebLabel8.SetParentComponent(Self);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 342;
    WebLabel8.Top := 568;
    WebLabel8.Width := 185;
    WebLabel8.Height := 23;
    WebLabel8.Caption := 'Scanner: 1D | Imager';
    WebLabel8.Font.Charset := DEFAULT_CHARSET;
    WebLabel8.Font.Color := clWindowText;
    WebLabel8.Font.Height := -19;
    WebLabel8.Font.Name := 'Tahoma';
    WebLabel8.Font.Style := [];
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.ParentFont := False;
    WebLabel8.WidthPercent := 100.000000000000000000;
    WebLabel9.SetParentComponent(Self);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 342;
    WebLabel9.Top := 593;
    WebLabel9.Width := 244;
    WebLabel9.Height := 23;
    WebLabel9.Caption := 'Bluetooth, version 2.1 + EDR';
    WebLabel9.Font.Charset := DEFAULT_CHARSET;
    WebLabel9.Font.Color := clWindowText;
    WebLabel9.Font.Height := -19;
    WebLabel9.Font.Name := 'Tahoma';
    WebLabel9.Font.Style := [];
    WebLabel9.HeightPercent := 100.000000000000000000;
    WebLabel9.ParentFont := False;
    WebLabel9.WidthPercent := 100.000000000000000000;
    WebLabel10.SetParentComponent(Self);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Left := 342;
    WebLabel10.Top := 618;
    WebLabel10.Width := 336;
    WebLabel10.Height := 23;
    WebLabel10.Caption := 'USB-laddningskabel, handledsrem ing'#229'r';
    WebLabel10.Font.Charset := DEFAULT_CHARSET;
    WebLabel10.Font.Color := clWindowText;
    WebLabel10.Font.Height := -19;
    WebLabel10.Font.Name := 'Tahoma';
    WebLabel10.Font.Style := [];
    WebLabel10.HeightPercent := 100.000000000000000000;
    WebLabel10.ParentFont := False;
    WebLabel10.WidthPercent := 100.000000000000000000;
    WebLabel11.SetParentComponent(Self);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Left := 342;
    WebLabel11.Top := 643;
    WebLabel11.Width := 283;
    WebLabel11.Height := 23;
    WebLabel11.Caption := 'Android  iOS | Windows-surfplatts';
    WebLabel11.Font.Charset := DEFAULT_CHARSET;
    WebLabel11.Font.Color := clWindowText;
    WebLabel11.Font.Height := -19;
    WebLabel11.Font.Name := 'Tahoma';
    WebLabel11.Font.Style := [];
    WebLabel11.HeightPercent := 100.000000000000000000;
    WebLabel11.ParentFont := False;
    WebLabel11.WidthPercent := 100.000000000000000000;
    WebImageControl5.SetParentComponent(Self);
    WebImageControl5.Name := 'WebImageControl5';
    WebImageControl5.Left := 300;
    WebImageControl5.Top := 558;
    WebImageControl5.Width := 38;
    WebImageControl5.Height := 109;
    WebImageControl5.HeightPercent := 100.000000000000000000;
    WebImageControl5.WidthPercent := 100.000000000000000000;
    WebImageControl5.ChildOrder := 15;
    WebImageControl5.Picture.LoadFromFile('DEMOSIDA.WebImageControl5.Picture.jpg');
    TMSFNCPanel1.SetParentComponent(Self);
    TMSFNCPanel1.Name := 'TMSFNCPanel1';
    TMSFNCPanel1.Left := 0;
    TMSFNCPanel1.Top := 0;
    TMSFNCPanel1.Width := 1200;
    TMSFNCPanel1.Height := 50;
    TMSFNCPanel1.Align := alTop;
    TMSFNCPanel1.ParentDoubleBuffered := False;
    TMSFNCPanel1.DoubleBuffered := True;
    TMSFNCPanel1.TabOrder := 0;
    TMSFNCPanel1.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel1.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel1.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel1.SectionsAppearance.Font.Style := [];
    TMSFNCPanel1.SectionsAppearance.Fill.Color := 15790320;
    TMSFNCPanel1.Header.Size := 50.000000000000000000;
    TMSFNCPanel1.Header.HorizontalTextAlign := gtaLeading;
    TMSFNCPanel1.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Header.Font.Color := clWindowText;
    TMSFNCPanel1.Header.Font.Height := -11;
    TMSFNCPanel1.Header.Font.Name := 'Tahoma';
    TMSFNCPanel1.Header.Font.Style := [];
    TMSFNCPanel1.Header.Fill.Color := 15000804;
    TMSFNCPanel1.Header.Buttons := [pbClose];
    TMSFNCPanel1.Header.ButtonSize := 40.000000000000000000;
    TMSFNCPanel1.Footer.Text := 'Footer';
    TMSFNCPanel1.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Footer.Font.Color := clWindowText;
    TMSFNCPanel1.Footer.Font.Height := -11;
    TMSFNCPanel1.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel1.Footer.Font.Style := [];
    TMSFNCPanel1.Footer.Fill.Color := 15000804;
    SetEvent(TMSFNCPanel1, Self, 'OnHeaderCloseButtonClick', 'AVSLUTA');
    TMSFNCToolBarButton1.SetParentComponent(TMSFNCPanel1);
    TMSFNCToolBarButton1.Name := 'TMSFNCToolBarButton1';
    TMSFNCToolBarButton1.Left := 3;
    TMSFNCToolBarButton1.Top := 3;
    TMSFNCToolBarButton1.Width := 158;
    TMSFNCToolBarButton1.Height := 40;
    TMSFNCToolBarButton1.ParentDoubleBuffered := False;
    TMSFNCToolBarButton1.DoubleBuffered := True;
    TMSFNCToolBarButton1.Font.Charset := DEFAULT_CHARSET;
    TMSFNCToolBarButton1.Font.Color := clWindowText;
    TMSFNCToolBarButton1.Font.Height := -19;
    TMSFNCToolBarButton1.Font.Name := 'Tahoma';
    TMSFNCToolBarButton1.Font.Style := [];
    TMSFNCToolBarButton1.ParentColor := True;
    TMSFNCToolBarButton1.TabOrder := 0;
    SetEvent(TMSFNCToolBarButton1, Self, 'OnClick', 'DEMOAVCONNECT');
    TMSFNCToolBarButton1.Text := 'Demo Connect';
    TMSFNCToolBarButton1.Appearance.InnerStroke.Color := clWhite;
    TMSFNCToolBarButton1.Appearance.NormalFill.Kind := gfkGradient;
    TMSFNCToolBarButton1.Appearance.NormalFill.Color := 16579577;
    TMSFNCToolBarButton1.Appearance.NormalFill.ColorTo := clWhite;
    TMSFNCToolBarButton1.Appearance.NormalFill.ColorMirror := 15523036;
    TMSFNCToolBarButton1.Appearance.NormalFill.ColorMirrorTo := 15524316;
    TMSFNCToolBarButton1.Appearance.NormalStroke.Color := clGray;
    TMSFNCToolBarButton1.Appearance.HoverFill.Kind := gfkGradient;
    TMSFNCToolBarButton1.Appearance.HoverFill.Color := 15263717;
    TMSFNCToolBarButton1.Appearance.HoverFill.ColorTo := 14145490;
    TMSFNCToolBarButton1.Appearance.HoverFill.ColorMirror := 14207176;
    TMSFNCToolBarButton1.Appearance.HoverFill.ColorMirrorTo := 14208456;
    TMSFNCToolBarButton1.Appearance.HoverStroke.Color := clGray;
    TMSFNCToolBarButton1.Appearance.DownFill.Kind := gfkGradient;
    TMSFNCToolBarButton1.Appearance.DownFill.Color := 14605787;
    TMSFNCToolBarButton1.Appearance.DownFill.ColorTo := 13487560;
    TMSFNCToolBarButton1.Appearance.DownFill.ColorMirror := 13549246;
    TMSFNCToolBarButton1.Appearance.DownFill.ColorMirrorTo := 13550526;
    TMSFNCToolBarButton1.Appearance.DownStroke.Color := clGray;
    TMSFNCToolBarButton1.Appearance.DisabledFill.Color := 13882323;
    TMSFNCToolBarButton1.Appearance.DisabledStroke.Color := clGray;
    TMSFNCToolBarButton1.ControlIndex := 0;
    TMSFNCToolBarButton2.SetParentComponent(TMSFNCPanel1);
    TMSFNCToolBarButton2.Name := 'TMSFNCToolBarButton2';
    TMSFNCToolBarButton2.Left := 190;
    TMSFNCToolBarButton2.Top := 3;
    TMSFNCToolBarButton2.Width := 158;
    TMSFNCToolBarButton2.Height := 40;
    TMSFNCToolBarButton2.ParentDoubleBuffered := False;
    TMSFNCToolBarButton2.DoubleBuffered := True;
    TMSFNCToolBarButton2.Font.Charset := DEFAULT_CHARSET;
    TMSFNCToolBarButton2.Font.Color := clWindowText;
    TMSFNCToolBarButton2.Font.Height := -19;
    TMSFNCToolBarButton2.Font.Name := 'Tahoma';
    TMSFNCToolBarButton2.Font.Style := [];
    TMSFNCToolBarButton2.ParentColor := True;
    TMSFNCToolBarButton2.TabOrder := 1;
    SetEvent(TMSFNCToolBarButton2, Self, 'OnClick', 'VIDEO_CONNECT');
    TMSFNCToolBarButton2.Text := 'Demo Video';
    TMSFNCToolBarButton2.Appearance.InnerStroke.Color := clWhite;
    TMSFNCToolBarButton2.Appearance.NormalFill.Kind := gfkGradient;
    TMSFNCToolBarButton2.Appearance.NormalFill.Color := 16579577;
    TMSFNCToolBarButton2.Appearance.NormalFill.ColorTo := clWhite;
    TMSFNCToolBarButton2.Appearance.NormalFill.ColorMirror := 15523036;
    TMSFNCToolBarButton2.Appearance.NormalFill.ColorMirrorTo := 15524316;
    TMSFNCToolBarButton2.Appearance.NormalStroke.Color := clGray;
    TMSFNCToolBarButton2.Appearance.HoverFill.Kind := gfkGradient;
    TMSFNCToolBarButton2.Appearance.HoverFill.Color := 15263717;
    TMSFNCToolBarButton2.Appearance.HoverFill.ColorTo := 14145490;
    TMSFNCToolBarButton2.Appearance.HoverFill.ColorMirror := 14207176;
    TMSFNCToolBarButton2.Appearance.HoverFill.ColorMirrorTo := 14208456;
    TMSFNCToolBarButton2.Appearance.HoverStroke.Color := clGray;
    TMSFNCToolBarButton2.Appearance.DownFill.Kind := gfkGradient;
    TMSFNCToolBarButton2.Appearance.DownFill.Color := 14605787;
    TMSFNCToolBarButton2.Appearance.DownFill.ColorTo := 13487560;
    TMSFNCToolBarButton2.Appearance.DownFill.ColorMirror := 13549246;
    TMSFNCToolBarButton2.Appearance.DownFill.ColorMirrorTo := 13550526;
    TMSFNCToolBarButton2.Appearance.DownStroke.Color := clGray;
    TMSFNCToolBarButton2.Appearance.DisabledFill.Color := 13882323;
    TMSFNCToolBarButton2.Appearance.DisabledStroke.Color := clGray;
    TMSFNCToolBarButton2.ControlIndex := 0;
  finally
    WebImageControl1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebImageControl2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebImageControl4.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebImageControl3.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebImageControl5.AfterLoadDFMValues;
    TMSFNCPanel1.AfterLoadDFMValues;
    TMSFNCToolBarButton1.AfterLoadDFMValues;
    TMSFNCToolBarButton2.AfterLoadDFMValues;
  end;
end;

end.
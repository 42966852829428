unit MEDDELANDE;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, WEBLib.ExtCtrls, Vcl.Controls,
  VCL.TMSFNCCustomControl, VCL.TMSFNCToolBar;

type
  TForm3 = class(TForm)
    OkBarButton1: TTMSFNCToolBarButton;
    WebPanel1: TPanel;
    procedure WebFormCreate(Sender: TObject);
    procedure AVSLUTA(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form3: TForm3;

implementation

Uses FRONT;

{$R *.dfm}

procedure TForm3.WebFormCreate(Sender: TObject);
  Begin
    //
  End;

procedure TForm3.AVSLUTA(Sender: TObject);
  Begin
    Close;
  End;

procedure TForm3.LoadDFMValues;
begin
  inherited LoadDFMValues;

  OkBarButton1 := TTMSFNCToolBarButton.Create(Self);
  WebPanel1 := TPanel.Create(Self);

  OkBarButton1.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  try
    Name := 'Form3';
    Width := 282;
    Height := 103;
    OkBarButton1.SetParentComponent(Self);
    OkBarButton1.Name := 'OkBarButton1';
    OkBarButton1.Left := 100;
    OkBarButton1.Top := 64;
    OkBarButton1.Width := 80;
    OkBarButton1.Height := 22;
    OkBarButton1.ParentDoubleBuffered := False;
    OkBarButton1.DoubleBuffered := True;
    OkBarButton1.Font.Charset := DEFAULT_CHARSET;
    OkBarButton1.Font.Color := clBlack;
    OkBarButton1.Font.Height := -13;
    OkBarButton1.Font.Name := 'Tahoma';
    OkBarButton1.Font.Style := [];
    OkBarButton1.ParentColor := True;
    OkBarButton1.TabOrder := 0;
    SetEvent(OkBarButton1, Self, 'OnClick', 'AVSLUTA');
    OkBarButton1.Text := 'OK';
    OkBarButton1.Appearance.InnerStroke.Color := clWhite;
    OkBarButton1.Appearance.NormalFill.Kind := gfkGradient;
    OkBarButton1.Appearance.NormalFill.Color := 16579577;
    OkBarButton1.Appearance.NormalFill.ColorTo := 15461350;
    OkBarButton1.Appearance.NormalFill.ColorMirror := 15523036;
    OkBarButton1.Appearance.NormalFill.ColorMirrorTo := 15524316;
    OkBarButton1.Appearance.NormalStroke.Color := clGray;
    OkBarButton1.Appearance.HoverFill.Kind := gfkGradient;
    OkBarButton1.Appearance.HoverFill.Color := 15263717;
    OkBarButton1.Appearance.HoverFill.ColorTo := 14145490;
    OkBarButton1.Appearance.HoverFill.ColorMirror := 14207176;
    OkBarButton1.Appearance.HoverFill.ColorMirrorTo := 14208456;
    OkBarButton1.Appearance.HoverStroke.Color := clGray;
    OkBarButton1.Appearance.DownFill.Kind := gfkGradient;
    OkBarButton1.Appearance.DownFill.Color := 14605787;
    OkBarButton1.Appearance.DownFill.ColorTo := 13487560;
    OkBarButton1.Appearance.DownFill.ColorMirror := 13549246;
    OkBarButton1.Appearance.DownFill.ColorMirrorTo := 13550526;
    OkBarButton1.Appearance.DownStroke.Color := clGray;
    OkBarButton1.Appearance.DisabledFill.Color := 13882323;
    OkBarButton1.Appearance.DisabledStroke.Color := clGray;
    OkBarButton1.ControlIndex := 0;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 14;
    WebPanel1.Top := 22;
    WebPanel1.Width := 252;
    WebPanel1.Height := 25;
    WebPanel1.HeightPercent := 100.000000000000000000;
    WebPanel1.WidthPercent := 100.000000000000000000;
    WebPanel1.Anchors := [akLeft,akTop,akRight];
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsNone;
    WebPanel1.ChildOrder := 3;
    WebPanel1.Color := clWhite;
    WebPanel1.Font.Charset := DEFAULT_CHARSET;
    WebPanel1.Font.Color := clWindowText;
    WebPanel1.Font.Height := -16;
    WebPanel1.Font.Name := 'Arial';
    WebPanel1.Font.Style := [];
    WebPanel1.ParentFont := False;
  finally
    OkBarButton1.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
  end;
end;

end.
unit FRAMSIDAN;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes,
  VCL.TMSFNCGridCell, VCL.TMSFNCGridOptions, VCL.TMSFNCCustomControl,
  VCL.TMSFNCCustomScrollControl, VCL.TMSFNCGridData, VCL.TMSFNCCustomGrid,
  VCL.TMSFNCGrid, WEBLib.ExtCtrls, Vcl.Imaging.jpeg, Vcl.Imaging.pngimage,
  VCL.TMSFNCToolBar, WEBLib.REST, StrUtils, VCL.TMSFNCPanel, VCL.TMSFNCPassLock,
  DateUtils, WEBLib.DBCtrls, WEBLib.ComCtrls;

type
  TForm2 = class(TForm)
    TMSFNCGrid1: TTMSFNCGrid;
    TMSFNCGrid2: TTMSFNCGrid;
    TMSFNCGrid3: TTMSFNCGrid;
    WebPanel1: TPanel;
    WebPanel2: TPanel;
    WebPanel3: TPanel;
    WebPanel4: TPanel;
    WebPanel5: TPanel;
    WebPanel6: TPanel;
    WebPanel7: TPanel;
    WebPanel8: TPanel;
    WebPanelSida1: TPanel;
    WebPanelSida2: TPanel;
    WebPanelSida3: TPanel;
    WebPanelSida4: TPanel;
    WebPanelSida5: TPanel;
    WebImageControl1: TImageControl;
    WebImageControl2: TImageControl;
    WebImageControl3: TImageControl;
    WebImageControl4: TImageControl;
    WebImageControl5: TImageControl;
    WebImageControl6: TImageControl;
    WebImageControl7: TImageControl;
    WebImageControl11: TImageControl;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebLabel7: TLabel;
    WebLabel9: TLabel;
    WebLabel10: TLabel;
    WebLabel13: TLabel;
    WebLabel14: TLabel;
    WebLabel15: TLabel;
    WebLabel16: TLabel;
    WebLabel17: TLabel;
    WebLabel18: TLabel;
    WebLabel19: TLabel;
    WebLabel22: TLabel;
    WebLabel23: TLabel;
    WebLabel24: TLabel;
    WebLabel25: TLabel;
    WebLabel26: TLabel;
    WebLabel27: TLabel;
    WebLabel29: TLabel;
    WebLabel30: TLabel;
    WebLabel31: TLabel;
    WebLabel32: TLabel;
    WebLabel33: TLabel;
    WebLabel34: TLabel;
    WebLabel35: TLabel;
    WebLabel36: TLabel;
    WebLabel37: TLabel;
    WebLabel41: TLabel;
    WebLabel42: TLabel;
    WebLabel43: TLabel;
    WebLabel44: TLabel;
    WebLabel45: TLabel;
    WebLabel46: TLabel;
    WebLabel47: TLabel;
    WebHttpRequest1: THttpRequest;
    WebHttpRequest2: THttpRequest;
    WebHttpRequest3: THttpRequest;
    WebHttpRequest4: THttpRequest;
    WebHttpRequest5: THttpRequest;
    ReqKontt1: THttpRequest;
    ReqKontt2: THttpRequest;
    ReqKontt3: THttpRequest;
    ReqKontt4: THttpRequest;
    WebEdit1: TEdit;
    WebEdit2: TEdit;
    WebEdit3: TEdit;
    WebEdit5: TEdit;
    WebEdit6: TEdit;
    WebMemo1: TMemo;
    WebMemo2: TMemo;
    TMSFNCToolBarButton1: TTMSFNCToolBarButton;
    TMSFNCToolBarButton2: TTMSFNCToolBarButton;
    TMSFNCToolBarButton3: TTMSFNCToolBarButton;
    WebButton1: TButton;
    WebTimer1: TTimer;
    WebTimer2: TTimer;
    WebTimer3: TTimer;
    WebTimer4: TTimer;
    WebImageControl8: TImageControl;
    TMSFNCToolBarButton5: TTMSFNCToolBarButton;
    TMSFNCToolBarButton6: TTMSFNCToolBarButton;
    procedure VISA_MEDDELANDE(info: String);
    Procedure PROJECTLISTA;
    procedure DEMO_CONNECT(sprakval: Integer);
    procedure KORRIGERA;
    procedure KORR_REFLISTA;
    procedure KNAPPSTATUS_KONTAKT;
    procedure RENSA_KONTAKT;
    Procedure KUNDLISTA;
    Procedure PROJLISTA;
    procedure NYTT_PROJECT;
    procedure FIL_NUMMER_1;
    procedure FIL_NUMMER_2;
    procedure MAIL_WEB_PHP;
    procedure VISAR_LOGGIN;
    procedure STANG_LOGGIN;
    procedure WebFormCreate(Sender: TObject);
    procedure KOMMANDO1(Sender: TObject);
    procedure KOMMANDO2(Sender: TObject);
    procedure KOMMANDO3(Sender: TObject);
    procedure DEMOCONNECT(Sender: TObject);
    procedure WebEdit1Change(Sender: TObject);
    procedure WebEdit2Change(Sender: TObject);
    procedure WebEdit3Change(Sender: TObject);
    procedure WebMemo1Change(Sender: TObject);
    procedure EMAIL_SAND_KONTAKT(Sender: TObject);
    procedure LOSENORD(Sender: TObject);
    procedure WebEdit6Change(Sender: TObject);
    procedure OPPNA_LOSENORD(Sender: TObject);
    procedure STANG_LOSENORD(Sender: TObject);
    procedure VISA_CONNECT(Sender: TObject);
    procedure TMSFNCGrid1GetCellLayout(Sender: TObject; ACol, ARow: Integer; ALayout: TTMSFNCGridCellLayout; ACellState: TTMSFNCGridCellState);
    procedure TMSFNCGrid1GetCellProperties(Sender: TObject; ACol, ARow: Integer; Cell: TTMSFNCGridCell);
    procedure TMSFNCGrid2GetCellLayout(Sender: TObject; ACol, ARow: Integer; ALayout: TTMSFNCGridCellLayout; ACellState: TTMSFNCGridCellState);
    procedure TMSFNCGrid3GetCellLayout(Sender: TObject; ACol, ARow: Integer; ALayout: TTMSFNCGridCellLayout; ACellState: TTMSFNCGridCellState);
    procedure TMSFNCGrid1SelectCell(Sender: TObject; ACol, ARow: Integer; var Allow: Boolean);
    procedure TMSFNCGrid1MouseDown(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: Integer);
    procedure ReqKontt1RequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
    procedure ReqKontt2RequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
    procedure WebHttpRequest4RequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
    procedure WebHttpRequest5RequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
    procedure ReqKontt4RequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
    procedure KEYTEST(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure KEYTEST2(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure TANGENTER1(Sender: TObject; var Key: Char);
    procedure TANGENTER2(Sender: TObject; var Key: Char);
    procedure TANGENTER3(Sender: TObject; var Key: Char);
    procedure AVBRYTA_KONTAKT(Sender: TObject);
  private
    { Private declarations }
    pwtext,pwd,pro,typ: String;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form2: TForm2;

implementation

Uses FRONT, MEDDELANDE, PROJECT, KORRIGERING, KORRLISTAN, DEMOSIDA;

{$R *.dfm}

Function TOKEN(aString,SepChar: String; TokenNum: Byte): String;
  Var
    retvar: String;
    StrLen,Num,EndofToken: Integer;
  Begin
    StrLen:=Length(aString);
    Num:=1;
    EndofToken:=StrLen;
    While ((Num<=TokenNum) And (EndofToken<>0)) Do
      Begin
        EndofToken:=Pos(SepChar,aString);
        If EndofToken<>0 Then
          Begin
            retvar:=Copy(aString,1,EndofToken-1);
            Delete(aString,1,EndofToken);
            Inc(Num);
          End
        Else
          retvar:=aString;
      End;
    If Num>=TokenNum Then
      Result:=retvar
    Else
      Result:='';
  End;

Procedure TForm2.PROJECTLISTA;
  Var wp,path: String;
  Begin
    wp:='https://www.connectsystem.se/';
    path:=wp+'dsidinfologg.php';

    WebHttpRequest5.URL:=path;
    WebHttpRequest5.Execute;
  End;

procedure TForm2.DEMO_CONNECT(sprakval: Integer);
  Var newform: TForm;

  procedure AfterShowModal(AValue: TModalResult);
    Begin
      TMSFNCGrid1.SetFocus;
    End;

  procedure AfterCreate(AForm: TObject);
    Begin
   //   (AForm as TForm9).Width:=Form1.WebImageControl1.Width - 70;
   //   (AForm as TForm9).Height:=Form1.WebImageControl1.Height;
   //   (AForm as TForm9).Left:=20;
   //   (AForm as TForm9).Top:=0;
    // (AForm as TForm9).Top:=Form1.Height Div 2 - 150;
    End;

  Begin
    Form1.PARAM_D:=IntToStr(sprakval);

    newform:= TForm9.CreateNew(@AfterCreate);
    newform.Popup:=True;
    newform.Border:=fbNone;
    newform.ShowClose:=False;
    newform.ShowModal(@AfterShowModal);
  End;

procedure TForm2.KORRIGERA;
  Var newform: TForm;

  procedure AfterShowModal(AValue: TModalResult);
    Begin
      TMSFNCGrid1.SetFocus;
    End;

  procedure AfterCreate(AForm: TObject);
    Begin
     (AForm as TForm6).Left:=Form1.Width Div 2 - 500;
     (AForm as TForm6).Top:=Form1.Height Div 2 - 150;
    End;

  Begin
    newform := TForm6.CreateNew(@AfterCreate);
    newform.Popup := True;
    newform.ShowModal(@AfterShowModal);
  End;

procedure TForm2.KORR_REFLISTA;
  Var newform: TForm;

  procedure AfterShowModal(AValue: TModalResult);
    Begin
      If Form1.PARAM_U='uppdaterat' Then
        Begin
          Form1.PARAM_U:='';

          KUNDLISTA;
        End;

      TMSFNCGrid1.SetFocus;
    End;

  procedure AfterCreate(AForm: TObject);
    Begin
     (AForm as TForm5).Left:=Form1.Width Div 2 + 12;
     (AForm as TForm5).Top:=Form1.Height Div 2 - 150;
    End;

  Begin
    newform := TForm5.CreateNew(@AfterCreate);
    newform.Popup := True;
    newform.ShowModal(@AfterShowModal);
  End;

procedure TForm2.KNAPPSTATUS_KONTAKT;
  Var t1,t2,t3,t4: String;
  Begin
    TMSFNCToolBarButton2.Font.Color:=clRed;
    TMSFNCToolBarButton2.Font.Size:=12;

    WebLabel16.Visible:=True;
    WebLabel17.Visible:=True;
    WebLabel25.Visible:=True;
    WebLabel29.Visible:=True;

    t1:=Trim(WebEdit1.Text);
    t2:=Trim(WebEdit2.Text);
    t3:=Trim(WebEdit3.Text);
    t4:=Trim(WebMemo1.Text);

    If Length(Trim(WebEdit1.Text))<>0 Then
      WebLabel16.Visible:=False;
    If Length(Trim(WebEdit2.Text))<>0 Then
      WebLabel17.Visible:=False;
    If Length(Trim(WebEdit3.Text))<>0 Then
      WebLabel25.Visible:=False;
    If Length(Trim(WebMemo1.Text))<>0 Then
      WebLabel29.Visible:=False;

    If (Length(t1)<>0) And (Length(t2)<>0) And (Length(t3)<>0) And (Length(t4)<>0) Then
      Begin
        TMSFNCToolBarButton2.Font.Color:=clGreen;
        TMSFNCToolBarButton2.Font.Size:=14;
      End;

    WebTimer1.Interval:=60000;
  End;

procedure TForm2.RENSA_KONTAKT;
  Begin
    WebTimer1.Enabled:=False;

    WebEdit1.Text:='';
    WebEdit2.Text:='';
    WebEdit3.Text:='';
    WebMemo1.Lines.Clear;
    WebPanelSida5.Left:= -800;
    WebPanelSida5.Visible:=False;

    TMSFNCToolBarButton1.Visible:=True;

    TMSFNCGrid1.Selection:=MakeCellRange(0,0,0,0);
    TMSFNCGrid1.Resize;
  End;

procedure TForm2.VISA_MEDDELANDE(info: String);
  Var newform: TForm;

  procedure AfterShowModal(AValue: TModalResult);
    Begin
      RENSA_KONTAKT;
    End;

  procedure AfterCreate(AForm: TObject);
    Begin
      (AForm as TForm3).Top:=Form1.Height Div 2 + 200;
      (AForm as TForm3).WebPanel1.Caption:=info;
    End;

  Begin
    newform := TForm3.CreateNew(@AfterCreate);
    newform.Popup := True;
    newform.ShowModal(@AfterShowModal);
  End;

Procedure TForm2.KUNDLISTA;
  Begin
    WebHttpRequest4.URL:='dsidrefkunder.php';
    WebHttpRequest4.Execute;
  End;

Procedure TForm2.PROJLISTA;
  Var wp,path: String;
  Begin
    wp:='https://connectsystem.se/';
    path:=wp+'dprojectlista.php';

    ReqKontt4.URL:=path;
    ReqKontt4.Execute;
  End;

procedure TForm2.NYTT_PROJECT;
  Var newform: TForm;

  procedure AfterShowModal(AValue: TModalResult);
    Begin
      //
    End;

  procedure AfterCreate(AForm: TObject);
    Begin
      //
    End;

  Begin
    newform := TForm4.CreateNew(@AfterCreate);
    newform.Popup := True;
    newform.ShowModal(@AfterShowModal);
  End;

procedure TForm2.FIL_NUMMER_1;
  Var mfran,mfrna,mtila,mtnam,mrubr,bifog,mxt,win,kat,fil,path: String;
  Begin
    mfran:='info@connectsystem.se';          // från adress
    mfrna:=Trim(WebEdit1.Text);              // från namn
    mtila:=Trim('info@connectsystem.se');    // till adress
    mtnam:='Connectsystem';                  // till namn
    mrubr:=Trim(WebEdit3.Text);              // mailrubrik
    bifog:='';

    mxt:=mfran+';';
    mxt:=mxt+mfrna+';';
    mxt:=mxt+mtila+';';
    mxt:=mxt+mtnam+';';
    mxt:=mxt+mrubr+';';
    mxt:=mxt+bifog+';';

    win:='https://connectsystem.se/';
    kat:='C:/inetpub/wwwroot/customer/mail/';
    fil:='fil1.txt';

    path:=win+'dsidinfo.php?kat='+kat+'&fil='+fil+'&inf='+mxt;

    ReqKontt1.URL:=path;
    ReqKontt1.Execute;
  End;

procedure TForm2.FIL_NUMMER_2;
  Var
    i: Integer;
    dn,de,mxt,win,kat,fil,path: String;
  Begin
    dn:=Trim(WebEdit1.Text);         // Ditt namn
    de:=Trim(WebEdit2.Text);         // Din email

    mxt:='';
    mxt:=mxt+'Meddelande från kund'+';'+' '+';|';
    mxt:=mxt+'<br>'+';'+' '+';|';
    mxt:=mxt+'Namn:'+';'+dn+';|';
    mxt:=mxt+'Mail:'+';'+de+';|';
    mxt:=mxt+'<br>'+';'+' '+';|';

    For i:=0 To WebMemo1.Lines.Count-1 Do
      mxt:=mxt+WebMemo1.Lines[i]+';|';

    win:='https://connectsystem.se/';
    kat:='C:/inetpub/wwwroot/customer/mail/';
    fil:='fil2.txt';

    path:=win+'dsidinfo.php?kat='+kat+'&fil='+fil+'&inf='+mxt;

    ReqKontt2.URL:=path;
    ReqKontt2.Execute;
  End;

procedure TForm2.MAIL_WEB_PHP;
  Var path: String;
  Begin
    path:='https://connectsystem.se/customer/mail/skicka.php';

    ReqKontt3.URL:=path;
    ReqKontt3.Execute;
  End;

procedure TForm2.VISAR_LOGGIN;
  Begin
    WebTimer3.Enabled:=True;

    WebLabel19.Visible:=False;
    WebPanel8.Top:=26;
    WebPanel8.Visible:=True;
    WebEdit6.SetFocus;
  End;

procedure TForm2.STANG_LOGGIN;
  Begin
    WebTimer3.Enabled:=False;

    WebEdit5.Text:='';
    WebEdit6.Text:='';
    pwtext:='';
    WebPanel8.Visible:=False;
    WebLabel19.Visible:=False;
  End;

procedure TForm2.WebFormCreate(Sender: TObject);
  Begin
    TMSFNCGrid1.ColumnWidths[0]:=134;
    TMSFNCGrid1.ColumnWidths[1]:=128;
    TMSFNCGrid1.ColumnWidths[2]:=96;
    TMSFNCGrid1.ColumnWidths[3]:=96;
    TMSFNCGrid1.ColumnWidths[4]:=134;

    TMSFNCGrid1.Cells[0,0]:='FINANS EXPORT';
    TMSFNCGrid1.Cells[1,0]:='LAGERSYSTEM';
    TMSFNCGrid1.Cells[2,0]:='DEMO';
    TMSFNCGrid1.Cells[3,0]:='OM OSS';
    TMSFNCGrid1.Cells[4,0]:='KONTAKTA OSS';

    TMSFNCGrid3.ColumnWidths[0]:=90;
    TMSFNCGrid3.ColumnWidths[1]:=340;
    TMSFNCGrid3.ColumnWidths[2]:=130;
    TMSFNCGrid3.ColumnWidths[3]:=138;

    TMSFNCGrid1.cOLOR:=RGB(243,243,243);
    TMSFNCGrid1.Appearance.SelectedLayout.Fill.Color:=RGB(22,161,230);
    TMSFNCGrid1.Appearance.FocusedLayout.Fill.Color:=RGB(22,161,230);

    WebPanel1.Color:=RGB(22,161,230);

    WebPanel3.Color:=RGB(243,243,243);

    WebPanelSida2.Left:= -800;
    WebPanelSida3.Left:= -800;
    WebPanelSida4.Left:= -800;
    WebPanelSida5.Left:= -800;

    WebPanelSida2.Visible:=False;
    WebPanelSida3.Visible:=False;
    WebPanelSida4.Visible:=False;
    WebPanelSida5.Visible:=False;

    WebPanel2.Visible:=False;  // Chr(169)+

    WebLabel7.Caption:='Copyright '+Chr(169)+' '+Copy(DateToStr(Date),1,4)+', Connectsystem';

    WebPanel2.Visible:=True;

    pwtext:='';

    WebTimer1.Enabled:=False;
    WebTimer2.Enabled:=False;
    WebTimer3.Enabled:=False;

    KUNDLISTA;
  End;

procedure TForm2.KOMMANDO1(Sender: TObject);
  Begin
    WebTimer1.Enabled:=False;

    RENSA_KONTAKT;
  End;

procedure TForm2.KOMMANDO2(Sender: TObject);
  Var
    y: Integer;
    remsa,pwd,pro,wpa,nam,typ: String;
  Begin
    WebTimer2.Enabled:=False;

    pwd:=Trim(WebEdit5.Text);

    typ:='';
    For y:=0 To TMSFNCGrid3.RowCount-1 Do
      Begin
        If pwd=Trim(TMSFNCGrid3.Cells[0,y]) Then
          Begin
            typ:='web';
            pro:=Trim(TMSFNCGrid3.Cells[1,y]);
         //   wpa:=Trim(TMSFNCGrid3.Cells[2,y]);
         //   pro:=pro+'/'+wpa;
            nam:=Trim(TMSFNCGrid3.Cells[3,y]);
            Break;
          End;
      End;

    Form1.PARAM_U:='';        // test om uppdaterat


    STANG_LOGGIN;

    If Length(typ)=0 Then
      Begin
        pwd:=TOKEN(remsa,';',1);
        pro:=TOKEN(remsa,';',2);
        typ:=TOKEN(remsa,';',3);
      End;

    If typ='web' Then
      Application.Navigate(pro);

    If pro='korrigeraprojeklista' Then
      KORRIGERA;

    If pro='korrigerarefernslista' Then
      KORR_REFLISTA;

    If pro='visaprojektlista' Then
      NYTT_PROJECT;
  End;

procedure TForm2.KOMMANDO3(Sender: TObject);
  Begin
    WebTimer2.Enabled:=True;
  End;

procedure TForm2.DEMOCONNECT(Sender: TObject);
  Var sprnr: Integer;
  Begin
    sprnr:=TTMSFNCToolBarButton(Sender).Tag;
    DEMO_CONNECT(sprnr);
  End;

procedure TForm2.WebEdit1Change(Sender: TObject);
  Begin
    KNAPPSTATUS_KONTAKT;
  End;

procedure TForm2.WebEdit2Change(Sender: TObject);
  Begin
    KNAPPSTATUS_KONTAKT;
  End;

procedure TForm2.WebEdit3Change(Sender: TObject);
  Begin
    KNAPPSTATUS_KONTAKT;
  End;

procedure TForm2.WebMemo1Change(Sender: TObject);
  Begin
    KNAPPSTATUS_KONTAKT;
  End;

procedure TForm2.EMAIL_SAND_KONTAKT(Sender: TObject);
  Begin
    If TMSFNCToolBarButton2.Font.Size=14 Then
      FIL_NUMMER_1;
  End;

procedure TForm2.LOSENORD(Sender: TObject);
  Begin
    WebTimer2.Enabled:=True;
  End;

procedure TForm2.WebEdit6Change(Sender: TObject);
  Begin
    If WebLabel19.Visible Then
      WebLabel19.Visible:=False;

    pwtext:=pwtext+Copy(WebEdit6.Text,Length(WebEdit6.Text),1);
    pwtext:=Copy(pwtext,1,Length(WebEdit6.Text));

    WebEdit6.Text:=Copy('********************',1,Length(Trim(WebEdit6.Text)));


    WebEdit5.Text:=pwtext;

    WebTimer3.Interval:=30000;
  End;

procedure TForm2.OPPNA_LOSENORD(Sender: TObject);
  Begin
    VISAR_LOGGIN;
  End;

procedure TForm2.STANG_LOSENORD(Sender: TObject);
  Begin
    WebEdit5.Text:='';
    WebEdit6.Text:='';
    WebPanel8.Visible:=False;

    WebTimer3.Enabled:=False;
  End;

procedure TForm2.VISA_CONNECT(Sender: TObject);
  Var path: String;
  Begin
    path:='https://connectsystem.se/customer/connect/';
    Application.Navigate(path);
  End;

procedure TForm2.TMSFNCGrid1GetCellLayout(Sender: TObject; ACol, ARow: Integer; ALayout: TTMSFNCGridCellLayout; ACellState: TTMSFNCGridCellState);
  Begin
    ALayout.Font.Size:=9;
  End;

procedure TForm2.TMSFNCGrid1GetCellProperties(Sender: TObject; ACol, ARow: Integer; Cell: TTMSFNCGridCell);
  Begin
    Cell.Layout.TextAlign:=gtaCenter;
  End;

procedure TForm2.TMSFNCGrid1SelectCell(Sender: TObject; ACol, ARow: Integer; var Allow: Boolean);
  Begin
 //   WebMultimediaPlayer1.Pause;

    WebPanelSida1.Left:= -800;
    WebPanelSida2.Left:= -800;
    WebPanelSida3.Left:= -1000;
    WebPanelSida4.Left:= -800;
    WebPanelSida5.Left:= -800;

    WebPanelSida1.Visible:=False;
    WebPanelSida2.Visible:=False;
    WebPanelSida3.Visible:=False;
    WebPanelSida4.Visible:=False;
    WebPanelSida5.Visible:=False;

    WebTimer1.Enabled:=False;

    Case ACol Of
      0: Begin
           WebPanelSida1.Left:=44;
           WebPanelSida1.Top:=200;
           WebPanelSida1.Visible:=True;
           TMSFNCToolBarButton1.Visible:=True;
         End;
      1: Begin
           WebPanelSida2.Left:=44;
           WebPanelSida2.Top:=200;
           WebPanelSida2.Visible:=True;
           TMSFNCToolBarButton1.Visible:=True;
         End;
      2: Begin
           WebPanelSida3.Left:=44;
           WebPanelSida3.Top:=200;
           WebPanelSida3.Width:=1000;
           WebPanelSida3.Visible:=True;
           TMSFNCToolBarButton1.Visible:=True;

        //   WebMultimediaPlayer1.ReLoad;
        //   WebMultimediaPlayer1.URL:='https://www.connectsystem.se/diverse/orbit.mp4';
        //   WebMultimediaPlayer1.Play;
         End;
      3: Begin
           WebPanelSida4.Left:=44;
           WebPanelSida4.Top:=200;
           WebPanelSida4.Visible:=True;
           TMSFNCToolBarButton1.Visible:=True;
         End;
      4: Begin
           WebPanelSida5.Left:=44;
           WebPanelSida5.Top:=200;
           WebPanelSida5.Visible:=True;
           WebEdit1.SetFocus;

           TMSFNCToolBarButton1.Visible:=False;

           WebTimer1.Enabled:=True;

           KNAPPSTATUS_KONTAKT;
         End; 
    End;
  End;

procedure TForm2.TMSFNCGrid1MouseDown(Sender: TObject; Button: TMouseButton; Shift: TShiftState; X, Y: Integer);
  Begin
    Case X Of
      6..124: TMSFNCGrid1.Selection := MakeCellRange(0,0,0,0);
    142..252: TMSFNCGrid1.Selection := MakeCellRange(1,0,1,0);
    268..352: TMSFNCGrid1.Selection := MakeCellRange(2,0,2,0);
    366..444: TMSFNCGrid1.Selection := MakeCellRange(3,0,3,0);
    460..578: TMSFNCGrid1.Selection := MakeCellRange(4,0,4,0);
    End;
  End;

procedure TForm2.WebHttpRequest4RequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
  Var
    remsa,kund: String;
    y,i: Integer;
  Begin
    remsa:=AResponse;

    TMSFNCGrid2.Clear;
    TMSFNCGrid2.RowCount:=2;

    y:=-1;
    For i:=1 To 50 Do
      Begin
        kund:=TOKEN(remsa,';',i);
        If Length(kund)>0 Then
          Begin
            y:=y+1;
            If y>1 Then
              TMSFNCGrid2.RowCount:=TMSFNCGrid2.RowCount+1;

            TMSFNCGrid2.Cells[0,y]:=' '+Trim(kund);
          End;
      End;

    TMSFNCGrid2.Selection:=MakeCellRange(0,0,0,0);
    TMSFNCGrid2.Resize;

  //  PROJLISTA;
  End;

procedure TForm2.WebHttpRequest5RequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
  Var
    remsa,numm,proj,typv,namn,tele,spra: String;
    y,i: Integer;
  Begin
    WebMemo2.Text:=AResponse;

    Form1.TGrid.Clear;
    Form1.TGrid.RowCount:=1;

    y:=-1;

    Form1.TGrid.BeginUpdate;

    For i:=0 To WebMemo2.Lines.Count-1 Do
      Begin
        remsa:=WebMemo2.Lines[i];

        numm:=TOKEN(remsa,';',1);
        proj:=TOKEN(remsa,';',2);
        typv:=TOKEN(remsa,';',3);
        namn:=TOKEN(remsa,';',4);
        tele:=TOKEN(remsa,';',5);
        spra:=TOKEN(remsa,';',6);

        y:=y+1;
        If y>0 Then
          Form1.TGrid.RowCount:=Form1.TGrid.RowCount+1;

        Form1.TGrid.Cells[0,y]:=numm;
        Form1.TGrid.Cells[1,y]:=proj;
        Form1.TGrid.Cells[2,y]:=typv;
        Form1.TGrid.Cells[3,y]:=namn;
        Form1.TGrid.Cells[4,y]:=tele;
      End;

    Form1.TGrid.EndUpdate;

    WebMemo2.Lines.Clear;
  End;

procedure TForm2.ReqKontt1RequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
  Begin
    FIL_NUMMER_2;
  End;

procedure TForm2.ReqKontt2RequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
  Begin
    MAIL_WEB_PHP;
    VISA_MEDDELANDE('Mail är sänt till Connectsystem');
  End;

procedure TForm2.ReqKontt4RequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
  Var
    remsa,wapa,proj,wpat,namn: String;
    y,i: Integer;
  Begin
    WebMemo2.Text:=AResponse;

    TMSFNCGrid3.clear;
    TMSFNCGrid3.RowCount:=1;
    y:=-1;

    For i:=0 To WebMemo2.Lines.Count-1 Do
      Begin
        remsa:=WebMemo2.Lines[i];

        wapa:=TOKEN(remsa,';',1);
        proj:=TOKEN(remsa,';',2);
        wpat:=TOKEN(remsa,';',3);
        namn:=TOKEN(remsa,';',4);

        y:=y+1;
        If y>0 Then
          TMSFNCGrid3.RowCount:=TMSFNCGrid3.RowCount+1;

        TMSFNCGrid3.Cells[0,y]:=wapa;
        TMSFNCGrid3.Cells[1,y]:=proj;
        TMSFNCGrid3.Cells[2,y]:=wpat;
        TMSFNCGrid3.Cells[3,y]:=namn;
      End;

    WebMemo2.Lines.Clear;

    TMSFNCGrid2.SetFocus;
  End;

procedure TForm2.TMSFNCGrid2GetCellLayout(Sender: TObject; ACol, ARow: Integer; ALayout: TTMSFNCGridCellLayout; ACellState: TTMSFNCGridCellState);
  Begin
    ALayout.Font.Color:=clBlack;
    ALayout.Font.Size:=11;
  End;

procedure TForm2.TMSFNCGrid3GetCellLayout(Sender: TObject; ACol, ARow: Integer; ALayout: TTMSFNCGridCellLayout; ACellState: TTMSFNCGridCellState);
  Begin
    ALayout.Font.Color:=clBlack;
    ALayout.Font.Size:=10;
  End;

procedure TForm2.KEYTEST(Sender: TObject; var Key: Word; Shift: TShiftState);
  Var editnr: Integer;
  Begin
    editnr:=(Sender as TEdit).Tag;

    If Key=40 Then                         // Key down
      Case editnr Of
        1: WebEdit2.SetFocus;
        2: WebEdit3.SetFocus;
      End;

    If Key=38 Then                         // Key up
      Case editnr Of
        2: WebEdit1.SetFocus;
        3: WebEdit2.SetFocus;
        4: WebEdit3.SetFocus;
      End;
  End;

procedure TForm2.KEYTEST2(Sender: TObject; var Key: Word; Shift: TShiftState);
  Begin
    If Key=27 Then
      STANG_LOGGIN;
  End;

procedure TForm2.TANGENTER1(Sender: TObject; var Key: Char);
  Begin
    If Ord(Key)=13 Then
      Begin
        Key:=#0;

        Case (Sender as TEdit).Tag Of
          1: WebEdit2.SetFocus;
          2: WebEdit3.SetFocus;
          3: WebMemo1.SetFocus;
        end;
      End;
  End;

procedure TForm2.TANGENTER2(Sender: TObject; var Key: Char);
  Begin
    If Ord(Key)=13 Then
      Begin
        Key:=#0;

        VISAR_LOGGIN;
      End;
  End;

procedure TForm2.TANGENTER3(Sender: TObject; var Key: Char);
  Begin
    If Ord(Key)=13 Then
      Begin
        Key:=#0;

        WebTimer2.Enabled:=True;
      End;
  End;

procedure TForm2.AVBRYTA_KONTAKT(Sender: TObject);
  Begin
    RENSA_KONTAKT;
  End;

procedure TForm2.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebImageControl5 := TImageControl.Create(Self);
  TMSFNCGrid1 := TTMSFNCGrid.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebPanelSida1 := TPanel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebImageControl1 := TImageControl.Create(Self);
  WebImageControl2 := TImageControl.Create(Self);
  WebLabel22 := TLabel.Create(Self);
  WebLabel23 := TLabel.Create(Self);
  WebLabel45 := TLabel.Create(Self);
  WebLabel46 := TLabel.Create(Self);
  WebLabel47 := TLabel.Create(Self);
  TMSFNCGrid3 := TTMSFNCGrid.Create(Self);
  WebPanel3 := TPanel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebPanel4 := TPanel.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  TMSFNCGrid2 := TTMSFNCGrid.Create(Self);
  WebPanel6 := TPanel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebPanel5 := TPanel.Create(Self);
  TMSFNCToolBarButton1 := TTMSFNCToolBarButton.Create(Self);
  WebPanel8 := TPanel.Create(Self);
  WebLabel18 := TLabel.Create(Self);
  WebImageControl11 := TImageControl.Create(Self);
  WebLabel19 := TLabel.Create(Self);
  WebButton1 := TButton.Create('100');
  WebEdit6 := TEdit.Create(Self);
  WebPanelSida2 := TPanel.Create(Self);
  WebLabel24 := TLabel.Create(Self);
  WebImageControl6 := TImageControl.Create(Self);
  WebLabel30 := TLabel.Create(Self);
  WebLabel31 := TLabel.Create(Self);
  WebLabel32 := TLabel.Create(Self);
  WebLabel33 := TLabel.Create(Self);
  WebLabel36 := TLabel.Create(Self);
  WebLabel37 := TLabel.Create(Self);
  WebImageControl7 := TImageControl.Create(Self);
  WebPanelSida4 := TPanel.Create(Self);
  WebLabel27 := TLabel.Create(Self);
  WebImageControl4 := TImageControl.Create(Self);
  WebLabel34 := TLabel.Create(Self);
  WebLabel35 := TLabel.Create(Self);
  WebLabel41 := TLabel.Create(Self);
  WebLabel42 := TLabel.Create(Self);
  WebLabel43 := TLabel.Create(Self);
  WebLabel44 := TLabel.Create(Self);
  WebPanelSida5 := TPanel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebPanel7 := TPanel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel13 := TLabel.Create(Self);
  WebLabel14 := TLabel.Create(Self);
  WebLabel15 := TLabel.Create(Self);
  WebLabel16 := TLabel.Create(Self);
  WebLabel17 := TLabel.Create(Self);
  WebLabel25 := TLabel.Create(Self);
  WebLabel29 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebEdit3 := TEdit.Create(Self);
  WebMemo1 := TMemo.Create(Self);
  TMSFNCToolBarButton2 := TTMSFNCToolBarButton.Create(Self);
  TMSFNCToolBarButton3 := TTMSFNCToolBarButton.Create(Self);
  WebMemo2 := TMemo.Create(Self);
  WebEdit5 := TEdit.Create('101');
  WebPanelSida3 := TPanel.Create(Self);
  WebImageControl3 := TImageControl.Create(Self);
  WebLabel26 := TLabel.Create(Self);
  WebImageControl8 := TImageControl.Create(Self);
  TMSFNCToolBarButton5 := TTMSFNCToolBarButton.Create(Self);
  TMSFNCToolBarButton6 := TTMSFNCToolBarButton.Create(Self);
  WebHttpRequest1 := THttpRequest.Create(Self);
  WebHttpRequest2 := THttpRequest.Create(Self);
  WebHttpRequest3 := THttpRequest.Create(Self);
  WebHttpRequest4 := THttpRequest.Create(Self);
  ReqKontt1 := THttpRequest.Create(Self);
  ReqKontt2 := THttpRequest.Create(Self);
  ReqKontt3 := THttpRequest.Create(Self);
  WebTimer1 := TTimer.Create(Self);
  WebHttpRequest5 := THttpRequest.Create(Self);
  WebTimer2 := TTimer.Create(Self);
  WebTimer3 := TTimer.Create(Self);
  WebTimer4 := TTimer.Create(Self);
  ReqKontt4 := THttpRequest.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebImageControl5.BeforeLoadDFMValues;
  TMSFNCGrid1.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebPanelSida1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebImageControl1.BeforeLoadDFMValues;
  WebImageControl2.BeforeLoadDFMValues;
  WebLabel22.BeforeLoadDFMValues;
  WebLabel23.BeforeLoadDFMValues;
  WebLabel45.BeforeLoadDFMValues;
  WebLabel46.BeforeLoadDFMValues;
  WebLabel47.BeforeLoadDFMValues;
  TMSFNCGrid3.BeforeLoadDFMValues;
  WebPanel3.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebPanel4.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  TMSFNCGrid2.BeforeLoadDFMValues;
  WebPanel6.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebPanel5.BeforeLoadDFMValues;
  TMSFNCToolBarButton1.BeforeLoadDFMValues;
  WebPanel8.BeforeLoadDFMValues;
  WebLabel18.BeforeLoadDFMValues;
  WebImageControl11.BeforeLoadDFMValues;
  WebLabel19.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebEdit6.BeforeLoadDFMValues;
  WebPanelSida2.BeforeLoadDFMValues;
  WebLabel24.BeforeLoadDFMValues;
  WebImageControl6.BeforeLoadDFMValues;
  WebLabel30.BeforeLoadDFMValues;
  WebLabel31.BeforeLoadDFMValues;
  WebLabel32.BeforeLoadDFMValues;
  WebLabel33.BeforeLoadDFMValues;
  WebLabel36.BeforeLoadDFMValues;
  WebLabel37.BeforeLoadDFMValues;
  WebImageControl7.BeforeLoadDFMValues;
  WebPanelSida4.BeforeLoadDFMValues;
  WebLabel27.BeforeLoadDFMValues;
  WebImageControl4.BeforeLoadDFMValues;
  WebLabel34.BeforeLoadDFMValues;
  WebLabel35.BeforeLoadDFMValues;
  WebLabel41.BeforeLoadDFMValues;
  WebLabel42.BeforeLoadDFMValues;
  WebLabel43.BeforeLoadDFMValues;
  WebLabel44.BeforeLoadDFMValues;
  WebPanelSida5.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebPanel7.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel13.BeforeLoadDFMValues;
  WebLabel14.BeforeLoadDFMValues;
  WebLabel15.BeforeLoadDFMValues;
  WebLabel16.BeforeLoadDFMValues;
  WebLabel17.BeforeLoadDFMValues;
  WebLabel25.BeforeLoadDFMValues;
  WebLabel29.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebEdit3.BeforeLoadDFMValues;
  WebMemo1.BeforeLoadDFMValues;
  TMSFNCToolBarButton2.BeforeLoadDFMValues;
  TMSFNCToolBarButton3.BeforeLoadDFMValues;
  WebMemo2.BeforeLoadDFMValues;
  WebEdit5.BeforeLoadDFMValues;
  WebPanelSida3.BeforeLoadDFMValues;
  WebImageControl3.BeforeLoadDFMValues;
  WebLabel26.BeforeLoadDFMValues;
  WebImageControl8.BeforeLoadDFMValues;
  TMSFNCToolBarButton5.BeforeLoadDFMValues;
  TMSFNCToolBarButton6.BeforeLoadDFMValues;
  WebHttpRequest1.BeforeLoadDFMValues;
  WebHttpRequest2.BeforeLoadDFMValues;
  WebHttpRequest3.BeforeLoadDFMValues;
  WebHttpRequest4.BeforeLoadDFMValues;
  ReqKontt1.BeforeLoadDFMValues;
  ReqKontt2.BeforeLoadDFMValues;
  ReqKontt3.BeforeLoadDFMValues;
  WebTimer1.BeforeLoadDFMValues;
  WebHttpRequest5.BeforeLoadDFMValues;
  WebTimer2.BeforeLoadDFMValues;
  WebTimer3.BeforeLoadDFMValues;
  WebTimer4.BeforeLoadDFMValues;
  ReqKontt4.BeforeLoadDFMValues;
  try
    Name := 'Form2';
    Left := 48;
    Top := 180;
    Width := 1102;
    Height := 1000;
    Cursor := crHandPoint;
    Caption := '103';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 44;
    WebLabel1.Top := 42;
    WebLabel1.Width := 234;
    WebLabel1.Height := 42;
    WebLabel1.Caption := 'Connectsystem';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -35;
    WebLabel1.Font.Name := 'Tahoma';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebImageControl5.SetParentComponent(Self);
    WebImageControl5.Name := 'WebImageControl5';
    WebImageControl5.Left := 598;
    WebImageControl5.Top := 64;
    WebImageControl5.Width := 437;
    WebImageControl5.Height := 51;
    WebImageControl5.HeightPercent := 100.000000000000000000;
    WebImageControl5.WidthPercent := 100.000000000000000000;
    WebImageControl5.ChildOrder := 11;
    WebImageControl5.Picture.LoadFromFile('FRAMSIDAN.WebImageControl5.Picture.jpg');
    TMSFNCGrid1.SetParentComponent(Self);
    TMSFNCGrid1.Name := 'TMSFNCGrid1';
    TMSFNCGrid1.Tag := 1;
    TMSFNCGrid1.Left := 24;
    TMSFNCGrid1.Top := 121;
    TMSFNCGrid1.Width := 1005;
    TMSFNCGrid1.Height := 48;
    TMSFNCGrid1.ParentDoubleBuffered := False;
    TMSFNCGrid1.DoubleBuffered := True;
    TMSFNCGrid1.TabOrder := 0;
    SetEvent(TMSFNCGrid1, Self, 'OnMouseDown', 'TMSFNCGrid1MouseDown');
    TMSFNCGrid1.DefaultRowHeight := 48.000000000000000000;
    TMSFNCGrid1.DefaultColumnWidth := 128.000000000000000000;
    TMSFNCGrid1.FixedColumns := 0;
    TMSFNCGrid1.FixedRows := 0;
    TMSFNCGrid1.RowCount := 1;
    TMSFNCGrid1.Options.Borders.FixedCellBorders := bNone;
    TMSFNCGrid1.Options.Borders.CellBorders := bNone;
    TMSFNCGrid1.Options.Editing.CalcFormat := '%g';
    TMSFNCGrid1.Options.Editing.Enabled := False;
    TMSFNCGrid1.Options.Editing.EditWithTags := False;
    TMSFNCGrid1.Options.Grouping.CalcFormat := '%g';
    TMSFNCGrid1.Options.Grouping.GroupCountFormat := '(%d)';
    TMSFNCGrid1.Options.IO.XMLEncoding := 'ISO-8859-1';
    TMSFNCGrid1.Options.Mouse.ClickMargin := 0;
    TMSFNCGrid1.Options.Mouse.ColumnSizeMargin := 6;
    TMSFNCGrid1.Options.Mouse.RowSizeMargin := 6;
    TMSFNCGrid1.Options.ScrollBar.HorizontalScrollBarVisible := False;
    TMSFNCGrid1.Options.Selection.Mode := smSingleColumn;
    SetEvent(TMSFNCGrid1, Self, 'OnSelectCell', 'TMSFNCGrid1SelectCell');
    TMSFNCGrid1.HorizontalScrollBarVisible := False;
    TMSFNCGrid1.Columns.Clear;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 128.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      HorzAlignment := gtaCenter;
      ID := '';
      Width := 128.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 128.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      HorzAlignment := gtaTrailing;
      ID := '';
      Width := 128.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      HorzAlignment := gtaTrailing;
      ID := '';
      Width := 128.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      HorzAlignment := gtaTrailing;
      ID := '';
      Width := 128.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      HorzAlignment := gtaTrailing;
      ID := '';
      Width := 128.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      HorzAlignment := gtaTrailing;
      ID := '';
      Width := 128.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 128.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 128.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 128.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 128.000000000000000000;
    end;
    with TMSFNCGrid1.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -11;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -11;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 128.000000000000000000;
    end;
    TMSFNCGrid1.DefaultFont.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.DefaultFont.Color := clWindowText;
    TMSFNCGrid1.DefaultFont.Height := -11;
    TMSFNCGrid1.DefaultFont.Name := 'Tahoma';
    TMSFNCGrid1.DefaultFont.Style := [];
    TMSFNCGrid1.TopRow := 0;
    TMSFNCGrid1.Appearance.FixedLayout.Fill.Color := 15132390;
    TMSFNCGrid1.Appearance.FixedLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.FixedLayout.Font.Color := clWindowText;
    TMSFNCGrid1.Appearance.FixedLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.FixedLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.FixedLayout.Font.Style := [fsBold];
    TMSFNCGrid1.Appearance.NormalLayout.Fill.Color := -1;
    TMSFNCGrid1.Appearance.NormalLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.NormalLayout.Font.Color := clWindowText;
    TMSFNCGrid1.Appearance.NormalLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.NormalLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.NormalLayout.Font.Style := [];
    TMSFNCGrid1.Appearance.GroupLayout.Fill.Color := 12817262;
    TMSFNCGrid1.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.GroupLayout.Font.Color := clWhite;
    TMSFNCGrid1.Appearance.GroupLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.GroupLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.GroupLayout.Font.Style := [];
    TMSFNCGrid1.Appearance.SummaryLayout.Fill.Color := 14009785;
    TMSFNCGrid1.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.SummaryLayout.Font.Color := clWindowText;
    TMSFNCGrid1.Appearance.SummaryLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.SummaryLayout.Font.Style := [];
    TMSFNCGrid1.Appearance.SelectedLayout.Fill.Color := 14009785;
    TMSFNCGrid1.Appearance.SelectedLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.SelectedLayout.Font.Color := clWhite;
    TMSFNCGrid1.Appearance.SelectedLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.SelectedLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.SelectedLayout.Font.Style := [];
    TMSFNCGrid1.Appearance.FocusedLayout.Fill.Color := 14599344;
    TMSFNCGrid1.Appearance.FocusedLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.FocusedLayout.Font.Color := clWhite;
    TMSFNCGrid1.Appearance.FocusedLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.FocusedLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.FocusedLayout.Font.Style := [];
    TMSFNCGrid1.Appearance.FixedSelectedLayout.Fill.Color := 14599344;
    TMSFNCGrid1.Appearance.FixedSelectedLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.FixedSelectedLayout.Font.Color := clWindowText;
    TMSFNCGrid1.Appearance.FixedSelectedLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.FixedSelectedLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.FixedSelectedLayout.Font.Style := [];
    TMSFNCGrid1.Appearance.BandLayout.Fill.Color := 15987699;
    TMSFNCGrid1.Appearance.BandLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid1.Appearance.BandLayout.Font.Color := clWindowText;
    TMSFNCGrid1.Appearance.BandLayout.Font.Height := -11;
    TMSFNCGrid1.Appearance.BandLayout.Font.Name := 'Tahoma';
    TMSFNCGrid1.Appearance.BandLayout.Font.Style := [];
    TMSFNCGrid1.Appearance.ProgressLayout.Color := 14009785;
    TMSFNCGrid1.Appearance.ProgressLayout.Format := '%.0f%%';
    TMSFNCGrid1.LeftCol := 0;
    TMSFNCGrid1.ScrollMode := scmItemScrolling;
    TMSFNCGrid1.CustomScrollBars := True;
    SetEvent(TMSFNCGrid1, Self, 'OnGetCellProperties', 'TMSFNCGrid1GetCellProperties');
    SetEvent(TMSFNCGrid1, Self, 'OnGetCellLayout', 'TMSFNCGrid1GetCellLayout');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 24;
    WebPanel1.Top := 168;
    WebPanel1.Width := 1005;
    WebPanel1.Height := 3;
    WebPanel1.HeightPercent := 100.000000000000000000;
    WebPanel1.WidthPercent := 100.000000000000000000;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsNone;
    WebPanel1.ChildOrder := 2;
    WebPanelSida1.SetParentComponent(Self);
    WebPanelSida1.Name := 'WebPanelSida1';
    WebPanelSida1.Left := 44;
    WebPanelSida1.Top := 203;
    WebPanelSida1.Width := 733;
    WebPanelSida1.Height := 721;
    WebPanelSida1.HeightPercent := 100.000000000000000000;
    WebPanelSida1.WidthPercent := 100.000000000000000000;
    WebPanelSida1.Anchors := [akLeft,akTop,akBottom];
    WebPanelSida1.BorderColor := clSilver;
    WebPanelSida1.BorderStyle := bsNone;
    WebPanelSida1.ChildOrder := 3;
    WebPanelSida1.Color := clWhite;
    WebLabel2.SetParentComponent(WebPanelSida1);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 24;
    WebLabel2.Top := 18;
    WebLabel2.Width := 550;
    WebLabel2.Height := 29;
    WebLabel2.Caption := 'Finans Export - Oracle kassa till redovisningssystem';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -24;
    WebLabel2.Font.Name := 'Tahoma';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebImageControl1.SetParentComponent(WebPanelSida1);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 24;
    WebImageControl1.Top := 74;
    WebImageControl1.Width := 601;
    WebImageControl1.Height := 247;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 100.000000000000000000;
    WebImageControl1.ChildOrder := 1;
    WebImageControl1.Picture.LoadFromFile('FRAMSIDAN.WebPanelSida1.WebImageControl1.Picture.jpg');
    WebImageControl2.SetParentComponent(WebPanelSida1);
    WebImageControl2.Name := 'WebImageControl2';
    WebImageControl2.Left := 24;
    WebImageControl2.Top := 520;
    WebImageControl2.Width := 657;
    WebImageControl2.Height := 113;
    WebImageControl2.HeightPercent := 100.000000000000000000;
    WebImageControl2.WidthPercent := 100.000000000000000000;
    WebImageControl2.ChildOrder := 8;
    WebImageControl2.Picture.LoadFromFile('FRAMSIDAN.WebPanelSida1.WebImageControl2.Picture.jpg');
    WebLabel22.SetParentComponent(WebPanelSida1);
    WebLabel22.Name := 'WebLabel22';
    WebLabel22.Left := 24;
    WebLabel22.Top := 349;
    WebLabel22.Width := 308;
    WebLabel22.Height := 23;
    WebLabel22.Cursor := crHandPoint;
    WebLabel22.Caption := 'Skapar verifikationer med automatik ';
    WebLabel22.Font.Charset := DEFAULT_CHARSET;
    WebLabel22.Font.Color := clWindowText;
    WebLabel22.Font.Height := -19;
    WebLabel22.Font.Name := 'Tahoma';
    WebLabel22.Font.Style := [];
    WebLabel22.HeightPercent := 100.000000000000000000;
    WebLabel22.ParentFont := False;
    WebLabel22.WidthPercent := 100.000000000000000000;
    WebLabel23.SetParentComponent(WebPanelSida1);
    WebLabel23.Name := 'WebLabel23';
    WebLabel23.Left := 24;
    WebLabel23.Top := 374;
    WebLabel23.Width := 269;
    WebLabel23.Height := 23;
    WebLabel23.Cursor := crHandPoint;
    WebLabel23.Caption := 'Daglig export fr'#229'n Oracle kassor';
    WebLabel23.Font.Charset := DEFAULT_CHARSET;
    WebLabel23.Font.Color := clWindowText;
    WebLabel23.Font.Height := -19;
    WebLabel23.Font.Name := 'Tahoma';
    WebLabel23.Font.Style := [];
    WebLabel23.HeightPercent := 100.000000000000000000;
    WebLabel23.ParentFont := False;
    WebLabel23.WidthPercent := 100.000000000000000000;
    WebLabel45.SetParentComponent(WebPanelSida1);
    WebLabel45.Name := 'WebLabel45';
    WebLabel45.Left := 24;
    WebLabel45.Top := 399;
    WebLabel45.Width := 292;
    WebLabel45.Height := 23;
    WebLabel45.Cursor := crHandPoint;
    WebLabel45.Caption := 'Import via API eller med SIE4 filer ';
    WebLabel45.Font.Charset := DEFAULT_CHARSET;
    WebLabel45.Font.Color := clWindowText;
    WebLabel45.Font.Height := -19;
    WebLabel45.Font.Name := 'Tahoma';
    WebLabel45.Font.Style := [];
    WebLabel45.HeightPercent := 100.000000000000000000;
    WebLabel45.ParentFont := False;
    WebLabel45.WidthPercent := 100.000000000000000000;
    WebLabel46.SetParentComponent(WebPanelSida1);
    WebLabel46.Name := 'WebLabel46';
    WebLabel46.Left := 24;
    WebLabel46.Top := 424;
    WebLabel46.Width := 332;
    WebLabel46.Height := 23;
    WebLabel46.Cursor := crHandPoint;
    WebLabel46.Caption := 'SIE4 import f'#246'r svenska ekonomisystem';
    WebLabel46.Font.Charset := DEFAULT_CHARSET;
    WebLabel46.Font.Color := clWindowText;
    WebLabel46.Font.Height := -19;
    WebLabel46.Font.Name := 'Tahoma';
    WebLabel46.Font.Style := [];
    WebLabel46.HeightPercent := 100.000000000000000000;
    WebLabel46.ParentFont := False;
    WebLabel46.WidthPercent := 100.000000000000000000;
    WebLabel47.SetParentComponent(WebPanelSida1);
    WebLabel47.Name := 'WebLabel47';
    WebLabel47.Left := 24;
    WebLabel47.Top := 449;
    WebLabel47.Width := 334;
    WebLabel47.Height := 23;
    WebLabel47.Cursor := crHandPoint;
    WebLabel47.Caption := 'API (direkt) import till Fortnox med flera';
    WebLabel47.Font.Charset := DEFAULT_CHARSET;
    WebLabel47.Font.Color := clWindowText;
    WebLabel47.Font.Height := -19;
    WebLabel47.Font.Name := 'Tahoma';
    WebLabel47.Font.Style := [];
    WebLabel47.HeightPercent := 100.000000000000000000;
    WebLabel47.ParentFont := False;
    WebLabel47.WidthPercent := 100.000000000000000000;
    TMSFNCGrid3.SetParentComponent(WebPanelSida1);
    TMSFNCGrid3.Name := 'TMSFNCGrid3';
    TMSFNCGrid3.Left := 24;
    TMSFNCGrid3.Top := 639;
    TMSFNCGrid3.Width := 698;
    TMSFNCGrid3.Height := 79;
    TMSFNCGrid3.ParentDoubleBuffered := False;
    TMSFNCGrid3.DoubleBuffered := True;
    TMSFNCGrid3.TabOrder := 0;
    TMSFNCGrid3.Visible := False;
    TMSFNCGrid3.DefaultColumnWidth := 174.000000000000000000;
    TMSFNCGrid3.FixedColumns := 0;
    TMSFNCGrid3.FixedRows := 0;
    TMSFNCGrid3.ColumnCount := 4;
    TMSFNCGrid3.RowCount := 1;
    TMSFNCGrid3.Options.Editing.CalcFormat := '%g';
    TMSFNCGrid3.Options.Editing.Enabled := False;
    TMSFNCGrid3.Options.Grouping.CalcFormat := '%g';
    TMSFNCGrid3.Options.Grouping.GroupCountFormat := '(%d)';
    TMSFNCGrid3.Options.IO.XMLEncoding := 'ISO-8859-1';
    TMSFNCGrid3.Options.Mouse.ClickMargin := 0;
    TMSFNCGrid3.Options.Mouse.ColumnSizeMargin := 6;
    TMSFNCGrid3.Options.Mouse.RowSizeMargin := 6;
    TMSFNCGrid3.Options.Selection.Mode := smSingleRow;
    TMSFNCGrid3.HorizontalScrollBarVisible := False;
    TMSFNCGrid3.VerticalScrollBarVisible := False;
    TMSFNCGrid3.Columns.Clear;
    with TMSFNCGrid3.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 174.000000000000000000;
    end;
    with TMSFNCGrid3.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 174.000000000000000000;
    end;
    with TMSFNCGrid3.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 174.000000000000000000;
    end;
    with TMSFNCGrid3.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 174.000000000000000000;
    end;
    with TMSFNCGrid3.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 174.000000000000000000;
    end;
    TMSFNCGrid3.DefaultFont.Charset := DEFAULT_CHARSET;
    TMSFNCGrid3.DefaultFont.Color := clWindowText;
    TMSFNCGrid3.DefaultFont.Height := -11;
    TMSFNCGrid3.DefaultFont.Name := 'Tahoma';
    TMSFNCGrid3.DefaultFont.Style := [];
    TMSFNCGrid3.TopRow := 0;
    TMSFNCGrid3.Appearance.FixedLayout.Fill.Color := 15132390;
    TMSFNCGrid3.Appearance.FixedLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid3.Appearance.FixedLayout.Font.Color := clWindowText;
    TMSFNCGrid3.Appearance.FixedLayout.Font.Height := -11;
    TMSFNCGrid3.Appearance.FixedLayout.Font.Name := 'Tahoma';
    TMSFNCGrid3.Appearance.FixedLayout.Font.Style := [fsBold];
    TMSFNCGrid3.Appearance.NormalLayout.Fill.Color := -1;
    TMSFNCGrid3.Appearance.NormalLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid3.Appearance.NormalLayout.Font.Color := clWindowText;
    TMSFNCGrid3.Appearance.NormalLayout.Font.Height := -11;
    TMSFNCGrid3.Appearance.NormalLayout.Font.Name := 'Tahoma';
    TMSFNCGrid3.Appearance.NormalLayout.Font.Style := [];
    TMSFNCGrid3.Appearance.GroupLayout.Fill.Color := 12817262;
    TMSFNCGrid3.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid3.Appearance.GroupLayout.Font.Color := clWhite;
    TMSFNCGrid3.Appearance.GroupLayout.Font.Height := -11;
    TMSFNCGrid3.Appearance.GroupLayout.Font.Name := 'Tahoma';
    TMSFNCGrid3.Appearance.GroupLayout.Font.Style := [];
    TMSFNCGrid3.Appearance.SummaryLayout.Fill.Color := 14009785;
    TMSFNCGrid3.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid3.Appearance.SummaryLayout.Font.Color := clWindowText;
    TMSFNCGrid3.Appearance.SummaryLayout.Font.Height := -11;
    TMSFNCGrid3.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    TMSFNCGrid3.Appearance.SummaryLayout.Font.Style := [];
    TMSFNCGrid3.Appearance.SelectedLayout.Fill.Color := 14599344;
    TMSFNCGrid3.Appearance.SelectedLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid3.Appearance.SelectedLayout.Font.Color := clWhite;
    TMSFNCGrid3.Appearance.SelectedLayout.Font.Height := -11;
    TMSFNCGrid3.Appearance.SelectedLayout.Font.Name := 'Tahoma';
    TMSFNCGrid3.Appearance.SelectedLayout.Font.Style := [];
    TMSFNCGrid3.Appearance.FocusedLayout.Fill.Color := 14599344;
    TMSFNCGrid3.Appearance.FocusedLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid3.Appearance.FocusedLayout.Font.Color := clWhite;
    TMSFNCGrid3.Appearance.FocusedLayout.Font.Height := -11;
    TMSFNCGrid3.Appearance.FocusedLayout.Font.Name := 'Tahoma';
    TMSFNCGrid3.Appearance.FocusedLayout.Font.Style := [];
    TMSFNCGrid3.Appearance.FixedSelectedLayout.Fill.Color := 14599344;
    TMSFNCGrid3.Appearance.FixedSelectedLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid3.Appearance.FixedSelectedLayout.Font.Color := clWindowText;
    TMSFNCGrid3.Appearance.FixedSelectedLayout.Font.Height := -11;
    TMSFNCGrid3.Appearance.FixedSelectedLayout.Font.Name := 'Tahoma';
    TMSFNCGrid3.Appearance.FixedSelectedLayout.Font.Style := [];
    TMSFNCGrid3.Appearance.BandLayout.Fill.Color := 14745599;
    TMSFNCGrid3.Appearance.BandLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid3.Appearance.BandLayout.Font.Color := clWindowText;
    TMSFNCGrid3.Appearance.BandLayout.Font.Height := -11;
    TMSFNCGrid3.Appearance.BandLayout.Font.Name := 'Tahoma';
    TMSFNCGrid3.Appearance.BandLayout.Font.Style := [];
    TMSFNCGrid3.Appearance.ProgressLayout.Format := '%.0f%%';
    TMSFNCGrid3.LeftCol := 0;
    TMSFNCGrid3.ScrollMode := scmItemScrolling;
    SetEvent(TMSFNCGrid3, Self, 'OnGetCellLayout', 'TMSFNCGrid3GetCellLayout');
    WebPanel3.SetParentComponent(Self);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 0;
    WebPanel3.Top := 930;
    WebPanel3.Width := 1102;
    WebPanel3.Height := 65;
    WebPanel3.HeightPercent := 100.000000000000000000;
    WebPanel3.WidthPercent := 100.000000000000000000;
    WebPanel3.Anchors := [akLeft,akRight,akBottom];
    WebPanel3.BorderColor := clSilver;
    WebPanel3.BorderStyle := bsNone;
    WebPanel3.ChildOrder := 4;
    WebLabel9.SetParentComponent(WebPanel3);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 68;
    WebLabel9.Top := 26;
    WebLabel9.Width := 295;
    WebLabel9.Height := 18;
    WebLabel9.Caption := 'Connectsystem AB,  info@connectsystem.se';
    WebLabel9.Font.Charset := DEFAULT_CHARSET;
    WebLabel9.Font.Color := clWindowText;
    WebLabel9.Font.Height := -15;
    WebLabel9.Font.Name := 'Tahoma';
    WebLabel9.Font.Style := [];
    WebLabel9.HeightPercent := 100.000000000000000000;
    WebLabel9.ParentFont := False;
    WebLabel9.WidthPercent := 100.000000000000000000;
    WebLabel7.SetParentComponent(WebPanel3);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 816;
    WebLabel7.Top := 26;
    WebLabel7.Width := 103;
    WebLabel7.Height := 18;
    WebLabel7.Caption := 'Copyright  2022';
    WebLabel7.Font.Charset := DEFAULT_CHARSET;
    WebLabel7.Font.Color := clWindowText;
    WebLabel7.Font.Height := -15;
    WebLabel7.Font.Name := 'Tahoma';
    WebLabel7.Font.Style := [];
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.ParentFont := False;
    WebLabel7.WidthPercent := 100.000000000000000000;
    WebPanel4.SetParentComponent(Self);
    WebPanel4.Name := 'WebPanel4';
    WebPanel4.Left := 772;
    WebPanel4.Top := 203;
    WebPanel4.Width := 277;
    WebPanel4.Height := 721;
    WebPanel4.HeightPercent := 100.000000000000000000;
    WebPanel4.WidthPercent := 100.000000000000000000;
    WebPanel4.BorderColor := clSilver;
    WebPanel4.BorderStyle := bsNone;
    WebPanel4.ChildOrder := 5;
    WebPanel4.Color := clWhite;
    WebPanel2.SetParentComponent(WebPanel4);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 24;
    WebPanel2.Top := 150;
    WebPanel2.Width := 233;
    WebPanel2.Height := 315;
    WebPanel2.HeightPercent := 100.000000000000000000;
    WebPanel2.WidthPercent := 100.000000000000000000;
    WebPanel2.BorderColor := clWhite;
    WebPanel2.BorderStyle := bsNone;
    WebPanel2.ChildOrder := 15;
    WebPanel2.Color := clWhite;
    TMSFNCGrid2.SetParentComponent(WebPanel2);
    TMSFNCGrid2.Name := 'TMSFNCGrid2';
    TMSFNCGrid2.Left := 0;
    TMSFNCGrid2.Top := 36;
    TMSFNCGrid2.Width := 233;
    TMSFNCGrid2.Height := 279;
    TMSFNCGrid2.Align := alClient;
    TMSFNCGrid2.BevelInner := bvNone;
    TMSFNCGrid2.BevelOuter := bvNone;
    TMSFNCGrid2.ParentDoubleBuffered := False;
    TMSFNCGrid2.DoubleBuffered := True;
    TMSFNCGrid2.Enabled := False;
    TMSFNCGrid2.TabOrder := 0;
    SetEvent(TMSFNCGrid2, Self, 'OnKeyPress', 'TANGENTER2');
    TMSFNCGrid2.DefaultRowHeight := 22.000000000000000000;
    TMSFNCGrid2.DefaultColumnWidth := 228.000000000000000000;
    TMSFNCGrid2.FixedColumns := 0;
    TMSFNCGrid2.FixedRows := 0;
    TMSFNCGrid2.ColumnCount := 1;
    TMSFNCGrid2.RowCount := 1;
    TMSFNCGrid2.Options.Borders.FixedCellBorders := bNone;
    TMSFNCGrid2.Options.Borders.CellBorders := bNone;
    TMSFNCGrid2.Options.Editing.CalcFormat := '%g';
    TMSFNCGrid2.Options.Editing.Enabled := False;
    TMSFNCGrid2.Options.Grouping.CalcFormat := '%g';
    TMSFNCGrid2.Options.Grouping.GroupCountFormat := '(%d)';
    TMSFNCGrid2.Options.IO.XMLEncoding := 'ISO-8859-1';
    TMSFNCGrid2.Options.Mouse.ClickMargin := 0;
    TMSFNCGrid2.Options.Mouse.ColumnSizeMargin := 6;
    TMSFNCGrid2.Options.Mouse.RowSizeMargin := 6;
    TMSFNCGrid2.Options.Selection.Mode := smSingleRow;
    TMSFNCGrid2.HorizontalScrollBarVisible := False;
    TMSFNCGrid2.VerticalScrollBarVisible := False;
    TMSFNCGrid2.Columns.Clear;
    with TMSFNCGrid2.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 228.000000000000000000;
    end;
    with TMSFNCGrid2.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 228.000000000000000000;
    end;
    with TMSFNCGrid2.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 228.000000000000000000;
    end;
    with TMSFNCGrid2.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 228.000000000000000000;
    end;
    with TMSFNCGrid2.Columns.Add do
    begin
      BorderWidth := 1;
      FixedFont.Charset := DEFAULT_CHARSET;
      FixedFont.Color := clWindowText;
      FixedFont.Height := -8;
      FixedFont.Name := 'Tahoma';
      FixedFont.Style := [fsBold];
      Font.Charset := DEFAULT_CHARSET;
      Font.Color := clWindowText;
      Font.Height := -8;
      Font.Name := 'Tahoma';
      Font.Style := [];
      ID := '';
      Width := 228.000000000000000000;
    end;
    TMSFNCGrid2.DefaultFont.Charset := DEFAULT_CHARSET;
    TMSFNCGrid2.DefaultFont.Color := clWindowText;
    TMSFNCGrid2.DefaultFont.Height := -11;
    TMSFNCGrid2.DefaultFont.Name := 'Tahoma';
    TMSFNCGrid2.DefaultFont.Style := [];
    TMSFNCGrid2.TopRow := 0;
    TMSFNCGrid2.Appearance.FixedLayout.Fill.Color := -1;
    TMSFNCGrid2.Appearance.FixedLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid2.Appearance.FixedLayout.Font.Color := clWindowText;
    TMSFNCGrid2.Appearance.FixedLayout.Font.Height := -11;
    TMSFNCGrid2.Appearance.FixedLayout.Font.Name := 'Tahoma';
    TMSFNCGrid2.Appearance.FixedLayout.Font.Style := [fsBold];
    TMSFNCGrid2.Appearance.NormalLayout.Fill.Color := -1;
    TMSFNCGrid2.Appearance.NormalLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid2.Appearance.NormalLayout.Font.Color := clWindowText;
    TMSFNCGrid2.Appearance.NormalLayout.Font.Height := -11;
    TMSFNCGrid2.Appearance.NormalLayout.Font.Name := 'Tahoma';
    TMSFNCGrid2.Appearance.NormalLayout.Font.Style := [];
    TMSFNCGrid2.Appearance.GroupLayout.Fill.Color := -1;
    TMSFNCGrid2.Appearance.GroupLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid2.Appearance.GroupLayout.Font.Color := clWhite;
    TMSFNCGrid2.Appearance.GroupLayout.Font.Height := -11;
    TMSFNCGrid2.Appearance.GroupLayout.Font.Name := 'Tahoma';
    TMSFNCGrid2.Appearance.GroupLayout.Font.Style := [];
    TMSFNCGrid2.Appearance.SummaryLayout.Fill.Color := -1;
    TMSFNCGrid2.Appearance.SummaryLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid2.Appearance.SummaryLayout.Font.Color := clWindowText;
    TMSFNCGrid2.Appearance.SummaryLayout.Font.Height := -11;
    TMSFNCGrid2.Appearance.SummaryLayout.Font.Name := 'Tahoma';
    TMSFNCGrid2.Appearance.SummaryLayout.Font.Style := [];
    TMSFNCGrid2.Appearance.SelectedLayout.Fill.Color := -1;
    TMSFNCGrid2.Appearance.SelectedLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid2.Appearance.SelectedLayout.Font.Color := clWhite;
    TMSFNCGrid2.Appearance.SelectedLayout.Font.Height := -11;
    TMSFNCGrid2.Appearance.SelectedLayout.Font.Name := 'Tahoma';
    TMSFNCGrid2.Appearance.SelectedLayout.Font.Style := [];
    TMSFNCGrid2.Appearance.FocusedLayout.Fill.Color := clBtnFace;
    TMSFNCGrid2.Appearance.FocusedLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid2.Appearance.FocusedLayout.Font.Color := clWhite;
    TMSFNCGrid2.Appearance.FocusedLayout.Font.Height := -11;
    TMSFNCGrid2.Appearance.FocusedLayout.Font.Name := 'Tahoma';
    TMSFNCGrid2.Appearance.FocusedLayout.Font.Style := [];
    TMSFNCGrid2.Appearance.FixedSelectedLayout.Fill.Color := -1;
    TMSFNCGrid2.Appearance.FixedSelectedLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid2.Appearance.FixedSelectedLayout.Font.Color := clWindowText;
    TMSFNCGrid2.Appearance.FixedSelectedLayout.Font.Height := -11;
    TMSFNCGrid2.Appearance.FixedSelectedLayout.Font.Name := 'Tahoma';
    TMSFNCGrid2.Appearance.FixedSelectedLayout.Font.Style := [];
    TMSFNCGrid2.Appearance.BandLayout.Fill.Color := 16053492;
    TMSFNCGrid2.Appearance.BandLayout.Font.Charset := DEFAULT_CHARSET;
    TMSFNCGrid2.Appearance.BandLayout.Font.Color := clWindowText;
    TMSFNCGrid2.Appearance.BandLayout.Font.Height := -11;
    TMSFNCGrid2.Appearance.BandLayout.Font.Name := 'Tahoma';
    TMSFNCGrid2.Appearance.BandLayout.Font.Style := [];
    TMSFNCGrid2.Appearance.BandLayout.WordWrapping := True;
    TMSFNCGrid2.Appearance.ProgressLayout.Format := '%.0f%%';
    TMSFNCGrid2.LeftCol := 0;
    TMSFNCGrid2.ScrollMode := scmItemScrolling;
    SetEvent(TMSFNCGrid2, Self, 'OnGetCellLayout', 'TMSFNCGrid2GetCellLayout');
    WebPanel6.SetParentComponent(WebPanel2);
    WebPanel6.Name := 'WebPanel6';
    WebPanel6.Left := 0;
    WebPanel6.Top := 0;
    WebPanel6.Width := 233;
    WebPanel6.Height := 32;
    WebPanel6.HeightPercent := 100.000000000000000000;
    WebPanel6.WidthPercent := 100.000000000000000000;
    WebPanel6.Align := alTop;
    WebPanel6.Alignment := taLeftJustify;
    WebPanel6.BorderColor := clSilver;
    WebPanel6.BorderStyle := bsNone;
    WebPanel6.ChildOrder := 4;
    WebPanel6.Font.Charset := DEFAULT_CHARSET;
    WebPanel6.Font.Color := clWindowText;
    WebPanel6.Font.Height := -13;
    WebPanel6.Font.Name := 'Tahoma';
    WebPanel6.Font.Style := [];
    WebPanel6.ParentFont := False;
    WebLabel10.SetParentComponent(WebPanel6);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Left := 12;
    WebLabel10.Top := 8;
    WebLabel10.Width := 63;
    WebLabel10.Height := 16;
    WebLabel10.Caption := 'Referenser';
    WebLabel10.HeightPercent := 100.000000000000000000;
    WebLabel10.WidthPercent := 100.000000000000000000;
    WebPanel5.SetParentComponent(WebPanel2);
    WebPanel5.Name := 'WebPanel5';
    WebPanel5.Left := 0;
    WebPanel5.Top := 32;
    WebPanel5.Width := 233;
    WebPanel5.Height := 4;
    WebPanel5.HeightPercent := 100.000000000000000000;
    WebPanel5.WidthPercent := 100.000000000000000000;
    WebPanel5.Align := alTop;
    WebPanel5.BorderColor := clSilver;
    WebPanel5.BorderStyle := bsNone;
    WebPanel5.ChildOrder := 2;
    WebPanel5.Color := clWhite;
    TMSFNCToolBarButton1.SetParentComponent(WebPanel4);
    TMSFNCToolBarButton1.Name := 'TMSFNCToolBarButton1';
    TMSFNCToolBarButton1.Left := 120;
    TMSFNCToolBarButton1.Top := 27;
    TMSFNCToolBarButton1.Width := 116;
    TMSFNCToolBarButton1.Height := 24;
    TMSFNCToolBarButton1.ParentDoubleBuffered := False;
    TMSFNCToolBarButton1.DoubleBuffered := True;
    TMSFNCToolBarButton1.Font.Charset := DEFAULT_CHARSET;
    TMSFNCToolBarButton1.Font.Color := clWindowText;
    TMSFNCToolBarButton1.Font.Height := -13;
    TMSFNCToolBarButton1.Font.Name := 'Tahoma';
    TMSFNCToolBarButton1.Font.Style := [];
    TMSFNCToolBarButton1.ParentColor := True;
    TMSFNCToolBarButton1.TabOrder := 1;
    SetEvent(TMSFNCToolBarButton1, Self, 'OnClick', 'OPPNA_LOSENORD');
    TMSFNCToolBarButton1.Text := 'Customer login';
    TMSFNCToolBarButton1.Appearance.InnerStroke.Color := clWhite;
    TMSFNCToolBarButton1.Appearance.NormalFill.Kind := gfkGradient;
    TMSFNCToolBarButton1.Appearance.NormalFill.Color := 16579577;
    TMSFNCToolBarButton1.Appearance.NormalFill.ColorTo := 15461350;
    TMSFNCToolBarButton1.Appearance.NormalFill.ColorMirror := 15523036;
    TMSFNCToolBarButton1.Appearance.NormalFill.ColorMirrorTo := 15524316;
    TMSFNCToolBarButton1.Appearance.NormalStroke.Color := clGray;
    TMSFNCToolBarButton1.Appearance.HoverFill.Kind := gfkGradient;
    TMSFNCToolBarButton1.Appearance.HoverFill.Color := 15263717;
    TMSFNCToolBarButton1.Appearance.HoverFill.ColorTo := 14145490;
    TMSFNCToolBarButton1.Appearance.HoverFill.ColorMirror := 14207176;
    TMSFNCToolBarButton1.Appearance.HoverFill.ColorMirrorTo := 14208456;
    TMSFNCToolBarButton1.Appearance.HoverStroke.Color := clGray;
    TMSFNCToolBarButton1.Appearance.DownFill.Kind := gfkGradient;
    TMSFNCToolBarButton1.Appearance.DownFill.Color := 14605787;
    TMSFNCToolBarButton1.Appearance.DownFill.ColorTo := 13487560;
    TMSFNCToolBarButton1.Appearance.DownFill.ColorMirror := 13549246;
    TMSFNCToolBarButton1.Appearance.DownFill.ColorMirrorTo := 13550526;
    TMSFNCToolBarButton1.Appearance.DownStroke.Color := clGray;
    TMSFNCToolBarButton1.Appearance.DisabledFill.Color := 13882323;
    TMSFNCToolBarButton1.Appearance.DisabledStroke.Color := clGray;
    TMSFNCToolBarButton1.ControlIndex := 0;
    WebPanel8.SetParentComponent(WebPanel4);
    WebPanel8.Name := 'WebPanel8';
    WebPanel8.Left := 24;
    WebPanel8.Top := 57;
    WebPanel8.Width := 250;
    WebPanel8.Height := 87;
    WebPanel8.HeightPercent := 100.000000000000000000;
    WebPanel8.WidthPercent := 100.000000000000000000;
    WebPanel8.BorderColor := clSilver;
    WebPanel8.BorderStyle := bsSingle;
    WebPanel8.ChildOrder := 2;
    WebPanel8.Visible := False;
    SetEvent(WebPanel8, Self, 'OnKeyDown', 'KEYTEST2');
    WebLabel18.SetParentComponent(WebPanel8);
    WebLabel18.Name := 'WebLabel18';
    WebLabel18.Left := 3;
    WebLabel18.Top := 10;
    WebLabel18.Width := 55;
    WebLabel18.Height := 16;
    WebLabel18.Caption := 'Password';
    WebLabel18.Font.Charset := DEFAULT_CHARSET;
    WebLabel18.Font.Color := clWindowText;
    WebLabel18.Font.Height := -13;
    WebLabel18.Font.Name := 'Tahoma';
    WebLabel18.Font.Style := [];
    WebLabel18.HeightPercent := 100.000000000000000000;
    WebLabel18.ParentFont := False;
    WebLabel18.WidthPercent := 100.000000000000000000;
    WebImageControl11.SetParentComponent(WebPanel8);
    WebImageControl11.Name := 'WebImageControl11';
    WebImageControl11.Left := 225;
    WebImageControl11.Top := 3;
    WebImageControl11.Width := 22;
    WebImageControl11.Height := 22;
    WebImageControl11.HeightPercent := 100.000000000000000000;
    WebImageControl11.WidthPercent := 100.000000000000000000;
    WebImageControl11.ChildOrder := 3;
    SetEvent(WebImageControl11, Self, 'OnClick', 'STANG_LOSENORD');
    WebImageControl11.Picture.LoadFromFile('FRAMSIDAN.WebPanel8.WebImageControl11.Picture.jpg');
    WebLabel19.SetParentComponent(WebPanel8);
    WebLabel19.Name := 'WebLabel19';
    WebLabel19.Left := 82;
    WebLabel19.Top := 10;
    WebLabel19.Width := 97;
    WebLabel19.Height := 16;
    WebLabel19.Caption := 'Wrong password';
    WebLabel19.Font.Charset := DEFAULT_CHARSET;
    WebLabel19.Font.Color := clRed;
    WebLabel19.Font.Height := -13;
    WebLabel19.Font.Name := 'Tahoma';
    WebLabel19.Font.Style := [];
    WebLabel19.HeightPercent := 100.000000000000000000;
    WebLabel19.ParentFont := False;
    WebLabel19.Visible := False;
    WebLabel19.WidthPercent := 100.000000000000000000;
    WebButton1.SetParentComponent(WebPanel8);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 140;
    WebButton1.Top := 60;
    WebButton1.Width := 80;
    WebButton1.Height := 20;
    WebButton1.Caption := 'Loggin';
    WebButton1.ChildOrder := 4;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'LOSENORD');
    WebEdit6.SetParentComponent(WebPanel8);
    WebEdit6.Name := 'WebEdit6';
    WebEdit6.Left := 3;
    WebEdit6.Top := 32;
    WebEdit6.Width := 244;
    WebEdit6.Height := 22;
    WebEdit6.ChildOrder := 13;
    WebEdit6.Font.Charset := DEFAULT_CHARSET;
    WebEdit6.Font.Color := clWindowText;
    WebEdit6.Font.Height := -13;
    WebEdit6.Font.Name := 'Tahoma';
    WebEdit6.Font.Style := [];
    WebEdit6.HeightPercent := 101.000000000000000000;
    WebEdit6.ParentFont := False;
    WebEdit6.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit6, Self, 'OnChange', 'WebEdit6Change');
    SetEvent(WebEdit6, Self, 'OnKeyDown', 'KEYTEST2');
    SetEvent(WebEdit6, Self, 'OnKeyPress', 'TANGENTER3');
    WebPanelSida2.SetParentComponent(Self);
    WebPanelSida2.Name := 'WebPanelSida2';
    WebPanelSida2.Left := -800;
    WebPanelSida2.Top := 8;
    WebPanelSida2.Width := 722;
    WebPanelSida2.Height := 721;
    WebPanelSida2.HeightPercent := 100.000000000000000000;
    WebPanelSida2.WidthPercent := 100.000000000000000000;
    WebPanelSida2.Anchors := [akLeft,akTop,akBottom];
    WebPanelSida2.BorderColor := clSilver;
    WebPanelSida2.BorderStyle := bsNone;
    WebPanelSida2.ChildOrder := 3;
    WebPanelSida2.Color := clWhite;
    WebLabel24.SetParentComponent(WebPanelSida2);
    WebLabel24.Name := 'WebLabel24';
    WebLabel24.Left := 24;
    WebLabel24.Top := 18;
    WebLabel24.Width := 149;
    WebLabel24.Height := 33;
    WebLabel24.Caption := 'Lagersystem';
    WebLabel24.Font.Charset := DEFAULT_CHARSET;
    WebLabel24.Font.Color := clWindowText;
    WebLabel24.Font.Height := -27;
    WebLabel24.Font.Name := 'Tahoma';
    WebLabel24.Font.Style := [];
    WebLabel24.HeightPercent := 100.000000000000000000;
    WebLabel24.ParentFont := False;
    WebLabel24.WidthPercent := 100.000000000000000000;
    WebImageControl6.SetParentComponent(WebPanelSida2);
    WebImageControl6.Name := 'WebImageControl6';
    WebImageControl6.Left := 24;
    WebImageControl6.Top := 96;
    WebImageControl6.Width := 516;
    WebImageControl6.Height := 228;
    WebImageControl6.HeightPercent := 100.000000000000000000;
    WebImageControl6.WidthPercent := 100.000000000000000000;
    WebImageControl6.ChildOrder := 1;
    WebImageControl6.Picture.LoadFromFile('FRAMSIDAN.WebPanelSida2.WebImageControl6.Picture.jpg');
    WebLabel30.SetParentComponent(WebPanelSida2);
    WebLabel30.Name := 'WebLabel30';
    WebLabel30.Left := 24;
    WebLabel30.Top := 362;
    WebLabel30.Width := 268;
    WebLabel30.Height := 29;
    WebLabel30.Caption := 'CONNECT LAGERSYSTEM';
    WebLabel30.Font.Charset := DEFAULT_CHARSET;
    WebLabel30.Font.Color := clWindowText;
    WebLabel30.Font.Height := -24;
    WebLabel30.Font.Name := 'Tahoma';
    WebLabel30.Font.Style := [];
    WebLabel30.HeightPercent := 100.000000000000000000;
    WebLabel30.ParentFont := False;
    WebLabel30.WidthPercent := 100.000000000000000000;
    WebLabel31.SetParentComponent(WebPanelSida2);
    WebLabel31.Name := 'WebLabel31';
    WebLabel31.Left := 24;
    WebLabel31.Top := 416;
    WebLabel31.Width := 223;
    WebLabel31.Height := 19;
    WebLabel31.Caption := 'Menystyrt och sj'#228'lvinstruerande';
    WebLabel31.Font.Charset := DEFAULT_CHARSET;
    WebLabel31.Font.Color := clWindowText;
    WebLabel31.Font.Height := -16;
    WebLabel31.Font.Name := 'Tahoma';
    WebLabel31.Font.Style := [];
    WebLabel31.HeightPercent := 100.000000000000000000;
    WebLabel31.ParentFont := False;
    WebLabel31.WidthPercent := 100.000000000000000000;
    WebLabel32.SetParentComponent(WebPanelSida2);
    WebLabel32.Name := 'WebLabel32';
    WebLabel32.Left := 24;
    WebLabel32.Top := 441;
    WebLabel32.Width := 470;
    WebLabel32.Height := 19;
    WebLabel32.Caption := 'Prisuppdatering fr'#229'n olika leverant'#246'rer med elektronisk best'#228'llning';
    WebLabel32.Font.Charset := DEFAULT_CHARSET;
    WebLabel32.Font.Color := clWindowText;
    WebLabel32.Font.Height := -16;
    WebLabel32.Font.Name := 'Tahoma';
    WebLabel32.Font.Style := [];
    WebLabel32.HeightPercent := 100.000000000000000000;
    WebLabel32.ParentFont := False;
    WebLabel32.WidthPercent := 100.000000000000000000;
    WebLabel33.SetParentComponent(WebPanelSida2);
    WebLabel33.Name := 'WebLabel33';
    WebLabel33.Left := 24;
    WebLabel33.Top := 466;
    WebLabel33.Width := 366;
    WebLabel33.Height := 19;
    WebLabel33.Caption := 'Komplett utvecklad recepthantering med kalkylering';
    WebLabel33.Font.Charset := DEFAULT_CHARSET;
    WebLabel33.Font.Color := clWindowText;
    WebLabel33.Font.Height := -16;
    WebLabel33.Font.Name := 'Tahoma';
    WebLabel33.Font.Style := [];
    WebLabel33.HeightPercent := 100.000000000000000000;
    WebLabel33.ParentFont := False;
    WebLabel33.WidthPercent := 100.000000000000000000;
    WebLabel36.SetParentComponent(WebPanelSida2);
    WebLabel36.Name := 'WebLabel36';
    WebLabel36.Left := 24;
    WebLabel36.Top := 491;
    WebLabel36.Width := 331;
    WebLabel36.Height := 19;
    WebLabel36.Caption := 'Inventering p'#229' m'#229'nad, vecka eller fritt intervall';
    WebLabel36.Font.Charset := DEFAULT_CHARSET;
    WebLabel36.Font.Color := clWindowText;
    WebLabel36.Font.Height := -16;
    WebLabel36.Font.Name := 'Tahoma';
    WebLabel36.Font.Style := [];
    WebLabel36.HeightPercent := 100.000000000000000000;
    WebLabel36.ParentFont := False;
    WebLabel36.WidthPercent := 100.000000000000000000;
    WebLabel37.SetParentComponent(WebPanelSida2);
    WebLabel37.Name := 'WebLabel37';
    WebLabel37.Left := 24;
    WebLabel37.Top := 516;
    WebLabel37.Width := 397;
    WebLabel37.Height := 19;
    WebLabel37.Caption := 'Streckkodshantering g'#228'llande inventering och inleverans';
    WebLabel37.Font.Charset := DEFAULT_CHARSET;
    WebLabel37.Font.Color := clWindowText;
    WebLabel37.Font.Height := -16;
    WebLabel37.Font.Name := 'Tahoma';
    WebLabel37.Font.Style := [];
    WebLabel37.HeightPercent := 100.000000000000000000;
    WebLabel37.ParentFont := False;
    WebLabel37.WidthPercent := 100.000000000000000000;
    WebImageControl7.SetParentComponent(WebPanelSida2);
    WebImageControl7.Name := 'WebImageControl7';
    WebImageControl7.Left := 24;
    WebImageControl7.Top := 560;
    WebImageControl7.Width := 689;
    WebImageControl7.Height := 113;
    WebImageControl7.HeightPercent := 100.000000000000000000;
    WebImageControl7.WidthPercent := 100.000000000000000000;
    WebImageControl7.ChildOrder := 8;
    WebImageControl7.Picture.LoadFromFile('FRAMSIDAN.WebPanelSida2.WebImageControl7.Picture.jpg');
    WebPanelSida4.SetParentComponent(Self);
    WebPanelSida4.Name := 'WebPanelSida4';
    WebPanelSida4.Left := -800;
    WebPanelSida4.Top := 8;
    WebPanelSida4.Width := 722;
    WebPanelSida4.Height := 721;
    WebPanelSida4.HeightPercent := 100.000000000000000000;
    WebPanelSida4.WidthPercent := 100.000000000000000000;
    WebPanelSida4.Anchors := [akLeft,akTop,akBottom];
    WebPanelSida4.BorderColor := clSilver;
    WebPanelSida4.BorderStyle := bsNone;
    WebPanelSida4.ChildOrder := 3;
    WebPanelSida4.Color := clWhite;
    WebLabel27.SetParentComponent(WebPanelSida4);
    WebLabel27.Name := 'WebLabel27';
    WebLabel27.Left := 24;
    WebLabel27.Top := 18;
    WebLabel27.Width := 89;
    WebLabel27.Height := 33;
    WebLabel27.Caption := 'Om oss';
    WebLabel27.Font.Charset := DEFAULT_CHARSET;
    WebLabel27.Font.Color := clWindowText;
    WebLabel27.Font.Height := -27;
    WebLabel27.Font.Name := 'Tahoma';
    WebLabel27.Font.Style := [];
    WebLabel27.HeightPercent := 100.000000000000000000;
    WebLabel27.ParentFont := False;
    WebLabel27.WidthPercent := 100.000000000000000000;
    WebImageControl4.SetParentComponent(WebPanelSida4);
    WebImageControl4.Name := 'WebImageControl4';
    WebImageControl4.Left := 24;
    WebImageControl4.Top := 62;
    WebImageControl4.Width := 580;
    WebImageControl4.Height := 176;
    WebImageControl4.HeightPercent := 100.000000000000000000;
    WebImageControl4.WidthPercent := 100.000000000000000000;
    WebImageControl4.ChildOrder := 8;
    WebImageControl4.Picture.LoadFromFile('FRAMSIDAN.WebPanelSida4.WebImageControl4.Picture.jpg');
    WebLabel34.SetParentComponent(WebPanelSida4);
    WebLabel34.Name := 'WebLabel34';
    WebLabel34.Left := 24;
    WebLabel34.Top := 244;
    WebLabel34.Width := 57;
    WebLabel34.Height := 16;
    WebLabel34.Caption := 'Bill Lind'#233'n';
    WebLabel34.Font.Charset := DEFAULT_CHARSET;
    WebLabel34.Font.Color := clWindowText;
    WebLabel34.Font.Height := -13;
    WebLabel34.Font.Name := 'Tahoma';
    WebLabel34.Font.Style := [];
    WebLabel34.HeightPercent := 100.000000000000000000;
    WebLabel34.ParentFont := False;
    WebLabel34.WidthPercent := 100.000000000000000000;
    WebLabel35.SetParentComponent(WebPanelSida4);
    WebLabel35.Name := 'WebLabel35';
    WebLabel35.Left := 24;
    WebLabel35.Top := 262;
    WebLabel35.Width := 82;
    WebLabel35.Height := 16;
    WebLabel35.Caption := 'Administration';
    WebLabel35.Font.Charset := DEFAULT_CHARSET;
    WebLabel35.Font.Color := clWindowText;
    WebLabel35.Font.Height := -13;
    WebLabel35.Font.Name := 'Tahoma';
    WebLabel35.Font.Style := [];
    WebLabel35.HeightPercent := 100.000000000000000000;
    WebLabel35.ParentFont := False;
    WebLabel35.WidthPercent := 100.000000000000000000;
    WebLabel41.SetParentComponent(WebPanelSida4);
    WebLabel41.Name := 'WebLabel41';
    WebLabel41.Left := 214;
    WebLabel41.Top := 244;
    WebLabel41.Width := 81;
    WebLabel41.Height := 16;
    WebLabel41.Caption := 'Anders Lind'#233'n';
    WebLabel41.Font.Charset := DEFAULT_CHARSET;
    WebLabel41.Font.Color := clWindowText;
    WebLabel41.Font.Height := -13;
    WebLabel41.Font.Name := 'Tahoma';
    WebLabel41.Font.Style := [];
    WebLabel41.HeightPercent := 100.000000000000000000;
    WebLabel41.ParentFont := False;
    WebLabel41.WidthPercent := 100.000000000000000000;
    WebLabel42.SetParentComponent(WebPanelSida4);
    WebLabel42.Name := 'WebLabel42';
    WebLabel42.Left := 214;
    WebLabel42.Top := 262;
    WebLabel42.Width := 49;
    WebLabel42.Height := 16;
    WebLabel42.Caption := 'Tekniker';
    WebLabel42.Font.Charset := DEFAULT_CHARSET;
    WebLabel42.Font.Color := clWindowText;
    WebLabel42.Font.Height := -13;
    WebLabel42.Font.Name := 'Tahoma';
    WebLabel42.Font.Style := [];
    WebLabel42.HeightPercent := 100.000000000000000000;
    WebLabel42.ParentFont := False;
    WebLabel42.WidthPercent := 100.000000000000000000;
    WebLabel43.SetParentComponent(WebPanelSida4);
    WebLabel43.Name := 'WebLabel43';
    WebLabel43.Left := 432;
    WebLabel43.Top := 244;
    WebLabel43.Width := 90;
    WebLabel43.Height := 16;
    WebLabel43.Caption := 'Jonas Andersen';
    WebLabel43.Font.Charset := DEFAULT_CHARSET;
    WebLabel43.Font.Color := clWindowText;
    WebLabel43.Font.Height := -13;
    WebLabel43.Font.Name := 'Tahoma';
    WebLabel43.Font.Style := [];
    WebLabel43.HeightPercent := 100.000000000000000000;
    WebLabel43.ParentFont := False;
    WebLabel43.WidthPercent := 100.000000000000000000;
    WebLabel44.SetParentComponent(WebPanelSida4);
    WebLabel44.Name := 'WebLabel44';
    WebLabel44.Left := 432;
    WebLabel44.Top := 261;
    WebLabel44.Width := 87;
    WebLabel44.Height := 16;
    WebLabel44.Caption := 'Kommunikation';
    WebLabel44.Font.Charset := DEFAULT_CHARSET;
    WebLabel44.Font.Color := clWindowText;
    WebLabel44.Font.Height := -13;
    WebLabel44.Font.Name := 'Tahoma';
    WebLabel44.Font.Style := [];
    WebLabel44.HeightPercent := 100.000000000000000000;
    WebLabel44.ParentFont := False;
    WebLabel44.WidthPercent := 100.000000000000000000;
    WebPanelSida5.SetParentComponent(Self);
    WebPanelSida5.Name := 'WebPanelSida5';
    WebPanelSida5.Left := -800;
    WebPanelSida5.Top := 203;
    WebPanelSida5.Width := 722;
    WebPanelSida5.Height := 721;
    WebPanelSida5.HeightPercent := 100.000000000000000000;
    WebPanelSida5.WidthPercent := 100.000000000000000000;
    WebPanelSida5.BorderColor := clSilver;
    WebPanelSida5.BorderStyle := bsNone;
    WebPanelSida5.ChildOrder := 11;
    WebPanelSida5.Color := clWhite;
    WebLabel3.SetParentComponent(WebPanelSida5);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 24;
    WebLabel3.Top := 18;
    WebLabel3.Width := 152;
    WebLabel3.Height := 33;
    WebLabel3.Caption := 'Kontakta oss';
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -27;
    WebLabel3.Font.Name := 'Tahoma';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebPanel7.SetParentComponent(WebPanelSida5);
    WebPanel7.Name := 'WebPanel7';
    WebPanel7.Left := 48;
    WebPanel7.Top := 84;
    WebPanel7.Width := 486;
    WebPanel7.Height := 517;
    WebPanel7.HeightPercent := 100.000000000000000000;
    WebPanel7.WidthPercent := 100.000000000000000000;
    WebPanel7.BorderColor := clSilver;
    WebPanel7.BorderStyle := bsSingle;
    WebPanel7.ChildOrder := 3;
    WebLabel4.SetParentComponent(WebPanel7);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 32;
    WebLabel4.Top := 32;
    WebLabel4.Width := 160;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'Ditt namn (obligatoriskt)';
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -13;
    WebLabel4.Font.Name := 'Tahoma';
    WebLabel4.Font.Style := [fsBold];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel13.SetParentComponent(WebPanel7);
    WebLabel13.Name := 'WebLabel13';
    WebLabel13.Left := 32;
    WebLabel13.Top := 93;
    WebLabel13.Width := 156;
    WebLabel13.Height := 16;
    WebLabel13.Caption := 'Din epost  (obligatorisk)';
    WebLabel13.Font.Charset := DEFAULT_CHARSET;
    WebLabel13.Font.Color := clWindowText;
    WebLabel13.Font.Height := -13;
    WebLabel13.Font.Name := 'Tahoma';
    WebLabel13.Font.Style := [fsBold];
    WebLabel13.HeightPercent := 100.000000000000000000;
    WebLabel13.ParentFont := False;
    WebLabel13.WidthPercent := 100.000000000000000000;
    WebLabel14.SetParentComponent(WebPanel7);
    WebLabel14.Name := 'WebLabel14';
    WebLabel14.Left := 32;
    WebLabel14.Top := 156;
    WebLabel14.Width := 132;
    WebLabel14.Height := 16;
    WebLabel14.Caption := #196'mne  (obligatorisk)';
    WebLabel14.Font.Charset := DEFAULT_CHARSET;
    WebLabel14.Font.Color := clWindowText;
    WebLabel14.Font.Height := -13;
    WebLabel14.Font.Name := 'Tahoma';
    WebLabel14.Font.Style := [fsBold];
    WebLabel14.HeightPercent := 100.000000000000000000;
    WebLabel14.ParentFont := False;
    WebLabel14.WidthPercent := 100.000000000000000000;
    WebLabel15.SetParentComponent(WebPanel7);
    WebLabel15.Name := 'WebLabel15';
    WebLabel15.Left := 35;
    WebLabel15.Top := 233;
    WebLabel15.Width := 201;
    WebLabel15.Height := 16;
    WebLabel15.Caption := 'Ditt meddelande  (obligatorisk)';
    WebLabel15.Font.Charset := DEFAULT_CHARSET;
    WebLabel15.Font.Color := clWindowText;
    WebLabel15.Font.Height := -13;
    WebLabel15.Font.Name := 'Tahoma';
    WebLabel15.Font.Style := [fsBold];
    WebLabel15.HeightPercent := 100.000000000000000000;
    WebLabel15.ParentFont := False;
    WebLabel15.WidthPercent := 100.000000000000000000;
    WebLabel16.SetParentComponent(WebPanel7);
    WebLabel16.Name := 'WebLabel16';
    WebLabel16.Left := 327;
    WebLabel16.Top := 38;
    WebLabel16.Width := 130;
    WebLabel16.Height := 13;
    WebLabel16.Caption := 'Detta f'#228'lt m'#229'ste anges';
    WebLabel16.Font.Charset := DEFAULT_CHARSET;
    WebLabel16.Font.Color := clWindowText;
    WebLabel16.Font.Height := -11;
    WebLabel16.Font.Name := 'Tahoma';
    WebLabel16.Font.Style := [fsBold];
    WebLabel16.HeightPercent := 100.000000000000000000;
    WebLabel16.ParentFont := False;
    WebLabel16.WidthPercent := 100.000000000000000000;
    WebLabel17.SetParentComponent(WebPanel7);
    WebLabel17.Name := 'WebLabel17';
    WebLabel17.Left := 327;
    WebLabel17.Top := 99;
    WebLabel17.Width := 130;
    WebLabel17.Height := 13;
    WebLabel17.Caption := 'Detta f'#228'lt m'#229'ste anges';
    WebLabel17.Font.Charset := DEFAULT_CHARSET;
    WebLabel17.Font.Color := clWindowText;
    WebLabel17.Font.Height := -11;
    WebLabel17.Font.Name := 'Tahoma';
    WebLabel17.Font.Style := [fsBold];
    WebLabel17.HeightPercent := 100.000000000000000000;
    WebLabel17.ParentFont := False;
    WebLabel17.WidthPercent := 100.000000000000000000;
    WebLabel25.SetParentComponent(WebPanel7);
    WebLabel25.Name := 'WebLabel25';
    WebLabel25.Left := 327;
    WebLabel25.Top := 162;
    WebLabel25.Width := 130;
    WebLabel25.Height := 13;
    WebLabel25.Caption := 'Detta f'#228'lt m'#229'ste anges';
    WebLabel25.Font.Charset := DEFAULT_CHARSET;
    WebLabel25.Font.Color := clWindowText;
    WebLabel25.Font.Height := -11;
    WebLabel25.Font.Name := 'Tahoma';
    WebLabel25.Font.Style := [fsBold];
    WebLabel25.HeightPercent := 100.000000000000000000;
    WebLabel25.ParentFont := False;
    WebLabel25.WidthPercent := 100.000000000000000000;
    WebLabel29.SetParentComponent(WebPanel7);
    WebLabel29.Name := 'WebLabel29';
    WebLabel29.Left := 327;
    WebLabel29.Top := 240;
    WebLabel29.Width := 130;
    WebLabel29.Height := 13;
    WebLabel29.Caption := 'Detta f'#228'lt m'#229'ste anges';
    WebLabel29.Font.Charset := DEFAULT_CHARSET;
    WebLabel29.Font.Color := clWindowText;
    WebLabel29.Font.Height := -11;
    WebLabel29.Font.Name := 'Tahoma';
    WebLabel29.Font.Style := [fsBold];
    WebLabel29.HeightPercent := 100.000000000000000000;
    WebLabel29.ParentFont := False;
    WebLabel29.WidthPercent := 100.000000000000000000;
    WebEdit1.SetParentComponent(WebPanel7);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Tag := 1;
    WebEdit1.Left := 32;
    WebEdit1.Top := 54;
    WebEdit1.Width := 425;
    WebEdit1.Height := 26;
    WebEdit1.ChildOrder := 1;
    WebEdit1.Font.Charset := DEFAULT_CHARSET;
    WebEdit1.Font.Color := clWindowText;
    WebEdit1.Font.Height := -16;
    WebEdit1.Font.Name := 'Tahoma';
    WebEdit1.Font.Style := [];
    WebEdit1.HeightPercent := 100.000000000000000000;
    WebEdit1.ParentFont := False;
    WebEdit1.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit1, Self, 'OnChange', 'WebEdit1Change');
    SetEvent(WebEdit1, Self, 'OnClick', 'LOSENORD');
    SetEvent(WebEdit1, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(WebEdit1, Self, 'OnKeyPress', 'TANGENTER1');
    WebEdit2.SetParentComponent(WebPanel7);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Tag := 2;
    WebEdit2.Left := 32;
    WebEdit2.Top := 115;
    WebEdit2.Width := 425;
    WebEdit2.Height := 26;
    WebEdit2.ChildOrder := 3;
    WebEdit2.Font.Charset := DEFAULT_CHARSET;
    WebEdit2.Font.Color := clWindowText;
    WebEdit2.Font.Height := -16;
    WebEdit2.Font.Name := 'Tahoma';
    WebEdit2.Font.Style := [];
    WebEdit2.HeightPercent := 100.000000000000000000;
    WebEdit2.ParentFont := False;
    WebEdit2.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit2, Self, 'OnChange', 'WebEdit2Change');
    SetEvent(WebEdit2, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(WebEdit2, Self, 'OnKeyPress', 'TANGENTER1');
    WebEdit3.SetParentComponent(WebPanel7);
    WebEdit3.Name := 'WebEdit3';
    WebEdit3.Tag := 3;
    WebEdit3.Left := 32;
    WebEdit3.Top := 178;
    WebEdit3.Width := 425;
    WebEdit3.Height := 26;
    WebEdit3.ChildOrder := 5;
    WebEdit3.Font.Charset := DEFAULT_CHARSET;
    WebEdit3.Font.Color := clWindowText;
    WebEdit3.Font.Height := -16;
    WebEdit3.Font.Name := 'Tahoma';
    WebEdit3.Font.Style := [];
    WebEdit3.HeightPercent := 100.000000000000000000;
    WebEdit3.ParentFont := False;
    WebEdit3.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit3, Self, 'OnChange', 'WebEdit3Change');
    SetEvent(WebEdit3, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(WebEdit3, Self, 'OnKeyPress', 'TANGENTER1');
    WebMemo1.SetParentComponent(WebPanel7);
    WebMemo1.Name := 'WebMemo1';
    WebMemo1.Left := 32;
    WebMemo1.Top := 259;
    WebMemo1.Width := 425;
    WebMemo1.Height := 191;
    WebMemo1.AutoSize := False;
    WebMemo1.Font.Charset := DEFAULT_CHARSET;
    WebMemo1.Font.Color := clWindowText;
    WebMemo1.Font.Height := -16;
    WebMemo1.Font.Name := 'Tahoma';
    WebMemo1.Font.Style := [];
    WebMemo1.HeightPercent := 100.000000000000000000;
    WebMemo1.ParentFont := False;
    WebMemo1.SelLength := 0;
    WebMemo1.SelStart := 0;
    WebMemo1.WidthPercent := 100.000000000000000000;
    SetEvent(WebMemo1, Self, 'OnChange', 'WebMemo1Change');
    TMSFNCToolBarButton2.SetParentComponent(WebPanel7);
    TMSFNCToolBarButton2.Name := 'TMSFNCToolBarButton2';
    TMSFNCToolBarButton2.Left := 32;
    TMSFNCToolBarButton2.Top := 468;
    TMSFNCToolBarButton2.Width := 105;
    TMSFNCToolBarButton2.Height := 32;
    TMSFNCToolBarButton2.ParentDoubleBuffered := False;
    TMSFNCToolBarButton2.DoubleBuffered := True;
    TMSFNCToolBarButton2.Font.Charset := DEFAULT_CHARSET;
    TMSFNCToolBarButton2.Font.Color := clWindowText;
    TMSFNCToolBarButton2.Font.Height := -19;
    TMSFNCToolBarButton2.Font.Name := 'Tahoma';
    TMSFNCToolBarButton2.Font.Style := [];
    TMSFNCToolBarButton2.ParentColor := True;
    TMSFNCToolBarButton2.TabOrder := 4;
    SetEvent(TMSFNCToolBarButton2, Self, 'OnClick', 'EMAIL_SAND_KONTAKT');
    TMSFNCToolBarButton2.Text := 'Skicka';
    TMSFNCToolBarButton2.Appearance.InnerStroke.Color := clWhite;
    TMSFNCToolBarButton2.Appearance.NormalFill.Kind := gfkGradient;
    TMSFNCToolBarButton2.Appearance.NormalFill.Color := 16579577;
    TMSFNCToolBarButton2.Appearance.NormalFill.ColorTo := 15461350;
    TMSFNCToolBarButton2.Appearance.NormalFill.ColorMirror := 15523036;
    TMSFNCToolBarButton2.Appearance.NormalFill.ColorMirrorTo := 15524316;
    TMSFNCToolBarButton2.Appearance.NormalStroke.Color := clGray;
    TMSFNCToolBarButton2.Appearance.HoverFill.Kind := gfkGradient;
    TMSFNCToolBarButton2.Appearance.HoverFill.Color := 15263717;
    TMSFNCToolBarButton2.Appearance.HoverFill.ColorTo := 14145490;
    TMSFNCToolBarButton2.Appearance.HoverFill.ColorMirror := 14207176;
    TMSFNCToolBarButton2.Appearance.HoverFill.ColorMirrorTo := 14208456;
    TMSFNCToolBarButton2.Appearance.HoverStroke.Color := clGray;
    TMSFNCToolBarButton2.Appearance.DownFill.Kind := gfkGradient;
    TMSFNCToolBarButton2.Appearance.DownFill.Color := 14605787;
    TMSFNCToolBarButton2.Appearance.DownFill.ColorTo := 13487560;
    TMSFNCToolBarButton2.Appearance.DownFill.ColorMirror := 13549246;
    TMSFNCToolBarButton2.Appearance.DownFill.ColorMirrorTo := 13550526;
    TMSFNCToolBarButton2.Appearance.DownStroke.Color := clGray;
    TMSFNCToolBarButton2.Appearance.DisabledFill.Color := 13882323;
    TMSFNCToolBarButton2.Appearance.DisabledStroke.Color := clGray;
    TMSFNCToolBarButton2.ControlIndex := 0;
    TMSFNCToolBarButton3.SetParentComponent(WebPanel7);
    TMSFNCToolBarButton3.Name := 'TMSFNCToolBarButton3';
    TMSFNCToolBarButton3.Left := 349;
    TMSFNCToolBarButton3.Top := 468;
    TMSFNCToolBarButton3.Width := 105;
    TMSFNCToolBarButton3.Height := 32;
    TMSFNCToolBarButton3.ParentDoubleBuffered := False;
    TMSFNCToolBarButton3.DoubleBuffered := True;
    TMSFNCToolBarButton3.Font.Charset := DEFAULT_CHARSET;
    TMSFNCToolBarButton3.Font.Color := clWindowText;
    TMSFNCToolBarButton3.Font.Height := -19;
    TMSFNCToolBarButton3.Font.Name := 'Tahoma';
    TMSFNCToolBarButton3.Font.Style := [];
    TMSFNCToolBarButton3.ParentColor := True;
    TMSFNCToolBarButton3.TabOrder := 5;
    SetEvent(TMSFNCToolBarButton3, Self, 'OnClick', 'AVBRYTA_KONTAKT');
    TMSFNCToolBarButton3.Text := 'Avbryt';
    TMSFNCToolBarButton3.Appearance.InnerStroke.Color := clWhite;
    TMSFNCToolBarButton3.Appearance.NormalFill.Kind := gfkGradient;
    TMSFNCToolBarButton3.Appearance.NormalFill.Color := 16579577;
    TMSFNCToolBarButton3.Appearance.NormalFill.ColorTo := 15461350;
    TMSFNCToolBarButton3.Appearance.NormalFill.ColorMirror := 15523036;
    TMSFNCToolBarButton3.Appearance.NormalFill.ColorMirrorTo := 15524316;
    TMSFNCToolBarButton3.Appearance.NormalStroke.Color := clGray;
    TMSFNCToolBarButton3.Appearance.HoverFill.Kind := gfkGradient;
    TMSFNCToolBarButton3.Appearance.HoverFill.Color := 15263717;
    TMSFNCToolBarButton3.Appearance.HoverFill.ColorTo := 14145490;
    TMSFNCToolBarButton3.Appearance.HoverFill.ColorMirror := 14207176;
    TMSFNCToolBarButton3.Appearance.HoverFill.ColorMirrorTo := 14208456;
    TMSFNCToolBarButton3.Appearance.HoverStroke.Color := clGray;
    TMSFNCToolBarButton3.Appearance.DownFill.Kind := gfkGradient;
    TMSFNCToolBarButton3.Appearance.DownFill.Color := 14605787;
    TMSFNCToolBarButton3.Appearance.DownFill.ColorTo := 13487560;
    TMSFNCToolBarButton3.Appearance.DownFill.ColorMirror := 13549246;
    TMSFNCToolBarButton3.Appearance.DownFill.ColorMirrorTo := 13550526;
    TMSFNCToolBarButton3.Appearance.DownStroke.Color := clGray;
    TMSFNCToolBarButton3.Appearance.DisabledFill.Color := 13882323;
    TMSFNCToolBarButton3.Appearance.DisabledStroke.Color := clGray;
    TMSFNCToolBarButton3.ControlIndex := 0;
    WebMemo2.SetParentComponent(Self);
    WebMemo2.Name := 'WebMemo2';
    WebMemo2.Left := 548;
    WebMemo2.Top := 16;
    WebMemo2.Width := 205;
    WebMemo2.Height := 25;
    WebMemo2.AutoSize := False;
    WebMemo2.HeightPercent := 100.000000000000000000;
    WebMemo2.Lines.BeginUpdate;
    try
      WebMemo2.Lines.Clear;
      WebMemo2.Lines.Add('WebMemo2');
    finally
      WebMemo2.Lines.EndUpdate;
    end;
    WebMemo2.SelLength := 0;
    WebMemo2.SelStart := 0;
    WebMemo2.Visible := False;
    WebMemo2.WidthPercent := 100.000000000000000000;
    WebEdit5.SetParentComponent(Self);
    WebEdit5.Name := 'WebEdit5';
    WebEdit5.Left := 895;
    WebEdit5.Top := 177;
    WebEdit5.Width := 121;
    WebEdit5.Height := 22;
    WebEdit5.ChildOrder := 14;
    WebEdit5.HeightPercent := 100.000000000000000000;
    WebEdit5.Visible := False;
    WebEdit5.WidthPercent := 100.000000000000000000;
    WebPanelSida3.SetParentComponent(Self);
    WebPanelSida3.Name := 'WebPanelSida3';
    WebPanelSida3.Left := -1200;
    WebPanelSida3.Top := 66;
    WebPanelSida3.Width := 950;
    WebPanelSida3.Height := 721;
    WebPanelSida3.HeightPercent := 100.000000000000000000;
    WebPanelSida3.WidthPercent := 100.000000000000000000;
    WebPanelSida3.Anchors := [akLeft,akTop,akRight,akBottom];
    WebPanelSida3.BorderColor := clSilver;
    WebPanelSida3.BorderStyle := bsNone;
    WebPanelSida3.ChildOrder := 3;
    WebPanelSida3.Color := clWhite;
    WebImageControl3.SetParentComponent(WebPanelSida3);
    WebImageControl3.Name := 'WebImageControl3';
    WebImageControl3.Left := -700;
    WebImageControl3.Top := 64;
    WebImageControl3.Width := 580;
    WebImageControl3.Height := 176;
    WebImageControl3.HeightPercent := 100.000000000000000000;
    WebImageControl3.WidthPercent := 100.000000000000000000;
    WebImageControl3.ChildOrder := 8;
    WebImageControl3.Picture.LoadFromFile('FRAMSIDAN.WebPanelSida3.WebImageControl3.Picture.jpg');
    WebLabel26.SetParentComponent(WebPanelSida3);
    WebLabel26.Name := 'WebLabel26';
    WebLabel26.Left := 24;
    WebLabel26.Top := 20;
    WebLabel26.Width := 246;
    WebLabel26.Height := 33;
    WebLabel26.Caption := 'Connect lagersystem';
    WebLabel26.Font.Charset := DEFAULT_CHARSET;
    WebLabel26.Font.Color := clWindowText;
    WebLabel26.Font.Height := -27;
    WebLabel26.Font.Name := 'Tahoma';
    WebLabel26.Font.Style := [];
    WebLabel26.HeightPercent := 100.000000000000000000;
    WebLabel26.ParentFont := False;
    WebLabel26.WidthPercent := 100.000000000000000000;
    WebImageControl8.SetParentComponent(WebPanelSida3);
    WebImageControl8.Name := 'WebImageControl8';
    WebImageControl8.Left := 24;
    WebImageControl8.Top := 156;
    WebImageControl8.Width := 905;
    WebImageControl8.Height := 333;
    WebImageControl8.HeightPercent := 100.000000000000000000;
    WebImageControl8.WidthPercent := 100.000000000000000000;
    WebImageControl8.ChildOrder := 4;
    WebImageControl8.Picture.LoadFromFile('FRAMSIDAN.WebPanelSida3.WebImageControl8.Picture.jpg');
    TMSFNCToolBarButton5.SetParentComponent(WebPanelSida3);
    TMSFNCToolBarButton5.Name := 'TMSFNCToolBarButton5';
    TMSFNCToolBarButton5.Tag := 1;
    TMSFNCToolBarButton5.Left := 24;
    TMSFNCToolBarButton5.Top := 126;
    TMSFNCToolBarButton5.Width := 153;
    TMSFNCToolBarButton5.Height := 24;
    TMSFNCToolBarButton5.ParentDoubleBuffered := False;
    TMSFNCToolBarButton5.DoubleBuffered := True;
    TMSFNCToolBarButton5.Font.Charset := DEFAULT_CHARSET;
    TMSFNCToolBarButton5.Font.Color := clWindowText;
    TMSFNCToolBarButton5.Font.Height := -16;
    TMSFNCToolBarButton5.Font.Name := 'Tahoma';
    TMSFNCToolBarButton5.Font.Style := [];
    TMSFNCToolBarButton5.ParentColor := True;
    TMSFNCToolBarButton5.TabOrder := 0;
    SetEvent(TMSFNCToolBarButton5, Self, 'OnClick', 'DEMOCONNECT');
    TMSFNCToolBarButton5.Text := 'Demo p'#229' Svenska';
    TMSFNCToolBarButton5.Appearance.InnerStroke.Color := clWhite;
    TMSFNCToolBarButton5.Appearance.NormalFill.Kind := gfkGradient;
    TMSFNCToolBarButton5.Appearance.NormalFill.Color := 16579577;
    TMSFNCToolBarButton5.Appearance.NormalFill.ColorTo := 15461350;
    TMSFNCToolBarButton5.Appearance.NormalFill.ColorMirror := 15523036;
    TMSFNCToolBarButton5.Appearance.NormalFill.ColorMirrorTo := 15524316;
    TMSFNCToolBarButton5.Appearance.NormalStroke.Color := clGray;
    TMSFNCToolBarButton5.Appearance.HoverFill.Kind := gfkGradient;
    TMSFNCToolBarButton5.Appearance.HoverFill.Color := 15263717;
    TMSFNCToolBarButton5.Appearance.HoverFill.ColorTo := 14145490;
    TMSFNCToolBarButton5.Appearance.HoverFill.ColorMirror := 14207176;
    TMSFNCToolBarButton5.Appearance.HoverFill.ColorMirrorTo := 14208456;
    TMSFNCToolBarButton5.Appearance.HoverStroke.Color := clGray;
    TMSFNCToolBarButton5.Appearance.DownFill.Kind := gfkGradient;
    TMSFNCToolBarButton5.Appearance.DownFill.Color := 14605787;
    TMSFNCToolBarButton5.Appearance.DownFill.ColorTo := 13487560;
    TMSFNCToolBarButton5.Appearance.DownFill.ColorMirror := 13549246;
    TMSFNCToolBarButton5.Appearance.DownFill.ColorMirrorTo := 13550526;
    TMSFNCToolBarButton5.Appearance.DownStroke.Color := clGray;
    TMSFNCToolBarButton5.Appearance.DisabledFill.Color := 13882323;
    TMSFNCToolBarButton5.Appearance.DisabledStroke.Color := clGray;
    TMSFNCToolBarButton5.ControlIndex := 0;
    TMSFNCToolBarButton6.SetParentComponent(WebPanelSida3);
    TMSFNCToolBarButton6.Name := 'TMSFNCToolBarButton6';
    TMSFNCToolBarButton6.Tag := 2;
    TMSFNCToolBarButton6.Left := 199;
    TMSFNCToolBarButton6.Top := 125;
    TMSFNCToolBarButton6.Width := 153;
    TMSFNCToolBarButton6.Height := 24;
    TMSFNCToolBarButton6.ParentDoubleBuffered := False;
    TMSFNCToolBarButton6.DoubleBuffered := True;
    TMSFNCToolBarButton6.Font.Charset := DEFAULT_CHARSET;
    TMSFNCToolBarButton6.Font.Color := clWindowText;
    TMSFNCToolBarButton6.Font.Height := -16;
    TMSFNCToolBarButton6.Font.Name := 'Tahoma';
    TMSFNCToolBarButton6.Font.Style := [];
    TMSFNCToolBarButton6.ParentColor := True;
    TMSFNCToolBarButton6.TabOrder := 1;
    SetEvent(TMSFNCToolBarButton6, Self, 'OnClick', 'DEMOCONNECT');
    TMSFNCToolBarButton6.Text := 'Demo in English';
    TMSFNCToolBarButton6.Appearance.InnerStroke.Color := clWhite;
    TMSFNCToolBarButton6.Appearance.NormalFill.Kind := gfkGradient;
    TMSFNCToolBarButton6.Appearance.NormalFill.Color := 16579577;
    TMSFNCToolBarButton6.Appearance.NormalFill.ColorTo := 15461350;
    TMSFNCToolBarButton6.Appearance.NormalFill.ColorMirror := 15523036;
    TMSFNCToolBarButton6.Appearance.NormalFill.ColorMirrorTo := 15524316;
    TMSFNCToolBarButton6.Appearance.NormalStroke.Color := clGray;
    TMSFNCToolBarButton6.Appearance.HoverFill.Kind := gfkGradient;
    TMSFNCToolBarButton6.Appearance.HoverFill.Color := 15263717;
    TMSFNCToolBarButton6.Appearance.HoverFill.ColorTo := 14145490;
    TMSFNCToolBarButton6.Appearance.HoverFill.ColorMirror := 14207176;
    TMSFNCToolBarButton6.Appearance.HoverFill.ColorMirrorTo := 14208456;
    TMSFNCToolBarButton6.Appearance.HoverStroke.Color := clGray;
    TMSFNCToolBarButton6.Appearance.DownFill.Kind := gfkGradient;
    TMSFNCToolBarButton6.Appearance.DownFill.Color := 14605787;
    TMSFNCToolBarButton6.Appearance.DownFill.ColorTo := 13487560;
    TMSFNCToolBarButton6.Appearance.DownFill.ColorMirror := 13549246;
    TMSFNCToolBarButton6.Appearance.DownFill.ColorMirrorTo := 13550526;
    TMSFNCToolBarButton6.Appearance.DownStroke.Color := clGray;
    TMSFNCToolBarButton6.Appearance.DisabledFill.Color := 13882323;
    TMSFNCToolBarButton6.Appearance.DisabledStroke.Color := clGray;
    TMSFNCToolBarButton6.ControlIndex := 0;
    WebHttpRequest1.SetParentComponent(Self);
    WebHttpRequest1.Name := 'WebHttpRequest1';
    WebHttpRequest1.Headers.BeginUpdate;
    try
      WebHttpRequest1.Headers.Clear;
      WebHttpRequest1.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
    finally
      WebHttpRequest1.Headers.EndUpdate;
    end;
    WebHttpRequest1.Left := 64;
    WebHttpRequest1.Top := 1;
    WebHttpRequest2.SetParentComponent(Self);
    WebHttpRequest2.Name := 'WebHttpRequest2';
    WebHttpRequest2.Headers.BeginUpdate;
    try
      WebHttpRequest2.Headers.Clear;
      WebHttpRequest2.Headers.Add('Cache-Control=no-cache, no-store, must-revalidate');
    finally
      WebHttpRequest2.Headers.EndUpdate;
    end;
    WebHttpRequest2.Left := 160;
    WebHttpRequest2.Top := 1;
    WebHttpRequest3.SetParentComponent(Self);
    WebHttpRequest3.Name := 'WebHttpRequest3';
    WebHttpRequest3.Left := 256;
    WebHttpRequest4.SetParentComponent(Self);
    WebHttpRequest4.Name := 'WebHttpRequest4';
    SetEvent(WebHttpRequest4, Self, 'OnRequestResponse', 'WebHttpRequest4RequestResponse');
    WebHttpRequest4.Left := 360;
    ReqKontt1.SetParentComponent(Self);
    ReqKontt1.Name := 'ReqKontt1';
    SetEvent(ReqKontt1, Self, 'OnRequestResponse', 'ReqKontt1RequestResponse');
    ReqKontt1.Left := 320;
    ReqKontt1.Top := 56;
    ReqKontt2.SetParentComponent(Self);
    ReqKontt2.Name := 'ReqKontt2';
    SetEvent(ReqKontt2, Self, 'OnRequestResponse', 'ReqKontt2RequestResponse');
    ReqKontt2.Left := 392;
    ReqKontt2.Top := 56;
    ReqKontt3.SetParentComponent(Self);
    ReqKontt3.Name := 'ReqKontt3';
    ReqKontt3.Left := 464;
    ReqKontt3.Top := 56;
    WebTimer1.SetParentComponent(Self);
    WebTimer1.Name := 'WebTimer1';
    WebTimer1.Enabled := False;
    WebTimer1.Interval := 60000;
    SetEvent(WebTimer1, Self, 'OnTimer', 'KOMMANDO1');
    WebTimer1.Left := 768;
    WebTimer1.Top := 8;
    WebHttpRequest5.SetParentComponent(Self);
    WebHttpRequest5.Name := 'WebHttpRequest5';
    SetEvent(WebHttpRequest5, Self, 'OnRequestResponse', 'WebHttpRequest5RequestResponse');
    WebHttpRequest5.Left := 472;
    WebTimer2.SetParentComponent(Self);
    WebTimer2.Name := 'WebTimer2';
    WebTimer2.Enabled := False;
    WebTimer2.Interval := 500;
    SetEvent(WebTimer2, Self, 'OnTimer', 'KOMMANDO2');
    WebTimer2.Left := 864;
    WebTimer2.Top := 8;
    WebTimer3.SetParentComponent(Self);
    WebTimer3.Name := 'WebTimer3';
    WebTimer3.Enabled := False;
    WebTimer3.Interval := 30000;
    SetEvent(WebTimer3, Self, 'OnTimer', 'KOMMANDO3');
    WebTimer3.Left := 960;
    WebTimer3.Top := 8;
    WebTimer4.SetParentComponent(Self);
    WebTimer4.Name := 'WebTimer4';
    WebTimer4.Enabled := False;
    WebTimer4.Interval := 500;
    SetEvent(WebTimer4, Self, 'OnTimer', 'KOMMANDO3');
    WebTimer4.Left := 1495;
    WebTimer4.Top := 3;
    ReqKontt4.SetParentComponent(Self);
    ReqKontt4.Name := 'ReqKontt4';
    SetEvent(ReqKontt4, Self, 'OnRequestResponse', 'ReqKontt4RequestResponse');
    ReqKontt4.Left := 536;
    ReqKontt4.Top := 56;
  finally
    WebLabel1.AfterLoadDFMValues;
    WebImageControl5.AfterLoadDFMValues;
    TMSFNCGrid1.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebPanelSida1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebImageControl1.AfterLoadDFMValues;
    WebImageControl2.AfterLoadDFMValues;
    WebLabel22.AfterLoadDFMValues;
    WebLabel23.AfterLoadDFMValues;
    WebLabel45.AfterLoadDFMValues;
    WebLabel46.AfterLoadDFMValues;
    WebLabel47.AfterLoadDFMValues;
    TMSFNCGrid3.AfterLoadDFMValues;
    WebPanel3.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebPanel4.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    TMSFNCGrid2.AfterLoadDFMValues;
    WebPanel6.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebPanel5.AfterLoadDFMValues;
    TMSFNCToolBarButton1.AfterLoadDFMValues;
    WebPanel8.AfterLoadDFMValues;
    WebLabel18.AfterLoadDFMValues;
    WebImageControl11.AfterLoadDFMValues;
    WebLabel19.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebEdit6.AfterLoadDFMValues;
    WebPanelSida2.AfterLoadDFMValues;
    WebLabel24.AfterLoadDFMValues;
    WebImageControl6.AfterLoadDFMValues;
    WebLabel30.AfterLoadDFMValues;
    WebLabel31.AfterLoadDFMValues;
    WebLabel32.AfterLoadDFMValues;
    WebLabel33.AfterLoadDFMValues;
    WebLabel36.AfterLoadDFMValues;
    WebLabel37.AfterLoadDFMValues;
    WebImageControl7.AfterLoadDFMValues;
    WebPanelSida4.AfterLoadDFMValues;
    WebLabel27.AfterLoadDFMValues;
    WebImageControl4.AfterLoadDFMValues;
    WebLabel34.AfterLoadDFMValues;
    WebLabel35.AfterLoadDFMValues;
    WebLabel41.AfterLoadDFMValues;
    WebLabel42.AfterLoadDFMValues;
    WebLabel43.AfterLoadDFMValues;
    WebLabel44.AfterLoadDFMValues;
    WebPanelSida5.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebPanel7.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel13.AfterLoadDFMValues;
    WebLabel14.AfterLoadDFMValues;
    WebLabel15.AfterLoadDFMValues;
    WebLabel16.AfterLoadDFMValues;
    WebLabel17.AfterLoadDFMValues;
    WebLabel25.AfterLoadDFMValues;
    WebLabel29.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebEdit3.AfterLoadDFMValues;
    WebMemo1.AfterLoadDFMValues;
    TMSFNCToolBarButton2.AfterLoadDFMValues;
    TMSFNCToolBarButton3.AfterLoadDFMValues;
    WebMemo2.AfterLoadDFMValues;
    WebEdit5.AfterLoadDFMValues;
    WebPanelSida3.AfterLoadDFMValues;
    WebImageControl3.AfterLoadDFMValues;
    WebLabel26.AfterLoadDFMValues;
    WebImageControl8.AfterLoadDFMValues;
    TMSFNCToolBarButton5.AfterLoadDFMValues;
    TMSFNCToolBarButton6.AfterLoadDFMValues;
    WebHttpRequest1.AfterLoadDFMValues;
    WebHttpRequest2.AfterLoadDFMValues;
    WebHttpRequest3.AfterLoadDFMValues;
    WebHttpRequest4.AfterLoadDFMValues;
    ReqKontt1.AfterLoadDFMValues;
    ReqKontt2.AfterLoadDFMValues;
    ReqKontt3.AfterLoadDFMValues;
    WebTimer1.AfterLoadDFMValues;
    WebHttpRequest5.AfterLoadDFMValues;
    WebTimer2.AfterLoadDFMValues;
    WebTimer3.AfterLoadDFMValues;
    WebTimer4.AfterLoadDFMValues;
    ReqKontt4.AfterLoadDFMValues;
  end;
end;

end.
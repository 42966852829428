{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2022                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.ExtCtrls;

{$modeswitch externalclass}

{$DEFINE NOPP}

interface

uses
  Classes, SysUtils, Types, WEBLib.Controls, WEBLib.StdCtrls, WEBLib.Graphics,
  Web, JS, WEBLib.WebTools, WEBLib.Menus;

type
  TCustomLinkLabel = class(TCustomLabel)
  private
    FDisplText: string;
    FOnLinkClick: TLinkClickEvent;
    FWidthStyle: TSizeStyle;
    FHeightPercent: TPercentSize;
    FHeightStyle: TSizeStyle;
    FWidthPercent: TPercentSize;
    procedure SetHeightPercent(const Value: TPercentSize); reintroduce;
    procedure SetHeightStyle(const Value: TSizeStyle); reintroduce;
    procedure SetWidthPercent(const Value: TPercentSize); reintroduce;
    procedure SetWidthStyle(const Value: TSizeStyle); reintroduce;
  protected
    function GetOuterWidth: integer; override;
    function GetOuterHeight: integer; override;
    function GetDisplayText: string; override;

    procedure SetCaption(const AValue: string); override;
    procedure BindEvents; override;
    function DoLinkClick(Event: TJSMouseEvent): boolean;
  public
    constructor Create(AOwner: TComponent); overload; override;
  published
    property Align;
    property Alignment;
    property AlignWithMargins;
    property Anchors;
    property AutoSize;
    property Caption;
    property Color;
    property DragMode;
    property EllipsisPosition;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property Hint;
    property Layout;
    property Left;
    property Margins;
    property ShowHint;
    property Top;
    property Visible;
    property Width;
    property WordWrap;

    property HeightStyle: TSizeStyle read FHeightStyle write SetHeightStyle default ssAbsolute;
    property WidthStyle: TSizeStyle read FWidthStyle write SetWidthStyle default ssAbsolute;
    property HeightPercent: TPercentSize read FHeightPercent write SetHeightPercent default 100;
    property WidthPercent: TPercentSize read FWidthPercent write SetWidthPercent default 100;

    property OnClick;
    property OnDblClick;
    property OnLinkClick: TLinkClickEvent read FOnLinkClick write FOnLinkClick;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TLinkLabel = class(TCustomLinkLabel)
  published
    property Caption;
    property ParentFont;
  end;

  TWebLinkLabel = class(TLinkLabel);

  TTrackBarOrientation = (trHorizontal, trVertical);

  TTrackBar = class(TCustomInput)
  private
    FMax: integer;
    FMin: integer;
    FPosition: integer;
    FOnChange: TNotifyEvent;
    FOrientation: TTrackBarOrientation;
    procedure SetOrientation(const Value: TTrackBarOrientation);
  protected
    function GetElementInputHandle: TJSHTMLInputElement;
    function GetInputType: string; override;
    function DoHandleChange(Event: TEventListenerEvent): Boolean;
    procedure BindEvents; override;
    procedure SetMax(AValue: integer);
    procedure SetMin(AValue: integer);
    procedure SetPosition(AValue: integer);
    function GetPosition: integer;
    procedure DoUpdate;
    procedure Change; virtual;
    procedure CreateControl; override;
    procedure UpdateElementVisual; override;
  public
    procedure CreateInitialize; override;
    property ElementInputHandle: TJSHTMLInputElement read GetElementInputHandle;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property DragMode;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Left;
    property Margins;
    property Max: integer read FMax write SetMax default 10;
    property Min: integer read FMin write SetMin default 0;
    property Orientation: TTrackBarOrientation read FOrientation write SetOrientation default trHorizontal;
    property Position: integer read GetPosition write SetPosition default 0;
    property Role;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebTrackBar = class(TTrackBar);

  TTimer = class(TComponent)
  private
    FInterval: integer;
    FTimerID: integer;
    FOnTimer: TNotifyEvent;
    FEnabled: boolean;
  protected
    procedure SetEnabled(Value: Boolean); virtual;
    procedure SetInterval(AValue: integer);
    procedure DoTimer;
    procedure DoUpdateTimer;
    procedure DoClearTimer;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
  published
    property Enabled: boolean read FEnabled write SetEnabled default True;
    property Interval: integer read FInterval write SetInterval default 1000;
    property OnTimer: TNotifyEvent read FOnTimer write FOnTimer;
  end;

  TWebTimer = class(TTimer);

  TGeolocationEvent = procedure(Sender: TObject; Lat,Lon,Alt:double) of object;

  TGeolocationResponseProc = reference to procedure(APosition: TJSPosition);

  TGeoLocation = class(TComponent)
  private
    FOnGeolocation: TGeolocationEvent;
    FWidth: integer;
    FHeight: integer;
    FMaximumAge: integer;
    FTimeOut: integer;
    FHighPrecision: boolean;
    FTrackID: integer;
    FOnGeolocationUpdate: TGeolocationEvent;
  protected
    procedure DoHandleGeolocation(APosition: TJSPosition);
    procedure DoHandleGeolocationAsync(AProc: TGeolocationResponseProc);
    procedure DoHandleGeolocationUpdate(APosition: TJSPosition);
  public
    constructor Create(AOwner: TComponent); override;
    function HasGeolocation: boolean;
    procedure GetGeolocation;
    function GetGeolocationAsync: TJSPromise;
    property Width: integer read FWidth write FWidth;
    property Height: integer read FHeight write FHeight;
    procedure StartTracking;
    procedure StopTracking;
  published
    property HighPrecision: boolean read FHighPrecision write FHighPrecision default false;
    property TimeOut: integer read FTimeOut write FTimeOut default 60000;
    property MaximumAge: integer read FMaximumAge write FMaximumAge default 0;
    property OnGeolocation: TGeolocationEvent read FOnGeolocation write FOnGeolocation;
    property OnGeolocationUpdate: TGeolocationEvent read FOnGeolocationUpdate write FOnGeolocationUpdate;
  end;

  TWebGeoLocation = class(TGeoLocation);

  TPaintBox = class(TWebGraphicControl)
  private
    FOnPaint: TNotifyEvent;
  protected
    procedure Paint; override;
    procedure UpdateElementVisual; override;
  public
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property DragMode;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property HeightPercent;
    property HeightStyle;
    property Margins;
    property PopupMenu;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnDblClick;
    property OnMouseWheel;
    property OnPaint: TNotifyEvent read FOnPaint write FOnPaint;
    property OnTouchStart;
    property OnTouchMove;
    property OnTouchEnd;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebPaintBox = class(TPaintBox);

  TImageLoadedProc = reference to procedure(AEvent: TJSEvent);
  TImageErrorProc = reference to procedure(AEvent: TJSEvent);


  TCustomImageControl = class(TWebCustomControl)
  private
    FURL: string;
    FPicture: TURLPicture;
    FAutoSize: boolean;
    FOnLoaded: TNotifyEvent;
    FOnError: THTTPErrorEvent;
    FImageLoadPtr: pointer;
    FImageErrorPtr: pointer;
    FImageLoaded: TImageLoadedProc;
    FImageError: TImageErrorProc;
    procedure SetURL(AURL: string);
    function GetBase64Img: string;
  protected
    function HandleImageLoad(AEvent: TJSEvent): boolean; virtual;
    function HandleError(AEvent: TJSEvent): boolean; virtual;

    procedure SetPicture(const Value: TURLPicture);
    procedure PictureChanged(Sender: TObject);
    procedure PictureDataChanged(Sender: TObject);
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
    procedure SetContainerURL(AURL: string);
    property Picture: TURLPicture read FPicture write SetPicture;
    property URL: string read FURL write SetURL;
    procedure BindEvents; override;
    procedure UnbindEvents; override;
    procedure ClearMethodPointers; override;
    procedure GetMethodPointers; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    function DataURL: string; overload;
    function DataURL(AWidth, AHeight: integer): string; overload;
    procedure ResizeImage; overload;
    procedure ResizeImage(AWidth, AHeight: integer); overload;
    procedure ResizeImage(AWidth, AHeight: integer; AspectRatio: boolean); overload;
    property Base64Image: string read GetBase64Img;
    function ImageWidth: integer;
    function ImageHeight: integer;
    function LoadFromURL(const AURL: string): TJSPromise; overload;
    procedure LoadFromURL(const AURL: string; ImageLoaded: TImageLoadedProc; ImageError: TImageErrorProc); overload;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property AutoSize: boolean read FAutoSize write FAutoSize default false;
    property ChildOrder;
    property DragMode;
    property ElementClassName;
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property HeightPercent;
    property HeightStyle;
    property Margins;
    property PopupMenu;
    property ShowHint;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnDblClick;
    property OnError: THTTPErrorEvent read FOnError write FOnError;
    property OnLoaded: TNotifyEvent read FOnLoaded write FOnLoaded;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TImageControl = class(TCustomImageControl)
  published
    property Picture;
    property URL;
  end;

  TWebImageControl = class(TImageControl);

  TImageZoomControl = class;

  TImageZoomAppearance = class(TPersistent)
  private
    FOwner: TImageZoomControl;
    FHeightPercent: Integer;
    FWidthPercent: Integer;
    FResponsiveHeightPercent: Integer;
    FResponsiveWidthPercent: Integer;
    FResponsiveMaxWidth: Integer;
    procedure SetHeightPercent(const Value: Integer);
    procedure SetWidthPercent(const Value: Integer);
    procedure SetResponsiveHeightPercent(const Value: Integer);
    procedure SetResponsiveWidthPercent(const Value: Integer);
    procedure SetResponsiveMaxWidth(const Value: Integer);
  public
    constructor Create(AOwner: TImageZoomControl); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  published
    property HeightPercent: Integer read FHeightPercent write SetHeightPercent default 100;
    property WidthPercent: Integer read FWidthPercent write SetWidthPercent default 100;
    property ResponsiveHeightPercent: Integer read FResponsiveHeightPercent write SetResponsiveHeightPercent default 100;
    property ResponsiveWidthPercent: Integer read FResponsiveWidthPercent write SetResponsiveWidthPercent default 100;
    property ResponsiveMaxWidth: Integer read FResponsiveMaxWidth write SetResponsiveMaxWidth default 768;
  end;

  TImageZoomControl = class(TCustomImageControl)
  private
    FStyle: TJSElement;
    FOverlay: TJSElement;
    FPictureZoom: TURLPicture;
    FURLZoom: string;
    FPictureZoomDataURL: string;
    FAppearance: TImageZoomAppearance;
    FZoomBkgOpacity: single;
    FZoomBkgColor: TColor;
    procedure SetPictureZoom(const Value: TURLPicture);
    procedure SetURLZoom(const Value: string);
  protected
    procedure PictureZoomChanged(Sender: TObject);
    procedure PictureZoomDataChanged(Sender: TObject);
    procedure UpdateElement; override;
  public
    procedure CreateInitialize; override;
    function CreateElement: TJSElement; override;
    destructor Destroy; override;
  published
    property Appearance: TImageZoomAppearance read FAppearance write FAppearance;
    property Picture;
    property PopupMenu;
    property URL;
    property PictureZoom: TURLPicture read FPictureZoom write SetPictureZoom;
    property URLZoom: string read FURLZoom write SetURLZoom;
    property ZoomBkgColor: TColor read FZoomBkgColor write FZoomBkgColor default clBlack;
    property ZoomBkgOpacity: single read FZoomBkgOpacity write FZoomBkgOpacity;
  end;

  TWebImageZoomControl = class(TImageZoomControl);

  TCustomPanel = class(TWebCustomControl)
  private
    FAutoSize: Boolean;
    FIsSizing: Boolean;
    FPadding: TPadding;
    FShowCaption: boolean;
    FLabel: TJSHTMLElement;
    FPanelBody: TJSHTMLElement;
    FElementBodyClassName: TElementClassName;
    FAlignment: TAlignment;
    procedure SetShowCaption(const Value: boolean);
    procedure SetPadding(const Value: TPadding);
    procedure SetAlignment(const Value: TAlignment);
  protected
    function CreateElement: TJSElement; override;
    procedure SetCaption(const AValue: string); override;
    procedure SetBorderStyle(const AValue: TBorderStyle); override;
    procedure SetAutoSize(AValue: boolean);
    procedure UpdateElementVisual; override;
    property AutoSize: boolean read FAutoSize write SetAutoSize default false;
    property Alignment: TAlignment read FAlignment write SetAlignment default taCenter;
    property Padding: TPadding read FPadding write SetPadding;
    property ShowCaption: boolean read FShowCaption write SetShowCaption default true;
    function GetOuterWidth: integer; override;
    function GetOuterHeight: integer; override;
    function GetChildContainer: TJSElement; override;
    property ElementBodyClassName: TElementClassName read FElementBodyClassName write FElementBodyClassName;
    procedure RegisterParent(AValue: TControl); override;
    procedure UnRegisterParent(AValue: TControl); override;
    procedure DoAutoSize;
    procedure AlignControl(AControl: TControl); override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure EndUpdate; override;
    procedure SetBounds(X, Y, AWidth, AHeight: Integer); override;
  end;

  TPanel = class(TCustomPanel)
  published
    property Align;
    property Alignment;
    property AlignWithMargins;
    property Anchors;
    property AutoSize;
    property BorderColor;
    property BorderStyle;
    property Caption;
    property ChildOrder;
    property Color;
    property DragMode;
    property ElementBodyClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Font;
    property HeightPercent;
    property HeightStyle;
    property Margins;
    property Padding;
    property ParentFont;
    property PopupMenu;
    property Role;
    property ShowCaption;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnDblClick;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TDivPanel = class(TPanel)
  protected
    function CreateElement: TJSElement; override;
  end;

  TWebPanel = class(TPanel);

  TCustomGroupBox = class(TWebCustomControl)
  private
    FCaption: string;
    FLegend: TJSHTMLElement;
    FFieldSet: TJSHTMLElement;
    FElementLegendClassName: TElementClassName;
    procedure SetElementLegendClassName(const Value: TElementClassName);
  protected
    procedure SetFieldSetSize; virtual;
    procedure SetCaption(const Value: string); override;
    function CreateElement: TJSElement; override;
    procedure UpdateElementData; override;
    procedure UpdateElementVisual; override;
    procedure SetBoundsInt(X, Y, AWidth, AHeight: Integer); override;
    property Caption: string read FCaption write SetCaption;
  public
    procedure CreateInitialize; override;
    property ElementLegendClassName: TElementClassName read FElementLegendClassName write SetElementLegendClassName;
    property Legend: TJSHTMLElement read FLegend;
    property FieldSet: TJSHTMLElement read FFieldSet;
  end;

  TGroupBox = class(TCustomGroupBox)
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property BorderColor;
    property Caption;
    property Color;
    property DragMode;
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property Font;
    property HeightPercent;
    property HeightStyle;
    property Margins;
    property ParentFont;
    property PopupMenu;
    property Role;
    property WidthPercent;
    property WidthStyle;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebGroupBox = class(TGroupBox);

  TScrollBox = class(TCustomPanel)
  private
    FAutoScroll: boolean;
    FScrollBars: TScrollStyle;
    FScrollPtr: pointer;
    FOnScroll: TNotifyEvent;
    FVertScrollBar: TControlScrollBar;
    FHorzScrollBar: TControlScrollBar;
    function GetScrollLeft: integer;
    function GetScrollTop: integer;
    procedure SetScrollLeft(const Value: integer);
    procedure SetScrollTop(const Value: integer);
    procedure SetScrollBars(const Value: TScrollStyle);
  protected
    procedure UpdateElement; override;
    procedure SetAutoScroll(AValue: boolean);
    function GetClientRect: TRect; override;
    function HandleDoScroll(Event: TJSMouseEvent): Boolean; virtual;
    procedure DoScroll; virtual;
    procedure ClearMethodPointers; override;
    procedure GetMethodPointers; override;
    procedure BindEvents; override;
    procedure UnbindEvents; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    property ScrollLeft: integer read GetScrollLeft write SetScrollLeft;
    property ScrollTop: integer read GetScrollTop write SetScrollTop;
    procedure ScrollBy(DeltaX: integer; DeltaY: integer);
    procedure EndUpdate; override;
    property VertScrollBar: TControlScrollBar read FVertScrollBar;
    property HorzScrollBar: TControlScrollBar read FHorzScrollBar;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property AutoScroll: boolean read FAutoScroll write SetAutoScroll;
    property BorderStyle;
    property Color;
    property DragMode;
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property HeightPercent;
    property HeightStyle;
    property Margins;
    property PopupMenu;
    property Role;
    property ScrollBars: TScrollStyle read FScrollBars write SetScrollBars;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnDblClick;
    property OnMouseDown;
    property OnMouseMove;
    property OnMouseUp;
    property OnResize;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnScroll: TNotifyEvent read FOnScroll write FOnScroll;
    property OnStartDrag;
  end;

  TWebScrollBox = class(TScrollBox);

  TSplitter = class(TCustomControl)
  private
    FLayer: TJSElement;
    FTouched: boolean;
    FFirstMove: boolean;
    FSplitControl: TControl;
    FSizing: boolean;
    FSizingX,FSizingY: double;
    FOriginalWidth,FOriginalHeight: integer;
    FGripColor: TColor;
    FOnMoved: TNotifyEvent;
    FOnMove: TNotifyEvent;
    procedure SetGripColor(const Value: TColor);
  protected
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
    procedure UpdateElementSize; override;

    procedure ColorChanging; override;
    procedure DoSizeStart(X,Y: integer);
    function HandleDoTouchStart(Event: TJSTouchEvent): Boolean; override;

    function HandleDocDoMouseMove(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDocDoMouseUp(Event: TJSMouseEvent): Boolean; virtual;

    function HandleDocDoTouchMove(Event: TJSTouchEvent): Boolean; virtual;
    function HandleDocDoTouchEnd(Event: TJSTouchEvent): Boolean; virtual;

    procedure DoMouseEnter; override;
    procedure MouseUp(Button: TMouseButton; Shift: TShiftState; X,Y: Integer); override;
    procedure MouseDown(Button: TMouseButton; Shift: TShiftState; X,Y: Integer); override;
    procedure Paint; override;
  public
    procedure CreateInitialize; override;
  published
    property Align;
    property AlignWithMargins;
    property Color;
    property HeightPercent;
    property HeightStyle;
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property GripColor: TColor read FGripColor write SetGripColor default clWhite;
    property Margins;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnMove: TNotifyEvent read FOnMove write FOnMove;
    property OnMoved: TNotifyEvent read FOnMoved write FOnMoved;
  end;

  TWebSplitter = class(TSplitter);

  TVerticalAlignment = (vaTop, vaCenter, vaBottom);
  TGridPanelExpandStyle = (esAddRows, esAddColumns);

  TGridPanelRow = class(TCollectionItem)
  private
    FSizeStyle: TSizeStyle;
    FValue: integer;
    FMarginBottom: integer;
    FMarginTop: integer;
    FAlignment: TVerticalAlignment;
    FElementClassName: string;
    procedure SetMarginBottom(const Value: integer);
    procedure SetMarginTop(const Value: integer);
    procedure SetSizeStyle(const Value: TSizeStyle);
    procedure SetValue(const Value: integer);
  protected
    function HeightAttribute: string;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
  published
    property Alignment: TVerticalAlignment read FAlignment write FAlignment default vaTop;
    property ElementClassName: string read FElementClassName write FElementClassName;
    property MarginTop: integer read FMarginTop write SetMarginTop;
    property MarginBottom: integer read FMarginBottom write SetMarginBottom;
    property SizeStyle: TSizeStyle read FSizeStyle write SetSizeStyle;
    property Value: integer read FValue write SetValue;
  end;

  TGridPanelRows = class(TOwnedCollection)
  private
    FOnChange: TNotifyEvent;
    function GetItem(Index: integer): TGridPanelRow; reintroduce;
    procedure SetItem(Index: integer; const Value: TGridPanelRow); reintroduce;
  protected
    procedure Update(Item: TCollectionItem); override;
  public
    constructor Create(AOwner: TComponent); reintroduce;
    function Add: TGridPanelRow; reintroduce;
    function Insert(Index: integer): TGridPanelRow; reintroduce;
    property Items[Index: integer]: TGridPanelRow read GetItem write SetItem; default;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TGridPanelColumn = class(TCollectionItem)
  private
    FSizeStyle: TSizeStyle;
    FValue: integer;
    FMarginLeft: integer;
    FAlignment: TAlignment;
    FMarginRight: integer;
    FElementClassName: string;
    procedure SetMarginLeft(const Value: integer);
    procedure SetMarginRight(const Value: integer);
    procedure SetSizeStyle(const Value: TSizeStyle);
    procedure SetValue(const Value: integer);
  protected
    function WidthAttribute: string;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
  published
    property Alignment: TAlignment read FAlignment write FAlignment default taLeftJustify;
    property ElementClassName: string read FElementClassName write FElementClassName;
    property MarginLeft: integer read FMarginLeft write SetMarginLeft default 0;
    property MarginRight: integer read FMarginRight write SetMarginRight default 0;
    property SizeStyle: TSizeStyle read FSizeStyle write SetSizeStyle default ssPercent;
    property Value: integer read FValue write SetValue;
  end;

  TGridPanelColumns = class(TOwnedCollection)
  private
    FOnChange: TNotifyEvent;
    function GetItem(Index: integer): TGridPanelColumn; reintroduce;
    procedure SetItem(Index: integer; const Value: TGridPanelColumn); reintroduce;
  protected
    procedure Update(Item: TCollectionItem); override;
  public
    constructor Create(AOwner: TComponent); reintroduce;
    function Add: TGridPanelColumn; reintroduce;
    function Insert(Index: integer): TGridPanelColumn; reintroduce;
    property Items[Index: integer]: TGridPanelColumn read GetItem write SetItem; default;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TControlCollectionItem = class(TCollectionItem)
  private
    FControl: TWinControl;
    FRow: integer;
    FColumn: integer;
  public
    procedure Assign(Source: TPersistent); override;
  published
    property Column: integer read FColumn write FColumn;
    property Row: integer read FRow write FRow;
    property Control: TWinControl read FControl write FControl;
  end;

  TControlCollection = class(TOwnedCollection)
  private
    function GetItem(Index: Integer): TControlCollectionItem; reintroduce;
    procedure SetItem(Index: Integer; const Value: TControlCollectionItem); reintroduce;
  public
    constructor Create(AOwner: TComponent); reintroduce;
    function Add: TControlCollectionItem; reintroduce;
    function Insert(Index: Integer): TControlCollectionItem; reintroduce;
    property Items[Index: Integer]: TControlCollectionItem read GetItem write SetItem;
    function FindItem(AControl: TWinControl): boolean;
  end;

  TGridPanel = class(TWebCustomControl)
  private
    FDesignTime: boolean;
    FUpdateTable: boolean;
    FColCount: integer;
    FRowCollection: TGridPanelRows;
    FColumnCollection: TGridPanelColumns;
    FControlCollection: TControlCollection;
    FGridLineWidth: integer;
    FGridLineColor: TColor;
    FExpandStyle: TGridPanelExpandStyle;
    FTbl: TJSElement;
    FTblBody: TJSElement;
    procedure SetColumnCollection(const Value: TGridPanelColumns);
    procedure SetRowCollection(const Value: TGridPanelRows);
    procedure SetControlCollection(const Value: TControlCollection);
  protected
    function CreateTable: TJSElement; virtual;
    function CreateRow(AIndex: integer): TJSElement; virtual;
    function CreateElement: TJSElement; override;
    procedure AlignControl(AControl: TControl); override;
    procedure UpdateTable;
    procedure UpdateElement; override;
    procedure UpdateElementVisual; override;
    procedure RegisterParent(AValue: TControl); override;
    procedure TableChanged(Sender: TObject);
    procedure Notification(AComponent: TComponent;  Operation: TOperation); override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure AddControl(AControl: TWinControl);
    procedure RemoveControl(AControl: TWinControl);
    procedure EndUpdate; override;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property Color;
    property ControlCollection: TControlCollection read FControlCollection write SetControlCollection;
    property ColumnCollection: TGridPanelColumns read FColumnCollection write SetColumnCollection;
    property DragMode;
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property ExpandStyle: TGridPanelExpandStyle read FExpandStyle write FExpandStyle default esAddRows;
    property GridLineWidth: integer read FGridLineWidth write FGridLineWidth default 0;
    property GridLineColor: TColor read FGridLineColor write FGridLineColor default clBlack;
    property HeightPercent;
    property HeightStyle;
    property Margins;
    property PopupMenu;
    property Role;
    property RowCollection: TGridPanelRows read FRowCollection write SetRowCollection;
    property WidthPercent;
    property WidthStyle;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebGridPanel = class(TGridPanel);

  TMultiMediaType = (mtVideo, mtAudio);

  TMultiMediaVolume = 0..100;

  TMultimediaPlayer = class(TCustomControl)
  private
    FURL: string;
    FMultimediaType: TMultimediaType;
    FAutoPlay: boolean;
    FControls: boolean;
    FMuted: boolean;
    FLoop: boolean;
    FVolume: TMultiMediaVolume;
    FPlaybackRate: double;
    FContextMenu: boolean;
    procedure SetAutoPlay(const Value: boolean);
    procedure SetControls(const Value: boolean);
    procedure SetMuted(const Value: boolean);
    procedure SetLoop(const Value: boolean);
    procedure SetURL(const Value: string);
    procedure SetVolume(const Value: TMultiMediaVolume);
    function GetCurrentTime: double;
    procedure SetCurrentTime(const Value: double);
    function GetDuration: double;
    function GetEnded: boolean;
    function GetPaused: boolean;
    procedure SetPlaybackRate(const Value: double);
    procedure SetContextMenu(const Value: boolean);
  protected
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
  public
    procedure CreateInitialize; override;
    procedure Play;
    procedure Pause;
    procedure ReLoad;
    property CurrentTime: double read GetCurrentTime write SetCurrentTime;
    property Duration: double read GetDuration;
    property Ended: boolean read GetEnded;
    property Paused: boolean read GetPaused;
  published
    property AlignWithMargins;
    property AutoPlay: boolean read FAutoPlay write SetAutoPlay;
    property Controls: boolean read FControls write SetControls;
    property ContextMenu: boolean read FContextMenu write SetContextMenu default true;
    property HeightPercent;
    property HeightStyle;
    property Loop: boolean read FLoop write SetLoop;
    property Margins;
    property MultimediaType: TMultimediaType read FMultiMediaType write FMultiMediaType;
    property Muted: boolean read FMuted write SetMuted;
    property PlaybackRate: double read FPlaybackRate write SetPlaybackRate;
    property URL: string read FURL write SetURL;
    property Volume: TMultiMediaVolume read FVolume write SetVolume;
    property WidthPercent;
    property WidthStyle;
//    property OnContextMenu;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
  end;

  TWebMultiMediaPlayer = class(TMultiMediaPlayer);

  THTMLContainer = class(TCustomControl)
  private
    FHTML: TStringList;
    FScrollStyle: TScrollStyle;
    procedure SetHTML(const Value: TStringList);
  protected
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
    procedure HTMLChanged(Sender: TObject); virtual;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property DragMode;
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property Font;
    property HeightPercent;
    property HeightStyle;
    property HTML: TStringList read FHTML write SetHTML;
    property Margins;
    property Role;
    property ScrollStyle: TScrollStyle read FScrollStyle write FScrollStyle;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnDblClick;
    property OnMouseDown;
    property OnMouseMove;
    property OnMouseUp;
    property OnTouchEnd;
    property OnTouchCancel;
    property OnTouchStart;
    property OnTouchMove;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebHTMLContainer = class(THTMLContainer);

  THTMLForm = class(TCustomControl)
  private
    FOnSubmit: TNotifyEvent;
    FAction: string;
    procedure SetAction(const Value: string);
  protected
    procedure UpdateElement; override;
    function CreateElement: TJSElement; override;
    function DoHandleSubmit(Event: TJSEvent): boolean; virtual;
    function GetChildContainer: TJSElement; override;
    procedure BindEvents; override;
  public
    function CheckValidity: boolean;
    procedure CreateInitialize; override;
  published
    property Action: string read FAction write SetAction;
    property Align;
    property Anchors;
    property ElementPosition;
    property ElementFont;
    property ElementID;
    property Font;
    property HeightPercent;
    property HeightStyle;
    property Role;
    property WidthPercent;
    property WidthStyle;
    property OnSubmit: TNotifyEvent read FOnSubmit write FOnSubmit;
  end;

  TWebHTMLForm = class(THTMLForm);

  TBadge = class(TCustomControl)
  private
    FTextColor: TColor;
    FText: string;
    procedure SetTextColor(const Value: TColor);
    procedure SetText(const Value: string);
  protected
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
    procedure SetElementClassName(AValue: string); override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property Color default clRed;
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property HeightPercent;
    property HeightStyle;
    property Margins;
    property Role;
    property Text: string read FText write SetText;
    property TextColor: TColor read FTextColor write SetTextColor;
    property WidthPercent;
    property WidthStyle;
  end;


  TWebBadge = class(TBadge);

  TAccordionSection = class(TCollectionItem)
  private
    FCaption: string;
    FContent: string;
    FTag: integer;
    FExpanded: boolean;
    procedure SetExpanded(const Value: boolean);
    procedure SetCaption(const Value: string);
    procedure SetContent(const Value: string);
  public
    procedure Assign(Source: TPersistent); override;
    property Expanded: boolean read FExpanded write SetExpanded;
    function CaptionElement: TJSHTMLElement;
    function PanelElement: TJSHTMLElement;
  published
    property Caption: string read FCaption write SetCaption;
    property Content: string read FContent write SetContent;
    property Tag: integer read FTag write FTag;
  end;

  TAccordionSections = class(TOwnedCollection)
  private
    FOnChange: TNotifyEvent;
    function GetItem(Index: integer): TAccordionSection; reintroduce;
    procedure SetItem(Index: integer; const Value: TAccordionSection);
  protected
    procedure Update(Item: TCollectionItem); override;
  public
    constructor Create(AOwner: TComponent); overload;
    function Add: TAccordionSection; reintroduce;
    function Insert(Index: integer): TAccordionSection; reintroduce;
    property Items[Index: integer]: TAccordionSection read GetItem write SetItem; default;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TAccordionSectionEvent =  procedure(Sender: TObject; ASection: TAccordionSection) of object;
  TAccordionSectionAllowEvent =  procedure(Sender: TObject; ASection: TAccordionSection; var Allow: boolean) of object;

  TAccordionSectionRenderEvent =  procedure(Sender: TObject; ASection: TAccordionSection; ACaption, APanel: TJSHTMLElementRecord) of object;

  TAccordion = class(TCustomControl)
  private
    FStyleRendered: boolean;
    FSections: TAccordionSections;
    FOnCollapsing: TAccordionSectionAllowEvent;
    FOnExpanding: TAccordionSectionAllowEvent;
    FOnCollapsed: TAccordionSectionEvent;
    FOnExpanded: TAccordionSectionEvent;
    FOnRenderSection: TAccordionSectionRenderEvent;
    FElementSectionClassName: TElementClassName;
    FElementContentClassName: TElementClassName;
    procedure SetSections(const Value: TAccordionSections);
  protected
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
    procedure RenderAccordion;
    procedure RenderStyle;
    procedure SectionsChanged(Sender: TObject); virtual;
    function DoAccordionClick(Event: TJSEvent): Boolean; virtual;
    procedure UpdateElementVisual; override;
    procedure Expand(ASection: TAccordionSection);
    procedure Collapse(ASection: TAccordionSection);
    procedure SetElementClassName(AValue: string); override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure EndUpdate; override;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property Margins;
    property HeightPercent;
    property HeightStyle;
    property ElementSectionClassName: TElementClassName read FElementSectionClassName write FElementSectionClassName;
    property ElementContentClassName: TElementClassName read FElementContentClassName write FElementContentClassName;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Role;
    property Sections: TAccordionSections read FSections write SetSections;
    property WidthPercent;
    property WidthStyle;
    property OnCollapsed: TAccordionSectionEvent read FOnCollapsed write FOnCollapsed;
    property OnCollapsing: TAccordionSectionAllowEvent read FOnCollapsing write FOnCollapsing;
    property OnExpanded: TAccordionSectionEvent read FOnExpanded write FOnExpanded;
    property OnExpanding: TAccordionSectionAllowEvent read FOnExpanding write FOnExpanding;
    property OnRenderSection: TAccordionSectionRenderEvent read FOnRenderSection write FOnRenderSection;
  end;

  TWebAccordion = class(TAccordion);


  TGridStyle = (gTemplateColumns, gTemplateRows);

  TResponsiveLayoutItem = class(TCollectionItem)
  private
    FWidth: integer;
    FStyleType: TGridStyle;
    FStyle: string;
    FColumnGap: string;
    FRowGap: string;
    FTag: integer;
    FDescription: string;
    FMargins: TMargins;
    procedure SetMargins(const Value: TMargins);
    procedure SetColumnGap(const Value: string);
    procedure SetRowGap(const Value: string);
    procedure SetStyle(const Value: string);
    procedure SetStyleType(const Value: TGridStyle);
    procedure SetWidth(const Value: integer);
  public
    constructor Create(AOwner: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property ColumnGap: string read FColumnGap write SetColumnGap;
    property Description: string read FDescription write FDescription;
    property Margins: TMargins read FMargins write SetMargins;
    property RowGap: string read FRowGap write SetRowGap;
    property StyleType: TGridStyle read FStyleType write SetStyleType;
    property Style: string read FStyle write SetStyle;
    property Tag: integer read FTag write FTag;
    property Width: integer read FWidth write SetWidth;
  end;

  TResponsiveLayout = class(TOwnedCollection)
  private
    FOnChange: TNotifyEvent;
    function GetItem(Index: integer): TResponsiveLayoutItem; reintroduce;
    procedure SetItem(Index: integer; const Value: TResponsiveLayoutItem);
  protected
    procedure Update(Item: TCollectionItem); override;
  public
    function GetLayoutForWidth(w: integer): TResponsiveLayoutItem;
    constructor Create(AOwner: TComponent); reintroduce;
    function Add(AWidth: integer; AStyle: string): TResponsiveLayoutItem; overload; reintroduce;
    function Add: TResponsiveLayoutItem; overload; reintroduce;
    function Insert(Index: integer): TResponsiveLayoutItem; reintroduce;
    property Items[Index: integer]: TResponsiveLayoutItem read GetItem write SetItem;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  //https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout

  TResponsiveLayoutChangeEvent = procedure(Sender: TObject; ALayout: TResponsiveLayoutItem) of object;

  TResponsiveGridPanel = class(TCustomControl)
  private
    FDesignTime: boolean;
    FLabel: TJSHTMLElement;
    FLayout: TResponsiveLayout;
    FActiveLayoutItem: TResponsiveLayoutItem;
    FOldWidth: integer;
    FControlCollection: TControlCollection;
    FOnLayoutChange: TResponsiveLayoutChangeEvent;
    FResizePtr: pointer;
    procedure SetControlCollection(const Value: TControlCollection);
    function HandleResize(Event: TEventListenerEvent): boolean; virtual;
    procedure SetResponsiveStyle;
  protected
    procedure BindEvents; override;
    procedure UnbindEvents; override;
    procedure UpdateControls;
    function CreateElement: TJSElement; override;
    function GridElementCount: integer;
    procedure UpdateElement; override;
    procedure Loaded; override;
    procedure RegisterParent(AValue: TControl); override;
    procedure Notification(AComponent: TComponent;  Operation: TOperation); override;
    procedure LayoutChanged(Sender: TObject);
  public
    constructor Create(AOwner: TComponent); overload; override;
    destructor Destroy; override;
    procedure CreateInitialize; override;
    procedure AlignControls(AControl: TControl; var Rect: TRect); override;
    procedure GetChildren(Proc: TGetChildProc; Root: TComponent); override;
    procedure Resize; override;
    procedure AddControl(AControl: TWinControl);
    procedure RemoveControl(AControl: TWinControl);
    procedure EndUpdate; override;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property ChildOrder;
    property Color;
    property ControlCollection: TControlCollection read FControlCollection write SetControlCollection;
    property ElementClassName;
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property Layout: TResponsiveLayout read FLayout write FLayout;
    property Margins;
    property Role;
    property ShowHint;
    property Visible;
    property WidthPercent;
    property WidthStyle;
    property OnLayoutChange: TResponsiveLayoutChangeEvent read FOnLayoutChange write FOnLayoutChange;
  end;

  TWebResponsiveGridPanel = class(TResponsiveGridPanel);


  TLookupValueItem = class(TCollectionItem)
  private
    FValue: string;
    FDisplayText: string;
  public

  published
    property Value: string read FValue write FValue;
    property DisplayText: string read FDisplayText write FDisplayText;
  end;

  TLookupValues = class(TOwnedCollection)
  private
    FOnChange: TNotifyEvent;
    function GetItem(Index: integer): TLookupValueItem; reintroduce;
    procedure SetItem(Index: integer; const Value: TLookupValueItem);
  protected
    procedure DoChange; virtual;
    procedure Update(Item: TCollectionItem); override;
  public
    constructor Create(AOwner: TComponent); reintroduce;
    procedure AddPair(AValue, ADisplayText: string);
    function Add: TLookupValueItem; reintroduce;
    function Insert(Index: integer): TLookupValueItem; reintroduce;
    property Items[Index: integer]: TLookupValueItem read GetItem write SetItem; default;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TCustomLookupComboBox = class(TCustomComboBox)
  private
    FLookupValues: TLookupValues;
    procedure SetLookupValues(const Value: TLookupValues);
    function GetDisplayText: string;
    function GetValue: string;
    procedure SetDisplayText(const Value: string);
  protected
    procedure SetValue(const Value: string); virtual;
    procedure ValuesChanged(Sender: TObject);
    procedure DoUpdateList; override;
  public
    destructor Destroy; override;
    procedure CreateInitialize; override;
    property Value: string read GetValue write SetValue;
    property DisplayText: string read GetDisplayText write SetDisplayText;
    property LookupValues: TLookupValues read FLookupValues write SetLookupValues;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property Color;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Font;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property ItemIndex;
    property Left;
    property Margins;
    property ParentFont;
    property Role;
    property ShowFocus;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property TextDirection;
    property TextHint;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnChange;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
  end;

  TLookupComboBox = class(TCustomLookupComboBox)
  published
    property LookupValues;
  end;

  TWebLookupComboBox = class(TLookupComboBox);


implementation

uses
  WEBLib.Utils;

var
  geolocation : TJSGeoLocation; external name 'navigator.geolocation';


{ TCustomLinkLabel }

procedure TCustomLinkLabel.SetCaption(const AValue: string);
var
  iopen, iopena, iopeni, iopenend, iopenendt, iclose: integer;
  scaption, shref, stext, sanchor, sid, stype, slcaption: string;
  anchor, span, contentelement: TJSElement;
begin
  inherited SetCaption(AValue);

  if not Assigned(Container) then
    Exit;

  contentelement := ContentHandle;

  if (ElementID <> '') then
  begin
    contentelement := document.getElementById(ElementID);
  end;

  if Assigned(contentelement.nodeValue) then
    contentelement.nodeValue := '';

  scaption := Caption;

  slcaption := lowercase(scaption);

  if (Pos('https://', slcaption) = 1) or
     (Pos('http://', slcaption) = 1) then
  begin
    scaption := '<a href="'+ scaption + '">' + scaption + '</a>';
    if not Assigned(FOnLinkClick) then
    begin
      contentelement.innerHTML := scaption;
      Exit;
    end;
  end;


  sanchor := '<a href="';
  sid := '<a id="';

  if Assigned(FOnLinkClick) and ((pos(sanchor, scaption) > 0) or (pos(sid, scaption) > 0)) then
  begin
    while (Pos(sanchor, scaption) > 0) or (Pos(sid, scaption) > 0) do
    begin
      //find first link in string
      iopena := pos(sanchor, scaption);
      iopeni := pos(sid, scaption);

      if ((iopena < iopeni) or (iopeni <= 0)) and (iopena > 0) then
      begin
        stype := '#url#';
        iopen := iopena + length(sanchor);
      end
      else
      begin
        stype := '#id#';
        iopen := iopeni + length(sid);
      end;

      iopenend := pos('">', scaption);
      iopenendt := pos('" ', scaption);
      if (iopenendt > 0) and (iopenendt < iopenend) then
        iopenend := iopenendt;

      iclose := pos('</a>', scaption);
      shref := copy(scaption, iopen, iopenend - iopen);

      iopenend := pos('">', scaption);
      iclose := pos('</a>', scaption);
      stext := copy(scaption, iopenend + 2, iclose - (iopenend + 2));

      //add prefix text
      span := document.createElement('SPAN');
      span.innerHTML := copy(scaption, 0, iopen);
      contentelement.appendChild(span);

      //add link
      anchor := document.createElement('A');
      anchor['href'] := '#';
      anchor['id'] := stype + shref;
      anchor.innerHTML := stext;
      TJSHTMLElement(anchor).onclick := DoLinkClick;
      contentelement.appendChild(anchor);

      //remove prefix + link
      Delete(scaption, 1, Pos('</a>', scaption) + 3);
    end;

    //suffix text
    span := document.createElement('SPAN');
    span.innerHTML := scaption;
    contentelement.appendChild(span);
    FDisplText := scaption;
  end
  else
  begin
    FDisplText := StringReplace(AValue, '> <', '>&nbsp;<', [rfReplaceAll]);

    if Assigned(contentElement.nodeValue) then
      contentelement.nodeValue := FDisplText
    else
      if (contentelement.childelementcount <= 1) then
        contentelement.innerHTML := FDisplText;
  end;
end;

procedure TCustomLinkLabel.SetHeightPercent(const Value: TPercentSize);
begin
  FHeightPercent := Value;
end;

procedure TCustomLinkLabel.SetHeightStyle(const Value: TSizeStyle);
begin
  FHeightStyle := Value;
end;

procedure TCustomLinkLabel.SetWidthPercent(const Value: TPercentSize);
begin
  FWidthPercent := Value;
end;

procedure TCustomLinkLabel.SetWidthStyle(const Value: TSizeStyle);
begin
  FWidthStyle := Value;
end;

procedure TCustomLinkLabel.BindEvents;
begin
  inherited;
end;

constructor TCustomLinkLabel.Create(AOwner: TComponent);
begin
  inherited;
  FWidthStyle := ssAbsolute;
  FWidthPercent := 100;
  FHeightStyle := ssAbsolute;
  FHeightPercent := 100;
  Transparent := false;
end;

function TCustomLinkLabel.DoLinkClick(Event: TJSMouseEvent): boolean;
var
 slink, stype, svalue: string;
 ltype: TSysLinkType;
begin
  svalue := event.targetElement['id'];
  stype := '#url#';
  ltype := sltURL;

  if not (pos(stype, svalue) > 0) then
  begin
    stype := '#id#';
    ltype := sltID;
  end;

  slink := StringReplace(svalue, stype, '', []);

  if Assigned(FOnLinkClick) then
    FOnLinkClick(Self, slink, ltype);

  Result := true;
end;


function TCustomLinkLabel.GetDisplayText: string;
begin
  Result := FDisplText;
end;

function TCustomLinkLabel.GetOuterHeight: integer;
begin
  Result := Height;
//  if AutoSize then
//    Result := Result + 4;
end;

function TCustomLinkLabel.GetOuterWidth: integer;
begin
  Result := Width;
//  if AutoSize then
//    Result := Result + 4;
end;

{ TTrackBar }

function TTrackBar.GetElementInputHandle: TJSHTMLInputElement;
begin
  Result := TJSHTMLInputElement(Container);
end;

function TTrackBar.GetInputType: string;
begin
  Result := 'range';
end;

procedure TTrackBar.Change;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

{$HINTS OFF}
function TTrackBar.DoHandleChange(Event: TEventListenerEvent): Boolean;
var
  el: TJSHTMLElement;
begin
  el := ElementHandle;
  asm
    this.FPosition = el.value;
  end;

  Change;
  Result := True;
end;
{$HINTS ON}

procedure TTrackBar.BindEvents;
begin
  inherited;
  if Assigned(ElementHandle) then
  begin
    ElementHandle.oninput := DoHandleChange;
  end;
end;

procedure TTrackBar.CreateControl;
begin
  inherited;
  DoUpdate;
end;

procedure TTrackBar.CreateInitialize;
begin
  inherited;
  FMax := 100;
  FMin := 0;
  FPosition := 0;
end;

procedure TTrackBar.SetMax(AValue: integer);
begin
  if (FMax <> AValue) then
  begin
    FMax := AValue;
    DoUpdate;
  end;
end;

procedure TTrackBar.SetMin(AValue: integer);
begin
  if (FMin <> AValue) then
  begin
    FMin := AValue;
    DoUpdate;
  end;
end;

procedure TTrackBar.SetOrientation(const Value: TTrackBarOrientation);
var
  ow, oh: integer;
begin
  if (FOrientation <> Value) then
  begin
    ow := Width;
    oh := Height;
    FOrientation := Value;
    if not (csLoading in ComponentState) then
    begin
      Width := oh;
      Height := ow;
    end;
    UpdateElement;
  end;
end;

{$HINTS OFF}
procedure TTrackBar.DoUpdate;
var
  el: TJSHTMLElement;
begin
  if not Assigned(Container) then
    Exit;

  Container['max'] := IntToStr(FMax);
  Container['min'] := IntToStr(FMin);

  el := ElementHandle;

  asm
    el.value = this.FPosition;
    el.setAttribute('value',this.FPosition);
  end;
end;
{$HINTS ON}

procedure TTrackBar.SetPosition(AValue: integer);
begin
  if (FPosition <> AValue) then
  begin
    FPosition := AValue;
    DoUpdate;
  end;
end;

procedure TTrackBar.UpdateElementVisual;
var
  el: TJSHTMLElement;
begin
  inherited;

  el := ElementHandle;

  if Orientation = trHorizontal then
  begin
    el.removeAttribute('orient');
    el.style.removeProperty('writing-mode');
    el.style.removeProperty('-webkit-appearance');
    el.style.removeProperty('height');
    if WidthStyle = ssAbsolute then
      el.style.setProperty('width', IntToStr(Width));
  end
  else
  begin
    el.setAttribute('orient','vertical');
    el.style.setProperty('writing-mode','bt-lt');
    el.style.setProperty('-webkit-appearance','slider-vertical');
    el.style.removeProperty('width');
    if HeightStyle = ssAbsolute then
      el.style.setProperty('height', IntToStr(Height));
    el.style.setProperty('width','22px');
  end;

end;

function TTrackBar.GetPosition: integer;
var
  s: string;
begin
  Result := FPosition;

  if not Assigned(Container) then
    Exit;

  s := TJSHTMLInputElement(Container).value;
  if (s <> '') then
    Result := StrToInt(s);
end;

{ TTimer }

constructor TTimer.Create(AOwner: TComponent);
begin
  inherited;
  FInterval := 1000;
  FTimerID := -1;
  Enabled := True;
end;

procedure TTimer.SetEnabled(Value: boolean);
begin
  FEnabled := Value;
  DoUpdateTimer;
end;

procedure TTimer.SetInterval(AValue: integer);
begin
  FInterval := AValue;
  DoUpdateTimer;
end;

procedure TTimer.DoTimer;
begin
  if Assigned(FOnTimer) then
    FOnTimer(Self);
end;

destructor TTimer.Destroy;
begin
  DoClearTimer;
  inherited;
end;

procedure TTimer.DoClearTimer;
begin
  if FTimerID <> -1 then
  begin
    window.clearInterval(FTimerID);
    FTimerID := -1;
  end;
end;

procedure TTimer.DoUpdateTimer;
begin
  DoClearTimer;

  if Enabled then
    FTimerID := window.setInterval(@DoTimer, FInterval);
end;

{ TPaintBox }

procedure TPaintBox.UpdateElementVisual;
begin
  inherited;

  if (csDesigning in ComponentState) then
  begin
    ElementHandle.style.setProperty('border', '1px dotted gray');
  end;
end;

procedure TPaintBox.Paint;
begin
  inherited;
  if Assigned(OnPaint) then
    OnPaint(Self);
end;

{ TScrollBox }

procedure TScrollBox.BindEvents;
var
  eh: TJSEventTarget;
begin
  inherited;
  if Assigned(ElementBindHandle) then
  begin
    eh := ElementBindHandle;
    eh.addEventListener('scroll',FScrollPtr);
  end;
end;

procedure TScrollBox.ClearMethodPointers;
begin
  inherited;
  FScrollPtr := nil;
end;

procedure TScrollBox.CreateInitialize;
begin
  inherited;
  FAutoScroll := true;
  FScrollBars := ssBoth;
  FHorzScrollBar := TControlScrollBar.Create(Self, TScrollBarKind.sbHorizontal);
  FVertScrollBar := TControlScrollBar.Create(Self, TScrollBarKind.sbVertical);
end;

destructor TScrollBox.Destroy;
begin
  FHorzScrollBar.Free;
  FVertScrollBar.Free;
  inherited;
end;

procedure TScrollBox.DoScroll;
begin
  if Assigned(OnScroll) then
    OnScroll(Self);
end;

procedure TScrollBox.UnbindEvents;
var
  eh: TJSEventTarget;
begin
  inherited;
  if Assigned(ElementBindHandle) then
  begin
    eh := ElementBindHandle;
    eh.removeEventListener('scroll', FScrollPtr);
  end;
end;

procedure TScrollBox.UpdateElement;
begin
  inherited;
  if Assigned(ElementHandle) and not IsUpdating then
  begin
    case ScrollBars of
    ssNone:
      begin
        ElementHandle.style.setProperty('overflow', 'hidden');
      end;
    ssVertical:
      begin
        ElementHandle.style.removeProperty('overflow');
        ElementHandle.style.setProperty('overflow-x', 'hidden');
        ElementHandle.style.setProperty('overflow-y', 'auto');
      end;
    ssHorizontal:
      begin
        ElementHandle.style.removeProperty('overflow');
        ElementHandle.style.setProperty('overflow-x', 'auto');
        ElementHandle.style.setProperty('overflow-y', 'hidden');
      end;
    ssBoth:
      begin
        ElementHandle.style.setProperty('overflow', 'auto');
      end;
    end;

    if Visible and not IsLinked then
      ElementHandle.style.setProperty('display', 'inline-block');
  end;
end;

procedure TScrollBox.EndUpdate;
begin
  inherited;
  AlignControl(Self);
  // re-align in case align had effect on scrollbar appearance
  AlignControl(Self);
end;

function TScrollBox.GetClientRect: TRect;
var
  dw, dh: integer;
begin
  dw := 0;
  dh := 0;

  if (ElementHandle.scrollHeight > ElementHandle.ClientHeight) and (ScrollBars in [ssBoth, ssVertical]) then
    dw := 16; //GetScrollBarWidth;

  if (ElementHandle.scrollWidth > ElementHandle.ClientWidth) and (ScrollBars in [ssBoth, ssHorizontal]) then
    dh := 16; //GetScrollBarHeight;

  Result := Rect(0,0, TJSHTMLElement(ElementHandle).clientWidth - dw, TJSHTMLElement(ElementHandle).clientHeight - dh);
end;

procedure TScrollBox.GetMethodPointers;
begin
  inherited;
  if FScrollPtr = nil then
  begin
    FScrollPtr := @HandleDoScroll;
  end;
end;

function TScrollBox.GetScrollLeft: integer;
begin
  Result := ElementHandle.scrollLeft;
end;

function TScrollBox.GetScrollTop: integer;
begin
  Result := ElementHandle.scrollTop;
end;

function TScrollBox.HandleDoScroll(Event: TJSMouseEvent): Boolean;
begin
  DoScroll;
  Result := true;
end;

procedure TScrollBox.SetAutoScroll(AValue: boolean);
begin
  FAutoScroll := AValue;
end;

procedure TScrollBox.SetScrollBars(const Value: TScrollStyle);
begin
  if (FScrollBars <> Value) then
  begin
    FScrollBars := Value;
    UpdateElement;
  end;
end;

procedure TScrollBox.SetScrollLeft(const Value: integer);
begin
  ElementHandle.scrollLeft := Value;
end;

procedure TScrollBox.SetScrollTop(const Value: integer);
begin
  ElementHandle.scrollTop := Value;
end;

procedure TScrollBox.ScrollBy(DeltaX: integer; DeltaY: integer);
begin
  ScrollLeft := ScrollLeft + deltaX;
  ScrollTop := ScrollTop + deltaY;
end;

{ TCustomImageControl }

procedure TCustomImageControl.CreateInitialize;
begin
  inherited;
  FPicture := TURLPicture.Create;
  FPicture.OnChange := PictureChanged;
  FPicture.OnDataChange := PictureDataChanged;
  Color := clNone;
  TabStop := false;
  if (csDesigning in ComponentState) then
  begin
    Width := 400;
    Height := 300;
  end;
end;

function TCustomImageControl.DataURL: string;
begin
  Result := 'data:image/png;base64,' + GetBase64Img;
end;

function TCustomImageControl.DataURL(AWidth, AHeight: integer): string;
begin
  Result := 'data:image/png;base64,' + GetBase64Image(ElementHandle, AWidth, AHeight);
end;

destructor TCustomImageControl.Destroy;
begin
  FPicture.Free;
  inherited Destroy;
end;

function TCustomImageControl.GetBase64Img: string;
begin
  Result := GetBase64Image(ElementHandle);
end;

procedure TCustomImageControl.GetMethodPointers;
begin
  inherited;
  if FImageLoadPtr = nil then
    FImageLoadPtr := @HandleImageLoad;
  if FImageErrorPtr = nil then
    FImageErrorPtr := @HandleError;
end;

procedure TCustomImageControl.BindEvents;
begin
  inherited;

  if Assigned(ElementBindHandle) then
  begin
    ElementBindHandle.addEventListener('load',FImageLoadPtr);
    if not (csDesigning in ComponentState) then
      ElementBindHandle.addEventListener('error',FImageErrorPtr);
  end;
end;

procedure TCustomImageControl.ClearMethodPointers;
begin
  inherited;

  FImageLoadPtr := nil;
  FImageErrorPtr := nil;
end;

function TCustomImageControl.CreateElement: TJSElement;
begin
  Result := document.createElement('IMG');

  if URL <> '' then
    Result.setAttribute('src', URL);
end;

{$HINTS OFF}
function TCustomImageControl.HandleError(AEvent: TJSEvent): boolean;
var
  Handled: boolean;
  LEventRec: TJSEventRecord;
  LRequestRec: TJSXMLHttpRequestRecord;
begin
  Result := true;
  Handled := false;
  if Assigned(FOnError) then
  begin
    LEventRec.event := aEvent;
    LRequestRec.req := TJSXMLHttpRequest(aEvent.Target);
    FOnError(Self, LRequestRec, LEventRec, Handled);
  end;

  if Assigned(FImageError) then
    FImageError(AEvent);

  if not Handled and not (csDesigning in ComponentState) then
    raise Exception.Create('Error loading image ' + URL);
end;

function TCustomImageControl.HandleImageLoad(AEvent: TJSEvent): boolean;
var
  el: TJSElement;
  w,h: integer;
begin
  el := ElementHandle;

  asm
    w = el.naturalWidth;
    h = el.naturalHeight;
  end;

  FPicture.Width := w;
  FPicture.Height := h;

  if Assigned(OnLoaded) then
    OnLoaded(Self);

  if Assigned(FImageLoaded) then
    FImageLoaded(AEvent);

  Result := true;
end;

function TCustomImageControl.ImageHeight: integer;
var
  el: TJSElement;
  h: integer;
begin
  Result := -1;
  if Assigned(ElementHandle) then
  begin
    el := ElementHandle;
    asm
      h = el.naturalHeight;
    end;
    Result := h;
  end;
end;

function TCustomImageControl.ImageWidth: integer;
var
  el: TJSElement;
  w: integer;
begin
  Result := -1;
  if Assigned(ElementHandle) then
  begin
    el := ElementHandle;
    asm
      w = el.naturalWidth;
    end;
    Result := w;
  end;
end;

procedure TCustomImageControl.LoadFromURL(const AURL: string;
  ImageLoaded: TImageLoadedProc; ImageError: TImageErrorProc);
begin
  FImageLoaded := ImageLoaded;
  FImageError := ImageError;

  URL := AURL;
end;

function TCustomImageControl.LoadFromURL(const AURL: string): TJSPromise;
begin
  Result := TJSPromise.new(
    procedure(ASuccess, AFailed: TJSPromiseResolver)
    begin
      LoadFromURL(AURL,
        procedure(AEvent: TJSEvent)
        begin
          ASuccess(AEvent);
        end,

        procedure(AEvent: TJSEvent)
        begin
          AFailed(AEvent);
        end)
    end);
end;

{$HINTS ON}

procedure TCustomImageControl.SetContainerURL(AURL: string);
begin
  if Assigned(Container) then
  begin
    if AURL = '' then
      Container.removeAttribute('src')
    else
      Container['src'] := AURL;
    UpdateElement;
  end;
end;

procedure TCustomImageControl.SetURL(AURL: string);
begin
  FURL := AURL;
  SetContainerURL(AURL);
end;

procedure TCustomImageControl.UnbindEvents;
var
  eh: TJSEventTarget;
begin
  inherited;

  if Assigned(ElementBindHandle) then
  begin
    eh := ElementBindHandle;
    eh.removeEventListener('load', FImageLoadPtr);
    if not (csDesigning in ComponentState) then
      eh.removeEventListener('error',FImageErrorPtr);
  end;
end;

procedure TCustomImageControl.UpdateElement;
begin
  inherited;
  if AutoSize and Assigned(ElementHandle) then
  begin
    ElementHandle.style.removeProperty('width');
    ElementHandle.style.removeProperty('height');
  end;

end;

procedure TCustomImageControl.SetPicture(const Value: TURLPicture);
begin
  FPicture.Assign(Value);
end;

procedure TCustomImageControl.PictureChanged(Sender: TObject);
begin
  SetURL(Picture.FileName);
  UpdateElement;
end;

procedure TCustomImageControl.PictureDataChanged(Sender: TObject);
begin
  SetContainerURL('data:image/png;base64,' + HexImageDecodeAsBase64(Picture.Data));
end;

procedure TCustomImageControl.ResizeImage(AWidth, AHeight: integer);
begin
  ResizeImage(AWidth, AHeight, false);
end;

procedure TCustomImageControl.ResizeImage;
begin
  ResizeImage(Width, Height, false);
end;

procedure TCustomImageControl.ResizeImage(AWidth, AHeight: integer;
  AspectRatio: boolean);
var
  w,h: integer;
  r: double;
begin
  if AspectRatio then
  begin
    if (ImageWidth = AWidth) or (ImageHeight = AHeight) then
      Exit;
  end
  else
  begin
    if (ImageWidth = AWidth) and (ImageHeight = AHeight) then
      Exit;
  end;

  if (AWidth = 0) or (AHeight = 0) then
    Exit;

  w := AWidth;
  h := AHeight;

  if AspectRatio then
  begin
    if (ImageWidth/w > ImageHeight/h) then
      r := ImageWidth/w
    else
      r := ImageHeight/h;

    w := Round(ImageWidth / r);
    h := Round(ImageHeight / r);
  end;

  URL := 'data:image/png;base64,' + GetBase64Image(ElementHandle, w, h);
end;

{ TSplitter }

procedure TSplitter.ColorChanging;
begin
  inherited;
  Invalidate;
end;

function TSplitter.CreateElement: TJSElement;
begin
  Result := inherited CreateElement;
end;

procedure TSplitter.CreateInitialize;
 begin
   inherited;
   Cursor := crHSplit;

   if (csDesigning in ComponentState) then
   begin
     Width := 6;
     Height := 100;
   end;
   Align := alLeft;
   FSplitControl := nil;
   GripColor := clWhite;
 end;

 procedure TSplitter.Paint;
 var
   xofs,dx: integer;
   yofs,dy: integer;
   i: integer;
 begin
   if FTouched then
   begin
     Canvas.Brush.Color := clGray;
     Canvas.Pen.Color := clGray;
   end
   else
   begin
     Canvas.Brush.Color := Color;
     Canvas.Pen.Color := Color;
   end;
   Canvas.Brush.Style := bsSolid;
   Canvas.Rectangle(ClientRect);

   if (Align in [alLeft, alRight]) then
   begin
     dx := 0;
     dy := 6;
     yofs := (Height div 2) - 9;
     xofs := (Width div 2);
   end;

   if (Align in [alTop, alBottom]) then
   begin
     dx := 6;
     dy := 0;
     xofs := (Width div 2) - 9;
     yofs := (Height div 2) - 1;
   end;

   Canvas.Brush.Color := GripColor;
   Canvas.Pen.Color := GripColor;

   for i := 0 to 2 do
   begin
     Canvas.Rectangle(xofs, yofs, xofs + 2, yofs + 2);
     xofs := xofs + dx;
     yofs := yofs + dy;
   end;
 end;

procedure TSplitter.SetGripColor(const Value: TColor);
begin
  if (FGripColor <> Value) then
  begin
    FGripColor := Value;
    Invalidate;
  end;
end;

procedure TSplitter.UpdateElement;
begin
  inherited;
end;

procedure TSplitter.UpdateElementSize;
begin
  inherited;
end;

procedure TSplitter.MouseUp(Button: TMouseButton; Shift: TShiftState; X,Y: Integer);
begin
  inherited;
  FSizing := false;
  FSplitControl := nil;
end;

procedure TSplitter.MouseDown(Button: TMouseButton; Shift: TShiftState; X,Y: Integer);
begin
  inherited;
  DoSizeStart(X,Y);
end;

procedure TSplitter.DoMouseEnter;
begin
  inherited;
  if (Align in [alLeft, alRight]) then
    Cursor := crHSplit;

  if (Align in [alTop, alBottom]) then
    Cursor := crVSplit;
end;

procedure TSplitter.DoSizeStart(X,Y: integer);
var
  r,rc: TRect;
  i: integer;
  c: TControl;
  eh: TJSHTMLElement;
begin
  FSizing := true;

  r := Rect(Left, Top, Left + Width, Top + Height);

  if Assigned(Parent) then
  begin
    for i := 0 to Parent.ControlCount - 1 do
    begin
      c := Parent.Controls[i];

      if (c.Align = Align) and (c <> Self) then
      begin
        rc := Rect(c.Left, c.Top, c.Left + c.Width, c.Top + c.Height);

        //OutputDebugString(c.GetID+':'+inttostr(rc.Right)+':'+inttostr(r.Left));

        if (Align = alLeft) and (rc.Right - r.Left < 4) then
        begin
          FSplitControl := c;
          FOriginalWidth := FSplitControl.Width;
          FSizing := true;
          FSizingX := X;
          FSizingY := Y;
          break;
          //OutputDebugString('left:'+c.GetID);
        end;

        if (Align = alRight) and (rc.Left - r.Right < 4) then
        begin
          FSplitControl := c;
          FOriginalWidth := FSplitControl.Width;
          FSizing := true;
          FSizingX := X;
          FSizingY := Y;
          break;
          //OutputDebugString('left:'+c.GetID);
        end;

        if (Align = alTop) and (rc.Bottom - r.Top < 4) then
        begin
          FSplitControl := c;
          FOriginalHeight := FSplitControl.Height;
          FSizing := true;
          FSizingX := X;
          FSizingY := Y;
          break;
          //OutputDebugString('top:'+c.GetID);
        end;

        if (Align = alBottom) and (rc.Top - r.Bottom < 4) then
        begin
          FSplitControl := c;
          FOriginalHeight := FSplitControl.Height;
          FSizing := true;
          FSizingX := X;
          FSizingY := Y;
          break;
          //OutputDebugString('top:'+c.GetID);
        end;

      end;
    end;
  end;

  FLayer := document.createElement('SPAN');
  document.body.appendChild(FLayer);

  eh := TJSHTMLElement(FLayer);
  eh.style.setProperty('top', '0');
  eh.style.setProperty('left', '0');
  eh.style.setProperty('right', '0');
  eh.style.setProperty('bottom', '0');
  // make sure it is higher than the z-index of the layer associated with popup forms
  eh.style.setProperty('z-index', '99999999');

  eh.style.setProperty('webkit-user-select', 'none');
  eh.style.setProperty('moz-user-select', 'none');
  eh.style.setProperty('khtml-user-select', 'none');
  eh.style.setProperty('ms-user-select', 'none');
  eh.style.setProperty('user-select', 'none');
  eh.style.setProperty('position', 'absolute');

  if (Align in [alLeft, alRight]) then
    eh.style.setProperty('cursor', 'col-resize');

  if (Align in [alTop, alBottom]) then
    eh.style.setProperty('cursor', 'row-resize');

  FFirstMove := true;
  FSizing := true;

  eh.addEventListener('mousemove',@HandleDocDoMouseMove);
  eh.addEventListener('mouseup',@HandleDocDoMouseUp);

  eh.addEventListener('touchmove', @HandleDocDoTouchMove);
  eh.addEventListener('touchend', @HandleDocDoTouchEnd);

end;

function TSplitter.HandleDocDoMouseMove(Event: TJSMouseEvent): Boolean;
var
  dx,dy: integer;
begin
  if FSizing and Assigned(FSplitControl) then
  begin
    if FFirstMove then
    begin
      FSizingX := Event.clientX;
      FSizingY := Event.clientY;
      FFirstMove := false;
    end
    else
    begin
      dx := Round(Event.clientX - FSizingX);
      dy := Round(Event.clientY - FSizingY);

      if Align = alLeft then
        FSplitControl.Width := FOriginalWidth + dx;

      if Align = alRight then
        FSplitControl.Width := FOriginalWidth - dx;

      if Align = alTop then
        FSplitControl.Height := FOriginalHeight + dy;

      if Align = alBottom then
        FSplitControl.Height := FOriginalHeight - dy;

      DoRealign;

      if Assigned(OnMove) then
        OnMove(Self);
    end;
  end;
  Result := true;
end;

function TSplitter.HandleDocDoMouseUp(Event: TJSMouseEvent): Boolean;
begin
  FSizing := false;
  FSplitControl := nil;
  FFirstMove := true;
  FLayer.parentNode.removeChild(FLayer);
  Result := true;
  if Assigned(OnMoved) then
    OnMoved(Self);
end;


function TSplitter.HandleDocDoTouchMove(Event: TJSTouchEvent): Boolean;
var
  touch: TJSTouch;
  dx,dy: integer;
begin
  StopPropagation;

  if Event.Touches.length > 0 then
  begin
    touch := Event.touches.Touches[0];

    if FSizing and Assigned(FSplitControl) then
    begin
      if FFirstMove then
      begin
        FSizingX := touch.clientX;
        FSizingY := touch.clientY;
        FFirstMove := false;
      end
      else
      begin
        dx := Round(touch.clientX - FSizingX);
        dy := Round(touch.clientY - FSizingY);

        if Align = alLeft then
          FSplitControl.Width := FOriginalWidth + dx;

        if Align = alRight then
          FSplitControl.Width := FOriginalWidth - dx;

        if Align = alTop then
          FSplitControl.Height := FOriginalHeight + dy;

        if Align = alBottom then
          FSplitControl.Height := FOriginalHeight - dy;

        DoRealign;

        if Assigned(OnMove) then
          OnMove(Self);
      end;
    end;
  end;

  Result := True;
end;

function TSplitter.HandleDoTouchStart(Event: TJSTouchEvent): Boolean;
var
  l,t: single;
  touch: TJSTouch;
begin
  StopPropagation;
  PreventDefault;

  if Event.Touches.length > 0 then
  begin
    touch := Event.touches.Touches[0];

    FTouched := true;
    Invalidate;
    Invalidate;
    XYToClient(touch.clientX, touch.clientY, l, t);

    DoSizeStart(round(l),round(t));
  end;

  Result := false;
end;

function TSplitter.HandleDocDoTouchEnd(Event: TJSTouchEvent): Boolean;
begin
  FTouched := false;
  Invalidate;

  FSizing := false;
  FSplitControl := nil;
  FFirstMove := true;
  FLayer.parentNode.removeChild(FLayer);
  Result := true;
  if Assigned(OnMoved) then
    OnMoved(Self);
end;

{ TDivPanel }

function TDivPanel.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');
end;

{ TCustomPanel }

procedure TCustomPanel.CreateInitialize;
begin
  inherited;
  if (csDesigning in ComponentState) then
    EnablePropagation := True;
  FAutoSize := False;
  FAlignment := taCenter;
  Color := clBtnFace;
  TabStop := False;
  CustomBorder := true;
  ShowCaption := true;
  ControlStyle := ControlStyle + [csAcceptsControls];
  FLabel := nil;
  FPadding := TPadding.Create;

  if (csDesigning in ComponentState) then
  begin
    Width := 400;
    Height := 300;
  end;
end;

destructor TCustomPanel.Destroy;
begin
  FPadding.Free;
  inherited;
end;

procedure TCustomPanel.EndUpdate;
begin
  inherited;
  if AutoSize then
    AutoSize := true;
end;

function TCustomPanel.GetChildContainer: TJSElement;
begin
  if Assigned(FPanelBody) then
    Result := FPanelBody
  else
    Result := inherited;
end;

function TCustomPanel.GetOuterHeight: integer;
begin
  Result := inherited GetOuterHeight;
end;

function TCustomPanel.GetOuterWidth: integer;
begin
  Result := inherited GetOuterWidth;
end;

procedure TCustomPanel.RegisterParent(AValue: TControl);
begin
  inherited;
  if AutoSize then
    DoAutoSize;
end;

procedure TCustomPanel.AlignControl(AControl: TControl);
begin
  inherited;
  if AutoSize then
    DoAutoSize;
end;

function TCustomPanel.CreateElement: TJSElement;
begin
  Result := document.createElement('SPAN');
  FPanelBody := TJSHTMLElement(document.createElement('DIV'));
  FPanelBody['class'] := 'card-body';
  Result.appendChild(FPanelBody);
end;

procedure TCustomPanel.DoAutoSize;
var
  i: integer;
  minx,miny,maxx,maxy: integer;
  r: TJSDomRect;
  el: TJSElement;
begin
  if FIsSizing then
    Exit;

  FIsSizing := true;

  if (WidthStyle = ssAbsolute) and (HeightStyle = ssAbsolute) then
  begin
    maxx := 0;
    maxy := 0;
    minx := $FFFF;
    miny := $FFFF;
    for i := 0 to ControlCount - 1 do
    begin
      if Controls[i].WidthStyle = ssAbsolute then
      begin
        if Controls[i].Left + Controls[i].Width > maxx then
          maxx := Controls[i].Left + Controls[i].Width;

        if Controls[i].Left < minx then
          minx := Controls[i].Left;
      end
      else
      begin
        el := Controls[i].ElementHandle;
        if Assigned(el) then
        begin
          r := el.getBoundingClientRect;
          if r.X - Left + r.Width > maxx then
            maxx := Round(r.X  - Left + r.Width);

          minx := 0;
        end;
      end;

      if Controls[i].HeightStyle = ssAbsolute then
      begin
        if Controls[i].Top + Controls[i].Height > maxy then
          maxy := Controls[i].Top + Controls[i].Height;

        if Controls[i].Top < miny then
          miny := Controls[i].Top;
      end
      else
      begin
        el := Controls[i].ElementHandle;
        if Assigned(el) then
        begin
          r := el.getBoundingClientRect;
          if r.Y - Top + r.Height > maxy then
            maxy := Round(r.Y - Top + r.Height);
          miny := 0;
        end;
      end;
    end;

    // don't do anything if it would render the panel invisible at design-time
    if (csDesigning in ComponentState) then
    begin
      if (ControlCount = 0) or (maxx = 0) or (maxy = 0) then
        Exit;
    end;

    if (BorderStyle = bsSingle) then
    begin
      inc(maxx);
      inc(maxy);
    end;

    for i := 0 to ControlCount - 1 do
    begin
      Controls[i].Left := Controls[i].Left - minx;
      Controls[i].Top := Controls[i].Top - miny;
    end;

    Width := maxx - minx;
    Height := maxy - miny;
  end
  else
  begin
    Width := -1;
    Height := -1;
  end;

  UpdateElement;

  FIsSizing := false;
end;

procedure TCustomPanel.SetAlignment(const Value: TAlignment);
begin
  if (FAlignment <> Value) then
  begin
    FAlignment := Value;
    UpdateElement;
  end;
end;

procedure TCustomPanel.SetAutoSize(AValue: boolean);
begin
  FAutoSize := AValue;
  if FAutoSize and not IsUpdating then
  begin
    DoAutoSize;
  end;
end;

procedure TCustomPanel.SetBorderStyle(const AValue: TBorderStyle);
begin
  inherited;
  UpdateElement;
end;

procedure TCustomPanel.SetBounds(X, Y, AWidth, AHeight: Integer);
begin
  inherited;
  if AutoSize then
    DoAutoSize;
end;

procedure TCustomPanel.SetCaption(const AValue: string);
begin
  inherited SetCaption(AValue);

  if Assigned(ElementHandle) and ShowCaption then
  begin
    if not Assigned(FLabel) then
    begin
      FLabel := TJSHTMLElement(document.createElement('SPAN'));
      FLabel.innerHTML := Caption;

      if Assigned(ChildContainer) then
        ChildContainer.appendChild(FLabel)
      else
        ElementHandle.appendChild(FLabel);

      UpdateElementVisual;
    end
    else
    begin
      FLabel.innerHTML := Caption;
    end;
  end;
end;

procedure TCustomPanel.SetPadding(const Value: TPadding);
begin
  FPadding.Assign(Value);
end;

procedure TCustomPanel.SetShowCaption(const Value: boolean);
begin
  if (FShowCaption <> Value) then
  begin
    FShowCaption := Value;
    UpdateElementVisual;
  end;
end;

procedure TCustomPanel.UnRegisterParent(AValue: TControl);
begin
  inherited;
  if AutoSize then
    DoAutoSize;

end;

procedure TCustomPanel.UpdateElementVisual;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    if not IsLinked then
    begin
      if AutoSize then
      begin
        ElementHandle.style.setProperty('overflow', '');
        ElementHandle.style.setProperty('white-space', 'normal');
        if Visible then
          ElementHandle.style.setProperty('display', 'inline');
      end
      else
      begin
        ElementHandle.style.setProperty('overflow', 'hidden');
        ElementHandle.style.setProperty('white-space', 'nowrap');
        if Visible then
          ElementHandle.style.setProperty('display', 'inline-block');
      end;

      ElementHandle.style.setProperty('padding-left',inttostr(Padding.Left)+'px');
      ElementHandle.style.setProperty('padding-right',inttostr(Padding.Right)+'px');
      ElementHandle.style.setProperty('padding-top',inttostr(Padding.Top)+'px');
      ElementHandle.style.setProperty('padding-bottom',inttostr(Padding.Bottom)+'px');
      ElementHandle.style.setProperty('box-sizing','border-box');

      if ElementBodyClassName <> '' then
        ChildContainer.setAttribute('class', ElementBodyClassName)
      else
        ChildContainer.removeAttribute('class');
    end;

    if Assigned(FLabel) then
    begin
      if ShowCaption then
        FLabel.innerHTML := Caption
      else
        FLabel.innerHTML := '';

      // center the label
      if Visible then
      begin
        ElementHandle.style.setProperty('display','flex');
        case Alignment of
        taLeftJustify: ElementHandle.style.setProperty('justify-content','flex-start');
        taCenter: ElementHandle.style.setProperty('justify-content','center');
        taRightJustify: ElementHandle.style.setProperty('justify-content','flex-end');
        end;
        ElementHandle.style.setProperty('align-items','center');
      end;
    end;

    // do not block selection in child controls
    ElementHandle.style.setProperty('user-select', '');

    // allow default caret on text
    if Cursor = crDefault then
      ElementHandle.style.setProperty('cursor', '');
  end;
end;

{ TGeoLocation }

constructor TGeoLocation.Create(AOwner: TComponent);
begin
  inherited;
  FHighPrecision := false;
  FTimeOut := 60000;
  FMaximumAge := 0;
  FTrackID := -1;
end;

procedure TGeoLocation.DoHandleGeolocation(APosition: TJSPosition);
begin
  if Assigned(OnGeolocation) then
    OnGeolocation(Self, APosition.Coords.Latitude, APosition.Coords.Longitude, APosition.Coords.Altitude);
end;

procedure TGeoLocation.DoHandleGeolocationAsync(
  AProc: TGeolocationResponseProc);
var
 posopt: TJSPositionOptions;

  procedure Handler(APosition: TJSPosition);
  begin
    AProc(APosition);
  end;

begin
  posopt.enableHighAccuracy := FHighPrecision;
  posopt.timeout := FTimeOut;
  posopt.maximumAge := FMaximumAge;
  geolocation.getCurrentPosition(TJSGeoLocationCallback(@Handler), nil, posopt);
end;

procedure TGeoLocation.DoHandleGeolocationUpdate(APosition: TJSPosition);
begin
  if Assigned(OnGeolocationUpdate) then
    OnGeolocationUpdate(Self, APosition.Coords.Latitude, APosition.Coords.Longitude, APosition.Coords.Altitude);
end;

{$HINTS OFF}
procedure TGeoLocation.GetGeolocation;
var
  posopt: TJSPositionOptions;
begin
  if Assigned(geolocation) then
  begin
    posopt.enableHighAccuracy := FHighPrecision;
    posopt.timeout := FTimeOut;
    posopt.maximumAge := FMaximumAge;
    geolocation.getCurrentPosition(TJSGeoLocationCallback(@DoHandleGeoLocation), nil, posopt);
  end;
end;

function TGeoLocation.GetGeolocationAsync: TJSPromise;
var
  posopt: TJSPositionOptions;
begin
  Result := TJSPromise.new(
    procedure(ASuccess, AFailed: TJSPromiseResolver)
    begin
      if Assigned(geolocation) then
      begin
        DoHandleGeolocationAsync(procedure(APosition: TJSPosition)
        begin
          ASuccess(APosition);
        end);
      end
      else
        AFailed(nil);
    end);
end;

{$HINTS ON}

function TGeoLocation.HasGeolocation: boolean;
begin
  Result := Assigned(geolocation);
end;

procedure TGeoLocation.StartTracking;
var
  posopt: TJSPositionOptions;
begin
  if FTrackID <> -1 then
    StopTracking;

  if Assigned(geolocation) then
  begin
    posopt.enableHighAccuracy := FHighPrecision;
    posopt.timeout := FTimeOut;
    posopt.maximumAge := FMaximumAge;
    FTrackId := geolocation.watchPosition(TJSGeoLocationCallback(@DoHandleGeoLocationUpdate), nil, posopt);
  end;
end;

procedure TGeoLocation.StopTracking;
begin
  if Assigned(geolocation) and (FTrackID <> -1) then
  begin
    geolocation.clearWatch(FTrackID);
    FTrackID := -1;
  end;
end;

{ TGridPanelRows }

function TGridPanelRows.Add: TGridPanelRow;
begin
  Result := TGridPanelRow(inherited Add);
end;

constructor TGridPanelRows.Create(AOwner: TComponent);
begin
   inherited Create(AOwner, TGridPanelRow);
end;

function TGridPanelRows.GetItem(Index: integer): TGridPanelRow;
begin
  Result := TGridPanelRow(inherited Items[Index]);
end;

function TGridPanelRows.Insert(Index: integer): TGridPanelRow;
begin
  Result := TGridPanelRow(inherited Insert(Index));
end;

procedure TGridPanelRows.SetItem(Index: integer; const Value: TGridPanelRow);
begin
  inherited Items[Index] := Value;
end;

procedure TGridPanelRows.Update(Item: TCollectionItem);
begin
  inherited;
  if Assigned(OnChange) then
    OnChange(Self);
end;

{ TGridPanelColumns }

function TGridPanelColumns.Add: TGridPanelColumn;
begin
  Result := TGridPanelColumn(inherited Add);
end;

constructor TGridPanelColumns.Create(AOwner: TComponent);
begin
  inherited Create(AOwner, TGridPanelColumn);
end;

function TGridPanelColumns.GetItem(Index: integer): TGridPanelColumn;
begin
  Result := TGridPanelColumn(inherited Items[Index]);
end;

function TGridPanelColumns.Insert(Index: integer): TGridPanelColumn;
begin
  Result := TGridPanelColumn(inherited Insert(Index));
end;

procedure TGridPanelColumns.SetItem(Index: integer;
  const Value: TGridPanelColumn);
begin
  inherited Items[Index] := Value;
end;

procedure TGridPanelColumns.Update(Item: TCollectionItem);
begin
  inherited;
  if Assigned(OnChange) then
    OnChange(Self);
end;

{ TGridPanel }

procedure TGridPanel.AddControl(AControl: TWinControl);
begin
  FControlCollection.Add.Control := AControl;
  AControl.Parent := Self;

  if FControlCollection.Count > FColumnCollection.Count * FRowCollection.Count then
  begin
    if ExpandStyle = esAddRows then
      FRowCollection.Add
    else
      FColumnCollection.Add;
  end;
end;

procedure TGridPanel.AlignControl(AControl: TControl);
begin
  if not (csDestroying in ComponentState) then
    UpdateTable;

  inherited;
end;

function TGridPanel.CreateElement: TJSElement;
begin
  Result := document.createElement('SPAN');
end;

procedure TGridPanel.CreateInitialize;
begin
  inherited;

  FDesignTime := (csDesigning in ComponentState) and not
    ((csReading in Owner.ComponentState) or (csLoading in Owner.ComponentState));

  EnablePropagation := true;
  ControlStyle := ControlStyle + [csAcceptsControls];
  FUpdateTable := true;
  FColCount := -1;
  FRowCollection := TGridPanelRows.Create(Self);
  FRowCollection.PropName := 'RowCollection';

  if FDesignTime and not (csLoading in ComponentState) then
  begin
    FRowCollection.Add;
    FRowCollection[0].Value := 100;
    FRowCollection[0].SizeStyle := ssPercent;
  end;

  FColumnCollection := TGridPanelColumns.Create(Self);
  FColumnCollection.PropName := 'ColumnCollection';

  if FDesignTime and not (csLoading in ComponentState) then
  begin
    FColumnCollection.Add;
    FColumnCollection.Add;
    FColumnCollection[0].Value := 50;
    FColumnCollection[1].Value := 50;
    FColumnCollection[0].SizeStyle := ssPercent;
    FColumnCollection[1].SizeStyle := ssPercent;
  end;

  FRowCollection.OnChange := TableChanged;
  FColumnCollection.OnChange := TableChanged;

  FControlCollection := TControlCollection.Create(Self);
  if (csDesigning in ComponentState) then
  begin
    Width := 400;
    Height := 300;
  end;
end;

function TGridPanel.CreateRow(AIndex: integer): TJSElement;
var
  row, cell: TJSElement;
  i: integer;
  ps: string;

begin
  if (GridLineColor <> clNone) and (GridLineWidth > 0) then
    ps := IntToStr(GridLineWidth)+'px solid '+ ColorToHTML(GridLineColor)
  else
  begin
    if (csDesigning in ComponentState) then
      ps := '1px dotted gray'
    else
      ps := '0px';
  end;

  row := document.createElement('tr');
  if (ElementClassName = '') then
    TJSHTMLElement(row).style.setProperty('border', ps);

  if RowCollection.Items[AIndex].MarginTop <> 0 then
    TJSHTMLElement(row).style.setProperty('margin-top', IntToStr(RowCollection.Items[AIndex].MarginTop));

  if RowCollection.Items[AIndex].MarginBottom <> 0 then
    TJSHTMLElement(row).style.setProperty('margin-bottom', IntToStr(RowCollection.Items[AIndex].MarginBottom));

  if (RowCollection.Items[AIndex].ElementClassName <> '') then
  begin
    TJSHTMLElement(row).setAttribute('class', RowCollection.Items[AIndex].ElementClassName);
  end;

  case RowCollection.Items[AIndex].Alignment of
  vaCenter: TJSHTMLElement(row).setAttribute('valign','middle');
  vaBottom: TJSHTMLElement(row).setAttribute('valign','bottom');
  end;

  if (ElementClassName = '') then
    TJSHTMLElement(row).style.setProperty('border', ps);

  row.setAttribute('height', RowCollection.Items[AIndex].HeightAttribute);

  for i := 0 to ColumnCollection.Count - 1 do
  begin
    cell := document.createElement('td');
    if (ElementClassName = '') then
      TJSHTMLElement(cell).style.setProperty('border', ps);

    if ColumnCollection.Items[i].MarginLeft <> 0 then
      TJSHTMLElement(cell).style.setProperty('padding-left', IntToStr(ColumnCollection.Items[i].MarginLeft) +'px');

    if ColumnCollection.Items[i].MarginRight <> 0 then
      TJSHTMLElement(cell).style.setProperty('padding-right', IntToStr(ColumnCollection.Items[i].MarginRight)+'px' );

    if (ColumnCollection.Items[i].ElementClassName <> '') then
    begin
      TJSHTMLElement(cell).setAttribute('class', ColumnCollection.Items[i].ElementClassName);
    end;

    case ColumnCollection.Items[i].Alignment of
    taCenter: TJSHTMLElement(cell).setAttribute('align','center');
    taRightJustify: TJSHTMLElement(cell).setAttribute('align','right');
    end;

    cell.setAttribute('id', Name+'R'+IntToStr(AIndex)+'C'+IntToStr(i));

    if AIndex = 0 then
    begin
      TJSHTMLElement(cell).style.setProperty('width',ColumnCollection.Items[i].WidthAttribute);
    end;

    //cellText := document.createTextNode('cell is row '+IntToStr(j)+', column '+IntToStr(i));
    //cell.appendChild(cellText);
    row.appendChild(cell);
  end;

  Result := row;
end;


function TGridPanel.CreateTable: TJSElement;
var
  row: TJSElement;
  j: integer;
  ps: string;
begin
  FTbl := document.createElement('table');

  FTbl.setAttribute('width','100%');
  FTbl.setAttribute('height','100%');

  if ElementClassName <> '' then
    FTbl.setAttribute('class', ElementClassName)
  else
  begin
    if (GridLineColor <> clNone) and (GridLineWidth > 0) then
      ps := IntToStr(GridLineWidth)+'px solid '+ ColorToHTML(GridLineColor)
    else
    begin
      if (csDesigning in ComponentState) then
        ps := '1px dotted gray'
      else
        ps := '0px';
    end;

    TJSHTMLElement(FTbl).style.setProperty('border', ps);
    TJSHTMLElement(FTbl).style.setProperty('border-collapse','collapse');
  end;

  SetHTMLElementColor(TJSHTMLElement(FTbl), Color, not ((ElementClassName = '') and not IsLinked));

  FTblBody := document.createElement('tbody');

  // cells creation
  for  j := 0 to RowCollection.Count - 1 do
  begin
    row := CreateRow(j);
    FTblBody.appendChild(row);
  end;

  // append the <tbody> inside the <table>
  FTbl.appendChild(FTblBody);
  Result := FTbl;
end;

procedure TGridPanel.UpdateTable;
var
  i,j,k,mw,numrows: integer;
  fragment: TJSDocumentFragment;
  destid: string;
  control: TWinControl;
  el,row: TJSElement;
  isPercent: boolean;
  f: single;

begin
  if not Assigned(FTblBody) then
    Exit;

  if IsUpdating then
    Exit;

  if (csDestroying in ComponentState) then
    Exit;

  // row height distribution
  isPercent := true;
  mw := 0;

  for i := 0 to RowCollection.Count - 1 do
  begin
    begin
      if RowCollection.Items[i].SizeStyle <> ssPercent then
      begin
        isPercent := false;
        break;
      end
      else
        mw := mw + RowCollection.Items[i].Value;
    end;
  end;

  if isPercent and (mw > 100) then
  begin
    f := mw/100;
    for i := 0 to RowCollection.Count - 1 do
    begin
      RowCollection.Items[i].FValue := Trunc(RowCollection.Items[i].FValue / f);
    end;
  end;

  // column width distribution
  isPercent := true;
  mw := 0;

  for i := 0 to ColumnCollection.Count - 1 do
  begin
    begin
      if ColumnCollection.Items[i].SizeStyle <> ssPercent then
      begin
        isPercent := false;
        break;
      end
      else
        mw := mw + ColumnCollection.Items[i].Value;
    end;
  end;

  if isPercent and (mw > 100) then
  begin
    f := mw/100;
    for i := 0 to ColumnCollection.Count - 1 do
    begin
      ColumnCollection.Items[i].FValue := Trunc(ColumnCollection.Items[i].FValue / f);
    end;
  end;

  numrows := RowCollection.Count;

  // change column structure
  if (FColCount <> ColumnCollection.Count) then
    numrows := 0;

  // remove rows when rows decreased
  while FTblBody.childNodes.length > numrows do
  begin
    FTblBody.removeChild(FTblBody.childNodes[FTblBody.childNodes.length - 1]);
  end;

  // fill rows to match RowCollection.Count
  while FTblBody.childNodes.length < RowCollection.Count do
  begin
    row := CreateRow(FTblBody.childNodes.length);
    FTblBody.appendChild(row);
  end;

  for i := 0 to RowCollection.Count - 1 do
  begin
    row := TJSElement(FTblBody.childNodes[i]);
    row.setAttribute('height', RowCollection.Items[i].HeightAttribute);
  end;

  i := 0;
  j := 0;

  // assign controls in grid cells
  for k := 0 to ControlCollection.Count - 1 do
  begin
    if Assigned(ControlCollection.Items[k].Control) then
    begin
      fragment := document.createDocumentFragment();
      control := ControlCollection.Items[k].Control;
      ControlCollection.Items[k].Column := i;
      ControlCollection.Items[k].Row := j;

      control.ElementPosition := epRelative;
      control.ChildOrder := -1;

      if control.Align = alLeft then
      begin
        control.HeightStyle := ssPercent;
        control.HeightPercent := 100;
        if Assigned(control.ElementHandle) then
          TJSHTMLElement(control.ElementHandle).style.setProperty('float','left');
      end;

      if control.Align = alRight then
      begin
        control.HeightStyle := ssPercent;
        control.HeightPercent := 100;
        if Assigned(control.ElementHandle) then
          TJSHTMLElement(control.ElementHandle).style.setProperty('float','right');
      end;

      if (control.Align in [alTop, alBottom]) then
      begin
        control.WidthStyle := ssPercent;
        control.WidthPercent := 100;
      end;

      if control.Align = alClient then
      begin
        control.WidthStyle := ssPercent;
        control.HeightStyle := ssPercent;
        control.WidthPercent := 100;
        control.HeightPercent:= 100;
      end;

      row := TJSElement(FTblBody.childNodes[j]);
      if Assigned(row) then
      begin
        row.setAttribute('height', RowCollection.Items[j].HeightAttribute);
        if (RowCollection.Items[j].ElementClassName <> '') then
          row.setAttribute('class', RowCollection.Items[j].ElementClassName)
        else
          row.removeAttribute('class');
      end;

      if Assigned(control.ElementHandle) then
        fragment.appendChild(control.ElementHandle);

      destid := Name + 'R'+ inttostr(j) + 'C' + inttostr(i);

      el := document.getElementById(destid);

      if not Assigned(el) then
      begin
        row := CreateRow(j);
        FTblBody.appendChild(row);
        el := document.getElementById(destid);
        row.setAttribute('height', RowCollection.Items[j].HeightAttribute);
      end
      else
      begin
        if (j = 0) then
        begin
          if ColumnCollection.Items[i].SizeStyle = ssAbsolute then
          begin
            TJSHTMLElement(FTbl).removeAttribute('width');
            TJSHTMLElement(FTbl).removeAttribute('height');
          end
          else
          begin
            TJSHTMLElement(FTbl).setAttribute('width','100%');
            TJSHTMLElement(FTbl).setAttribute('height','100%');
          end;

          TJSHTMLElement(el).style.setProperty('width',ColumnCollection.Items[i].WidthAttribute);
        end;
      end;

      if Assigned(el) then
      begin
        el.appendChild(fragment);

        if control.Align = alTop then
          TJSHTMLElement(el).style.setProperty('vertical-align','top');

        if control.Align = alBottom then
          TJSHTMLElement(el).style.setProperty('vertical-align','bottom');
      end;

      inc(i);
      if i = ColumnCollection.Count then
      begin
        i := 0;
        inc(j);
      end;
    end;
  end;

  for i := 0 to ColumnCollection.Count - 1 do
  begin
    destid := Name + 'R0C' + inttostr(i);
    el := document.getElementById(destid);
    if Assigned(el) then
      TJSHTMLElement(el).style.setProperty('width',ColumnCollection.Items[i].WidthAttribute);
  end;

  FColCount := ColumnCollection.Count;
end;

destructor TGridPanel.Destroy;
begin
  FRowCollection.Free;
  FColumnCollection.Free;
  FControlCollection.Free;
  inherited;
end;

procedure TGridPanel.EndUpdate;
begin
  inherited;
  UpdateTable;
end;

procedure TGridPanel.Notification(AComponent: TComponent;
  Operation: TOperation);
var
  i: integer;
  destid: string;
  el: TJSElement;
begin
  if (Operation = opRemove) and not (csDestroying in ComponentState) then
  begin
    for i := ControlCollection.Count - 1 downto 0 do
    begin
      if ControlCollection.Items[i].Control = AComponent then
      begin
        destid := Name + 'R'+ inttostr(ControlCollection.Items[i].Row) + 'C' + inttostr(ControlCollection.Items[i].Column);
        el := document.getElementById(destid);
        el.removeChild(el.firstChild);

        ControlCollection.Items[i].FControl := nil;
        ControlCollection.Delete(i);
      end;
    end;

    UpdateTable;
  end;

  inherited;
end;

procedure TGridPanel.RegisterParent(AValue: TControl);
begin
  inherited;

  if not IsUpdating and not (csLoading in ComponentState) then
  begin
//    if Assigned(Owner) and (csLoading in Owner.ComponentState) then
//      Exit;

    if (csLoading in AValue.ComponentState) then
      Exit;

    ControlCollection.Add.Control := TWinControl(AValue);

    if ControlCollection.Count > ColumnCollection.Count * RowCollection.Count then
    begin
      if ExpandStyle = esAddRows then
        RowCollection.Add
      else
        ColumnCollection.Add;
    end;

    UpdateTable;
  end;
end;

procedure TGridPanel.RemoveControl(AControl: TWinControl);
var
  i,r,c: integer;
  row: TJSElement;
  d: double;

begin
  for i := 0 to FControlCollection.Count - 1 do
  begin
    if FControlCollection.Items[i].Control =  AControl then
    begin
      r := FControlCollection.Items[i].Row;
      c := FControlCollection.Items[i].Column;

      row := TJSElement(FTblBody.childNodes[r]);

      TJSHTMLElement(row.childNodes[c]).innerHTML := '';

      FControlCollection.Delete(i);
      break;
    end;
  end;

  if ColumnCollection.Count > 0 then
  begin
    d := ControlCollection.Count / ColumnCollection.Count;

    if Frac(d) > 0 then
      d := Trunc(d + 1);

    while d < RowCollection.Count do
    begin
      RowCollection.Delete(RowCollection.Count - 1);
    end;
  end;

end;

procedure TGridPanel.SetColumnCollection(const Value: TGridPanelColumns);
begin
  FColumnCollection.Assign(Value);
end;

procedure TGridPanel.SetControlCollection(const Value: TControlCollection);
begin
  FControlCollection.Assign(Value);
end;

procedure TGridPanel.SetRowCollection(const Value: TGridPanelRows);
begin
  FRowCollection.Assign(Value);
end;

procedure TGridPanel.TableChanged(Sender: TObject);
begin
  UpdateTable;
end;

procedure TGridPanel.UpdateElement;
begin
  inherited;

  if Assigned(ElementHandle) and not IsUpdating then
  begin
    // do not block selection in child controls
    ElementHandle.style.setProperty('user-select', '');

    // allow default caret on text
    if Cursor = crDefault then
      ElementHandle.style.setProperty('cursor', '');

    if FUpdateTable and Assigned(Container) then
    begin
      TJSElement(Container).appendChild(CreateTable);
      UpdateTable;
      FUpdateTable := false;
    end;
  end;
end;

procedure TGridPanel.UpdateElementVisual;
begin
  inherited;

  if (csDesigning in ComponentState) then
  begin
    ElementHandle.style.setProperty('border', '1px dotted gray');
  end;
end;

{ TGridPanelColumn }

procedure TGridPanelColumn.Assign(Source: TPersistent);
begin
  if (Source is TGridPanelColumn) then
  begin
    FAlignment := (Source as TGridPanelColumn).Alignment;
    FSizeStyle := (Source as TGridPanelColumn).SizeStyle;
    FElementClassName := (Source as TGridPanelColumn).ElementClassName;
    FValue := (Source as TGridPanelColumn).Value;
    FMarginLeft := (Source as TGridPanelColumn).MarginLeft;
    FMarginRight := (Source as TGridPanelColumn).MarginRight;
  end;
end;

constructor TGridPanelColumn.Create(ACollection: TCollection);
begin
  inherited;
  FSizeStyle := ssPercent;
  FValue := 0;
  FMarginLeft := 0;
  FMarginRight := 0;
  FAlignment := taLeftJustify;
end;

procedure TGridPanelColumn.SetMarginLeft(const Value: integer);
begin
  if (FMarginLeft <> Value) then
  begin
    FMarginLeft := Value;
    TGridPanelColumns(Collection).Update(Self);
  end;
end;

procedure TGridPanelColumn.SetMarginRight(const Value: integer);
begin
  if (FMarginRight <> Value) then
  begin
    FMarginRight := Value;
    TGridPanelColumns(Collection).Update(Self);
  end;
end;

procedure TGridPanelColumn.SetSizeStyle(const Value: TSizeStyle);
begin
  if (FSizeStyle <> Value) then
  begin
    FSizeStyle := Value;
    TGridPanelColumns(Collection).Update(Self);
  end;
end;

procedure TGridPanelColumn.SetValue(const Value: integer);
begin
  if (FValue <> Value) then
  begin
    FValue := Value;
    TGridPanelColumns(Collection).Update(Self);
  end;
end;

function TGridPanelColumn.WidthAttribute: string;
begin
  Result := '';
  case SizeStyle of
  ssPercent: Result := IntTostr(Value)+'%';
  ssAbsolute: Result := IntTostr(Value)+'px';
  end;
end;

{ TGridPanelRow }

procedure TGridPanelRow.Assign(Source: TPersistent);
begin
  if (Source is TGridPanelRow) then
  begin
    FAlignment := (Source as TGridPanelRow).Alignment;
    FSizeStyle := (Source as TGridPanelRow).SizeStyle;
    FElementClassName := (Source as TGridPanelRow).ElementClassName;
    FValue := (Source as TGridPanelRow).Value;
    FMarginTop := (Source as TGridPanelRow).MarginTop;
    FMarginBottom := (Source as TGridPanelRow).MarginBottom;
  end;
end;

constructor TGridPanelRow.Create(ACollection: TCollection);
begin
  inherited;
  FSizeStyle := ssPercent;
  FAlignment := vaTop;
  FMarginTop := 0;
  FMarginBottom := 0;
end;

function TGridPanelRow.HeightAttribute: string;
begin
  Result := '';
  case SizeStyle of
  ssPercent: Result := IntTostr(Value)+'%';
  ssAbsolute: Result := IntTostr(Value)+'px';
  end;
end;

procedure TGridPanelRow.SetMarginBottom(const Value: integer);
begin
  if (FMarginBottom <> Value) then
  begin
    FMarginBottom := Value;
    TGridPanelColumns(Collection).Update(Self);
  end;
end;

procedure TGridPanelRow.SetMarginTop(const Value: integer);
begin
  if (FMarginTop <> Value) then
  begin
    FMarginTop := Value;
    TGridPanelColumns(Collection).Update(Self);
  end;
end;

procedure TGridPanelRow.SetSizeStyle(const Value: TSizeStyle);
begin
  if (FSizeStyle <> Value) then
  begin
    FSizeStyle := Value;
    TGridPanelColumns(Collection).Update(Self);
  end;
end;

procedure TGridPanelRow.SetValue(const Value: integer);
begin
  if (FValue <> Value) then
  begin
    FValue := Value;
    TGridPanelColumns(Collection).Update(Self);
  end;
end;

{ TControlCollectionItem }

procedure TControlCollectionItem.Assign(Source: TPersistent);
begin
  if (Source is TControlCollectionItem) then
  begin
    FColumn := (Source as TControlCollectionItem).Column;
    FRow := (Source as TControlCollectionItem).Row;
    FControl := (Source as TControlCollectionItem).Control;
  end;
end;


{ TControlCollection }

function TControlCollection.Add: TControlCollectionItem;
begin
  Result := TControlCollectionItem(inherited Add);
end;

constructor TControlCollection.Create(AOwner: TComponent);
begin
  inherited Create(AOwner, TControlCollectionItem);
end;

function TControlCollection.FindItem(AControl: TWinControl): boolean;
var
  i: integer;
begin
  Result := false;
  for i := 0 to Count - 1 do
  begin
    if Items[i].Control = AControl then
    begin
      Result := true;
      Exit;
    end;
  end;
end;

function TControlCollection.GetItem(Index: Integer): TControlCollectionItem;
begin
  Result := TControlCollectionItem(inherited Items[Index]);
end;

function TControlCollection.Insert(Index: Integer): TControlCollectionItem;
begin
  Result := TControlCollectionItem(inherited Insert(Index));
end;

procedure TControlCollection.SetItem(Index: Integer;
  const Value: TControlCollectionItem);
begin
  inherited Items[Index] := Value;
end;

{ TWebMultimediaPlayer }

function TMultimediaPlayer.CreateElement: TJSElement;
var
  src: TJSElement;
begin
  if MultimediaType = mtVideo then
    Result := document.createElement('VIDEO')
  else
    Result := document.createElement('AUDIO');

  src := document.createElement('SOURCE');
  Result.appendChild(src);
end;

procedure TMultimediaPlayer.CreateInitialize;
begin
  inherited;
  FVolume := 100;
  FPlaybackRate := 1;
  FMuted := false;
  FAutoPlay := false;
  FContextMenu := true;
  if (csDesigning in ComponentState) then
  begin
    Width := 400;
    Height := 300;
  end;
end;

function TMultimediaPlayer.GetCurrentTime: double;
var
  el: TJSElement;
  i: double;
begin
  el := ElementHandle;
  if Assigned(el) then
  begin
    asm
      i = el.currentTime;
    end;
  end;

  Result := i;
end;

function TMultimediaPlayer.GetDuration: double;
var
  el: TJSElement;
  i: double;
begin
  el := ElementHandle;
  if Assigned(el) then
  begin
    asm
      i = el.duration;
    end;
  end;
  Result := i;
end;

function TMultimediaPlayer.GetEnded: boolean;
var
  el: TJSElement;
  e: boolean;
begin
  el := ElementHandle;
  if Assigned(el) then
  begin
    asm
      e = el.ended;
    end;
  end;

  Result := e;
end;

function TMultimediaPlayer.GetPaused: boolean;
var
  el: TJSElement;
  e: boolean;
begin
  el := ElementHandle;
  if Assigned(el) then
  begin
    asm
      e = el.paused;
    end;
  end;

  Result := e;
end;


procedure TMultimediaPlayer.Pause;
var
  el: TJSElement;
begin
  el := ElementHandle;
  if Assigned(el) then
  begin
    asm
      el.pause();
    end;
  end;

end;

procedure TMultimediaPlayer.Play;
var
  el: TJSElement;
begin
  el := ElementHandle;
  if Assigned(el) then
  asm
    el.play();
  end;
end;

procedure TMultimediaPlayer.ReLoad;
var
  el: TJSElement;
begin
  el := ElementHandle;
  if Assigned(el) then
  asm
    el.load();
  end;
end;

procedure TMultimediaPlayer.SetAutoPlay(const Value: boolean);
begin
  if (FAutoPlay <> Value) then
  begin
    FAutoPlay := Value;
    UpdateElement;
  end;
end;

procedure TMultimediaPlayer.SetContextMenu(const Value: boolean);
begin
  if (FContextMenu <> Value) then
  begin
    FContextMenu := Value;
    UpdateElement;
  end;
end;

procedure TMultimediaPlayer.SetControls(const Value: boolean);
begin
  if (FControls <> Value) then
  begin
    FControls := Value;
    UpdateElement;
  end;
end;

procedure TMultimediaPlayer.SetCurrentTime(const Value: double);
var
  el: TJSElement;
begin
  el := ElementHandle;
  if Assigned(el) then
  asm
    el.currentTime = Value;
  end;
end;

{$HINTS OFF}

procedure TMultimediaPlayer.SetLoop(const Value: boolean);
var
  el: TJSElement;
begin
  if (FLoop <> Value) then
  begin
    FLoop := Value;
    if Assigned(ElementHandle) then
    begin
      el := ElementHandle;
      asm
        el.loop = Value;
      end;
    end;
  end;
end;

procedure TMultimediaPlayer.SetMuted(const Value: boolean);
var
  el: TJSElement;
begin
  if (FMuted <> Value) then
  begin
    FMuted := Value;
    if Assigned(ElementHandle) then
    begin
      el := ElementHandle;
      asm
        el.muted = Value;
      end;
    end;
  end;
end;

procedure TMultimediaPlayer.SetPlaybackRate(const Value: double);
begin
  if (FPlaybackRate <> Value) then
  begin
    FPlaybackRate := Value;
    UpdateElement;
  end;
end;

procedure TMultimediaPlayer.SetURL(const Value: string);
begin
  if (FURL <> Value) then
  begin
    FURL := Value;
    UpdateElement;
  end;
end;

procedure TMultimediaPlayer.SetVolume(const Value: TMultiMediaVolume);
var
  el: TJSElement;
begin
  if (FVolume <> Value) and (Value >= 0) and (Value <= 100) then
  begin
    FVolume := Value;
    if Assigned(ElementHandle) then
    begin
      el := ElementHandle;
      asm
        el.volume = Value/100;
      end;
    end;
  end;
end;


procedure TMultimediaPlayer.UpdateElement;
var
  el: TJSNode;
  vid: TJSElement;

  function booltoattr(b: boolean): string;
  begin
    if b then
      result := 'true'
    else
      result := 'false';
  end;

begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    vid := TJSElement(ElementHandle);
    asm
      vid.controls = this.FControls;
      vid.playbackRate = this.FPlaybackRate;
      vid.muted = this.FMuted;
    end;

    if FControls then
      ElementHandle.setAttribute('controls','')
    else
      ElementHandle.removeAttribute('controls');

    if FAutoPlay then
      ElementHandle.setAttribute('autoplay',booltoattr(FAutoplay))
    else
      ElementHandle.removeAttribute('autoplay');

    if FMuted then
      ElementHandle.setAttribute('muted',booltoattr(FMuted))
    else
      ElementHandle.removeAttribute('muted');

    if not FContextMenu then
      ElementHandle.setAttribute('oncontextmenu','return false;')
    else
      ElementHandle.removeAttribute('oncontextmenu');

    ElementHandle.setAttribute('volume',Format('%.2f',[FVolume/100]));
    ElementHandle.setAttribute('src',url);

//    el := ElementHandle.firstChild;
//    if Assigned(el) then
//    begin
//      (el as TJSElement).setAttribute('src',url);
//      (el as TJSElement).setAttribute('type','video/mp4');
//    end;
  end;
end;

{$HINTS ON}

{ TCustomGroupBox }

function TCustomGroupBox.CreateElement: TJSElement;
begin
//  Result := document.createElement('FIELDSET');
//  FFieldSet := TJSHTMLElement(Result);

  Result := document.createElement('DIV');

  FFieldSet := TJSHTMLElement(document.createElement('FIELDSET'));
  Result.appendChild(FFieldSet);

  FLegend := TJSHTMLElement(document.createElement('LEGEND'));
  FFieldSet.appendChild(FLegend);
  FLegend.innerHTML := Caption;
end;

procedure TCustomGroupBox.CreateInitialize;
begin
  inherited;
  EnablePropagation := True;
  ControlStyle := ControlStyle + [csAcceptsControls];
  Color := clBtnFace;
  if (csDesigning in ComponentState) then
  begin
    Width := 400;
    Height := 300;
  end;
end;


procedure TCustomGroupBox.SetBoundsInt(X, Y, AWidth, AHeight: Integer);
begin
  inherited;
  SetFieldSetSize;
end;

procedure TCustomGroupBox.SetCaption(const Value: string);
begin
  if FCaption <> Value then
  begin
    FCaption := Value;
    UpdateElementData;
  end;
end;

procedure TCustomGroupBox.SetElementLegendClassName(const Value: TElementClassName);
begin
  if (FElementLegendClassName <> Value) then
  begin
    FElementLegendClassName := Value;
    UpdateElementVisual;
  end;
end;

procedure TCustomGroupBox.SetFieldSetSize;
begin
  if Assigned(FFieldSet) then
  begin
    if WidthStyle = ssAbsolute then
      FFieldSet.style.setProperty('width',IntToStr(Width - 4 - Font.Size * 2)+'px')
    else
      FFieldSet.style.setProperty('width','90%');

    if HeightStyle = ssAbsolute then
      FFieldSet.style.setProperty('height',IntToStr(Height - Font.Size * 2)+'px')
    else
      FFieldSet.style.setProperty('height','90%');
  end;
end;

procedure TCustomGroupBox.UpdateElementData;
begin
  inherited;
  if Assigned(FLegend) then
  begin
    FLegend.innerHTML := Caption;
  end;
end;

procedure TCustomGroupBox.UpdateElementVisual;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    ElementHandle.style.removeProperty('background-color');
  end;

  if Assigned(FLegend) then
  begin
    FLegend['class'] := 'w-auto ' + ElementLegendClassName;
    TJSHTMLElement(FLegend).style.setProperty('float', 'none');
  end;

  if Assigned(FFieldSet) then
  begin
    FFieldSet.style.setProperty('background-color',ColorToHTML(Color));
    FFieldSet.style.setProperty('overflow', 'visible');

    SetFieldSetSize;

    FFieldSet.style.setProperty('white-space', 'nowrap');

    if Visible then
      FFieldSet.style.setProperty('display', 'inline-block');

    FFieldSet.style.setProperty('webkit-user-select', 'none');
    FFieldSet.style.setProperty('moz-user-select', 'none');
    FFieldSet.style.setProperty('khtml-user-select', 'none');
    FFieldSet.style.setProperty('ms-user-select', 'none');
    FFieldSet.style.setProperty('user-select', 'none');
    FFieldSet.style.setProperty('border','1px solid '+ ColorToHTML(BorderColor));
  end;
end;

{ THTMLContainer }

function THTMLContainer.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');
end;

procedure THTMLContainer.CreateInitialize;
begin
  inherited;
  FHTML := TStringList.Create;
  FHTML.SkipLastLinebreak := true;
  FHTML.OnChange := HTMLChanged;
  FScrollStyle := ssBoth;
end;

destructor THTMLContainer.Destroy;
begin
  FHTML.Free;
  inherited;
end;

procedure THTMLContainer.HTMLChanged(Sender: TObject);
begin
  UpdateElement;
end;

procedure THTMLContainer.SetHTML(const Value: TStringList);
begin
  FHTML.Assign(Value);
end;

procedure THTMLContainer.UpdateElement;
var
  TextFound: Boolean;
begin
  inherited;

  if not IsUpdating and Assigned(ElementHandle) and Assigned(Container) then
  begin
    if (ElementClassname <> '') then
    begin
      ElementHandle.style.removeProperty('border');
      ElementHandle.style.removeProperty('background-color');
    end
    else
      ElementHandle.style.setProperty('white-space','normal');

    if Assigned(FHTML) then
    begin
      TextFound := FHTML.Text <> '';
      Container.innerHTML := FHTML.Text;
    end;

   if not IsLinked and (csDesigning in ComponentState) then
      RenderDesigning(ClassName, Container, Self, (not TextFound));

    case ScrollStyle of
      ssBoth: ElementHandle.style.setProperty('overflow','auto');
      ssNone: ElementHandle.style.setProperty('overflow','');
      ssVertical: ElementHandle.style.setProperty('overflow-y','auto');
      ssHorizontal: ElementHandle.style.setProperty('overflow-x','auto');
    end;
  end;
end;


{ THMTMLForm }

{$HINTS OFF}
procedure THTMLForm.BindEvents;
begin
  inherited;
  GetChildContainer.addEventListener('submit',@DoHandleSubmit);
end;

function  THTMLForm.CheckValidity: boolean;
var
  el: TJSHTMLElement;
  res: boolean;
begin
  el := ElementHandle;
  asm
    res = el.checkValidity();
  end;
  Result := res;
end;

function THTMLForm.GetChildContainer: TJSElement;
begin
  if (csDesigning in ComponentState) then
    Result := ElementHandle
  else
  begin
    if IsLinked then
      Result := ElementHandle
    else
      Result := TJSElement(ElementHandle.firstChild);
  end;
end;

{$HINTS ON}

function THTMLForm.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');
  Result.appendChild(document.createElement('FORM'));
end;

procedure THTMLForm.CreateInitialize;
begin
  inherited;
  EnablePropagation := true;
  ControlStyle := ControlStyle + [csAcceptsControls];
  if (csDesigning in ComponentState) then
  begin
    Width := 400;
    Height := 300;
  end;
end;

function THTMLForm.DoHandleSubmit(Event: TJSEvent): boolean;
begin
  ElementEvent := Event;
  if Assigned(OnSubmit) then
    OnSubmit(Self);
  Result := true;
end;

procedure THTMLForm.SetAction(const Value: string);
var
  el: TJSElement;
begin
  FAction := Value;

  if not (csDesigning in ComponentState) then
  begin
    if IsLinked then
      el := ElementHandle
    else
      el := GetChildContainer;

    if Assigned(el) then
      el['action'] := FAction;
  end;
end;

procedure THTMLForm.UpdateElement;
begin
  if not Assigned(ElementHandle) then
    Exit;

  inherited;

  if (csDesigning in ComponentState) then
    TJSHTMLElement(ElementHandle).style.setProperty('background','repeating-linear-gradient(45deg,#EEEEEE 10px,#EEEEEE 10px, #ffffff 20px)');
end;

{ TBadge }

function TBadge.CreateElement: TJSElement;
begin
  Result := document.createElement('SPAN');
end;

procedure TBadge.CreateInitialize;
var
  css: string;
begin
  inherited;
  Color := clRed;
  FTextColor := clWhite;
  FText := '1';

  css := 'span.tmsbadge {' +
  '  background: #FF0000;' +
  '  border-radius: 0.8em;' +
  '  -moz-border-radius: 0.8em;' +
  '  -webkit-border-radius: 0.8em;' +
  '  color: #ffffff;' +
  '  display: inline-block;' +
  '  line-height: 1.6em;' +
  '  margin-right: 5px;' +
  '  text-align: center;' +
  '  width: 1.6em;' +
  '}';

  AddControlStyle(css);

  if (csDesigning in ComponentState) then
  begin
    Width := 20;
    Height := 20;
  end;
end;

destructor TBadge.Destroy;
begin

  inherited;
end;

procedure TBadge.SetElementClassName(AValue: string);
begin
  inherited;
  UpdateElement;
end;

procedure TBadge.SetText(const Value: string);
begin
  FText := Value;
  UpdateElement;
end;

procedure TBadge.SetTextColor(const Value: TColor);
begin
  FTextColor := Value;
  UpdateElement;
end;

procedure TBadge.UpdateElement;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    ElementHandle.innerHTML := FText;

    if ElementClassName = '' then
    begin
      ElementHandle.setAttribute('class','tmsbadge');
      ElementHandle.style.setProperty('color',ColorToHTML(TextColor));
    end
    else
    begin
      ElementHandle.style.removeProperty('color');
    end;

    ElementHandle.style.removeProperty('width');
    ElementHandle.style.removeProperty('height');
  end;
end;

{ TAccordion }

procedure TAccordion.Collapse(ASection: TAccordionSection);
var
  el,pnl: TJSHTMLElement;
  LClass: string;
begin
  el := ASection.CaptionElement;

  LClass := 'accordionactive_'+Name;

  if el.classList.contains(LClass) then
    el.classList.remove(LClass);

  pnl := TJSHTMLElement(el.nextElementSibling);
  pnl.style.setProperty('max-height','0')
end;

function TAccordion.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');
end;

procedure TAccordion.CreateInitialize;
begin
  inherited;

  FSections := TAccordionSections.Create(Self);
  FSections.OnChange := SectionsChanged;
  FSections.PropName := 'Sections';
  FStyleRendered := false;

  if (csDesigning in ComponentState) then
  begin
    Width := 400;
    Height := 300;
  end;
end;

destructor TAccordion.Destroy;
begin
  FSections.Free;
  inherited;
end;

function TAccordion.DoAccordionClick(Event: TJSEvent): Boolean;
var
  el: TJSElement;
  pnl: TJSHTMLElement;
  s: string;
  Allow: boolean;
  ASection: TAccordionSection;
  i,e: integer;
begin
  asm
    el = Event.srcElement;
  end;

  Allow := true;
  ASection := nil;

  if el.hasAttribute('id') then
  begin
    s := el['id'];
    s := Copy(s, pos(Name,s) + Length(Name) + 1, Length(s));
    val(s,i,e);

    if (e = 0) and (i < Sections.Count) then
      ASection := Sections[i];
  end;

  if el.classlist.contains('accordionactive_'+Name) then
  begin
    if Assigned(OnCollapsing) then
      OnCollapsing(Self, ASection, Allow);
  end
  else
  begin
    if Assigned(OnExpanding) then
      OnExpanding(Self, ASection, Allow);
  end;

  if not Allow then
    Exit;

  el.classList.toggle('accordionactive_'+Name);

  pnl := TJSHTMLElement(el.nextElementSibling);

  s := pnl.style.getPropertyValue('max-height');

  if (s <> '0px') and (s <> '') then
  begin
    pnl.style.setProperty('max-height','0')
  end
  else
  begin
    pnl.style.setProperty('max-height', IntToStr(pnl.scrollHeight) + 'px');
  end;

  if el.classlist.contains('accordionactive_'+Name) then
  begin
    ASection.FExpanded := true;
    if Assigned(OnExpanded) then
      OnExpanded(Self, ASection);
  end
  else
  begin
    ASection.FExpanded := false;
    if Assigned(OnCollapsed) then
      OnCollapsed(Self, ASection);
  end;

  Result := true;
end;

procedure TAccordion.EndUpdate;
begin
  inherited;
  RenderStyle;
  RenderAccordion;
end;

procedure TAccordion.Expand(ASection: TAccordionSection);
var
  el,pnl: TJSHTMLElement;
  LClass: string;
begin
  el := ASection.CaptionElement;

  LClass := 'accordionactive_'+Name;

  if not el.classList.contains(LClass) then
    el.classList.add(LClass);

  pnl := TJSHTMLElement(el.nextElementSibling);
  pnl.style.setProperty('max-height', IntToStr(pnl.scrollHeight) + 'px');
end;

procedure TAccordion.RenderAccordion;
var
  i: integer;
  sp,btn,divel,p: TJSHTMLElement;
  LCaptionElementRec, LPanelElementRec: TJSHTMLElementRecord;

begin
  if ElementHandle.childNodes.length > 0 then
  begin
    while Assigned(ElementHandle.firstChild) do
      ElementHandle.removeChild(ElementHandle.firstChild);

    if ElementClassname <> '' then
    begin
      ElementHandle.style.removeProperty('border');
      ElementHandle.style.removeProperty('background-color');
    end;
  end;

  sp := TJSHTMLElement(document.createElement('SPAN'));
  ElementHandle.appendChild(sp);

  for i := 0 to FSections.Count - 1 do
  begin
    btn := TJSHTMLElement(document.createElement('BUTTON'));
    btn.innerHTML := FSections.Items[i].Caption;
    btn['id'] := Name + '_' + inttostr(i);
    btn.setAttribute('class','accordion_'+Name);
    if ElementSectionClassName <> '' then
      btn.classList.Add(ElementSectionClassName);

    btn.addEventListener('click',@DoAccordionClick);
    divel := TJSHTMLElement(document.createElement('DIV'));
    divel.setAttribute('class','accordionpanel_'+Name);
    p := TJSHTMLElement(document.createElement('P'));
    p.style.SetProperty('user-select','text');
    if ElementContentClassName <> '' then
      p.setAttribute('class',ElementContentClassName);

    p.innerHTML := FSections[i].Content;

    sp.appendChild(btn);
    sp.appendChild(divel);
    divel.appendChild(p);

    if Assigned(OnRenderSection) then
    begin
      LCaptionElementRec.element := btn;
      LPanelElementRec.element := p;
      OnRenderSection(Self, FSections.Items[i], LCaptionElementRec, LPanelElementRec);
    end;
  end;

  if not IsLinked and (csDesigning in ComponentState) then
    RenderDesigning(Classname, Container, Self, (FSections.Count = 0));
end;

procedure TAccordion.RenderStyle;
var
  css: string;
begin
  if FStyleRendered then
    Exit;

  FStyleRendered := true;

  css :=
  '.accordion_'+ Name +' {'+
  'background-color: #eee;'+
  'color: #444;'+
  'cursor: pointer;'+
  'padding: 18px;'+
  'width: 100%;'+
  'text-align: left;'+
  'border: none;' +
  'outline: none;' +
  'transition: 0.4s;'+
  '}' +#13#10 +

  '.accordionactive_'+Name+', .accordion_'+Name+'::hover {'+
  'background-color: #ccc;'+
  '}' +#13#10 +

  '.accordionactive_'+Name+'::before {'+
  'transform: rotate(90deg);'+
  '}' +#13#10 +

  '.accordion_'+Name+'::before {'+
  '  content: "\25B6";'+
  '  font-size: 13px;'+
//  '  color: #777;'+
  '  display: inline-block;'+
  '  margin-right: 5px;'+
  '}' +#13#10 +

  '.accordionpanel_'+Name+' {'+
  'padding: 0 18px;'+
//  'background-color: white;'+
  'max-height: 0;'+
  'overflow: hidden;'+
  'transition: max-height 0.2s ease-out;'+
  '}';

  AddInstanceStyle(css);
end;

procedure TAccordion.SectionsChanged(Sender: TObject);
begin
  if not IsUpdating then
  begin
    RenderStyle;
    RenderAccordion;
  end;
end;

procedure TAccordion.SetElementClassName(AValue: string);
begin
  inherited;
  if ElementClassname <> '' then
    RenderAccordion;
end;

procedure TAccordion.SetSections(const Value: TAccordionSections);
begin
  FSections.Assign(Value);
end;

procedure TAccordion.UpdateElement;
begin
  inherited;
end;

procedure TAccordion.UpdateElementVisual;
begin
  inherited;
  ElementHandle.style.setProperty('overflow-y','auto');
  ElementHandle.style.setProperty('overflow-x','hidden');
end;

{ TAccordionSections }

function TAccordionSections.Add: TAccordionSection;
begin
  Result := TAccordionSection(inherited Add);
end;

constructor TAccordionSections.Create(AOwner: TComponent);
begin
  inherited Create(AOwner, TAccordionSection);
end;

function TAccordionSections.GetItem(Index: integer): TAccordionSection;
begin
  Result := TAccordionSection(inherited Items[Index]);
end;

function TAccordionSections.Insert(Index: integer): TAccordionSection;
begin
  Result := TAccordionSection(inherited Insert(Index));
end;

procedure TAccordionSections.SetItem(Index: integer;
  const Value: TAccordionSection);
begin
  inherited Items[Index] := Value;
end;

procedure TAccordionSections.Update(Item: TCollectionItem);
begin
  inherited;

  if Assigned(OnChange) and (UpdateCount = 0) then
    OnChange(Self);
end;

{ TResponsiveGridPanel }

procedure TResponsiveGridPanel.AddControl(AControl: TWinControl);
begin
  FControlCollection.Add.Control := AControl;
  AControl.Parent := Self;
end;

procedure TResponsiveGridPanel.AlignControls(AControl: TControl;
  var Rect: TRect);
begin
  inherited;
  Resize;
end;

procedure TResponsiveGridPanel.BindEvents;
begin
  inherited;
  FResizePtr := @HandleResize;
  document.defaultView.addEventListener('resize', FResizePtr);
end;

constructor TResponsiveGridPanel.Create(AOwner: TComponent);
begin
  inherited;
  FResizePtr := nil;
end;

function TResponsiveGridPanel.CreateElement: TJSElement;
begin
  if (csDesigning in ComponentState) and (ControlCollection.Count = 0) then
  begin
    Result := document.createElement('DIV');
    FLabel := TJSHTMLElement(document.createElement('DIV'));
    FLabel.innerHTML := ClassName;
    BorderStyle := bsSingle;
    FLabel['align'] := 'center';
    FLabel.style.setProperty('border','1px solid silver');
    FLabel.style.setProperty('vertical-align','middle');
    FLabel.style.setProperty('display','table-cell');
    Result.appendChild(FLabel);
  end
  else
  begin
    Result := document.createElement('DIV');
  end;
end;

procedure TResponsiveGridPanel.CreateInitialize;
var
  li: TResponsiveLayoutItem;
begin
  inherited;
  FDesignTime := (csDesigning in ComponentState) and not
    ((csReading in Owner.ComponentState) or (csLoading in Owner.ComponentState));

  EnablePropagation := true;
  ControlStyle := ControlStyle + [csAcceptsControls];
  FControlCollection := TControlCollection.Create(Self);
  FControlCollection.PropName := 'ControlCollection';
  if (csDesigning in ComponentState) then
  begin
    Width := 400;
    Height := 300;
  end;

  FLabel := nil;
  FActiveLayoutItem := nil;
  FLayout := TResponsiveLayout.Create(Self);
  FLayout.OnChange := LayoutChanged;
  FLayout.PropName := 'Layout';

  if FDesignTime then
  begin
    li := FLayout.Add;
    li.Description := 'Smartphone';
    li.Width := 575;
    li.Style := '1fr';

    li := FLayout.Add;
    li.Description := 'Tablet';
    li.Width := 768;
    li.Style := '1fr 1fr';

    li := FLayout.Add;
    li.Description := 'Desktop';
    li.Width := 991;
    li.Style := '1fr 1fr 1fr';

    li := FLayout.Add;
    li.Description := 'Large Desktop';
    li.Width := 1199;
    li.Style := '1fr 1fr 1fr 1fr';

  end;
end;

destructor TResponsiveGridPanel.Destroy;
begin
  FControlCollection.Free;
  FLayout.Free;
  inherited;
end;

procedure TResponsiveGridPanel.EndUpdate;
begin
  inherited;
  UpdateControls;
end;

procedure TResponsiveGridPanel.GetChildren(Proc: TGetChildProc;
  Root: TComponent);
//var
//  i: integer;
begin
  inherited;
//  for i := 0 to ControlCollection.Count - 1 do
//  begin
//    if Assigned(ControlCollection.Items[i].Control) then
//      Proc(ControlCollection.Items[i].Control);
//  end;
end;

function TResponsiveGridPanel.GridElementCount: integer;
var
  i: integer;
  el: TJSHTMLElement;
begin
  Result := 0;

  for i := 0 to ElementHandle.childElementCount - 1 do
  begin
    el := TJSHTMLElement(ElementHandle.children[i]);
    if (el['data'] = 'grid') then
      inc(Result);
  end;
end;

function TResponsiveGridPanel.HandleResize(Event: TEventListenerEvent): boolean;
var
  w: integer;
begin
  if (csDestroying in ComponentState) then
    Exit;

  Result := true;
  w := Width;
  if FOldWidth <> w then
  begin
    FOldWidth  := w;
    Resize;
  end;
end;

procedure TResponsiveGridPanel.LayoutChanged(Sender: TObject);
begin
  inherited;

  if IsUpdating or (csLoading in ComponentState) then
    Exit;

  UpdateControls;
end;

procedure TResponsiveGridPanel.Loaded;
begin
  inherited;
  UpdateControls;
end;

procedure TResponsiveGridPanel.Notification(AComponent: TComponent;
  Operation: TOperation);
var
  i: integer;
  upd: boolean;
  el: TJSHTMLElement;

begin
  upd := false;

  if (Operation = opRemove) and not (csDestroying in ComponentState) then
  begin
    for i := ControlCollection.Count - 1 downto 0 do
    begin
      if (ControlCollection.Items[i].Control = AComponent) then
      begin
        el := TJSHTMLElement(ElementHandle.children[i]);
        while el.childElementCount > 0 do
          el.removeChild(el.firstChild);

        ControlCollection.Items[i].Control := nil;
        ControlCollection.Delete(i);
        upd := true;
      end;
    end;
  end;

  inherited;

  if upd then
    UpdateControls;
end;

procedure TResponsiveGridPanel.RegisterParent(AValue: TControl);
begin
  inherited;

  if not IsUpdating and not (csLoading in ComponentState) then
  begin
    ControlCollection.Add.Control := TWinControl(AValue);
    UpdateControls;
  end;

end;

procedure TResponsiveGridPanel.RemoveControl(AControl: TWinControl);
var
  i: integer;

begin
  for i := 0 to FControlCollection.Count - 1 do
  begin
    if FControlCollection.Items[i].Control =  AControl then
    begin
      FControlCollection.Delete(i);
      break;
    end;
  end;
end;

procedure TResponsiveGridPanel.Resize;
begin
  inherited;
  SetResponsiveStyle;
end;

procedure TResponsiveGridPanel.SetControlCollection(
  const Value: TControlCollection);
begin
  FControlCollection.Assign(Value);
end;

procedure TResponsiveGridPanel.SetResponsiveStyle;
var
  li: TResponsiveLayoutItem;
  w: integer;
  lGap: string;
begin
  if not Assigned(Layout) then
    Exit;

  if (csDestroying in ComponentState) then
    Exit;

  if not Assigned(ElementHandle) then
    Exit;

  w := Width;

  li := Layout.GetLayoutForWidth(w);

  if Assigned(li) then
  begin
    if li.StyleType = gTemplateColumns then
    begin
      ElementHandle.style.setProperty('grid-template-columns', li.Style);
      ElementHandle.style.removeProperty('grid-template-rows');
    end
    else
    begin
      ElementHandle.style.removeProperty('grid-template-columns');
      ElementHandle.style.setProperty('grid-template-rows', li.Style);
    end;

    lGap := li.RowGap;
    if lGap.IsNumber then
      lGap := lGap + 'px';

    ElementHandle.style.setProperty('grid-row-gap', lGap);

    lGap := li.ColumnGap;
    if lGap.IsNumber then
      lGap := lGap + 'px';

    ElementHandle.style.setProperty('grid-column-gap', lGap);

    ElementHandle.style.setProperty('padding-left', inttostr(li.Margins.Left)+'px');
    ElementHandle.style.setProperty('padding-top', inttostr(li.Margins.Top)+'px');
    ElementHandle.style.setProperty('padding-right', inttostr(li.Margins.Right)+'px');
    ElementHandle.style.setProperty('padding-bottom', inttostr(li.Margins.Bottom)+'px');
  end;

  if li <> FActiveLayoutItem then
  begin
    FActiveLayoutItem := li;
    if Assigned(OnLayoutChange) then
      OnLayoutChange(Self, FActiveLayoutItem);
  end;
end;

procedure TResponsiveGridPanel.UnbindEvents;
begin
  inherited;
  if Assigned(FResizePtr) then
  begin
    document.defaultView.removeEventListener('resize', FResizePtr);
    FResizePtr := nil;
  end;
end;

procedure TResponsiveGridPanel.UpdateControls;
var
  i,j,k: integer;
  el,sp: TJSHTMLElement;
  fragment: TJSDocumentFragment;
  control: TControl;
begin
  if (csLoading in ComponentState) then
    Exit;

  if IsUpdating then
    Exit;

  if not Assigned(ElementHandle) then
    Exit;

  if (ControlCollection.Count > 0) and Assigned(FLabel) then
  begin
    ElementHandle.removeChild(FLabel);
    FLabel := nil;
    ElementHandle.style.setProperty('display','grid');
  end;

  if (csDesigning in ComponentState) then
  begin
    for i := ElementHandle.childElementCount - 1 downto 0 do
    begin
      if TJSElement(ElementHandle.children[i]).tagName = 'SPAN' then
        ElementHandle.removeChild(ElementHandle.children[i]);
    end;

    for i := 0 to Layout.Count - 1 do
    begin
      sp := TJSHTMLElement(document.createElement('SPAN'));
      sp.style.setProperty('position','absolute');
      sp.style.setProperty('width','4px');
      sp.style.setProperty('height','12px');
      sp.style.setProperty('background-color','red');
      sp.style.setProperty('top','0px');
      sp.style.setProperty('left', IntToStr(Layout.Items[i].Width)+'px');
      ElementHandle.appendChild(sp);
    end;
  end;

  for i := 0 to ControlCollection.Count - 1 do
  begin
    if GridElementCount <= i then
    begin
      el := TJSHTMLElement(document.createElement('DIV'));
      el['data'] := 'grid';

      if (csDesigning in ComponentState) then
        el.style.setProperty('border','1px silver dotted');

      ElementHandle.appendChild(el);
    end
    else
    begin
      k := 0;
      for j := 0 to ElementHandle.ChildElementCount - 1 do
      begin
        el := TJSHTMLElement(ElementHandle.children[j]);
        if (el['data'] = 'grid') then
        begin
          if (k = i) then
            break
          else
            inc(k);
        end;
      end;
    end;

    fragment := document.createDocumentFragment();
    control := ControlCollection.Items[i].Control;
    if Assigned(control) then
    begin
      control.ElementPosition := epRelative;
      control.ChildOrder := -1;
      if Assigned(control.ElementHandle) then
      begin
        fragment.appendChild(control.ElementHandle);
        el.appendChild(fragment);
      end;
    end;
  end;

  UpdateElement;
end;

procedure TResponsiveGridPanel.UpdateElement;
begin
  inherited;

  if (csDestroying in ComponentState) then
    Exit;

  if not Assigned(ElementHandle) then
    Exit;

  if (csDesigning in ComponentState) then
  begin
    if (ControlCollection.Count = 0) then
    begin
      TJSHTMLElement(ElementHandle).style.setProperty('display','table');
      if (Color = clNone) then
        TJSHTMLElement(ElementHandle).style.setProperty('background-color','silver');
    end;
  end;

  if (csDesigning in ComponentState) then
  begin
    ElementHandle.style.setProperty('border','1px silver dotted');
  end;

  ElementHandle.style.setProperty('display','grid');
  SetResponsiveStyle;
end;

{ TLayout }

function TResponsiveLayout.Add: TResponsiveLayoutItem;
begin
  Result := TResponsiveLayoutItem(inherited Add);
end;

function TResponsiveLayout.Add(AWidth: integer;
  AStyle: string): TResponsiveLayoutItem;
begin
  Result := Add;
  Result.Width := AWidth;
  Result.Style := AStyle;
end;

constructor TResponsiveLayout.Create(AOwner: TComponent);
begin
  inherited Create(AOwner, TResponsiveLayoutItem);
end;

function TResponsiveLayout.GetItem(Index: integer): TResponsiveLayoutItem;
begin
  Result := TResponsiveLayoutItem(inherited Items[Index]);
end;

function TResponsiveLayout.GetLayoutForWidth(w: integer): TResponsiveLayoutItem;
var
  i,d,j,l,mx: integer;

begin
  Result := nil;
  if Count = 0 then
    Exit;

  d := $FFFF;
  j := -1;
  mx := 0;

  for i := 0 to Count - 1 do
  begin
    if Items[i].Width > mx then
    begin
      mx := Items[i].Width;
      l := i;
    end;

    if w < Items[i].Width then
    begin
      if Items[i].Width - w < d then
      begin
        d := Items[i].Width - w;
        j := i;
      end;
    end;
  end;

  if j = -1 then
    j := l;

  Result := Items[j];
end;

function TResponsiveLayout.Insert(Index: integer): TResponsiveLayoutItem;
begin
  Result := TResponsiveLayoutItem(inherited Insert(Index));
end;

procedure TResponsiveLayout.SetItem(Index: integer; const Value: TResponsiveLayoutItem);
begin
  inherited Items[Index] := Value;
end;

procedure TResponsiveLayout.Update(Item: TCollectionItem);
begin
  inherited;
  if Assigned(OnChange) then
    OnChange(Self);
end;

{ TResponsiveLayoutItem }

procedure TResponsiveLayoutItem.Assign(Source: TPersistent);
begin
  if (Source is TResponsiveLayoutItem) then
  begin
    FColumnGap := (Source as TResponsiveLayoutItem).ColumnGap;
    FDescription := (Source as TResponsiveLayoutItem).Description;
    FRowGap := (Source as TResponsiveLayoutItem).RowGap;
    FWidth := (Source as TResponsiveLayoutItem).Width;
    FStyle := (Source as TResponsiveLayoutItem).Style;
    FStyleType := (Source as TResponsiveLayoutItem).StyleType;
    FTag := (Source as TResponsiveLayoutItem).Tag;
    FMargins.Assign((Source as TResponsiveLayoutItem).Margins);
  end;
end;

constructor TResponsiveLayoutItem.Create(AOwner: TCollection);
begin
  FMargins := TMargins.Create;
  FMargins.Left := 0;
  FMargins.Top := 0;
  FMargins.Right := 0;
  FMargins.Bottom := 0;

  inherited;

end;

destructor TResponsiveLayoutItem.Destroy;
begin
  FMargins.Free;
  inherited;
end;

procedure TResponsiveLayoutItem.SetColumnGap(const Value: string);
begin
  if (FColumnGap <> Value) then
  begin
    FColumnGap := Value;
    TResponsiveLayout(Collection).Update(Self);
  end;
end;

procedure TResponsiveLayoutItem.SetMargins(const Value: TMargins);
begin
  FMargins.Assign(Value);
  TResponsiveLayout(Collection).Update(Self);
end;

procedure TResponsiveLayoutItem.SetRowGap(const Value: string);
begin
  if (FRowGap <> Value) then
  begin
    FRowGap := Value;
    TResponsiveLayout(Collection).Update(Self);
  end;
end;

procedure TResponsiveLayoutItem.SetStyle(const Value: string);
begin
  if (FStyle <> Value) then
  begin
    FStyle := Value;
    TResponsiveLayout(Collection).Update(Self);
  end;
end;

procedure TResponsiveLayoutItem.SetStyleType(const Value: TGridStyle);
begin
  if (FStyleType <> Value) then
  begin
    FStyleType := Value;
    TResponsiveLayout(Collection).Update(Self);
  end;
end;

procedure TResponsiveLayoutItem.SetWidth(const Value: integer);
begin
  if (FWidth <> Value) then
  begin
    FWidth := Value;
    TResponsiveLayout(Collection).Update(Self);
  end;
end;

{ TAccordionSection }

procedure TAccordionSection.Assign(Source: TPersistent);
begin
  if (Source is TAccordionSection) then
  begin
    FCaption := (Source as TAccordionSection).Caption;
    FContent := (Source as TAccordionSection).Content;
    FTag := (Source as TAccordionSection).Tag;
  end;
end;

function TAccordionSection.CaptionElement: TJSHTMLElement;
begin
  Result := TJSHTMLElement(document.getElementById(((Collection as TAccordionSections).Owner as TAccordion).Name+'_'+inttostr(Index)));
end;

function TAccordionSection.PanelElement: TJSHTMLElement;
var
  el: TJSHTMLElement;
begin
  el := CaptionElement;
  Result := TJSHTMLElement(el.nextElementSibling);
end;

procedure TAccordionSection.SetCaption(const Value: string);
begin
  FCaption := Value;
  (Collection as TAccordionSections).Update(Self);
end;

procedure TAccordionSection.SetContent(const Value: string);
begin
  FContent := Value;
  (Collection as TAccordionSections).Update(Self);
end;

procedure TAccordionSection.SetExpanded(const Value: boolean);
begin
  if (FExpanded <> Value) then
  begin
    FExpanded := Value;

    if FExpanded then
      ((Collection as TAccordionSections).Owner as TAccordion).Expand(Self)
    else
      ((Collection as TAccordionSections).Owner as TAccordion).Collapse(Self);
  end;
end;

{ TImageZoomControl }

function TImageZoomControl.CreateElement: TJSElement;
begin
  Result := inherited;

  FStyle := document.createElement('STYLE');
  FOverlay := document.createElement('DIV');
  document.body.appendChild(FStyle);
  document.body.appendChild(FOverlay);
end;

procedure TImageZoomControl.CreateInitialize;
begin
  inherited;
  FAppearance := TImageZoomAppearance.Create(Self);
  FPictureZoom := TURLPicture.Create;
  FPictureZoom.OnChange := PictureZoomChanged;
  FPictureZoom.OnDataChange := PictureZoomDataChanged;
  FPictureZoomDataURL := '';
  FZoomBkgColor := clBlack;
  FZoomBkgOpacity := 0.9;

  if (csDesigning in ComponentState) then
  begin
    Width := 100;
    Height := 75;
  end;
end;

destructor TImageZoomControl.Destroy;
begin
  FAppearance.Free;
  FPictureZoom.Free;
  inherited;
end;

procedure TImageZoomControl.PictureZoomChanged(Sender: TObject);
begin
  SetURLZoom(PictureZoom.FileName);
  FPictureZoomDataURL := '';
  UpdateElement;
end;

procedure TImageZoomControl.PictureZoomDataChanged(Sender: TObject);
begin
  FPictureZoomDataURL := 'data:image/png;base64,' + HexImageDecodeAsBase64(PictureZoom.Data);
  UpdateElement;
end;

procedure TImageZoomControl.SetPictureZoom(const Value: TURLPicture);
begin
  FPictureZoom.Assign(Value);
end;

procedure TImageZoomControl.SetURLZoom(const Value: string);
begin
  if FURLZoom <> Value then
  begin
    FURLZoom := Value;
    UpdateElement;
  end;
end;

procedure TImageZoomControl.UpdateElement;
var
  PopupImage: string;
  Popup: TJSElement;
  r,g,b: byte;
  l: longint;
  op: string;
begin
  inherited;

  if not Assigned(ElementHandle) then
    Exit;

  if Assigned(FOverlay) then
  begin
    ElementHandle.setAttribute('onClick', 'document.getElementById("' + Name + 'myModal").style.display = "block"');

    if FPictureZoomDataURL <> '' then
      PopupImage := FPictureZoomDataURL
    else
      PopupImage := URLZoom;

    if PopupImage = '' then
      PopupImage := URL;

    op := floattostr(ZoomBkgOpacity);
    stringreplace(op,',','.',[rfReplaceAll]);
    l := ColorToRGB(ZoomBkgColor);
    r := GetRValue(l);
    g := GetGValue(l);
    b := GetBValue(l);

    FStyle.innerHTML := '.' + Name + 'modal-overlay {'#13
    + '  display: none;'#13
    + '  position: fixed;'#13
    + '  z-index: 99999999;'#13
    + '  width: 100%;'#13
    + '  height: 100%;'#13
    + '  overflow: hidden;'#13
    + '  top: 50%;'#13
    + '  left: 50%;'#13
    + '  -ms-transform: translate(-50%, -50%);'#13
    + '  transform: translate(-50%, -50%);'#13
    + '  background-color: rgba('+r.ToString+','+g.ToString+','+b.ToString+',0.2);'#13
    + '}'#13

    + '.' + Name + 'modal {'#13
    + '  display: block;'#13
    + '  position: absolute;'#13
    + '  z-index: 99999999;'#13
    + '  width: ' + IntToStr(Appearance.WidthPercent) + '%;'#13
    + '  height: ' + IntToStr(Appearance.HeightPercent) + '%;'#13
    + '  overflow: hidden;'#13
    + '  top: 50%;'#13
    + '  left: 50%;'#13
    + '  -ms-transform: translate(-50%, -50%);'#13
    + '  transform: translate(-50%, -50%);'#13
    + '  background-color: rgba('+r.ToString+','+g.ToString+','+b.ToString+','+op+');'#13
    + '}'#13

    + '.' + Name + 'modal-content {'#13
    + '  display: block;'#13
    + '  margin: 0;'#13
    + '  position: absolute;'#13
    + '  top: 50%;'#13
    + '  left: 50%;'#13
    + '  -ms-transform: translate(-50%, -50%);'#13
    + '  transform: translate(-50%, -50%);'#13
    + '  max-width: 100%;'#13
    + '  max-height: 100%;'#13
    + '}'#13

    + '.' + Name + 'close {'#13
    + '  z-index: 99999999;'#13
    + '  position: absolute;'#13
    + '  top: 15px;'#13
    + '  right: 35px;'#13
    + '  color: #f1f1f1;'#13
    + '  font-size: 40px;'#13
    + '  font-weight: bold;'#13
    + '  transition: 0.3s;'#13
    + '}'#13

    + '.' + Name + 'close:hover,'#13
    + '.' + Name + 'close:focus {'#13
    + '  color: #bbb;'#13
    + '  text-decoration: none;'#13
    + '  cursor: pointer;'#13
    + '}'#13

    + '@media only screen and (max-width: 769px) {'#13
    + '	.' + Name + 'modal {'#13
    + '  width: ' + IntToStr(Appearance.ResponsiveWidthPercent) + '%;'#13
    + '  height: ' + IntToStr(Appearance.ResponsiveHeightPercent) + '%;'#13
    + '	}'#13
    + '}'#13;

    FOverlay.setAttribute('onClick', 'this.style.display = "none";');
    FOverlay.setAttribute('id', Name + 'myModal');
    FOverlay.setAttribute('class', Name + 'modal-overlay');

    while Assigned(FOverlay.firstChild) do
      FOverlay.removeChild(FOverlay.firstChild);

    Popup := document.createElement('DIV');
    Popup.setAttribute('class', Name + 'modal');
    Popup.innerHTML := '<span class="' + Name + 'close">&times;</span>'
    + '<img class="' + Name + 'modal-content" src="' + PopupImage + '">';

    FOverlay.innerHTML := '';
    FOverlay.AppendChild(Popup);
  end;
end;

{ TImageZoomAppearance }

procedure TImageZoomAppearance.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TImageZoomAppearance) then
  begin
    FHeightPercent := (Source as TImageZoomAppearance).HeightPercent;
    FWidthPercent := (Source as TImageZoomAppearance).WidthPercent;
    FResponsiveHeightPercent := (Source as TImageZoomAppearance).ResponsiveHeightPercent;
    FResponsiveWidthPercent := (Source as TImageZoomAppearance).ResponsiveWidthPercent;
    FResponsiveMaxWidth := (Source as TImageZoomAppearance).ResponsiveMaxWidth;
  end;
end;

constructor TImageZoomAppearance.Create(AOwner: TImageZoomControl);
begin
  FHeightPercent := 100;
  FWidthPercent := 100;
  FResponsiveHeightPercent := 100;
  FResponsiveWidthPercent := 100;
  FResponsiveMaxWidth := 768;
  FOwner := AOwner;
end;

destructor TImageZoomAppearance.Destroy;
begin
  inherited;
end;

function TImageZoomAppearance.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TImageZoomAppearance.SetHeightPercent(const Value: Integer);
begin
  if (FHeightPercent <> Value) and (FHeightPercent <= 100) then
  begin
    FHeightPercent := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TImageZoomAppearance.SetResponsiveHeightPercent(const Value: Integer);
begin
  if (FResponsiveHeightPercent <> Value) and (FResponsiveHeightPercent <= 100) then
  begin
    FResponsiveHeightPercent := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TImageZoomAppearance.SetResponsiveMaxWidth(const Value: Integer);
begin
  if (FResponsiveMaxWidth <> Value) and (FResponsiveMaxWidth >= 0) then
  begin
    FResponsiveMaxWidth := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TImageZoomAppearance.SetResponsiveWidthPercent(const Value: Integer);
begin
  if (FResponsiveWidthPercent <> Value) and (FResponsiveWidthPercent <= 100) then
  begin
    FResponsiveWidthPercent := Value;
    FOwner.UpdateElement;
  end;
end;

procedure TImageZoomAppearance.SetWidthPercent(const Value: Integer);
begin
  if (FWidthPercent <> Value) and (FWidthPercent <= 100) then
  begin
    FWidthPercent := Value;
    FOwner.UpdateElement;
  end;
end;

{ TCustomLookupComboBox }

procedure TCustomLookupComboBox.CreateInitialize;
begin
  inherited;
  FLookupValues := TLookupValues.Create(Self);
  FLookupValues.PropName := 'LookupValues';
  FLookupValues.OnChange := ValuesChanged;
end;

destructor TCustomLookupComboBox.Destroy;
begin
  FLookupValues.Free;
  inherited;
end;

procedure TCustomLookupComboBox.DoUpdateList;
var
  i: integer;
  opt: TJSElement;
begin
  if not Assigned(Container) then
    Exit;

  for i := TJSHTMLSelectElement(Container).options.length - 1 downto 0 do
    TJSHTMLSelectElement(Container).remove(i);

  AddTextHint;

  for i := 0 to FLookupValues.Count - 1 do
  begin
    opt := document.createElement('OPTION');
    opt['value'] := TLookupValueItem(FLookupValues.Items[i]).Value;
    opt.innerHTML := TLookupValueItem(FLookupValues.Items[i]).DisplayText;
    Container.appendChild(opt);
  end;

  UpdateElement;
end;

function TCustomLookupComboBox.GetDisplayText: string;
begin
  Result := '';
  if ItemIndex >= 0 then
    Result := FLookupValues[ItemIndex].DisplayText;
end;

function TCustomLookupComboBox.GetValue: string;
begin
  Result := '';
  if ItemIndex >= 0 then
    Result := FLookupValues[ItemIndex].Value;
end;

procedure TCustomLookupComboBox.SetDisplayText(const Value: string);
var
  i,idx: integer;
begin
  idx := -1;
  for i := 0 to FLookupValues.Count - 1 do
  begin
    if FLookupValues[i].DisplayText = Value then
    begin
      idx := i;
      break;
    end;
  end;

  ItemIndex := idx;
end;

procedure TCustomLookupComboBox.SetLookupValues(const Value: TLookupValues);
begin
  FLookupValues.Assign(Value);
end;

procedure TCustomLookupComboBox.SetValue(const Value: string);
var
  i,idx: integer;
begin
  idx := -1;

  for i := 0 to FLookupValues.Count - 1 do
  begin
    if FLookupValues[i].Value = Value then
    begin
      idx := i;
      break;
    end;
  end;

  ItemIndex := idx;
end;

procedure TCustomLookupComboBox.ValuesChanged(Sender: TObject);
begin
  DoUpdateList;
end;

{ TLookupValues }

function TLookupValues.Add: TLookupValueItem;
begin
  Result := TLookupValueItem(inherited Add);
end;

procedure TLookupValues.AddPair(AValue, ADisplayText: string);
var
  lv: TLookupValueItem;
begin
  lv := TLookupValueItem(inherited Add);
  lv.Value := AValue;
  lv.DisplayText := ADisplayText;
  if (UpdateCount = 0) then
     DoChange;
end;

constructor TLookupValues.Create(AOwner: TComponent);
begin
  inherited Create(AOwner, TLookupValueItem);
end;

procedure TLookupValues.DoChange;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

function TLookupValues.GetItem(Index: integer): TLookupValueItem;
begin
  Result := TLookupValueItem(inherited Items[Index]);
end;

function TLookupValues.Insert(Index: integer): TLookupValueItem;
begin
  Result := TLookupValueItem(inherited Insert(Index));
end;

procedure TLookupValues.SetItem(Index: integer; const Value: TLookupValueItem);
begin
  inherited Items[Index] := Value;
end;

procedure TLookupValues.Update(Item: TCollectionItem);
begin
  inherited;
  if Item = nil then
    DoChange;
end;

end.

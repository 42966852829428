unit PROJECT;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, Vcl.Controls,
  VCL.TMSFNCCustomControl, VCL.TMSFNCPanel, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Imaging.jpeg, WEBLib.ExtCtrls, VCL.TMSFNCToolBar, WEBLib.REST;

type
  TForm4 = class(TForm)
    TMSFNCPanel1: TTMSFNCPanel;
    TMSFNCToolBarButton1: TTMSFNCToolBarButton;
    WebReq1: THttpRequest;
    WebEdit1: TEdit;
    WebEdit2: TEdit;
    WebEdit3: TEdit;
    WebEdit4: TEdit;
    WebEdit5: TEdit;
    WebEdit6: TEdit;
    WebEdit8: TEdit;
    WebEdit9: TEdit;
    WebEdit10: TEdit;
    WebEdit11: TEdit;
    WebEdit12: TEdit;
    WebImageControl1: TImageControl;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    WebLabel7: TLabel;
    WebLabel8: TLabel;
    WebLabel9: TLabel;
    WebLabel10: TLabel;
    WebLabel11: TLabel;
    WebLabel12: TLabel;
    WebReq2: THttpRequest;
    WebReq3: THttpRequest;
    WebTimer1: TTimer;
    WebEdit7: TEdit;
    WebLabel13: TLabel;
    WebMemo1: TMemo;
    TMSFNCToolBarButton2: TTMSFNCToolBarButton;
    WebLabel14: TLabel;
    procedure VISA_MEDDELANDE(info: String);
    procedure FIL_NUMMER_1;
    procedure FIL_NUMMER_2;
    procedure MAIL_WEB_PHP;
    procedure WebFormCreate(Sender: TObject);
    procedure SEND_PROJECT(Sender: TObject);
    procedure WebReq1RequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
    procedure WebReq2RequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
    procedure TANGENTER(Sender: TObject; var Key: Char);
    procedure KEYTEST(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure AVSLUTA(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form4: TForm4;

implementation

Uses FRONT, MEDDELANDE;

{$R *.dfm}

procedure TForm4.VISA_MEDDELANDE(info: String);
  Var newform: TForm;

  procedure AfterShowModal(AValue: TModalResult);
    Begin
      Close;
    End;

  procedure AfterCreate(AForm: TObject);
    Begin
      (AForm as TForm3).Top:=Form1.Height Div 2 + 200;
      (AForm as TForm3).WebPanel1.Caption:=info;
    End;

  Begin
    newform := TForm3.CreateNew(@AfterCreate);
    newform.Popup := True;
    newform.ShowModal(@AfterShowModal);
  End;

procedure TForm4.FIL_NUMMER_1;
  Var mfran,mfrna,mtila,mtnam,mrubr,remsa,wp,path: String;
  Begin
    mfran:='info@connectsystem.se';          // från adress
    mfrna:=Trim(WebEdit1.Text);              // från namn
    mtila:=Trim('info@connectsystem.se');    // till adress
    mtnam:='Connectsystem';                  // till namn
    mrubr:='Uppgifter till Oracle';          // mailrubrik

    remsa:=mfran+';';
    remsa:=remsa+mfrna+';';
    remsa:=remsa+mtila+';';
    remsa:=remsa+mtnam+';';
    remsa:=remsa+mrubr+';';

    wp:='https://www.connectsystem.se/';

    path:=wp+'dsidinfo.php?fil=fil1.txt'+'&txt='+remsa;

    WebReq1.URL:=path;
    WebReq1.Execute;
  End;

procedure TForm4.FIL_NUMMER_2;
  Var
    i: Integer;
    inf,wp,path: String;
  Begin
    WebMemo1.Lines.Clear;

    WebMemo1.Lines.Add(Trim(WebEdit1.Text));
    WebMemo1.Lines.Add(Trim(WebEdit2.Text));
    WebMemo1.Lines.Add(Trim(WebEdit3.Text));
    WebMemo1.Lines.Add(Trim(WebEdit4.Text));
    WebMemo1.Lines.Add(Trim(WebEdit5.Text));
    WebMemo1.Lines.Add(Trim(WebEdit6.Text));
    WebMemo1.Lines.Add(Trim(WebEdit7.Text));
    WebMemo1.Lines.Add(Trim(WebEdit8.Text));
    WebMemo1.Lines.Add(Trim(WebEdit9.Text));
    WebMemo1.Lines.Add(Trim(WebEdit10.Text));
    WebMemo1.Lines.Add(Trim(WebEdit11.Text));
    WebMemo1.Lines.Add(Trim(WebEdit12.Text));

    inf:='';
    For i:=0 To WebMemo1.Lines.Count-1 Do
      inf:=inf+Trim(WebMemo1.Lines[i])+'|';

    wp:='https://www.connectsystem.se/';

    path:=wp+'dsidinfo.php?fil=fil2.txt'+'&txt='+inf;

    WebReq2.URL:=path;
    WebReq2.Execute;
  End;

procedure TForm4.MAIL_WEB_PHP;
  Var path: String;
  Begin
    path:='https://www.connectsystem.se/mail/skicka.php';

    WebReq3.URL:=path;
    WebReq3.Execute;
  End;

procedure TForm4.WebFormCreate(Sender: TObject);
  Begin
    WebEdit1.SetFocus;

    WebTimer1.Interval:=60000;
    WebTimer1.Enabled:=True;
  End;

procedure TForm4.SEND_PROJECT(Sender: TObject);
  Begin
    WebTimer1.Enabled:=False;

    FIL_NUMMER_1;
  End;

procedure TForm4.WebReq1RequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
  Begin
    FIL_NUMMER_2;
  End;

procedure TForm4.WebReq2RequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
  Begin
    MAIL_WEB_PHP;

    VISA_MEDDELANDE('Mail är sänt till Oracle');
  End;

procedure TForm4.TANGENTER(Sender: TObject; var Key: Char);
  Var editnr: Integer;
  Begin
    IF Ord(Key)=13 Then
      Begin
        Key:=#0;

        editnr:=(Sender as TEdit).Tag;

        Case editnr Of
          1: WebEdit2.SetFocus;
          2: WebEdit3.SetFocus;
          3: WebEdit4.SetFocus;
          4: WebEdit5.SetFocus;
          5: WebEdit6.SetFocus;
          6: WebEdit7.SetFocus;
          7: WebEdit8.SetFocus;
          8: WebEdit9.SetFocus;
          9: WebEdit10.SetFocus;
         10: WebEdit11.SetFocus;
         11: WebEdit12.SetFocus;
        End;
      End;
  End;

procedure TForm4.KEYTEST(Sender: TObject; var Key: Word; Shift: TShiftState);
  Var editnr: Integer;
  Begin
    WebTimer1.Interval:=60000;

    If Key=27 Then
      Begin
        WebTimer1.Enabled:=False;
        CLose;
      End;

    If Key=40 Then            // Key down
      Begin
        editnr:=(Sender as TEdit).Tag;

        Case editnr Of
          1: WebEdit2.SetFocus;
          2: WebEdit3.SetFocus;
          3: WebEdit4.SetFocus;
          4: WebEdit5.SetFocus;
          5: WebEdit6.SetFocus;
          6: WebEdit7.SetFocus;
          7: WebEdit8.SetFocus;
          8: WebEdit9.SetFocus;
          9: WebEdit10.SetFocus;
         10: WebEdit11.SetFocus;
         11: WebEdit12.SetFocus;
        End;
      End;

    If Key=38 Then            // Key up
      Begin
        editnr:=(Sender as TEdit).Tag;

        Case editnr Of
          2: WebEdit1.SetFocus;
          3: WebEdit2.SetFocus;
          4: WebEdit3.SetFocus;
          5: WebEdit4.SetFocus;
          6: WebEdit5.SetFocus;
          7: WebEdit6.SetFocus;
          8: WebEdit7.SetFocus;
          9: WebEdit8.SetFocus;
         10: WebEdit9.SetFocus;
         11: WebEdit10.SetFocus;
         12: WebEdit11.SetFocus;
        End;
      End;
  End;

procedure TForm4.AVSLUTA(Sender: TObject);
  Begin
    Close;
  End;

procedure TForm4.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebImageControl1 := TImageControl.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebLabel12 := TLabel.Create(Self);
  WebLabel13 := TLabel.Create(Self);
  TMSFNCPanel1 := TTMSFNCPanel.Create(Self);
  WebLabel14 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebEdit3 := TEdit.Create(Self);
  WebEdit4 := TEdit.Create(Self);
  WebEdit5 := TEdit.Create(Self);
  WebEdit6 := TEdit.Create(Self);
  WebEdit8 := TEdit.Create(Self);
  WebEdit9 := TEdit.Create(Self);
  WebEdit10 := TEdit.Create(Self);
  WebEdit11 := TEdit.Create(Self);
  WebEdit12 := TEdit.Create(Self);
  TMSFNCToolBarButton1 := TTMSFNCToolBarButton.Create(Self);
  WebEdit7 := TEdit.Create(Self);
  WebMemo1 := TMemo.Create(Self);
  TMSFNCToolBarButton2 := TTMSFNCToolBarButton.Create(Self);
  WebReq1 := THttpRequest.Create(Self);
  WebReq2 := THttpRequest.Create(Self);
  WebReq3 := THttpRequest.Create(Self);
  WebTimer1 := TTimer.Create(Self);

  WebImageControl1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebLabel12.BeforeLoadDFMValues;
  WebLabel13.BeforeLoadDFMValues;
  TMSFNCPanel1.BeforeLoadDFMValues;
  WebLabel14.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebEdit3.BeforeLoadDFMValues;
  WebEdit4.BeforeLoadDFMValues;
  WebEdit5.BeforeLoadDFMValues;
  WebEdit6.BeforeLoadDFMValues;
  WebEdit8.BeforeLoadDFMValues;
  WebEdit9.BeforeLoadDFMValues;
  WebEdit10.BeforeLoadDFMValues;
  WebEdit11.BeforeLoadDFMValues;
  WebEdit12.BeforeLoadDFMValues;
  TMSFNCToolBarButton1.BeforeLoadDFMValues;
  WebEdit7.BeforeLoadDFMValues;
  WebMemo1.BeforeLoadDFMValues;
  TMSFNCToolBarButton2.BeforeLoadDFMValues;
  WebReq1.BeforeLoadDFMValues;
  WebReq2.BeforeLoadDFMValues;
  WebReq3.BeforeLoadDFMValues;
  WebTimer1.BeforeLoadDFMValues;
  try
    Name := 'Form4';
    Width := 507;
    Height := 584;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(Self, 'OnKeyPress', 'TANGENTER');
    WebImageControl1.SetParentComponent(Self);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 264;
    WebImageControl1.Top := 48;
    WebImageControl1.Width := 209;
    WebImageControl1.Height := 41;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 100.000000000000000000;
    WebImageControl1.ChildOrder := 12;
    WebImageControl1.Picture.LoadFromFile('PROJECT.WebImageControl1.Picture.jpg');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 56;
    WebLabel1.Top := 103;
    WebLabel1.Width := 413;
    WebLabel1.Height := 18;
    WebLabel1.Caption := 'Customer Financial Exports using Oracle FBGBU General Ledger';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -15;
    WebLabel1.Font.Name := 'Tahoma';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 32;
    WebLabel2.Top := 153;
    WebLabel2.Width := 97;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Customer Name:';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -13;
    WebLabel2.Font.Name := 'Tahoma';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 32;
    WebLabel3.Top := 181;
    WebLabel3.Width := 150;
    WebLabel3.Height := 16;
    WebLabel3.Caption := 'Customer Contact Person:';
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -13;
    WebLabel3.Font.Name := 'Tahoma';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 32;
    WebLabel4.Top := 209;
    WebLabel4.Width := 146;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'Customer Contact Phone:';
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -13;
    WebLabel4.Font.Name := 'Tahoma';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 32;
    WebLabel5.Top := 237;
    WebLabel5.Width := 142;
    WebLabel5.Height := 16;
    WebLabel5.Caption := 'Customer Contact Email:';
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clWindowText;
    WebLabel5.Font.Height := -13;
    WebLabel5.Font.Name := 'Tahoma';
    WebLabel5.Font.Style := [];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(Self);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 32;
    WebLabel6.Top := 265;
    WebLabel6.Width := 89;
    WebLabel6.Height := 16;
    WebLabel6.Caption := 'Location Name:';
    WebLabel6.Font.Charset := DEFAULT_CHARSET;
    WebLabel6.Font.Color := clWindowText;
    WebLabel6.Font.Height := -13;
    WebLabel6.Font.Name := 'Tahoma';
    WebLabel6.Font.Style := [];
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.ParentFont := False;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebLabel7.SetParentComponent(Self);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 32;
    WebLabel7.Top := 293;
    WebLabel7.Width := 69;
    WebLabel7.Height := 16;
    WebLabel7.Caption := 'Location Nr:';
    WebLabel7.Font.Charset := DEFAULT_CHARSET;
    WebLabel7.Font.Color := clWindowText;
    WebLabel7.Font.Height := -13;
    WebLabel7.Font.Name := 'Tahoma';
    WebLabel7.Font.Style := [];
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.ParentFont := False;
    WebLabel7.WidthPercent := 100.000000000000000000;
    WebLabel8.SetParentComponent(Self);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 32;
    WebLabel8.Top := 374;
    WebLabel8.Width := 114;
    WebLabel8.Height := 16;
    WebLabel8.Anchors := [akLeft,akBottom];
    WebLabel8.Caption := 'Oracle Sales Name:';
    WebLabel8.Font.Charset := DEFAULT_CHARSET;
    WebLabel8.Font.Color := clWindowText;
    WebLabel8.Font.Height := -13;
    WebLabel8.Font.Name := 'Tahoma';
    WebLabel8.Font.Style := [];
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.ParentFont := False;
    WebLabel8.WidthPercent := 100.000000000000000000;
    WebLabel9.SetParentComponent(Self);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 32;
    WebLabel9.Top := 402;
    WebLabel9.Width := 116;
    WebLabel9.Height := 16;
    WebLabel9.Anchors := [akLeft,akBottom];
    WebLabel9.Caption := 'Oracle Sales Phone:';
    WebLabel9.Font.Charset := DEFAULT_CHARSET;
    WebLabel9.Font.Color := clWindowText;
    WebLabel9.Font.Height := -13;
    WebLabel9.Font.Name := 'Tahoma';
    WebLabel9.Font.Style := [];
    WebLabel9.HeightPercent := 100.000000000000000000;
    WebLabel9.ParentFont := False;
    WebLabel9.WidthPercent := 100.000000000000000000;
    WebLabel10.SetParentComponent(Self);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Left := 32;
    WebLabel10.Top := 430;
    WebLabel10.Width := 112;
    WebLabel10.Height := 16;
    WebLabel10.Anchors := [akLeft,akBottom];
    WebLabel10.Caption := 'Oracle Sales Email:';
    WebLabel10.Font.Charset := DEFAULT_CHARSET;
    WebLabel10.Font.Color := clWindowText;
    WebLabel10.Font.Height := -13;
    WebLabel10.Font.Name := 'Tahoma';
    WebLabel10.Font.Style := [];
    WebLabel10.HeightPercent := 100.000000000000000000;
    WebLabel10.ParentFont := False;
    WebLabel10.WidthPercent := 100.000000000000000000;
    WebLabel11.SetParentComponent(Self);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Left := 32;
    WebLabel11.Top := 458;
    WebLabel11.Width := 149;
    WebLabel11.Height := 16;
    WebLabel11.Anchors := [akLeft,akBottom];
    WebLabel11.Caption := 'Oracle Project Mgr Name:';
    WebLabel11.Font.Charset := DEFAULT_CHARSET;
    WebLabel11.Font.Color := clWindowText;
    WebLabel11.Font.Height := -13;
    WebLabel11.Font.Name := 'Tahoma';
    WebLabel11.Font.Style := [];
    WebLabel11.HeightPercent := 100.000000000000000000;
    WebLabel11.ParentFont := False;
    WebLabel11.WidthPercent := 100.000000000000000000;
    WebLabel12.SetParentComponent(Self);
    WebLabel12.Name := 'WebLabel12';
    WebLabel12.Left := 35;
    WebLabel12.Top := 486;
    WebLabel12.Width := 147;
    WebLabel12.Height := 16;
    WebLabel12.Anchors := [akLeft,akBottom];
    WebLabel12.Caption := 'Oracle Project Mgr Email:';
    WebLabel12.Font.Charset := DEFAULT_CHARSET;
    WebLabel12.Font.Color := clWindowText;
    WebLabel12.Font.Height := -13;
    WebLabel12.Font.Name := 'Tahoma';
    WebLabel12.Font.Style := [];
    WebLabel12.HeightPercent := 100.000000000000000000;
    WebLabel12.ParentFont := False;
    WebLabel12.WidthPercent := 100.000000000000000000;
    WebLabel13.SetParentComponent(Self);
    WebLabel13.Name := 'WebLabel13';
    WebLabel13.Left := 32;
    WebLabel13.Top := 321;
    WebLabel13.Width := 116;
    WebLabel13.Height := 16;
    WebLabel13.Caption := 'Simphony EMC URL:';
    WebLabel13.Font.Charset := DEFAULT_CHARSET;
    WebLabel13.Font.Color := clWindowText;
    WebLabel13.Font.Height := -13;
    WebLabel13.Font.Name := 'Tahoma';
    WebLabel13.Font.Style := [];
    WebLabel13.HeightPercent := 100.000000000000000000;
    WebLabel13.ParentFont := False;
    WebLabel13.WidthPercent := 100.000000000000000000;
    TMSFNCPanel1.SetParentComponent(Self);
    TMSFNCPanel1.Name := 'TMSFNCPanel1';
    TMSFNCPanel1.Left := 0;
    TMSFNCPanel1.Top := 0;
    TMSFNCPanel1.Width := 507;
    TMSFNCPanel1.Height := 30;
    TMSFNCPanel1.Align := alTop;
    TMSFNCPanel1.ParentDoubleBuffered := False;
    TMSFNCPanel1.DoubleBuffered := True;
    TMSFNCPanel1.TabOrder := 0;
    SetEvent(TMSFNCPanel1, Self, 'OnKeyDown', 'KEYTEST');
    TMSFNCPanel1.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel1.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel1.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel1.SectionsAppearance.Font.Style := [];
    TMSFNCPanel1.SectionsAppearance.Fill.Color := 15790320;
    TMSFNCPanel1.Header.HorizontalTextAlign := gtaLeading;
    TMSFNCPanel1.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Header.Font.Color := clWindowText;
    TMSFNCPanel1.Header.Font.Height := -11;
    TMSFNCPanel1.Header.Font.Name := 'Tahoma';
    TMSFNCPanel1.Header.Font.Style := [];
    TMSFNCPanel1.Header.Fill.Color := 15000804;
    TMSFNCPanel1.Header.Buttons := [pbClose];
    TMSFNCPanel1.Footer.Text := 'Footer';
    TMSFNCPanel1.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel1.Footer.Font.Color := clWindowText;
    TMSFNCPanel1.Footer.Font.Height := -11;
    TMSFNCPanel1.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel1.Footer.Font.Style := [];
    TMSFNCPanel1.Footer.Fill.Color := 15000804;
    SetEvent(TMSFNCPanel1, Self, 'OnHeaderCloseButtonClick', 'AVSLUTA');
    WebLabel14.SetParentComponent(TMSFNCPanel1);
    WebLabel14.Name := 'WebLabel14';
    WebLabel14.Left := 8;
    WebLabel14.Top := 6;
    WebLabel14.Width := 69;
    WebLabel14.Height := 16;
    WebLabel14.Caption := 'New project';
    WebLabel14.Font.Charset := DEFAULT_CHARSET;
    WebLabel14.Font.Color := clWindowText;
    WebLabel14.Font.Height := -13;
    WebLabel14.Font.Name := 'Tahoma';
    WebLabel14.Font.Style := [];
    WebLabel14.HeightPercent := 100.000000000000000000;
    WebLabel14.ParentFont := False;
    WebLabel14.WidthPercent := 100.000000000000000000;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Tag := 1;
    WebEdit1.Left := 224;
    WebEdit1.Top := 152;
    WebEdit1.Width := 250;
    WebEdit1.Height := 22;
    WebEdit1.ChildOrder := 1;
    WebEdit1.HeightPercent := 100.000000000000000000;
    WebEdit1.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit1, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(WebEdit1, Self, 'OnKeyPress', 'TANGENTER');
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Tag := 2;
    WebEdit2.Left := 224;
    WebEdit2.Top := 180;
    WebEdit2.Width := 250;
    WebEdit2.Height := 22;
    WebEdit2.ChildOrder := 2;
    WebEdit2.HeightPercent := 100.000000000000000000;
    WebEdit2.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit2, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(WebEdit2, Self, 'OnKeyPress', 'TANGENTER');
    WebEdit3.SetParentComponent(Self);
    WebEdit3.Name := 'WebEdit3';
    WebEdit3.Tag := 3;
    WebEdit3.Left := 224;
    WebEdit3.Top := 208;
    WebEdit3.Width := 250;
    WebEdit3.Height := 22;
    WebEdit3.ChildOrder := 3;
    WebEdit3.HeightPercent := 100.000000000000000000;
    WebEdit3.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit3, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(WebEdit3, Self, 'OnKeyPress', 'TANGENTER');
    WebEdit4.SetParentComponent(Self);
    WebEdit4.Name := 'WebEdit4';
    WebEdit4.Tag := 4;
    WebEdit4.Left := 224;
    WebEdit4.Top := 236;
    WebEdit4.Width := 250;
    WebEdit4.Height := 22;
    WebEdit4.ChildOrder := 4;
    WebEdit4.HeightPercent := 100.000000000000000000;
    WebEdit4.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit4, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(WebEdit4, Self, 'OnKeyPress', 'TANGENTER');
    WebEdit5.SetParentComponent(Self);
    WebEdit5.Name := 'WebEdit5';
    WebEdit5.Tag := 5;
    WebEdit5.Left := 224;
    WebEdit5.Top := 264;
    WebEdit5.Width := 250;
    WebEdit5.Height := 22;
    WebEdit5.ChildOrder := 5;
    WebEdit5.HeightPercent := 100.000000000000000000;
    WebEdit5.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit5, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(WebEdit5, Self, 'OnKeyPress', 'TANGENTER');
    WebEdit6.SetParentComponent(Self);
    WebEdit6.Name := 'WebEdit6';
    WebEdit6.Tag := 6;
    WebEdit6.Left := 224;
    WebEdit6.Top := 292;
    WebEdit6.Width := 250;
    WebEdit6.Height := 22;
    WebEdit6.ChildOrder := 6;
    WebEdit6.HeightPercent := 100.000000000000000000;
    WebEdit6.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit6, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(WebEdit6, Self, 'OnKeyPress', 'TANGENTER');
    WebEdit8.SetParentComponent(Self);
    WebEdit8.Name := 'WebEdit8';
    WebEdit8.Tag := 8;
    WebEdit8.Left := 224;
    WebEdit8.Top := 373;
    WebEdit8.Width := 250;
    WebEdit8.Height := 22;
    WebEdit8.Anchors := [akLeft,akBottom];
    WebEdit8.ChildOrder := 7;
    WebEdit8.HeightPercent := 100.000000000000000000;
    WebEdit8.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit8, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(WebEdit8, Self, 'OnKeyPress', 'TANGENTER');
    WebEdit9.SetParentComponent(Self);
    WebEdit9.Name := 'WebEdit9';
    WebEdit9.Tag := 9;
    WebEdit9.Left := 224;
    WebEdit9.Top := 401;
    WebEdit9.Width := 250;
    WebEdit9.Height := 22;
    WebEdit9.Anchors := [akLeft,akBottom];
    WebEdit9.ChildOrder := 8;
    WebEdit9.HeightPercent := 100.000000000000000000;
    WebEdit9.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit9, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(WebEdit9, Self, 'OnKeyPress', 'TANGENTER');
    WebEdit10.SetParentComponent(Self);
    WebEdit10.Name := 'WebEdit10';
    WebEdit10.Tag := 10;
    WebEdit10.Left := 224;
    WebEdit10.Top := 429;
    WebEdit10.Width := 250;
    WebEdit10.Height := 22;
    WebEdit10.Anchors := [akLeft,akBottom];
    WebEdit10.ChildOrder := 9;
    WebEdit10.HeightPercent := 100.000000000000000000;
    WebEdit10.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit10, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(WebEdit10, Self, 'OnKeyPress', 'TANGENTER');
    WebEdit11.SetParentComponent(Self);
    WebEdit11.Name := 'WebEdit11';
    WebEdit11.Tag := 11;
    WebEdit11.Left := 224;
    WebEdit11.Top := 457;
    WebEdit11.Width := 250;
    WebEdit11.Height := 22;
    WebEdit11.Anchors := [akLeft,akBottom];
    WebEdit11.ChildOrder := 10;
    WebEdit11.HeightPercent := 100.000000000000000000;
    WebEdit11.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit11, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(WebEdit11, Self, 'OnKeyPress', 'TANGENTER');
    WebEdit12.SetParentComponent(Self);
    WebEdit12.Name := 'WebEdit12';
    WebEdit12.Tag := 12;
    WebEdit12.Left := 224;
    WebEdit12.Top := 485;
    WebEdit12.Width := 250;
    WebEdit12.Height := 25;
    WebEdit12.Anchors := [akLeft,akBottom];
    WebEdit12.ChildOrder := 11;
    WebEdit12.HeightPercent := 100.000000000000000000;
    WebEdit12.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit12, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(WebEdit12, Self, 'OnKeyPress', 'TANGENTER');
    TMSFNCToolBarButton1.SetParentComponent(Self);
    TMSFNCToolBarButton1.Name := 'TMSFNCToolBarButton1';
    TMSFNCToolBarButton1.Left := 32;
    TMSFNCToolBarButton1.Top := 544;
    TMSFNCToolBarButton1.Width := 112;
    TMSFNCToolBarButton1.Height := 26;
    TMSFNCToolBarButton1.ParentDoubleBuffered := False;
    TMSFNCToolBarButton1.Anchors := [akLeft,akBottom];
    TMSFNCToolBarButton1.DoubleBuffered := True;
    TMSFNCToolBarButton1.Font.Charset := DEFAULT_CHARSET;
    TMSFNCToolBarButton1.Font.Color := clWindowText;
    TMSFNCToolBarButton1.Font.Height := -13;
    TMSFNCToolBarButton1.Font.Name := 'Tahoma';
    TMSFNCToolBarButton1.Font.Style := [];
    TMSFNCToolBarButton1.ParentColor := True;
    TMSFNCToolBarButton1.TabOrder := 12;
    SetEvent(TMSFNCToolBarButton1, Self, 'OnClick', 'SEND_PROJECT');
    SetEvent(TMSFNCToolBarButton1, Self, 'OnKeyDown', 'KEYTEST');
    TMSFNCToolBarButton1.Text := 'Send project';
    TMSFNCToolBarButton1.Appearance.InnerStroke.Color := clWhite;
    TMSFNCToolBarButton1.Appearance.NormalFill.Kind := gfkGradient;
    TMSFNCToolBarButton1.Appearance.NormalFill.Color := 16579577;
    TMSFNCToolBarButton1.Appearance.NormalFill.ColorTo := 15461350;
    TMSFNCToolBarButton1.Appearance.NormalFill.ColorMirror := 15523036;
    TMSFNCToolBarButton1.Appearance.NormalFill.ColorMirrorTo := 15524316;
    TMSFNCToolBarButton1.Appearance.NormalStroke.Color := clGray;
    TMSFNCToolBarButton1.Appearance.HoverFill.Kind := gfkGradient;
    TMSFNCToolBarButton1.Appearance.HoverFill.Color := 15263717;
    TMSFNCToolBarButton1.Appearance.HoverFill.ColorTo := 14145490;
    TMSFNCToolBarButton1.Appearance.HoverFill.ColorMirror := 14207176;
    TMSFNCToolBarButton1.Appearance.HoverFill.ColorMirrorTo := 14208456;
    TMSFNCToolBarButton1.Appearance.HoverStroke.Color := clGray;
    TMSFNCToolBarButton1.Appearance.DownFill.Kind := gfkGradient;
    TMSFNCToolBarButton1.Appearance.DownFill.Color := 14605787;
    TMSFNCToolBarButton1.Appearance.DownFill.ColorTo := 13487560;
    TMSFNCToolBarButton1.Appearance.DownFill.ColorMirror := 13549246;
    TMSFNCToolBarButton1.Appearance.DownFill.ColorMirrorTo := 13550526;
    TMSFNCToolBarButton1.Appearance.DownStroke.Color := clGray;
    TMSFNCToolBarButton1.Appearance.DisabledFill.Color := 13882323;
    TMSFNCToolBarButton1.Appearance.DisabledStroke.Color := clGray;
    TMSFNCToolBarButton1.ControlIndex := 0;
    WebEdit7.SetParentComponent(Self);
    WebEdit7.Name := 'WebEdit7';
    WebEdit7.Tag := 7;
    WebEdit7.Left := 224;
    WebEdit7.Top := 320;
    WebEdit7.Width := 250;
    WebEdit7.Height := 22;
    WebEdit7.ChildOrder := 6;
    WebEdit7.HeightPercent := 100.000000000000000000;
    WebEdit7.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit7, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(WebEdit7, Self, 'OnKeyPress', 'TANGENTER');
    WebMemo1.SetParentComponent(Self);
    WebMemo1.Name := 'WebMemo1';
    WebMemo1.Left := 0;
    WebMemo1.Top := 516;
    WebMemo1.Width := 600;
    WebMemo1.Height := 22;
    WebMemo1.AutoSize := False;
    WebMemo1.HeightPercent := 100.000000000000000000;
    WebMemo1.Lines.BeginUpdate;
    try
      WebMemo1.Lines.Clear;
      WebMemo1.Lines.Add('WebMemo1');
    finally
      WebMemo1.Lines.EndUpdate;
    end;
    WebMemo1.SelLength := 0;
    WebMemo1.SelStart := 0;
    WebMemo1.Visible := False;
    WebMemo1.WidthPercent := 100.000000000000000000;
    SetEvent(WebMemo1, Self, 'OnKeyDown', 'KEYTEST');
    TMSFNCToolBarButton2.SetParentComponent(Self);
    TMSFNCToolBarButton2.Name := 'TMSFNCToolBarButton2';
    TMSFNCToolBarButton2.Left := 357;
    TMSFNCToolBarButton2.Top := 544;
    TMSFNCToolBarButton2.Width := 112;
    TMSFNCToolBarButton2.Height := 26;
    TMSFNCToolBarButton2.ParentDoubleBuffered := False;
    TMSFNCToolBarButton2.Anchors := [akLeft,akBottom];
    TMSFNCToolBarButton2.DoubleBuffered := True;
    TMSFNCToolBarButton2.Font.Charset := DEFAULT_CHARSET;
    TMSFNCToolBarButton2.Font.Color := clWindowText;
    TMSFNCToolBarButton2.Font.Height := -13;
    TMSFNCToolBarButton2.Font.Name := 'Tahoma';
    TMSFNCToolBarButton2.Font.Style := [];
    TMSFNCToolBarButton2.ParentColor := True;
    TMSFNCToolBarButton2.TabOrder := 15;
    SetEvent(TMSFNCToolBarButton2, Self, 'OnClick', 'AVSLUTA');
    SetEvent(TMSFNCToolBarButton2, Self, 'OnKeyDown', 'KEYTEST');
    TMSFNCToolBarButton2.Text := 'Cancel';
    TMSFNCToolBarButton2.Appearance.InnerStroke.Color := clWhite;
    TMSFNCToolBarButton2.Appearance.NormalFill.Kind := gfkGradient;
    TMSFNCToolBarButton2.Appearance.NormalFill.Color := 16579577;
    TMSFNCToolBarButton2.Appearance.NormalFill.ColorTo := 15461350;
    TMSFNCToolBarButton2.Appearance.NormalFill.ColorMirror := 15523036;
    TMSFNCToolBarButton2.Appearance.NormalFill.ColorMirrorTo := 15524316;
    TMSFNCToolBarButton2.Appearance.NormalStroke.Color := clGray;
    TMSFNCToolBarButton2.Appearance.HoverFill.Kind := gfkGradient;
    TMSFNCToolBarButton2.Appearance.HoverFill.Color := 15263717;
    TMSFNCToolBarButton2.Appearance.HoverFill.ColorTo := 14145490;
    TMSFNCToolBarButton2.Appearance.HoverFill.ColorMirror := 14207176;
    TMSFNCToolBarButton2.Appearance.HoverFill.ColorMirrorTo := 14208456;
    TMSFNCToolBarButton2.Appearance.HoverStroke.Color := clGray;
    TMSFNCToolBarButton2.Appearance.DownFill.Kind := gfkGradient;
    TMSFNCToolBarButton2.Appearance.DownFill.Color := 14605787;
    TMSFNCToolBarButton2.Appearance.DownFill.ColorTo := 13487560;
    TMSFNCToolBarButton2.Appearance.DownFill.ColorMirror := 13549246;
    TMSFNCToolBarButton2.Appearance.DownFill.ColorMirrorTo := 13550526;
    TMSFNCToolBarButton2.Appearance.DownStroke.Color := clGray;
    TMSFNCToolBarButton2.Appearance.DisabledFill.Color := 13882323;
    TMSFNCToolBarButton2.Appearance.DisabledStroke.Color := clGray;
    TMSFNCToolBarButton2.ControlIndex := 0;
    WebReq1.SetParentComponent(Self);
    WebReq1.Name := 'WebReq1';
    SetEvent(WebReq1, Self, 'OnRequestResponse', 'WebReq1RequestResponse');
    WebReq1.Left := 32;
    WebReq1.Top := 40;
    WebReq2.SetParentComponent(Self);
    WebReq2.Name := 'WebReq2';
    SetEvent(WebReq2, Self, 'OnRequestResponse', 'WebReq2RequestResponse');
    WebReq2.Left := 96;
    WebReq2.Top := 40;
    WebReq3.SetParentComponent(Self);
    WebReq3.Name := 'WebReq3';
    WebReq3.Left := 168;
    WebReq3.Top := 40;
    WebTimer1.SetParentComponent(Self);
    WebTimer1.Name := 'WebTimer1';
    WebTimer1.Enabled := False;
    WebTimer1.Interval := 60000;
    SetEvent(WebTimer1, Self, 'OnTimer', 'AVSLUTA');
    WebTimer1.Left := 16;
    WebTimer1.Top := 336;
  finally
    WebImageControl1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebLabel12.AfterLoadDFMValues;
    WebLabel13.AfterLoadDFMValues;
    TMSFNCPanel1.AfterLoadDFMValues;
    WebLabel14.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebEdit3.AfterLoadDFMValues;
    WebEdit4.AfterLoadDFMValues;
    WebEdit5.AfterLoadDFMValues;
    WebEdit6.AfterLoadDFMValues;
    WebEdit8.AfterLoadDFMValues;
    WebEdit9.AfterLoadDFMValues;
    WebEdit10.AfterLoadDFMValues;
    WebEdit11.AfterLoadDFMValues;
    WebEdit12.AfterLoadDFMValues;
    TMSFNCToolBarButton1.AfterLoadDFMValues;
    WebEdit7.AfterLoadDFMValues;
    WebMemo1.AfterLoadDFMValues;
    TMSFNCToolBarButton2.AfterLoadDFMValues;
    WebReq1.AfterLoadDFMValues;
    WebReq2.AfterLoadDFMValues;
    WebReq3.AfterLoadDFMValues;
    WebTimer1.AfterLoadDFMValues;
  end;
end;

end.
{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2016 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCPanel;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}

interface

uses
  Classes, WEBLib.TMSFNCCustomControl, Types, WEBLib.TMSFNCTypes, WEBLib.Controls,
  WEBLib.TMSFNCBitmapContainer, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCPopup
  ,WEBLib.TMSFNCGraphicsTypes
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  ,Generics.Collections, UITypes
  {$ENDIF}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ,TypInfo
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 1; // Release nr.
  BLD_VER = 2; // Build nr.
  MINSECTIONHEIGHT = 16;

  // version history
  // v1.0.0.0 : first release
  // v1.0.0.1 : Fixed : Issue with alignment of controls when header/footer is visible false
  // v1.0.0.2 : Fixed : Issue with applying style
  // v1.0.0.3 : Fixed : Header and footer anchoring behavior
  // v1.0.0.4 : Fixed : Issue with Anchor if font was changed
  // v1.0.0.5 : Fixed : Issue in Delphi 11 with begin and end scene for CreateBitmapCanvas
  // v1.0.1.0 : New : Support for high dpi
  // v1.0.1.1 : Improved : Bugfix for design-time components on high dpi fixed with november patch
  // v1.0.1.2 : Fixed : Issue with click detection in TMS WEB Core

type
  TTMSFNCCustomPanel = class;

  TTMSFNCPanelButton = (pbCompact, pbClose, pbExpander, pbDropDown);
  TTMSFNCPanelButtons = set of TTMSFNCPanelButton;
  TTMSFNCPanelCloseAction = (pcaNone, pcaFree, pcaHide);
  TTMSFNCPanelDropDownAction = (pddaNone, pddaPopup);
  TTMSFNCPanelExpandAction = (peaNone, peaExpand);
  TTMSFNCPanelCompactAction = (pcNone, pcCompact);

  TTMSFNCPanelElement = class(TPersistent)
  private
    FOnChanged: TNotifyEvent;
    FText: string;
    FSize: Single;
    FWordWrapping: Boolean;
    FVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FTrimming: TTMSFNCGraphicsTextTrimming;
    FFont: TTMSFNCGraphicsFont;
    FShowButtons: Boolean;
    FButtons: TTMSFNCPanelButtons;
    FVisible: Boolean;
    FButtonSpacing: Single;
    FButtonSize: Single;
    FFill: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    FStretchText: Boolean;
    FDropDownControl: TControl;
    FDropDownHeight: Single;
    FDropDownWidth: Single;
    procedure SetText(const Value: string);
    function IsSizeStored: Boolean;
    procedure SetSize(const Value: Single);
    procedure SetHorizontalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetWordWrapping(const Value: Boolean);
    procedure SetTrimming(const Value: TTMSFNCGraphicsTextTrimming);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetButtons(const Value: TTMSFNCPanelButtons);
    procedure SetShowButtons(const Value: Boolean);
    procedure SetVisible(const Value: Boolean);
    function IsButtonSizeStored: Boolean;
    function IsButtonSpacingStored: Boolean;
    procedure SetButtonSize(const Value: Single);
    procedure SetButtonSpacing(const Value: Single);
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetStretchText(const Value: Boolean);
    procedure SetDropDownControl(const Value: TControl);
    function IsDropDownHeightStored: Boolean;
    function IsDropDownWidthStored: Boolean;
  protected
    procedure ChangeDPIScale(M, D: Integer);
    procedure Changed;
    procedure FontChanged(Sender: TObject);
    procedure FillChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
    function IsHTML: Boolean; virtual;
  public
    property OnChanged: TNotifyEvent read FOnChanged write FOnChanged;
    constructor Create; virtual;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property StretchText: Boolean read FStretchText write SetStretchText default True;
    property Text: string read FText write SetText;
    property Size: Single read FSize write SetSize stored IsSizeStored nodefault;
    property HorizontalTextAlign: TTMSFNCGraphicsTextAlign read FHorizontalTextAlign write SetHorizontalTextAlign default gtaCenter;
    property VerticalTextAlign: TTMSFNCGraphicsTextAlign read FVerticalTextAlign write SetVerticalTextAlign default gtaCenter;
    property WordWrapping: Boolean read FWordWrapping write SetWordWrapping default True;
    property Trimming: TTMSFNCGraphicsTextTrimming read FTrimming write SetTrimming default gttNone;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property Visible: Boolean read FVisible write SetVisible default True;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property ShowButtons: Boolean read FShowButtons write SetShowButtons default True;
    property Buttons: TTMSFNCPanelButtons read FButtons write SetButtons default [];
    property ButtonSize: Single read FButtonSize write SetButtonSize stored IsButtonSizeStored nodefault;
    property ButtonSpacing: Single read FButtonSpacing write SetButtonSpacing stored IsButtonSpacingStored nodefault;
    property DropDownControl: TControl read FDropDownControl write SetDropDownControl;
    property DropDownWidth: Single read FDropDownWidth write FDropDownWidth stored IsDropDownWidthStored nodefault;
    property DropDownHeight: Single read FDropDownHeight write FDropDownHeight stored IsDropDownHeightStored nodefault;
  end;

  TTMSFNCPanelHeader = class(TTMSFNCPanelElement)
  end;

  TTMSFNCPanelFooter = class(TTMSFNCPanelElement)
  public
    constructor Create; override;
  published
    property Visible default False;
    property ShowButtons default False;
  end;

  TTMSFNCPanelButtonState = (bsNormal, bsHover, bsDown);
  {$IFDEF WEBLIB}
  TTMSFNCPanelButtonStates = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCPanelButtonState;
    procedure SetItem(Index: Integer; const Value: TTMSFNCPanelButtonState);
  public
    property Items[Index: Integer]: TTMSFNCPanelButtonState read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCPanelButtonStates = TList<TTMSFNCPanelButtonState>;
  {$ENDIF}

  TTMSFNCPanelDownButton = record
    Button: TTMSFNCPanelButton;
    Down: Boolean;
  end;

  TTMSFNCPanelExpandState = (pesCollapsed, pesExpanded);
  TTMSFNCPanelCompactState = (pcsCollapsed, pcsExpanded);

  TTMSFNCPanelAnchorClickEvent = procedure(Sender: TObject; AAnchor: String) of object;

  TTMSFNCPanelSection = class(TCollectionItem)
  private
    FOwner: TTMSFNCCustomPanel;
    FSize: Single;
    FTag: NativeInt;
    FText: string;
    FControl: TControl;
    procedure SetSize(const Value: Single);
    function IsSizeStored: Boolean;
    procedure SetText(const Value: string);
    procedure SetControl(const Value: TControl);
  protected
    procedure Changed;
    procedure UpdateControlBounds;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
  published
    property Text: string read FText write SetText;
    property Size: Single read FSize write SetSize stored IsSizeStored nodefault;
    property Control: TControl read FControl write SetControl;
    property Tag: NativeInt read FTag write FTag default 0;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCPanelSections = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCPanelSections = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCPanelSection>)
  {$ENDIF}
  private
    FOwner: TTMSFNCCustomPanel;
    FOnChanged: TNotifyEvent;
    function GetItem(Index: Integer): TTMSFNCPanelSection;
    procedure SetItem(Index: Integer; const Value: TTMSFNCPanelSection);
  protected
    procedure Changed;
    property OnChanged: TNotifyEvent read FOnChanged write FOnChanged;
  public
    constructor Create(AOwner: TTMSFNCCustomPanel);
    function Add: TTMSFNCPanelSection;
    function Insert(Index: Integer): TTMSFNCPanelSection;
    property Items[Index: Integer]: TTMSFNCPanelSection read GetItem write SetItem; default;
  end;

  TTMSFNCPanelSectionsAppearance = class(TPersistent)
  private
    FFont: TTMSFNCGraphicsFont;
    FFill: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    FOnChange: TNotifyEvent;
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
  protected
    procedure Changed;
    procedure FillChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
    procedure FontChanged(Sender: TObject);
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create; virtual;
    destructor Destroy; override;
  published
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
  end;

  TTMSFNCCustomPanel = class(TTMSFNCCustomControl, ITMSFNCBitmapContainer)
  private
    FOldHeight: Single;
    FOldWidth: Single;
    FPopup: TTMSFNCPopup;
    FExpandState: TTMSFNCPanelExpandState;
    FCompactState: TTMSFNCPanelCompactState;
    FHeaderDownButton, FFooterDownButton: TTMSFNCPanelDownButton;
    FHeaderButtonStates, FFooterButtonStates: TTMSFNCPanelButtonStates;
    FHeaderAnchor, FFooterAnchor: String;
    FBitmapContainer: TTMSFNCBitmapContainer;
    FHeader: TTMSFNCPanelHeader;
    FFooter: TTMSFNCPanelFooter;
    FOnHeaderDropDownButtonClick: TNotifyEvent;
    FOnHeaderExpandButtonClick: TNotifyEvent;
    FOnHeaderCloseButtonClick: TNotifyEvent;
    FOnHeaderAnchorClick: TTMSFNCPanelAnchorClickEvent;
    FOnFooterAnchorClick: TTMSFNCPanelAnchorClickEvent;
    FOnFooterDropDownButtonClick: TNotifyEvent;
    FOnFooterExpandButtonClick: TNotifyEvent;
    FOnFooterCloseButtonClick: TNotifyEvent;
    FCloseAction: TTMSFNCPanelCloseAction;
    FDropDownAction: TTMSFNCPanelDropDownAction;
    FExpandAction: TTMSFNCPanelExpandAction;
    FCompactAction: TTMSFNCPanelCompactAction;
    FSections: TTMSFNCPanelSections;
    FSectionsAppearance: TTMSFNCPanelSectionsAppearance;
    FOnHeaderCompactButtonClick: TNotifyEvent;
    FOnFooterCompactButtonClick: TNotifyEvent;
    FOnInternalHeaderCompactButtonClick: TNotifyEvent;
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    procedure SetFooter(const Value: TTMSFNCPanelFooter);
    procedure SetHeader(const Value: TTMSFNCPanelHeader);
    procedure SetCloseAction(const Value: TTMSFNCPanelCloseAction);
    procedure SetDropDownAction(const Value: TTMSFNCPanelDropDownAction);
    procedure SetExpandAction(const Value: TTMSFNCPanelExpandAction);
    procedure SetCompactAction(const Value: TTMSFNCPanelCompactAction);
    procedure SetSections(const Value: TTMSFNCPanelSections);
    procedure SetSectionsAppearance(
      const Value: TTMSFNCPanelSectionsAppearance);
  protected
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure ApplyStyle; override;
    procedure ResetToDefaultStyle; override;
    function GetElementRect(ARect: TRectF; AElement: TTMSFNCPanelElement): TRectF; virtual;
    function GetTextRect(ARect: TRectF; AElement: TTMSFNCPanelElement): TRectF; virtual;
    function GetSectionRect(ARect: TRectF): TRectF; virtual;
    function GetButtonsRect(ARect: TRectF; AElement: TTMSFNCPanelElement): TRectF; virtual;
    function XYToAnchor(X, Y: Single; AElement: TTMSFNCPanelElement): string; virtual;
    function XYToButton(X, Y: Single; AElement: TTMSFNCPanelElement; var AButton: TTMSFNCPanelButton): Boolean; virtual;
    function ClearButtonStates(AHoverOnly: Boolean = False): Boolean; virtual;
    function GetButtonRect(ARect: TRectF; AElement: TTMSFNCPanelElement; AButton: TTMSFNCPanelButton): TRectF;
    function GetCollapsedHeight: Single; virtual;
    function GetCollapsedWidth: Single; virtual;
    function IsAppearanceProperty(AObject: TObject; APropertyName: string; APropertyType: TTypeKind): Boolean; override;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure FooterChanged(Sender: TObject);
    procedure HeaderChanged(Sender: TObject);
    procedure SectionsChanged(Sender: TObject);
    procedure SectionsAppearanceChanged(Sender: TObject);
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure DrawSections(AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
    procedure DrawElement(AGraphics: TTMSFNCGraphics; AElement: TTMSFNCPanelElement; ATextRect: TRectF; ARect: TRectF; AButtonsRect: TRectF; AButtonStates: TTMSFNCPanelButtonStates); virtual;
    procedure HandleMouseLeave; override;
    procedure UpdateControlAfterResize; override;
    procedure HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseMove(Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleDropDownAction(AButton: TTMSFNCPanelButton; AElement: TTMSFNCPanelElement); virtual;
    procedure HandleCloseAction(AElement: TTMSFNCPanelElement); virtual;
    procedure HandleExpandAction(AElement: TTMSFNCPanelElement); virtual;
    procedure HandleCompactAction(AElement: TTMSFNCPanelElement); virtual;
    procedure DoHeaderAnchorClick(AAnchor: String); virtual;
    procedure DoHeaderCloseButtonClick; virtual;
    procedure DoHeaderExpandButtonClick; virtual;
    procedure DoHeaderDropDownButtonClick; virtual;
    procedure DoHeaderCompactButtonClick; virtual;
    procedure DoFooterAnchorClick(AAnchor: String); virtual;
    procedure DoFooterCloseButtonClick; virtual;
    procedure DoFooterExpandButtonClick; virtual;
    procedure DoFooterDropDownButtonClick; virtual;
    procedure DoFooterCompactButtonClick; virtual;
    procedure UpdateControlBounds; virtual;
    {$IFDEF CMNWEBLIB}
    procedure AlignControls(AControl: TControl; var Rect: TRect); override;
    {$ENDIF}
    procedure Loaded; override;
    property SectionsAppearance: TTMSFNCPanelSectionsAppearance read FSectionsAppearance write SetSectionsAppearance;
    property Sections: TTMSFNCPanelSections read FSections write SetSections;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
    property Header: TTMSFNCPanelHeader read FHeader write SetHeader;
    property Footer: TTMSFNCPanelFooter read FFooter write SetFooter;
    property CloseAction: TTMSFNCPanelCloseAction read FCloseAction write SetCloseAction default pcaHide;
    property DropDownAction: TTMSFNCPanelDropDownAction read FDropDownAction write SetDropDownAction default pddaPopup;
    property ExpandAction: TTMSFNCPanelExpandAction read FExpandAction write SetExpandAction default peaExpand;
    property CompactAction: TTMSFNCPanelCompactAction read FCompactAction write SetCompactAction default pcCompact;
    property OnHeaderAnchorClick: TTMSFNCPanelAnchorClickEvent read FOnHeaderAnchorClick write FOnHeaderAnchorClick;
    property OnHeaderCloseButtonClick: TNotifyEvent read FOnHeaderCloseButtonClick write FOnHeaderCloseButtonClick;
    property OnHeaderExpandButtonClick: TNotifyEvent read FOnHeaderExpandButtonClick write FOnHeaderExpandButtonClick;
    property OnHeaderCompactButtonClick: TNotifyEvent read FOnHeaderCompactButtonClick write FOnHeaderCompactButtonClick;
    property OnHeaderDropDownButtonClick: TNotifyEvent read FOnHeaderDropDownButtonClick write FOnHeaderDropDownButtonClick;
    property OnInternalHeaderCompactButtonClick: TNotifyEvent read FOnInternalHeaderCompactButtonClick write FOnInternalHeaderCompactButtonClick;
    property OnFooterAnchorClick: TTMSFNCPanelAnchorClickEvent read FOnFooterAnchorClick write FOnFooterAnchorClick;
    property OnFooterCloseButtonClick: TNotifyEvent read FOnFooterCloseButtonClick write FOnFooterCloseButtonClick;
    property OnFooterExpandButtonClick: TNotifyEvent read FOnFooterExpandButtonClick write FOnFooterExpandButtonClick;
    property OnFooterCompactButtonClick: TNotifyEvent read FOnFooterCompactButtonClick write FOnFooterCompactButtonClick;
    property OnFooterDropDownButtonClick: TNotifyEvent read FOnFooterDropDownButtonClick write FOnFooterDropDownButtonClick;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    procedure Expand(ACompact: Boolean = False);
    procedure Collapse(ACompact: Boolean = False);
    procedure Compact;
    procedure CompactAndCollapse;
    procedure Restore;
    property ExpandState: TTMSFNCPanelExpandState read FExpandState write FExpandState;
    property CompactState: TTMSFNCPanelCompactState read FCompactState write FCompactState;
    {$IFDEF FMXLIB}
    procedure SetBounds(X, Y, AWidth, AHeight: Single); override;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    procedure SetBounds(X, Y, {%H-}AWidth, {%H-}AHeight: Integer); override;
    {$ENDIF}
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCPanel = class(TTMSFNCCustomPanel)
  protected
    procedure RegisterRuntimeClasses; override;
    function GetVersion: string; override;
  published
    property BitmapContainer;
    property Version: String read GetVersion;
    property Fill;
    property Sections;
    property SectionsAppearance;
    property Stroke;
    property Header;
    property Footer;
    property CloseAction;
    property DropDownAction;
    property ExpandAction;
    property CompactAction;
    property OnHeaderAnchorClick;
    property OnHeaderCloseButtonClick;
    property OnHeaderExpandButtonClick;
    property OnHeaderCompactButtonClick;
    property OnHeaderDropDownButtonClick;
    property OnFooterAnchorClick;
    property OnFooterCloseButtonClick;
    property OnFooterExpandButtonClick;
    property OnFooterCompactButtonClick;
    property OnFooterDropDownButtonClick;
  end;

implementation

uses
  Math, SysUtils, WEBLib.TMSFNCUtils, WEBLib.TMSFNCStyles, WEBLib.Graphics;

{ TTMSFNCCustomPanel }

{$IFDEF FMXLIB}
procedure TTMSFNCCustomPanel.SetBounds(X, Y, AWidth, AHeight: Single);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCCustomPanel.AlignControls(AControl: TControl; var Rect: TRect);
begin
  if Header.Visible then
    Rect.Top := Rect.Top + Round(Header.Size);

  if Footer.Visible then
    Rect.Bottom := Rect.Bottom - Round(Footer.Size);

  inherited;
end;

procedure TTMSFNCCustomPanel.SetBounds(X, Y, AWidth, AHeight: Integer);
{$ENDIF}
begin
  inherited SetBounds(X, Y, AWidth, AHeight);
  UpdateControlBounds;
end;

procedure TTMSFNCCustomPanel.ChangeDPIScale(M, D: Integer);
var
  I: integer;
begin
  inherited;

  BeginUpdate;

  FOldHeight := TTMSFNCUtils.MulDivSingle(FOldHeight, M, D);
  FOldWidth := TTMSFNCUtils.MulDivSingle(FOldWidth, M, D);

  FHeader.ChangeDPIScale(M, D);
  FFooter.ChangeDPIScale(M, D);

  SectionsAppearance.Font.Height := TTMSFNCUtils.MulDivInt(SectionsAppearance.Font.Height, M, D);

  for I := 0 to FSections.Count - 1 do
  begin
    FSections[I].FSize := TTMSFNCUtils.MulDivSingle(FSections[I].FSize, M, D);
    FSections[I].UpdateControlBounds;
  end;
  EndUpdate;
end;

function TTMSFNCCustomPanel.ClearButtonStates(AHoverOnly: Boolean = False): Boolean;
var
  I: Integer;
  st: TTMSFNCPanelButtonState;
begin
  Result := False;
  for I := 0 to FHeaderButtonStates.Count - 1 do
  begin
    st := FHeaderButtonStates[I];
    if st <> bsNormal then
      Result := True;

    if (AHoverOnly and (st = bsHover)) or not AHoverOnly then
      FHeaderButtonStates[I] := bsNormal;
  end;

  for I := 0 to FFooterButtonStates.Count - 1 do
  begin
    st := FFooterButtonStates[I];
    if st <> bsNormal then
      Result := True;

    if (AHoverOnly and (st = bsHover)) or not AHoverOnly then
      FFooterButtonStates[I] := bsNormal;
  end;
end;

procedure TTMSFNCCustomPanel.Collapse(ACompact: Boolean = False);
begin
  if ACompact then
  begin
    if FCompactState = pcsExpanded then
    begin
      FOldWidth := Width;
      Width := Round(GetCollapsedWidth);
      FCompactState := pcsCollapsed;
    end;
  end
  else
  begin
    if FExpandState = pesExpanded then
    begin
      FOldHeight := Height;
      Height := Round(GetCollapsedHeight);
      FExpandState := pesCollapsed;
    end;
  end;
end;

procedure TTMSFNCCustomPanel.Compact;
begin
  Collapse(True);
end;

procedure TTMSFNCCustomPanel.CompactAndCollapse;
begin
  Collapse(False);
  Collapse(True);
end;

constructor TTMSFNCCustomPanel.Create(AOwner: TComponent);
var
  I: TTMSFNCPanelButton;
begin
  inherited;
  FSectionsAppearance := TTMSFNCPanelSectionsAppearance.Create;
  FSectionsAppearance.OnChange := @SectionsAppearanceChanged;
  FSections := TTMSFNCPanelSections.Create(Self);
  FSections.OnChanged := @SectionsChanged;

  FFooter := TTMSFNCPanelFooter.Create;
  if IsDesignTime then
    FFooter.Text := 'Footer';

  FFooter.OnChanged := @FooterChanged;
  FHeader := TTMSFNCPanelHeader.Create;
  if IsDesignTime then
    FHeader.Text := 'Header';

  FHeader.OnChanged := @HeaderChanged;

  FHeaderButtonStates := TTMSFNCPanelButtonStates.Create;
  for I := Low(TTMSFNCPanelButton) to High(TTMSFNCPanelButton) do
    FHeaderButtonStates.Add(bsNormal);

  FFooterButtonStates := TTMSFNCPanelButtonStates.Create;
  for I := Low(TTMSFNCPanelButton) to High(TTMSFNCPanelButton) do
    FFooterButtonStates.Add(bsNormal);

  FCloseAction := pcaHide;
  FExpandAction := peaExpand;
  FCompactAction := pcCompact;
  FDropDownAction := pddaPopup;

  FPopup := TTMSFNCPopup.Create(Self);
  FPopup.Placement := ppAbsolute;

  FExpandState := pesExpanded;
  FCompactState := pcsExpanded;

  Width := 200;
  Height := 275;
end;

destructor TTMSFNCCustomPanel.Destroy;
begin
  FHeaderButtonStates.Free;
  FFooterButtonStates.Free;
  FFooter.Free;
  FHeader.Free;
  FSections.Free;
  FSectionsAppearance.Free;
  inherited;
end;

procedure TTMSFNCCustomPanel.DoFooterAnchorClick(AAnchor: String);
begin
  if Assigned(OnFooterAnchorClick) then
    OnFooterAnchorClick(Self, AAnchor)
  else
    TTMSFNCUtils.OpenURL(AAnchor);
end;

procedure TTMSFNCCustomPanel.DoFooterCloseButtonClick;
begin
  if Assigned(OnFooterCloseButtonClick) then
    OnFooterCloseButtonClick(Self);
end;

procedure TTMSFNCCustomPanel.DoFooterCompactButtonClick;
begin
  if Assigned(OnFooterCompactButtonClick) then
    OnFooterCompactButtonClick(Self);
end;

procedure TTMSFNCCustomPanel.DoFooterDropDownButtonClick;
begin
  if Assigned(OnFooterDropDownButtonClick) then
    OnFooterDropDownButtonClick(Self);
end;

procedure TTMSFNCCustomPanel.DoFooterExpandButtonClick;
begin
  if Assigned(OnFooterExpandButtonClick) then
    OnFooterExpandButtonClick(Self);
end;

procedure TTMSFNCCustomPanel.DoHeaderAnchorClick(AAnchor: String);
begin
  if Assigned(OnHeaderAnchorClick) then
    OnHeaderAnchorClick(Self, AAnchor)
  else
    TTMSFNCUtils.OpenURL(AAnchor);
end;

procedure TTMSFNCCustomPanel.DoHeaderCloseButtonClick;
begin
  if Assigned(OnHeaderCloseButtonClick) then
    OnHeaderCloseButtonClick(Self);
end;

procedure TTMSFNCCustomPanel.DoHeaderCompactButtonClick;
begin
  if Assigned(OnInternalHeaderCompactButtonClick) then
    OnInternalHeaderCompactButtonClick(Self);

  if Assigned(OnHeaderCompactButtonClick) then
    OnHeaderCompactButtonClick(Self);
end;

procedure TTMSFNCCustomPanel.DoHeaderDropDownButtonClick;
begin
  if Assigned(OnHeaderDropDownButtonClick) then
    OnHeaderDropDownButtonClick(Self);
end;

procedure TTMSFNCCustomPanel.DoHeaderExpandButtonClick;
begin
  if Assigned(OnHeaderExpandButtonClick) then
    OnHeaderExpandButtonClick(Self);
end;

procedure TTMSFNCCustomPanel.Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  inherited;
  AGraphics.BitmapContainer := BitmapContainer;
  DrawSections(AGraphics, GetSectionRect(ARect));
  DrawElement(AGraphics, Header, GetTextRect(ARect, Header), GetElementRect(ARect, Header), GetButtonsRect(ARect, Header), FHeaderButtonStates);
  DrawElement(AGraphics, Footer, GetTextRect(ARect, Footer), GetElementRect(ARect, Footer), GetButtonsRect(ARect, Footer), FFooterButtonStates);
end;

procedure TTMSFNCCustomPanel.DrawElement(AGraphics: TTMSFNCGraphics;
  AElement: TTMSFNCPanelElement; ATextRect, ARect, AButtonsRect: TRectF; AButtonStates: TTMSFNCPanelButtonStates);
var
  tr: TRectF;
  btnr: TRectF;
  br: TRectF;
  I: TTMSFNCPanelButton;
  e, c: Integer;
begin
  if not AElement.Visible then
    Exit;

  AGraphics.Fill.Assign(AElement.Fill);
  AGraphics.Stroke.Assign(AElement.Stroke);
  AGraphics.DrawRectangle(ARect);

  if FCompactState = pcsExpanded then
  begin
    tr := ATextRect;
    InflateRectEx(tr, ScalePaintValue(-3), ScalePaintValue(-3));
    AGraphics.Font.AssignSource(AElement.Font);
    AGraphics.DrawText(tr, AElement.Text, AElement.WordWrapping, AElement.HorizontalTextAlign, AElement.VerticalTextAlign, AElement.Trimming);
  end;

  if AElement.ShowButtons and (AElement.Buttons <> []) then
  begin
    btnr := AButtonsRect;
    for I := Low(TTMSFNCPanelButton) to High(TTMSFNCPanelButton) do
    begin
      if (I in AElement.Buttons) and (((I = pbCompact) and (FCompactState = pcsCollapsed)) or (FCompactState = pcsExpanded)) then
      begin
        br := RectF(Round(btnr.Right - AElement.ButtonSpacing - AElement.ButtonSize), Round(btnr.Top + ((btnr.Bottom - btnr.Top) - AElement.ButtonSize) / 2), Round(btnr.Right - AElement.ButtonSpacing),
          Round(btnr.Top + ((btnr.Bottom - btnr.Top) - AElement.ButtonSize) / 2 + AElement.ButtonSize));

        e := Integer(FExpandState);
        c := Integer(FCompactState);

        case I of
          pbCompact: AGraphics.DrawCompactButton(br, TTMSFNCGraphicsCompactState(c), AButtonStates[Integer(I)] = bsDown, AButtonStates[Integer(I)] = bsHover, True, AdaptToStyle, PaintScaleFactor);
          pbClose: AGraphics.DrawCloseButton(br, AButtonStates[Integer(I)] = bsDown, AButtonStates[Integer(I)] = bsHover, True, AdaptToStyle, PaintScaleFactor);
          pbExpander: AGraphics.DrawExpanderButton(br, TTMSFNCGraphicsExpanderState(e), AButtonStates[Integer(I)] = bsDown, AButtonStates[Integer(I)] = bsHover, True, AdaptToStyle, PaintScaleFactor);
          pbDropDown: AGraphics.DrawDropDownButton(br, AButtonStates[Integer(I)] = bsDown, AButtonStates[Integer(I)] = bsHover, True, True, AdaptToStyle, PaintScaleFactor);
        end;

        btnr.Right := btnr.Right - AElement.ButtonSpacing - AElement.ButtonSize;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomPanel.DrawSections(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
var
  i: Integer;
  sec: TTMSFNCPanelSection;
  y: Single;
  br: TRectF;
begin
  y := 0;
  AGraphics.Fill.Assign(SectionsAppearance.Fill);
  AGraphics.Stroke.Assign(SectionsAppearance.Stroke);
  AGraphics.Font.AssignSource(SectionsAppearance.Font);
  for i := 0 to Sections.Count - 1 do
  begin
    sec := Sections[i];
    br := RectF(ARect.Left, ARect.Top + y, ARect.Right, ARect.Top + y + Max(MINSECTIONHEIGHT, AGraphics.CalculateTextHeight(sec.Text) + 4));
    AGraphics.DrawRectangle(br, gcrmShiftLeftAndExpandHeight);
    InflateRectEx(br, -2, -2);
    AGraphics.DrawText(br, sec.Text);
    y := y + sec.Size;
  end;
end;

procedure TTMSFNCCustomPanel.Expand(ACompact: Boolean = False);
begin
  if ACompact then
  begin
    if FCompactState = pcsCollapsed then
    begin
      Width := Round(FOldWidth);
      FCompactState := pcsExpanded;
    end;
  end
  else
  begin
    if FExpandState = pesCollapsed then
    begin
      Height := Round(FOldHeight);
      FExpandState := pesExpanded;
    end;
  end;
end;

procedure TTMSFNCCustomPanel.FooterChanged(Sender: TObject);
begin
  {$IFDEF FMXLIB}
  if Footer.Visible then
    Padding.Bottom := Footer.Size
  else
    Padding.Bottom := 0;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  Realign;
  {$ENDIF}
  Invalidate;
end;

procedure TTMSFNCCustomPanel.ApplyStyle;
var
  c: TTMSFNCGraphicsColor;
begin
  inherited;

  Fill.Kind := gfkNone;

  Header.Stroke.Color := Stroke.Color;
  Footer.Stroke.Color := Stroke.Color;

  c := gcNull;
  if TTMSFNCStyles.GetStyleHeaderFillColor(c) then
  begin
    Header.Fill.Kind := gfkSolid;
    Header.Fill.Color := c;
    Footer.Fill.Kind := gfkSolid;
    Footer.Fill.Color := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleHeaderFillColorTo(c) then
  begin
    Header.Fill.Kind := gfkGradient;
    Header.Fill.ColorTo := c;
    Footer.Fill.Kind := gfkGradient;
    Footer.Fill.ColorTo := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleTextFontColor(c) then
  begin
    Header.Font.Color := c;
    Footer.Font.Color := c;
  end;
end;

procedure TTMSFNCCustomPanel.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TTMSFNCCustomPanel) then
  begin
    FHeader.Assign((Source as TTMSFNCCustomPanel).Header);
    FFooter.Assign((Source as TTMSFNCCustomPanel).Footer);
    FCloseAction := (Source as TTMSFNCCustomPanel).CloseAction;
    FDropDownAction := (Source as TTMSFNCCustomPanel).DropDownAction;
    FExpandAction := (Source as TTMSFNCCustomPanel).ExpandAction;
    FCompactAction := (Source as TTMSFNCCustomPanel).CompactAction;
  end;
end;

function TTMSFNCCustomPanel.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FBitmapContainer;
end;

function TTMSFNCCustomPanel.GetButtonRect(ARect: TRectF;
  AElement: TTMSFNCPanelElement; AButton: TTMSFNCPanelButton): TRectF;
var
  I: TTMSFNCPanelButton;
  br: TRectF;
  btnr: TRectF;
begin
  btnr := GetButtonsRect(ARect, AElement);
  for I := Low(TTMSFNCPanelButton) to High(TTMSFNCPanelButton) do
  begin
    if (I in AElement.Buttons) and (((I = pbCompact) and (FCompactState = pcsCollapsed)) or (FCompactState = pcsExpanded)) then
    begin
      br := RectF(Round(btnr.Right - AElement.ButtonSpacing - AElement.ButtonSize), Round(btnr.Top + ((btnr.Bottom - btnr.Top) - AElement.ButtonSize) / 2), Round(btnr.Right - AElement.ButtonSpacing),
        Round(btnr.Top + ((btnr.Bottom - btnr.Top) - AElement.ButtonSize) / 2 + AElement.ButtonSize));
      btnr.Right := btnr.Right - AElement.ButtonSpacing - AElement.ButtonSize;
      if I = AButton then
      begin
        Result := br;
      end;
    end;
  end;
end;

function TTMSFNCCustomPanel.GetButtonsRect(ARect: TRectF; AElement: TTMSFNCPanelElement): TRectF;
var
  I: TTMSFNCPanelButton;
begin
  if AElement = Header then
    Result := RectF(ARect.Right, ARect.Top, ARect.Right, ARect.Top + AElement.Size)
  else if AElement = Footer then
    Result := RectF(ARect.Right, ARect.Bottom - AElement.Size, ARect.Right, ARect.Bottom);

  if AElement.ShowButtons and (AElement.Buttons <> []) then
  begin
    for I := Low(TTMSFNCPanelButton) to High(TTMSFNCPanelButton) do
      if (I in AElement.Buttons) and (((I = pbCompact) and (FCompactState = pcsCollapsed)) or (FCompactState = pcsExpanded)) then
        Result.Left := Result.Left - AElement.ButtonSpacing - AElement.ButtonSize;
  end;
end;

function TTMSFNCCustomPanel.GetCollapsedHeight: Single;
begin
  Result := 0;
  if Header.Visible then
    Result := Result + Header.Size;

  if Footer.Visible then
    Result := Result + Footer.Size;
end;

function TTMSFNCCustomPanel.GetCollapsedWidth: Single;
begin
  Result := Header.ButtonSize + Header.ButtonSpacing * 2;
end;

function TTMSFNCCustomPanel.GetElementRect(ARect: TRectF;
  AElement: TTMSFNCPanelElement): TRectF;
begin
  if AElement = Header then
    Result := RectF(ARect.Left, ARect.Top, ARect.Right, ARect.Top + AElement.Size)
  else if AElement = Footer then
    Result := RectF(ARect.Left, ARect.Bottom - AElement.Size, ARect.Right, ARect.Bottom);
end;

function TTMSFNCCustomPanel.GetSectionRect(ARect: TRectF): TRectF;
begin
  Result := ARect;
  if Header.Visible then
    Result.Top := Result.Top + Header.Size;

  if Footer.Visible then
    Result.Bottom := Result.Bottom - Footer.Size;
end;

function TTMSFNCCustomPanel.GetTextRect(ARect: TRectF; AElement: TTMSFNCPanelElement): TRectF;
var
  x: Single;
begin
  if AElement.StretchText then
    x := ARect.Right
  else
    x := GetButtonsRect(ARect, AElement).Left;

  if AElement = Header then
    Result := RectF(ARect.Left, ARect.Top, x, ARect.Top + AElement.Size)
  else if AElement = Footer then
    Result := RectF(ARect.Left, ARect.Bottom - AElement.Size, x, ARect.Bottom);
end;

procedure TTMSFNCCustomPanel.HandleCloseAction(AElement: TTMSFNCPanelElement);
begin
  case CloseAction of
    {$IFNDEF LCLLIB}
    {$IFNDEF WEBLIB}
    pcaFree: Self.DisposeOf;
    {$ENDIF}
    {$ENDIF}
    {$IFDEF LCLLIB}
    pcaFree: Self.Free;
    {$ENDIF}
    pcaHide: Visible := False;
  end;

  if AElement = Header then
    DoHeaderCloseButtonClick
  else if AElement = Footer then
    DoFooterCloseButtonClick
end;

procedure TTMSFNCCustomPanel.HandleCompactAction(AElement: TTMSFNCPanelElement);
begin
  case CompactAction of
    pcCompact:
    begin
      case FCompactState of
        pcsCollapsed: Expand(True);
        pcsExpanded: Collapse(True);
      end;
    end;
  end;

  if AElement = Header then
    DoHeaderCompactButtonClick
  else if AElement = Footer then
    DoFooterCompactButtonClick
end;

procedure TTMSFNCCustomPanel.HandleDropDownAction(AButton: TTMSFNCPanelButton; AElement: TTMSFNCPanelElement);
var
  br: TRectF;
  pt: TPointF;
begin
  case DropDownAction of
    pddaPopup:
    begin
      if Assigned(AElement.DropDownControl) then
      begin
        br := GetButtonRect(LocalRect, AElement, AButton);
        pt := LocalToScreenEx(PointF(br.Left, br.Top + (br.Bottom - br.Top)));
        AElement.DropDownControl.Width := Round(AElement.DropDownWidth);
        AElement.DropDownControl.Height := Round(AElement.DropDownHeight);
        FPopup.DropDownHeight := AElement.DropDownHeight;
        FPopup.DropDownWidth := AElement.DropDownWidth;
        FPopup.ContentControl := AElement.DropDownControl;
        FPopup.FocusedControl := AElement.DropDownControl;
        FPopup.PlacementControl := Self;

        FPopup.PlacementRectangle.Left := pt.X;
        FPopup.PlacementRectangle.Top := pt.Y;
        FPopup.PlacementRectangle.Right := pt.X + FPopup.DropDownWidth;
        FPopup.PlacementRectangle.Bottom := pt.Y + FPopup.DropDownHeight;

        FPopup.Popup;
      end
    end;
  end;

  if AElement = Header then
    DoHeaderDropDownButtonClick
  else if AElement = Footer then
    DoFooterDropDownButtonClick
end;

procedure TTMSFNCCustomPanel.HandleExpandAction(AElement: TTMSFNCPanelElement);
begin
  case ExpandAction of
    peaExpand:
    begin
      case FExpandState of
        pesCollapsed: Expand;
        pesExpanded: Collapse;
      end;
    end;
  end;

  if AElement = Header then
    DoHeaderExpandButtonClick
  else if AElement = Footer then
    DoFooterExpandButtonClick
end;

procedure TTMSFNCCustomPanel.HandleMouseDown(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
var
  hb, fb: Boolean;
  hbtn, fbtn: TTMSFNCPanelButton;
begin
  inherited;
  FHeaderDownButton.Down := False;
  FFooterDownButton.Down := False;
  fb := False;
  hbtn := pbClose;
  hb := XYToButton(X, Y, Header, hbtn);
  if not hb then
  begin
    fbtn := pbClose;
    fb := XYToButton(X, Y, Footer, fbtn);
    if fb then
    begin
      FFooterButtonStates[Integer(fbtn)] := bsDown;
      FFooterDownButton.Down := True;
      FFooterDownButton.Button := fbtn;
    end;
  end
  else
  begin
    FHeaderButtonStates[Integer(hbtn)] := bsDown;
    FHeaderDownButton.Down := True;
    FHeaderDownButton.Button := hbtn;
  end;

  if not hb and not fb then
  begin
    FHeaderAnchor := XYToAnchor(X, Y, Header);
    if FHeaderAnchor = '' then
      FFooterAnchor := XYToAnchor(X, Y, Footer)
  end;

  if fb or hb then
    Invalidate;
end;

procedure TTMSFNCCustomPanel.HandleMouseLeave;
begin
  inherited;
  if ClearButtonStates then
    Invalidate;
end;

procedure TTMSFNCCustomPanel.HandleMouseMove(Shift: TShiftState; X, Y: Single);
var
  h, f: String;
  hb, fb, c: Boolean;
  hbtn, fbtn: TTMSFNCPanelButton;
begin
  inherited;
  c := False;
  hb := False;
  fb := False;
  if not FHeaderDownButton.Down and not FFooterDownButton.Down then
  begin
    c := ClearButtonStates(True);
    fb := False;
    hbtn := pbClose;
    hb := XYToButton(X, Y, Header, hbtn);
    if not hb then
    begin
      fbtn := pbClose;
      fb := XYToButton(X, Y, Footer, fbtn);
      if fb then
        FFooterButtonStates[Integer(fbtn)] := bsHover;
    end
    else
      FHeaderButtonStates[Integer(hbtn)] := bsHover;
  end;

  if not hb and not fb then
  begin
    h := XYToAnchor(X, Y, Header);
    if h = '' then
      f := XYToAnchor(X, Y, Footer);

    if (h <> '') or (f <> '') then
      Cursor := crHandPoint
    else
      Cursor := crDefault;
  end;

  if fb or hb or c then
    Invalidate;
end;

procedure TTMSFNCCustomPanel.HandleMouseUp(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
var
  hb, fb: Boolean;
  hbtn, fbtn: TTMSFNCPanelButton;
begin
  inherited;
  if (FFooterAnchor = '') and (FHeaderAnchor = '') then
  begin
    hbtn := pbClose;
    hb := XYToButton(X, Y, Header, hbtn);
    if not hb then
    begin
      fbtn := pbClose;
      fb := XYToButton(X, Y, Footer, fbtn);
      if fb and (fbtn = FFooterDownButton.Button) and FFooterDownButton.Down then
      begin
        case fbtn of
          pbCompact: HandleCompactAction(Footer);
          pbClose: HandleCloseAction(Footer);
          pbExpander: HandleExpandAction(Header);
          pbDropDown: HandleDropDownAction(fbtn, Footer);
        end;
      end;
    end
    else if (hbtn = FHeaderDownButton.Button) and FHeaderDownButton.Down then
    begin
      case hbtn of
        pbCompact: HandleCompactAction(Header);
        pbClose: HandleCloseAction(Header);
        pbExpander: HandleExpandAction(Header);
        pbDropDown: HandleDropDownAction(hbtn, Header);
      end;
    end;
  end
  else
  begin
    if FHeaderAnchor <> '' then
      DoHeaderAnchorClick(FHeaderAnchor);
    if FFooterAnchor <> '' then
      DoFooterAnchorClick(FFooterAnchor);
  end;

  FFooterAnchor := '';
  FHeaderAnchor := '';
  FHeaderDownButton.Down := False;
  FFooterDownButton.Down := False;
  if ClearButtonStates then
    Invalidate;
end;

procedure TTMSFNCCustomPanel.HeaderChanged(Sender: TObject);
begin
  {$IFDEF FMXLIB}
  if Header.Visible then
    Padding.Top := Header.Size
  else
    Padding.Top := 0;
  {$ENDIF}
  {$IFDEF CMNWEBLIB}
  Realign;
  {$ENDIF}
  Invalidate;
end;

function TTMSFNCCustomPanel.IsAppearanceProperty(AObject: TObject;
  APropertyName: string; APropertyType: TTypeKind): Boolean;
begin
  Result := inherited IsAppearanceProperty(AObject, APropertyName, APropertyType);
  Result := Result or (APropertyName = 'Header') or (APropertyName = 'Footer');
end;

type
  TControlOpen = class(TControl);

procedure TTMSFNCCustomPanel.Loaded;
begin
  inherited;
end;

procedure TTMSFNCCustomPanel.Notification(AComponent: TComponent;
  Operation: TOperation);
var
  I: Integer;
begin
  inherited;
  if not (csDestroying in ComponentState) and (Operation = opRemove) and (AComponent = FBitmapContainer) then
    FBitmapContainer := nil;

  if not (csDestroying in ComponentState) and (Operation = opRemove) then
  begin
    for i := 0 to Sections.Count - 1 do
    begin
      if AComponent = Sections[i].FControl then
        Sections[i].FControl := nil;
    end;
  end;
end;

procedure TTMSFNCCustomPanel.ResetToDefaultStyle;
begin
  inherited;
  Fill.Kind := gfkSolid;
  Header.Stroke.Color := gcSilver;
  Footer.Stroke.Color := gcSilver;
  Header.Fill.Kind := gfkSolid;
  Header.Fill.Color := MakeGraphicsColor(228, 228, 228);
  Footer.Fill.Kind := gfkSolid;
  Footer.Fill.Color := MakeGraphicsColor(228, 228, 228);
  Header.Font.Color := gcBlack;
  Footer.Font.Color := gcBlack;
end;

procedure TTMSFNCCustomPanel.Restore;
begin
  Expand(False);
  Expand(True);
end;

procedure TTMSFNCCustomPanel.SectionsAppearanceChanged(Sender: TObject);
begin
  UpdateControlBounds;
  Invalidate;
end;

procedure TTMSFNCCustomPanel.SectionsChanged(Sender: TObject);
begin
  UpdateControlBounds;
  Invalidate;
end;

procedure TTMSFNCCustomPanel.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
begin
  FBitmapContainer := Value;
  Invalidate;
end;

procedure TTMSFNCCustomPanel.SetCloseAction(
  const Value: TTMSFNCPanelCloseAction);
begin
  FCloseAction := Value;
end;

procedure TTMSFNCCustomPanel.SetDropDownAction(
  const Value: TTMSFNCPanelDropDownAction);
begin
  FDropDownAction := Value;
end;

procedure TTMSFNCCustomPanel.SetCompactAction(
  const Value: TTMSFNCPanelCompactAction);
begin
  FCompactAction := Value;
end;

procedure TTMSFNCCustomPanel.SetExpandAction(
  const Value: TTMSFNCPanelExpandAction);
begin
  FExpandAction := Value;
end;

procedure TTMSFNCCustomPanel.SetFooter(const Value: TTMSFNCPanelFooter);
begin
  FFooter.Assign(Value);
end;

procedure TTMSFNCCustomPanel.SetHeader(const Value: TTMSFNCPanelHeader);
begin
  FHeader.Assign(Value);
end;

procedure TTMSFNCCustomPanel.SetSections(const Value: TTMSFNCPanelSections);
begin
  FSections.Assign(Value);
end;

procedure TTMSFNCCustomPanel.SetSectionsAppearance(
  const Value: TTMSFNCPanelSectionsAppearance);
begin
  FSectionsAppearance.Assign(Value);
end;

procedure TTMSFNCCustomPanel.UpdateControlAfterResize;
begin
  inherited;
  UpdateControlBounds;
end;

procedure TTMSFNCCustomPanel.UpdateControlBounds;
var
  I: Integer;
begin
  for I := 0 to Sections.Count - 1 do
    Sections[I].UpdateControlBounds;
end;

function TTMSFNCCustomPanel.XYToAnchor(X, Y: Single;
  AElement: TTMSFNCPanelElement): string;
var
  g: TTMSFNCGraphics;
  ha, va: TTMSFNCGraphicsTextAlign;
begin
  Result := '';
  if AElement.IsHTML then
  begin
    g := TTMSFNCGraphics.CreateBitmapCanvas;
    g.BeginScene;
    g.OptimizedHTMLDrawing := OptimizedHTMLDrawing;
    g.BitmapContainer := BitmapContainer;
    try
      g.Font.Assign(AElement.Font);
      ha := AElement.HorizontalTextAlign;
      va := AElement.VerticalTextAlign;
      Result := g.DrawText(GetTextRect(LocalRect, AElement), AElement.Text, AElement.WordWrapping, ha, va, AElement.Trimming, 0, -1, -1, True, True, X, Y);
    finally
      g.EndScene;
      g.Free;
    end;
  end;
end;

function TTMSFNCCustomPanel.XYToButton(X, Y: Single;
  AElement: TTMSFNCPanelElement; var AButton: TTMSFNCPanelButton): Boolean;
var
  I: TTMSFNCPanelButton;
  br: TRectF;
  btnr: TRectF;
begin
  Result := False;

  if not AElement.ShowButtons or (AElement.Buttons = []) then
    Exit;

  btnr := GetButtonsRect(LocalRect, AElement);
  for I := Low(TTMSFNCPanelButton) to High(TTMSFNCPanelButton) do
  begin
    if (I in AElement.Buttons) and (((I = pbCompact) and (FCompactState = pcsCollapsed)) or (FCompactState = pcsExpanded)) then
    begin
      br := RectF(btnr.Right - AElement.ButtonSpacing - AElement.ButtonSize, btnr.Top + ((btnr.Bottom - btnr.Top) - AElement.ButtonSize) / 2, btnr.Right - AElement.ButtonSpacing,
        btnr.Top + ((btnr.Bottom - btnr.Top) - AElement.ButtonSize) / 2 + AElement.ButtonSize);

      if PtInRectEx(br, PointF(X, Y)) then
      begin
        AButton := I;
        Result := True;
        Break;
      end;

      btnr.Right := btnr.Right - AElement.ButtonSpacing - AElement.ButtonSize;
    end;
  end;
end;

{ TTMSFNCPanel }

function TTMSFNCPanel.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCPanel.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCPanel);
end;

{ TTMSFNCPanelElement }

procedure TTMSFNCPanelElement.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCPanelElement) then
  begin
    FText := (Source as TTMSFNCPanelElement).Text;
    FStretchText := (Source as TTMSFNCPanelElement).StretchText;
    FSize := (Source as TTMSFNCPanelElement).Size;
    FHorizontalTextAlign := (Source as TTMSFNCPanelElement).HorizontalTextAlign;
    FVerticalTextAlign := (Source as TTMSFNCPanelElement).VerticalTextAlign;
    FWordWrapping := (Source as TTMSFNCPanelElement).WordWrapping;
    FTrimming := (Source as TTMSFNCPanelElement).Trimming;
    FFont.AssignSource((Source as TTMSFNCPanelElement).Font);
    FShowButtons := (Source as TTMSFNCPanelElement).ShowButtons;
    FButtons := (Source as TTMSFNCPanelElement).Buttons;
    FVisible := (Source as TTMSFNCPanelElement).Visible;
    FButtonSpacing := (Source as TTMSFNCPanelElement).ButtonSpacing;
    FButtonSize := (Source as TTMSFNCPanelElement).ButtonSize;
    FStretchText := (Source as TTMSFNCPanelElement).StretchText;
    FDropDownWidth := (Source as TTMSFNCPanelElement).DropDownWidth;
    FDropDownHeight := (Source as TTMSFNCPanelElement).DropDownHeight;
    FFill.Assign((Source as TTMSFNCPanelElement).Fill);
    FStroke.Assign((Source as TTMSFNCPanelElement).Stroke);
  end;
end;

procedure TTMSFNCPanelElement.Changed;
begin
  if Assigned(OnChanged) then
    OnChanged(Self);
end;

procedure TTMSFNCPanelElement.ChangeDPIScale(M, D: Integer);
begin
  FSize := TTMSFNCUtils.MulDivSingle(FSize, M, D);
  FFont.Height := TTMSFNCUtils.MulDivInt(FFont.Height, M, D);

  FButtonSpacing := TTMSFNCUtils.MulDivSingle(FButtonSpacing, M, D);
  FButtonSize := TTMSFNCUtils.MulDivSingle(FButtonSize, M, D);
  FDropDownHeight := TTMSFNCUtils.MulDivSingle(FDropDownHeight, M, D);
  FDropDownWidth := TTMSFNCUtils.MulDivSingle(FDropDownWidth, M, D);
end;

constructor TTMSFNCPanelElement.Create;
begin
  FSize := 28;
  FVisible := True;
  FStretchText := True;
  FHorizontalTextAlign := gtaCenter;
  FVerticalTextAlign := gtaCenter;
  FWordWrapping := True;
  FTrimming := gttNone;
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FontChanged;
  FShowButtons := True;
  FButtons := [];
  FButtonSpacing := 4;
  FButtonSize := 20;
  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, MakeGraphicsColor(228, 228, 228));
  FFill.OnChanged := @FillChanged;
  FStroke := TTMSFNCGraphicsStroke.Create;
  FStroke.OnChanged := @StrokeChanged;
  FDropDownWidth := 100;
  FDropDownHeight := 120;
end;

destructor TTMSFNCPanelElement.Destroy;
begin
  FFont.Free;
  FFill.Free;
  FStroke.Free;
  inherited;
end;

procedure TTMSFNCPanelElement.FillChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCPanelElement.FontChanged(Sender: TObject);
begin
  Changed;
end;

function TTMSFNCPanelElement.IsButtonSizeStored: Boolean;
begin
  Result := ButtonSize <> 20;
end;

function TTMSFNCPanelElement.IsButtonSpacingStored: Boolean;
begin
  Result := ButtonSpacing <> 4;
end;

function TTMSFNCPanelElement.IsDropDownHeightStored: Boolean;
begin
  Result := DropDownHeight <> 120;
end;

function TTMSFNCPanelElement.IsDropDownWidthStored: Boolean;
begin
  Result := DropDownWidth <> 100;
end;

function TTMSFNCPanelElement.IsHTML: Boolean;
begin
  Result := TTMSFNCUtils.IsHTML(Text);
end;

function TTMSFNCPanelElement.IsSizeStored: Boolean;
begin
  Result := Size <> 28;
end;

procedure TTMSFNCPanelElement.SetButtons(const Value: TTMSFNCPanelButtons);
begin
  if FButtons <> Value then
  begin
    FButtons := Value;
    Changed;
  end;
end;

procedure TTMSFNCPanelElement.SetButtonSize(const Value: Single);
begin
  if FButtonSize <> Value then
  begin
    FButtonSize := Value;
    Changed;
  end;
end;

procedure TTMSFNCPanelElement.SetButtonSpacing(const Value: Single);
begin
  if FButtonSpacing <> Value then
  begin
    FButtonSpacing := Value;
    Changed;
  end;
end;

procedure TTMSFNCPanelElement.SetDropDownControl(const Value: TControl);
begin
  if Value = nil then
  begin
    if Assigned(FDropDownControl) then
      FDropDownControl.Visible := True;
  end;

  FDropDownControl := Value;
  if Assigned(FDropDownControl) then
  begin
    FDropDownControl.Visible := False;
    DropDownWidth := FDropDownControl.Width;
    DropDownHeight := FDropDownControl.Height;
  end;
end;

procedure TTMSFNCPanelElement.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  FFill.Assign(Value);
end;

procedure TTMSFNCPanelElement.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  FFont.AssignSource(Value);
end;

procedure TTMSFNCPanelElement.SetHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FHorizontalTextAlign <> Value then
  begin
    FHorizontalTextAlign := Value;
    Changed;
  end;
end;

procedure TTMSFNCPanelElement.SetShowButtons(const Value: Boolean);
begin
  if FShowButtons <> Value then
  begin
    FShowButtons := Value;
    Changed;
  end;
end;

procedure TTMSFNCPanelElement.SetSize(const Value: Single);
begin
  if FSize <> Value then
  begin
    FSize := Value;
    Changed;
  end;
end;

procedure TTMSFNCPanelElement.SetStretchText(const Value: Boolean);
begin
  if FStretchText <> Value then
  begin
    FStretchText := Value;
    Changed;
  end;
end;

procedure TTMSFNCPanelElement.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  FStroke.Assign(Value);
end;

procedure TTMSFNCPanelElement.SetText(const Value: string);
begin
  if FText <> Value then
  begin
    FText := Value;
    Changed;
  end;
end;

procedure TTMSFNCPanelElement.SetTrimming(
  const Value: TTMSFNCGraphicsTextTrimming);
begin
  if FTrimming <> Value then
  begin
    FTrimming := Value;
    Changed;
  end;
end;

procedure TTMSFNCPanelElement.SetVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FVerticalTextAlign <> Value then
  begin
    FVerticalTextAlign := Value;
    Changed;
  end;
end;

procedure TTMSFNCPanelElement.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    Changed;
  end;
end;

procedure TTMSFNCPanelElement.SetWordWrapping(const Value: Boolean);
begin
  if FWordWrapping <> Value then
  begin
    FWordWrapping := Value;
    Changed;
  end;
end;

procedure TTMSFNCPanelElement.StrokeChanged(Sender: TObject);
begin
  Changed;
end;

{ TTMSFNCPanelFooter }

constructor TTMSFNCPanelFooter.Create;
begin
  inherited;
  FShowButtons := False;
  FVisible := False;
end;

{ TTMSFNCPanelSection }

procedure TTMSFNCPanelSection.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCPanelSection then
  begin
    FText := (Source as TTMSFNCPanelSection).Text;
    FSize := (Source as TTMSFNCPanelSection).Size;
  end;
end;

procedure TTMSFNCPanelSection.Changed;
begin
  if Assigned(FOwner) then
    FOwner.Invalidate;
end;

constructor TTMSFNCPanelSection.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(Collection) then
    FOwner := (Collection as TTMSFNCPanelSections).FOwner;
  FSize := 48;
  Changed;
end;

destructor TTMSFNCPanelSection.Destroy;
begin
  inherited;
  Changed;
end;

function TTMSFNCPanelSection.IsSizeStored: Boolean;
begin
  Result := Size <> 48;
end;

procedure TTMSFNCPanelSection.SetControl(const Value: TControl);
var
  i: Integer;
  Panel: TTMSFNCCustomPanel;
begin
  if (FControl <> Value) then
  begin

    if Assigned(Value) then
    begin
      Panel := FOwner;
      if Assigned(Panel) then
      begin
        for i := 0 to Panel.Sections.Count - 1 do
        begin
          if Panel.Sections[i].Control = Value then
            Panel.Sections[i].Control := nil;
        end;

        if Value.Parent <> Panel then
          Value.Parent := Panel;
      end;
    end;

    FControl := Value;

    if Assigned(FControl) then
      UpdateControlBounds;
  end;
end;

procedure TTMSFNCPanelSection.SetSize(const Value: Single);
begin
  if FSize <> Value then
  begin
    FSize := Value;
    Changed;
  end;
end;

procedure TTMSFNCPanelSection.SetText(const Value: string);
begin
  if FText <> Value then
  begin
    FText := Value;
    Changed;
  end;
end;

procedure TTMSFNCPanelSection.UpdateControlBounds;
var
  i: Integer;
  ARect: TRectF;
  Panel: TTMSFNCCustomPanel;
  g: TTMSFNCGraphics;
  h: Single;
begin
  if Assigned(FControl) then
  begin
    Panel := FOwner;
    if Assigned(Panel) then
    begin
      ARect := Panel.GetSectionRect(Panel.LocalRect);

      for i := 0 to Index - 1 do
        ARect.Top := ARect.Top + Panel.Sections[i].FSize;

      g := TTMSFNCGraphics.CreateBitmapCanvas;
      g.BeginScene;
      try
        g.Font.AssignSource(Panel.SectionsAppearance.Font);
        h := Max(MINSECTIONHEIGHT, g.CalculateTextHeight(Text) + 4);
      finally
        g.EndScene;
        g.Free;
      end;

      ARect.Top := ARect.Top + h - 1;

      if Index <> Panel.Sections.Count - 1 then
        ARect.Bottom := ARect.Top + FSize - h + 1;

      if not EqualRectEx(ConvertToRectF(FControl.BoundsRect), ARect) then
      begin
        {$IFDEF CMNWEBLIB}
        FControl.BoundsRect := ConvertToRect(ARect);
        {$ELSE}
        FControl.BoundsRect := ARect;
        {$ENDIF}
        Panel.Invalidate;
      end;
    end;
  end;
end;

{ TTMSFNCPanelSections }

function TTMSFNCPanelSections.Add: TTMSFNCPanelSection;
begin
  Result := TTMSFNCPanelSection(inherited Add);
end;

procedure TTMSFNCPanelSections.Changed;
begin
  if Assigned(OnChanged) then
    OnChanged(Self);
end;

constructor TTMSFNCPanelSections.Create(AOwner: TTMSFNCCustomPanel);
begin
  inherited Create(AOwner, TTMSFNCPanelSection);
  FOwner := AOwner;
end;

function TTMSFNCPanelSections.GetItem(Index: Integer): TTMSFNCPanelSection;
begin
  Result := TTMSFNCPanelSection(inherited Items[Index]);
end;

function TTMSFNCPanelSections.Insert(index: Integer): TTMSFNCPanelSection;
begin
  Result := TTMSFNCPanelSection(inherited Insert(Index));
end;

procedure TTMSFNCPanelSections.SetItem(Index: Integer; const Value: TTMSFNCPanelSection);
begin
  inherited SetItem(Index, Value);
end;

{ TTMSFNCPanelSectionsAppearance }

procedure TTMSFNCPanelSectionsAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCPanelSectionsAppearance then
  begin
    FFill.Assign((Source as TTMSFNCPanelSectionsAppearance).Fill);
    FStroke.Assign((Source as TTMSFNCPanelSectionsAppearance).Stroke);
    FFont.AssignSource((Source as TTMSFNCPanelSectionsAppearance).Font);
  end;
end;

procedure TTMSFNCPanelSectionsAppearance.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCPanelSectionsAppearance.Create;
begin
  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FontChanged;
  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, MakeGraphicsColor(240, 240, 240));
  FFill.OnChanged := @FillChanged;
  FStroke := TTMSFNCGraphicsStroke.Create;
  FStroke.OnChanged := @StrokeChanged;
end;

destructor TTMSFNCPanelSectionsAppearance.Destroy;
begin
  FFill.Free;
  FStroke.Free;
  FFont.Free;
  inherited;
end;

procedure TTMSFNCPanelSectionsAppearance.FillChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCPanelSectionsAppearance.FontChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCPanelSectionsAppearance.SetFill(
  const Value: TTMSFNCGraphicsFill);
begin
  FFill.Assign(Value);
end;

procedure TTMSFNCPanelSectionsAppearance.SetFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FFont.AssignSource(Value);
end;

procedure TTMSFNCPanelSectionsAppearance.SetStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FStroke.Assign(Value);
end;

procedure TTMSFNCPanelSectionsAppearance.StrokeChanged(Sender: TObject);
begin
  Changed;
end;

{$IFDEF WEBLIB}
function TTMSFNCPanelButtonStates.GetItem(Index: Integer): TTMSFNCPanelButtonState;
begin
  Result := TTMSFNCPanelButtonState(inherited Items[Index]);
end;

procedure TTMSFNCPanelButtonStates.SetItem(Index: Integer; const Value: TTMSFNCPanelButtonState);
begin
  inherited Items[Index] := Value;
end;
{$ENDIF}

end.

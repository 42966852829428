unit KORRPROJECT;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, Vcl.Controls,
  VCL.TMSFNCCustomControl, VCL.TMSFNCPanel, WEBLib.ExtCtrls, VCL.TMSFNCToolBar,
  Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.REST;

type
  TForm7 = class(TForm)
    WebPanel1: TPanel;
    TMSFNCToolBarButton1: TTMSFNCToolBarButton;
    TMSFNCToolBarButton2: TTMSFNCToolBarButton;
    WebEdit1: TEdit;
    WebEdit2: TEdit;
    WebEdit3: TEdit;
    WebEdit4: TEdit;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebHttp: THttpRequest;
    WebEdit5: TEdit;
    WebLabel5: TLabel;
    WebEdit6: TEdit;
    WebLabel6: TLabel;
    procedure WebFormCreate(Sender: TObject);
    procedure SPARA(Sender: TObject);
    procedure TANGENTER(Sender: TObject; var Key: Char);
    procedure KEYTEST(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure AVSLUTA(Sender: TObject);
  private
    { Private declarations }
    rekno: String;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form7: TForm7;

implementation

Uses FRONT;

{$R *.dfm}

procedure TForm7.WebFormCreate(Sender: TObject);
  Begin
    WebEdit1.Text:=Form1.katn;
    WebEdit2.Text:=Form1.proj;
    WebEdit3.Text:=Form1.typs;
    WebEdit4.Text:=Form1.webp;
    WebEdit5.Text:=Form1.namn;
    WebEdit6.Text:=Form1.tele;

    rekno:=Form1.PARAM_P;
    Form1.PARAM_P:='';

    WebEdit1.SetFocus;
  End;

procedure TForm7.SPARA(Sender: TObject);
  Var a,b,c,d,e,f,inf,web,rek,path: String;
  Begin
    Form1.katn:=Trim(WebEdit1.Text);
    Form1.proj:=Trim(WebEdit2.Text);
    Form1.typs:=Trim(WebEdit3.Text);
    Form1.webp:=Trim(WebEdit4.Text);
    Form1.namn:=Trim(WebEdit5.Text);
    Form1.tele:=Trim(WebEdit6.Text);

    a:=Form1.katn;
    b:=Form1.proj;
    c:=Form1.typs;
    d:=Form1.webp;
    e:=Form1.namn;
    f:=Form1.tele;

    web:='https://www.connectsystem.se/';
    rek:=rekno;
    inf:=a+';'+b+';'+c+';'+d+';'+e+';'+f+';';

    path:=web+'dsidinfologg_korr.php?rek='+rek+'&inf='+inf;

    WebHttp.URL:=path;
    WebHttp.Execute;

    Form1.PARAM_P:='korrigerat';

    Close;
  End;

procedure TForm7.TANGENTER(Sender: TObject; var Key: Char);
  Var editnr: Integer;
  Begin
    If Ord(Key)=13 Then
      Begin
        Key:=#0;

        editnr:=(Sender as TEdit).Tag;

        Case editnr Of
          1: WebEdit2.SetFocus;
          2: WebEdit3.SetFocus;
          3: WebEdit4.SetFocus;
          4: WebEdit5.SetFocus;
          5: WebEdit6.SetFocus;
        End;
      End;
  End;

procedure TForm7.KEYTEST(Sender: TObject; var Key: Word; Shift: TShiftState);
  Begin
    IF Key=27 Then
      Close;

    IF Key=40 Then                          // pil ner
      Begin
        Case (Sender as TEdit).Tag Of
          1: WebEdit2.SetFocus;
          2: WebEdit3.SetFocus;
          3: WebEdit4.SetFocus;
          4: WebEdit5.SetFocus;
          5: WebEdit6.SetFocus;
        End;
      End;

    IF Key=38 Then                          // pil upp
      Begin
        Case (Sender as TEdit).Tag Of
          2: WebEdit1.SetFocus;
          3: WebEdit2.SetFocus;
          4: WebEdit3.SetFocus;
          5: WebEdit4.SetFocus;
          6: WebEdit5.SetFocus;
        End;
      End;
  End;

procedure TForm7.AVSLUTA(Sender: TObject);
  Begin
    Close;
  End;

procedure TForm7.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  TMSFNCToolBarButton1 := TTMSFNCToolBarButton.Create(Self);
  TMSFNCToolBarButton2 := TTMSFNCToolBarButton.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebEdit3 := TEdit.Create(Self);
  WebEdit4 := TEdit.Create(Self);
  WebEdit5 := TEdit.Create(Self);
  WebEdit6 := TEdit.Create(Self);
  WebHttp := THttpRequest.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  TMSFNCToolBarButton1.BeforeLoadDFMValues;
  TMSFNCToolBarButton2.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebEdit3.BeforeLoadDFMValues;
  WebEdit4.BeforeLoadDFMValues;
  WebEdit5.BeforeLoadDFMValues;
  WebEdit6.BeforeLoadDFMValues;
  WebHttp.BeforeLoadDFMValues;
  try
    Name := 'Form7';
    Width := 549;
    Height := 240;
    SetEvent(Self, 'OnClick', 'AVSLUTA');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(Self, 'OnKeyPress', 'TANGENTER');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 56;
    WebLabel1.Top := 18;
    WebLabel1.Width := 50;
    WebLabel1.Height := 16;
    WebLabel1.Caption := 'Number:';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -13;
    WebLabel1.Font.Name := 'Tahoma';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 60;
    WebLabel2.Top := 46;
    WebLabel2.Width := 45;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Project:';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -13;
    WebLabel2.Font.Name := 'Tahoma';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 78;
    WebLabel3.Top := 74;
    WebLabel3.Width := 27;
    WebLabel3.Height := 16;
    WebLabel3.Caption := 'Tpe:';
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -13;
    WebLabel3.Font.Name := 'Tahoma';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 49;
    WebLabel4.Top := 102;
    WebLabel4.Width := 56;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'Webpath:';
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -13;
    WebLabel4.Font.Name := 'Tahoma';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 68;
    WebLabel5.Top := 130;
    WebLabel5.Width := 38;
    WebLabel5.Height := 16;
    WebLabel5.Caption := 'Name:';
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clWindowText;
    WebLabel5.Font.Height := -13;
    WebLabel5.Font.Name := 'Tahoma';
    WebLabel5.Font.Style := [];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(Self);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 66;
    WebLabel6.Top := 160;
    WebLabel6.Width := 40;
    WebLabel6.Height := 16;
    WebLabel6.Caption := 'Phone:';
    WebLabel6.Font.Charset := DEFAULT_CHARSET;
    WebLabel6.Font.Color := clWindowText;
    WebLabel6.Font.Height := -13;
    WebLabel6.Font.Name := 'Tahoma';
    WebLabel6.Font.Style := [];
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.ParentFont := False;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 197;
    WebPanel1.Width := 549;
    WebPanel1.Height := 43;
    WebPanel1.HeightPercent := 100.000000000000000000;
    WebPanel1.WidthPercent := 100.000000000000000000;
    WebPanel1.Align := alBottom;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsNone;
    SetEvent(WebPanel1, Self, 'OnKeyDown', 'KEYTEST');
    TMSFNCToolBarButton1.SetParentComponent(WebPanel1);
    TMSFNCToolBarButton1.Name := 'TMSFNCToolBarButton1';
    TMSFNCToolBarButton1.Left := 12;
    TMSFNCToolBarButton1.Top := 10;
    TMSFNCToolBarButton1.Width := 100;
    TMSFNCToolBarButton1.Height := 24;
    TMSFNCToolBarButton1.ParentDoubleBuffered := False;
    TMSFNCToolBarButton1.DoubleBuffered := True;
    TMSFNCToolBarButton1.Font.Charset := DEFAULT_CHARSET;
    TMSFNCToolBarButton1.Font.Color := clWindowText;
    TMSFNCToolBarButton1.Font.Height := -12;
    TMSFNCToolBarButton1.Font.Name := 'Tahoma';
    TMSFNCToolBarButton1.Font.Style := [];
    TMSFNCToolBarButton1.ParentColor := True;
    TMSFNCToolBarButton1.TabOrder := 0;
    SetEvent(TMSFNCToolBarButton1, Self, 'OnClick', 'SPARA');
    SetEvent(TMSFNCToolBarButton1, Self, 'OnKeyDown', 'KEYTEST');
    TMSFNCToolBarButton1.Text := 'Save';
    TMSFNCToolBarButton1.Appearance.InnerStroke.Color := clWhite;
    TMSFNCToolBarButton1.Appearance.NormalFill.Kind := gfkGradient;
    TMSFNCToolBarButton1.Appearance.NormalFill.Color := 16579577;
    TMSFNCToolBarButton1.Appearance.NormalFill.ColorTo := 15461350;
    TMSFNCToolBarButton1.Appearance.NormalFill.ColorMirror := 15523036;
    TMSFNCToolBarButton1.Appearance.NormalFill.ColorMirrorTo := 15524316;
    TMSFNCToolBarButton1.Appearance.NormalStroke.Color := clGray;
    TMSFNCToolBarButton1.Appearance.HoverFill.Kind := gfkGradient;
    TMSFNCToolBarButton1.Appearance.HoverFill.Color := 15263717;
    TMSFNCToolBarButton1.Appearance.HoverFill.ColorTo := 14145490;
    TMSFNCToolBarButton1.Appearance.HoverFill.ColorMirror := 14207176;
    TMSFNCToolBarButton1.Appearance.HoverFill.ColorMirrorTo := 14208456;
    TMSFNCToolBarButton1.Appearance.HoverStroke.Color := clGray;
    TMSFNCToolBarButton1.Appearance.DownFill.Kind := gfkGradient;
    TMSFNCToolBarButton1.Appearance.DownFill.Color := 14605787;
    TMSFNCToolBarButton1.Appearance.DownFill.ColorTo := 13487560;
    TMSFNCToolBarButton1.Appearance.DownFill.ColorMirror := 13549246;
    TMSFNCToolBarButton1.Appearance.DownFill.ColorMirrorTo := 13550526;
    TMSFNCToolBarButton1.Appearance.DownStroke.Color := clGray;
    TMSFNCToolBarButton1.Appearance.DisabledFill.Color := 13882323;
    TMSFNCToolBarButton1.Appearance.DisabledStroke.Color := clGray;
    TMSFNCToolBarButton1.ControlIndex := 0;
    TMSFNCToolBarButton2.SetParentComponent(WebPanel1);
    TMSFNCToolBarButton2.Name := 'TMSFNCToolBarButton2';
    TMSFNCToolBarButton2.Left := 434;
    TMSFNCToolBarButton2.Top := 10;
    TMSFNCToolBarButton2.Width := 100;
    TMSFNCToolBarButton2.Height := 24;
    TMSFNCToolBarButton2.ParentDoubleBuffered := False;
    TMSFNCToolBarButton2.Anchors := [akTop,akRight];
    TMSFNCToolBarButton2.DoubleBuffered := True;
    TMSFNCToolBarButton2.Font.Charset := DEFAULT_CHARSET;
    TMSFNCToolBarButton2.Font.Color := clWindowText;
    TMSFNCToolBarButton2.Font.Height := -12;
    TMSFNCToolBarButton2.Font.Name := 'Tahoma';
    TMSFNCToolBarButton2.Font.Style := [];
    TMSFNCToolBarButton2.ParentColor := True;
    TMSFNCToolBarButton2.TabOrder := 1;
    SetEvent(TMSFNCToolBarButton2, Self, 'OnClick', 'AVSLUTA');
    SetEvent(TMSFNCToolBarButton2, Self, 'OnKeyDown', 'KEYTEST');
    TMSFNCToolBarButton2.Text := 'Cancel';
    TMSFNCToolBarButton2.Appearance.InnerStroke.Color := clWhite;
    TMSFNCToolBarButton2.Appearance.NormalFill.Kind := gfkGradient;
    TMSFNCToolBarButton2.Appearance.NormalFill.Color := 16579577;
    TMSFNCToolBarButton2.Appearance.NormalFill.ColorTo := 15461350;
    TMSFNCToolBarButton2.Appearance.NormalFill.ColorMirror := 15523036;
    TMSFNCToolBarButton2.Appearance.NormalFill.ColorMirrorTo := 15524316;
    TMSFNCToolBarButton2.Appearance.NormalStroke.Color := clGray;
    TMSFNCToolBarButton2.Appearance.HoverFill.Kind := gfkGradient;
    TMSFNCToolBarButton2.Appearance.HoverFill.Color := 15263717;
    TMSFNCToolBarButton2.Appearance.HoverFill.ColorTo := 14145490;
    TMSFNCToolBarButton2.Appearance.HoverFill.ColorMirror := 14207176;
    TMSFNCToolBarButton2.Appearance.HoverFill.ColorMirrorTo := 14208456;
    TMSFNCToolBarButton2.Appearance.HoverStroke.Color := clGray;
    TMSFNCToolBarButton2.Appearance.DownFill.Kind := gfkGradient;
    TMSFNCToolBarButton2.Appearance.DownFill.Color := 14605787;
    TMSFNCToolBarButton2.Appearance.DownFill.ColorTo := 13487560;
    TMSFNCToolBarButton2.Appearance.DownFill.ColorMirror := 13549246;
    TMSFNCToolBarButton2.Appearance.DownFill.ColorMirrorTo := 13550526;
    TMSFNCToolBarButton2.Appearance.DownStroke.Color := clGray;
    TMSFNCToolBarButton2.Appearance.DisabledFill.Color := 13882323;
    TMSFNCToolBarButton2.Appearance.DisabledStroke.Color := clGray;
    TMSFNCToolBarButton2.ControlIndex := 0;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Tag := 1;
    WebEdit1.Left := 134;
    WebEdit1.Top := 17;
    WebEdit1.Width := 120;
    WebEdit1.Height := 22;
    WebEdit1.Anchors := [akLeft,akTop,akRight];
    WebEdit1.ChildOrder := 1;
    WebEdit1.Font.Charset := DEFAULT_CHARSET;
    WebEdit1.Font.Color := clWindowText;
    WebEdit1.Font.Height := -13;
    WebEdit1.Font.Name := 'Tahoma';
    WebEdit1.Font.Style := [];
    WebEdit1.HeightPercent := 100.000000000000000000;
    WebEdit1.ParentFont := False;
    WebEdit1.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit1, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(WebEdit1, Self, 'OnKeyPress', 'TANGENTER');
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Tag := 2;
    WebEdit2.Left := 134;
    WebEdit2.Top := 45;
    WebEdit2.Width := 399;
    WebEdit2.Height := 22;
    WebEdit2.Anchors := [akLeft,akTop,akRight];
    WebEdit2.ChildOrder := 2;
    WebEdit2.Font.Charset := DEFAULT_CHARSET;
    WebEdit2.Font.Color := clWindowText;
    WebEdit2.Font.Height := -13;
    WebEdit2.Font.Name := 'Tahoma';
    WebEdit2.Font.Style := [];
    WebEdit2.HeightPercent := 100.000000000000000000;
    WebEdit2.ParentFont := False;
    WebEdit2.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit2, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(WebEdit2, Self, 'OnKeyPress', 'TANGENTER');
    WebEdit3.SetParentComponent(Self);
    WebEdit3.Name := 'WebEdit3';
    WebEdit3.Tag := 3;
    WebEdit3.Left := 134;
    WebEdit3.Top := 73;
    WebEdit3.Width := 60;
    WebEdit3.Height := 22;
    WebEdit3.Anchors := [akLeft,akTop,akRight];
    WebEdit3.ChildOrder := 3;
    WebEdit3.Font.Charset := DEFAULT_CHARSET;
    WebEdit3.Font.Color := clWindowText;
    WebEdit3.Font.Height := -13;
    WebEdit3.Font.Name := 'Tahoma';
    WebEdit3.Font.Style := [];
    WebEdit3.HeightPercent := 100.000000000000000000;
    WebEdit3.ParentFont := False;
    WebEdit3.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit3, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(WebEdit3, Self, 'OnKeyPress', 'TANGENTER');
    WebEdit4.SetParentComponent(Self);
    WebEdit4.Name := 'WebEdit4';
    WebEdit4.Tag := 4;
    WebEdit4.Left := 134;
    WebEdit4.Top := 101;
    WebEdit4.Width := 234;
    WebEdit4.Height := 22;
    WebEdit4.Anchors := [akLeft,akTop,akRight];
    WebEdit4.ChildOrder := 4;
    WebEdit4.Font.Charset := DEFAULT_CHARSET;
    WebEdit4.Font.Color := clWindowText;
    WebEdit4.Font.Height := -13;
    WebEdit4.Font.Name := 'Tahoma';
    WebEdit4.Font.Style := [];
    WebEdit4.HeightPercent := 100.000000000000000000;
    WebEdit4.ParentFont := False;
    WebEdit4.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit4, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(WebEdit4, Self, 'OnKeyPress', 'TANGENTER');
    WebEdit5.SetParentComponent(Self);
    WebEdit5.Name := 'WebEdit5';
    WebEdit5.Tag := 5;
    WebEdit5.Left := 134;
    WebEdit5.Top := 129;
    WebEdit5.Width := 170;
    WebEdit5.Height := 22;
    WebEdit5.Anchors := [akLeft,akTop,akRight];
    WebEdit5.ChildOrder := 4;
    WebEdit5.Font.Charset := DEFAULT_CHARSET;
    WebEdit5.Font.Color := clWindowText;
    WebEdit5.Font.Height := -13;
    WebEdit5.Font.Name := 'Tahoma';
    WebEdit5.Font.Style := [];
    WebEdit5.HeightPercent := 100.000000000000000000;
    WebEdit5.ParentFont := False;
    WebEdit5.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit5, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(WebEdit5, Self, 'OnKeyPress', 'TANGENTER');
    WebEdit6.SetParentComponent(Self);
    WebEdit6.Name := 'WebEdit6';
    WebEdit6.Tag := 6;
    WebEdit6.Left := 134;
    WebEdit6.Top := 157;
    WebEdit6.Width := 120;
    WebEdit6.Height := 22;
    WebEdit6.Anchors := [akLeft,akTop,akRight];
    WebEdit6.ChildOrder := 4;
    WebEdit6.Font.Charset := DEFAULT_CHARSET;
    WebEdit6.Font.Color := clWindowText;
    WebEdit6.Font.Height := -13;
    WebEdit6.Font.Name := 'Tahoma';
    WebEdit6.Font.Style := [];
    WebEdit6.HeightPercent := 100.000000000000000000;
    WebEdit6.ParentFont := False;
    WebEdit6.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit6, Self, 'OnKeyDown', 'KEYTEST');
    SetEvent(WebEdit6, Self, 'OnKeyPress', 'TANGENTER');
    WebHttp.SetParentComponent(Self);
    WebHttp.Name := 'WebHttp';
    WebHttp.Left := 16;
    WebHttp.Top := 40;
  finally
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    TMSFNCToolBarButton1.AfterLoadDFMValues;
    TMSFNCToolBarButton2.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebEdit3.AfterLoadDFMValues;
    WebEdit4.AfterLoadDFMValues;
    WebEdit5.AfterLoadDFMValues;
    WebEdit6.AfterLoadDFMValues;
    WebHttp.AfterLoadDFMValues;
  end;
end;

end.
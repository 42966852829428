unit KORREFERENS;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, Vcl.Controls,
  VCL.TMSFNCCustomControl, VCL.TMSFNCPanel, Vcl.StdCtrls, WEBLib.StdCtrls,
  VCL.TMSFNCToolBar, WEBLib.REST;

type
  TForm8 = class(TForm)
    TMSFNCPanel2: TTMSFNCPanel;
    WebEdit1: TEdit;
    TMSFNCToolBarButton1: TTMSFNCToolBarButton;
    TMSFNCToolBarButton2: TTMSFNCToolBarButton;
    WebHttpRequest1: THttpRequest;
    procedure TILL_DISK;
    procedure WebFormCreate(Sender: TObject);
    procedure KORRIGERA(Sender: TObject);
    procedure WebHttpRequest1RequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
    procedure KEYTEST(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure AVSLUTA(Sender: TObject);
  private
    { Private declarations }
    ypos: String;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form8: TForm8;

implementation

Uses FRONT;

{$R *.dfm}

procedure TForm8.TILL_DISK;
  Var wp,rek,inf,path: String;
  Begin
    wp:='https://www.connectsystem.se/';
    rek:=ypos;
    inf:=Trim(WebEdit1.Text);

    path:=wp+'dsidrefkorrkunder.php?rek='+rek+'&inf='+inf;

    WebHttpRequest1.URL:=path;
    WebHttpRequest1.Execute;
  End;

procedure TForm8.WebFormCreate(Sender: TObject);
  Begin
    WebEdit1.Text:=Form1.PARAM_P;
    ypos:=IntToStr(StrToInt(Form1.PARAM_R)+1);

    Form1.PARAM_P:='';
    Form1.PARAM_R:='';

    WebEdit1.SelStart:=100;

    WebEdit1.SetFocus;
  End;

procedure TForm8.KORRIGERA(Sender: TObject);
  Begin
    Form1.PARAM_U:='uppdaterat';
    Form1.PARAM_P:=Trim(WebEdit1.Text);
    TILL_DISK;
  End;

procedure TForm8.WebHttpRequest1RequestResponse(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; AResponse: string);
  Var svar: String;
  Begin
    svar:=AResponse;

    If svar='korrigerat' Then
      Close;
  End;

procedure TForm8.KEYTEST(Sender: TObject; var Key: Word; Shift: TShiftState);
  Begin
    If Key=27 Then
      Close
  End;

procedure TForm8.AVSLUTA(Sender: TObject);
  Begin
    Close;
  End;

procedure TForm8.LoadDFMValues;
begin
  inherited LoadDFMValues;

  TMSFNCPanel2 := TTMSFNCPanel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  TMSFNCToolBarButton1 := TTMSFNCToolBarButton.Create(Self);
  TMSFNCToolBarButton2 := TTMSFNCToolBarButton.Create(Self);
  WebHttpRequest1 := THttpRequest.Create(Self);

  TMSFNCPanel2.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  TMSFNCToolBarButton1.BeforeLoadDFMValues;
  TMSFNCToolBarButton2.BeforeLoadDFMValues;
  WebHttpRequest1.BeforeLoadDFMValues;
  try
    Name := 'Form8';
    Width := 348;
    Height := 150;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnKeyDown', 'KEYTEST');
    TMSFNCPanel2.SetParentComponent(Self);
    TMSFNCPanel2.Name := 'TMSFNCPanel2';
    TMSFNCPanel2.Left := 0;
    TMSFNCPanel2.Top := 0;
    TMSFNCPanel2.Width := 348;
    TMSFNCPanel2.Height := 30;
    TMSFNCPanel2.Align := alTop;
    TMSFNCPanel2.BevelInner := bvNone;
    TMSFNCPanel2.BevelOuter := bvNone;
    TMSFNCPanel2.ParentDoubleBuffered := False;
    TMSFNCPanel2.DoubleBuffered := True;
    TMSFNCPanel2.TabOrder := 0;
    SetEvent(TMSFNCPanel2, Self, 'OnKeyDown', 'KEYTEST');
    TMSFNCPanel2.SectionsAppearance.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.SectionsAppearance.Font.Color := clWindowText;
    TMSFNCPanel2.SectionsAppearance.Font.Height := -11;
    TMSFNCPanel2.SectionsAppearance.Font.Name := 'Tahoma';
    TMSFNCPanel2.SectionsAppearance.Font.Style := [];
    TMSFNCPanel2.SectionsAppearance.Fill.Color := 15790320;
    TMSFNCPanel2.Header.Text := ' Correction';
    TMSFNCPanel2.Header.HorizontalTextAlign := gtaLeading;
    TMSFNCPanel2.Header.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Header.Font.Color := clWindowText;
    TMSFNCPanel2.Header.Font.Height := -11;
    TMSFNCPanel2.Header.Font.Name := 'Tahoma';
    TMSFNCPanel2.Header.Font.Style := [];
    TMSFNCPanel2.Header.Fill.Color := clBtnFace;
    TMSFNCPanel2.Header.Buttons := [pbClose];
    TMSFNCPanel2.Footer.Text := 'Footer';
    TMSFNCPanel2.Footer.Font.Charset := DEFAULT_CHARSET;
    TMSFNCPanel2.Footer.Font.Color := clWindowText;
    TMSFNCPanel2.Footer.Font.Height := -11;
    TMSFNCPanel2.Footer.Font.Name := 'Tahoma';
    TMSFNCPanel2.Footer.Font.Style := [];
    TMSFNCPanel2.Footer.Fill.Color := 15000804;
    SetEvent(TMSFNCPanel2, Self, 'OnHeaderCloseButtonClick', 'AVSLUTA');
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 42;
    WebEdit1.Top := 64;
    WebEdit1.Width := 264;
    WebEdit1.Height := 22;
    WebEdit1.ChildOrder := 1;
    WebEdit1.Font.Charset := DEFAULT_CHARSET;
    WebEdit1.Font.Color := clWindowText;
    WebEdit1.Font.Height := -16;
    WebEdit1.Font.Name := 'Tahoma';
    WebEdit1.Font.Style := [];
    WebEdit1.HeightPercent := 100.000000000000000000;
    WebEdit1.ParentFont := False;
    WebEdit1.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit1, Self, 'OnKeyDown', 'KEYTEST');
    TMSFNCToolBarButton1.SetParentComponent(Self);
    TMSFNCToolBarButton1.Name := 'TMSFNCToolBarButton1';
    TMSFNCToolBarButton1.Left := 19;
    TMSFNCToolBarButton1.Top := 118;
    TMSFNCToolBarButton1.Width := 70;
    TMSFNCToolBarButton1.Height := 24;
    TMSFNCToolBarButton1.ParentDoubleBuffered := False;
    TMSFNCToolBarButton1.Anchors := [akLeft,akBottom];
    TMSFNCToolBarButton1.DoubleBuffered := True;
    TMSFNCToolBarButton1.Font.Charset := DEFAULT_CHARSET;
    TMSFNCToolBarButton1.Font.Color := clWindowText;
    TMSFNCToolBarButton1.Font.Height := -13;
    TMSFNCToolBarButton1.Font.Name := 'Tahoma';
    TMSFNCToolBarButton1.Font.Style := [];
    TMSFNCToolBarButton1.ParentColor := True;
    TMSFNCToolBarButton1.TabOrder := 2;
    SetEvent(TMSFNCToolBarButton1, Self, 'OnClick', 'KORRIGERA');
    SetEvent(TMSFNCToolBarButton1, Self, 'OnKeyDown', 'KEYTEST');
    TMSFNCToolBarButton1.Text := 'Save';
    TMSFNCToolBarButton1.Appearance.InnerStroke.Color := clWhite;
    TMSFNCToolBarButton1.Appearance.NormalFill.Kind := gfkGradient;
    TMSFNCToolBarButton1.Appearance.NormalFill.Color := 16579577;
    TMSFNCToolBarButton1.Appearance.NormalFill.ColorTo := 15461350;
    TMSFNCToolBarButton1.Appearance.NormalFill.ColorMirror := 15523036;
    TMSFNCToolBarButton1.Appearance.NormalFill.ColorMirrorTo := 15524316;
    TMSFNCToolBarButton1.Appearance.NormalStroke.Color := clGray;
    TMSFNCToolBarButton1.Appearance.HoverFill.Kind := gfkGradient;
    TMSFNCToolBarButton1.Appearance.HoverFill.Color := 15263717;
    TMSFNCToolBarButton1.Appearance.HoverFill.ColorTo := 14145490;
    TMSFNCToolBarButton1.Appearance.HoverFill.ColorMirror := 14207176;
    TMSFNCToolBarButton1.Appearance.HoverFill.ColorMirrorTo := 14208456;
    TMSFNCToolBarButton1.Appearance.HoverStroke.Color := clGray;
    TMSFNCToolBarButton1.Appearance.DownFill.Kind := gfkGradient;
    TMSFNCToolBarButton1.Appearance.DownFill.Color := 14605787;
    TMSFNCToolBarButton1.Appearance.DownFill.ColorTo := 13487560;
    TMSFNCToolBarButton1.Appearance.DownFill.ColorMirror := 13549246;
    TMSFNCToolBarButton1.Appearance.DownFill.ColorMirrorTo := 13550526;
    TMSFNCToolBarButton1.Appearance.DownStroke.Color := clGray;
    TMSFNCToolBarButton1.Appearance.DisabledFill.Color := 13882323;
    TMSFNCToolBarButton1.Appearance.DisabledStroke.Color := clGray;
    TMSFNCToolBarButton1.ControlIndex := 0;
    TMSFNCToolBarButton2.SetParentComponent(Self);
    TMSFNCToolBarButton2.Name := 'TMSFNCToolBarButton2';
    TMSFNCToolBarButton2.Left := 270;
    TMSFNCToolBarButton2.Top := 118;
    TMSFNCToolBarButton2.Width := 70;
    TMSFNCToolBarButton2.Height := 24;
    TMSFNCToolBarButton2.ParentDoubleBuffered := False;
    TMSFNCToolBarButton2.Anchors := [akRight,akBottom];
    TMSFNCToolBarButton2.DoubleBuffered := True;
    TMSFNCToolBarButton2.Font.Charset := DEFAULT_CHARSET;
    TMSFNCToolBarButton2.Font.Color := clWindowText;
    TMSFNCToolBarButton2.Font.Height := -13;
    TMSFNCToolBarButton2.Font.Name := 'Tahoma';
    TMSFNCToolBarButton2.Font.Style := [];
    TMSFNCToolBarButton2.ParentColor := True;
    TMSFNCToolBarButton2.TabOrder := 3;
    SetEvent(TMSFNCToolBarButton2, Self, 'OnClick', 'AVSLUTA');
    SetEvent(TMSFNCToolBarButton2, Self, 'OnKeyDown', 'KEYTEST');
    TMSFNCToolBarButton2.Text := 'Cancel';
    TMSFNCToolBarButton2.Appearance.InnerStroke.Color := clWhite;
    TMSFNCToolBarButton2.Appearance.NormalFill.Kind := gfkGradient;
    TMSFNCToolBarButton2.Appearance.NormalFill.Color := 16579577;
    TMSFNCToolBarButton2.Appearance.NormalFill.ColorTo := 15461350;
    TMSFNCToolBarButton2.Appearance.NormalFill.ColorMirror := 15523036;
    TMSFNCToolBarButton2.Appearance.NormalFill.ColorMirrorTo := 15524316;
    TMSFNCToolBarButton2.Appearance.NormalStroke.Color := clGray;
    TMSFNCToolBarButton2.Appearance.HoverFill.Kind := gfkGradient;
    TMSFNCToolBarButton2.Appearance.HoverFill.Color := 15263717;
    TMSFNCToolBarButton2.Appearance.HoverFill.ColorTo := 14145490;
    TMSFNCToolBarButton2.Appearance.HoverFill.ColorMirror := 14207176;
    TMSFNCToolBarButton2.Appearance.HoverFill.ColorMirrorTo := 14208456;
    TMSFNCToolBarButton2.Appearance.HoverStroke.Color := clGray;
    TMSFNCToolBarButton2.Appearance.DownFill.Kind := gfkGradient;
    TMSFNCToolBarButton2.Appearance.DownFill.Color := 14605787;
    TMSFNCToolBarButton2.Appearance.DownFill.ColorTo := 13487560;
    TMSFNCToolBarButton2.Appearance.DownFill.ColorMirror := 13549246;
    TMSFNCToolBarButton2.Appearance.DownFill.ColorMirrorTo := 13550526;
    TMSFNCToolBarButton2.Appearance.DownStroke.Color := clGray;
    TMSFNCToolBarButton2.Appearance.DisabledFill.Color := 13882323;
    TMSFNCToolBarButton2.Appearance.DisabledStroke.Color := clGray;
    TMSFNCToolBarButton2.ControlIndex := 0;
    WebHttpRequest1.SetParentComponent(Self);
    WebHttpRequest1.Name := 'WebHttpRequest1';
    SetEvent(WebHttpRequest1, Self, 'OnRequestResponse', 'WebHttpRequest1RequestResponse');
    WebHttpRequest1.Left := 144;
    WebHttpRequest1.Top := 104;
  finally
    TMSFNCPanel2.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    TMSFNCToolBarButton1.AfterLoadDFMValues;
    TMSFNCToolBarButton2.AfterLoadDFMValues;
    WebHttpRequest1.AfterLoadDFMValues;
  end;
end;

end.